<script>
/**
 * SubNavigation is a navigation within the view making it possible to switch between several sub views. Each navigationItem can be disabled with the help of the isDisabled property.
 * Your current location will be reloaded on refresh.
 * - navigationItems (required): A list of navigationItems with id, label, isDisabled and target object with route name
 * - defaultLocationId (optional): If provided, the corresponding navigationItem will be selected as default, but only if it is not disabled. Otherwise, the first navigationItem which is not disabled will be selected.
 * - useDefaultLocationOnRefresh (default: false): If, true the default location will always be the starting point, even after page refresh
 **/
export default {
    name: 'SubNavigation',
    props: {
        navigationItems: Array,
        defaultLocationId: String,
        useDefaultLocationOnRefresh: {
            default: false,
            type: Boolean
        }
    },
    data () {
        return {
        }
    },
    methods: {
        setDefaultLocation () {
            let defaultLocation = null
            const refreshed = !this.useDefaultLocationOnRefresh &&
                this.navigationItems.some(navItem => this.$route.name.includes(navItem.target.name))

            if (!refreshed) {
                if (this.defaultLocationId) {
                    const navItem = this.navigationItems.find(navItem => navItem.id === this.defaultLocationId && !navItem.isDisabled)
                    defaultLocation = navItem ? navItem.target : null
                }
                if (!defaultLocation || defaultLocation.name !== this.$router.currentRoute.value.name) {
                    this.$router.replace(defaultLocation || this.navigationItems.find(navItem => !navItem.isDisabled).target)
                }
            }
        },

        isSelected (location) {
            return this.$route.name.includes(location.target.name)
        }
    },
    mounted () {
        this.setDefaultLocation()
    }
}
</script>

<template>
    <div class="c_sub-navigation-wrapper">
        <router-link v-for="navItem in navigationItems"
                     v-bind:key="navItem.id"
                     class="c_sub-navigation-item"
                     v-bind:class="{'m--selected': isSelected(navItem), 'm--disabled': navItem.isDisabled}"
                     v-bind:style="{width: 100 / navigationItems.length + '%'}"
                     v-bind:to="navItem.target"
                     replace>
            <span>{{$tc(navItem.label)}}</span>
            <span v-if="navItem.count && navItem.count > 0"
                  class="generals-badge-count">{{navItem.count}}</span>
        </router-link>
    </div>
</template>

<style lang="less">
.c_sub-navigation-wrapper {
    height: var(--sub-navigation-height);

    .c_sub-navigation-item {
        display: inline-block;
        height: 100%;
        text-align: center;
        padding-top: 14px;
        color: var(--color-text-mid);
        border-bottom: 2px solid var(--color-border-light);

        &:not(:first-child) {
            border-left: 1px solid var(--color-border-light);
        }

        &.m--selected {
            color: var(--color-text-highlighted);
            border-bottom-color: var(--color-border-active);
        }

        &.m--disabled {
            cursor: default;
            pointer-events: none;
            color: var(--color-text-disabled);
        }

        &:hover {
            color: var(--color-text-highlighted);
        }
    }
}
</style>
