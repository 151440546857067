<script>
/**
 * NumberInput is a pre styled input number element.
 * It will throw an @input-submit event on pressing enter or clicking the submit icon, an @input-change event when input value changes, an @input-blurred event when losing focus and an @input-close event on pressing esc.
 * - id: Should be provided in order to get full functionality. Without an id you won't get the ability to close on esc and instant focus the input field when opening.
 * - label (optional): Text displayed as the label of the input field.
 * - defaultValue (optional): The default value of the input. For editing purposes for example.
 * - placeholder (optional): Text shown if the input is empty.
 * - submitButton (default: true): Determines whether there is a submit button.
 * - minimumValue (optional): Determines the minimum input value.
 * - maximumValue (optional): Determines the maximum input value.
 * - iconClass: A font awesome icon class.
 * - isDisabled (default: false): If true, the user can't change the input value.
 * - isAutoFocused (default: false): If true, the input field will get focused automatically when it is rendered.
 * - showUnsavedChanges (default: false): If true, the input will highlight if it's value differs from the last submitted one.
 * - allowEstimate (default: false): If true, there will be a checkbox next to the input to declare a value as estimation.
 * - defaultEstimate (default: false): Set the default value for isEstimate.
 **/
import { notificationHandler } from '@/mixins/notificationHandler'

export default {
    name: 'NumberInput',
    mixins: [
        notificationHandler
    ],
    // @input-submit: Event emitted on submit | returns the event and current input value
    // @input-change: Event emitted on input | returns current input value
    // @input-blurred: Event emitted on blur | returns current input value
    // @input-close: Event emitted on pressing esc key | no return
    // Note: We do not declare the emits here, because we want to have the registrations as part of $attrs
    //       emits: ['input-submit', 'input-change', 'input-blurred', 'input-close'],
    props: {
        id: String,
        label: String,
        defaultValue: [String, Number],
        placeholder: String,
        submitButton: {
            default: true,
            type: Boolean
        },
        minimumValue: Number,
        maximumValue: Number,
        iconClass: String,
        isDisabled: {
            default: false,
            type: Boolean
        },
        isAutoFocused: {
            default: false,
            type: Boolean
        },
        showUnsavedChanges: {
            default: false,
            type: Boolean
        },
        allowEstimate: {
            default: false,
            type: Boolean
        },
        defaultEstimate: {
            default: false,
            type: Boolean
        }
    },
    data () {
        return {
            inputValue: null,
            lastSubmittedValue: null,
            isEstimate: this.defaultEstimate
        }
    },
    methods: {
        focusInputElement () {
            document.getElementById(`c_number-input_${this.id}`).focus()
        },

        toggleEstimation () {
            this.isEstimate = !this.isEstimate
            this.emitSubmit(null, true)
        },

        keyPressedHandler (event) {
            const keycode = (event.keyCode ? event.keyCode : event.which)
            const validKeys = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105] // Numbers on keyboard and numpad
            if (event.key === 'Enter') {
                this.emitSubmit(event)
            } else if (event.key === 'Escape') {
                this.$emit('input-close')
            } else if (validKeys.includes(keycode)) {
                if (this.isMaximumInputLengthReached) {
                    event.preventDefault()
                }
            }
        },

        emitSubmit (event, forceSubmit) {
            // We actually need to check for listeners as the lastSubmittedValue must not be
            // updated if there is no listener which performs the actual submit
            //
            // see https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/593#note_316549
            //     for further information
            if (this.$attrs.onInputSubmit) {
                this.emit(event, 'input-submit', forceSubmit)
                this.updateLastSubmittedValue()
            }
        },

        emitBlur () {
            // We actually need to check for listeners as the lastSubmittedValue must not be
            // updated if there is no listener which performs the actual submit
            //
            // see https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/593#note_316549
            //     for further information
            if (this.$attrs.onInputBlurred) {
                this.emit(null, 'input-blurred')
                this.updateLastSubmittedValue()
            }
        },

        emit (event, name, force) {
            if (event) {
                event.stopPropagation()
            }
            if (this.hasUnsavedChanges || force) {
                if (this.inputValue < this.minimumValue || this.inputValue > this.maximumValue) {
                    this.addNotification({
                        type: 'error',
                        duration: 10000,
                        message: this.inputValue < this.minimumValue
                            ? this.$t('numberInput.minimumViolated', [this.minimumValue])
                            : this.$t('numberInput.maximumViolated', [this.maximumValue])
                    })
                } else {
                    if (this.inputValue === '') {
                        this.isEstimate = false
                    }
                    this.$emit(name, {
                        event: event,
                        value: this.inputValue,
                        isEstimate: this.isEstimate
                    })
                }
            }
        },

        updateLastSubmittedValue () {
            this.lastSubmittedValue = this.inputValue
        },

        setDefault () {
            const value = this.hasDefault
                ? this.defaultValue
                : ''
            this.inputValue = value
            this.lastSubmittedValue = value
        }
    },
    computed: {
        hasUnsavedChanges () {
            return this.inputValue !== this.lastSubmittedValue
        },

        hasValue () {
            return !Number.isNaN(this.inputValue)
        },

        hasDefault () {
            if (this.defaultValue === undefined || this.defaultValue === null) {
                return false
            }
            const value = Number.parseFloat(this.defaultValue)
            return !Number.isNaN(value)
        },

        isMaximumInputLengthReached () {
            if (!this.inputValue || !this.maximumValue) {
                return false
            }
            return this.inputValue.toString().length >= this.maximumValue.toString().length
        },
        isMaximumExceeded () {
            if (!this.inputValue || !this.maximumValue) {
                return false
            }
            return this.inputValue > this.maximumValue
        },
        isMinimumUndershot () {
            if (!this.inputValue || !this.minimumValue) {
                return false
            }
            return this.inputValue < this.minimumValue
        }
    },
    watch: {
        inputValue () {
            // We actually need to check for listeners as the lastSubmittedValue must not be
            // updated if there is no listener which performs the actual submit
            //
            // see https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/593#note_316549
            //     for further information
            if (this.$attrs.onInputChange) {
                this.emit(null, 'input-change')
            }
        },
        defaultValue () {
            this.setDefault()
        },
        defaultEstimate () {
            this.isEstimate = this.defaultEstimate
        }
    },
    mounted () {
        this.setDefault()
        if (this.isAutoFocused) {
            this.focusInputElement()
        }
    }
}
</script>

<template>
    <div class="c_number-input-wrapper generals-input-wrapper">
        <label v-if="label" class="c_number-input-label generals-input-label">
            <span>{{label}}</span>
        </label>
        <div class="c_number-input-container generals-input-container">
            <div v-if="allowEstimate"
                 v-bind:title="$tc(`numberInput.${isEstimate ? 'disable' : 'enable'}Estimate`)"
                 class="c_number-input-estimation-icon"
                 v-bind:class="{
                    'm--disabled': !hasValue,
                    'm--active': isEstimate
                }" v-on:click="toggleEstimation()">
                <span>ca.</span>
            </div>
            <input v-bind:id="id ? `c_number-input_${id}`: null"
                   data-testid="number-input"
                   type="number"
                   class="c_number-input generals-input"
                   v-bind:disabled="isDisabled"
                   v-bind:class="{
                       'm--no-icon': !submitButton && !iconClass,
                       'm--unsaved': showUnsavedChanges && hasUnsavedChanges,
                       'm--estimate': allowEstimate,
                       'm--limit-error': isMinimumUndershot || isMaximumExceeded
                   }"
                   v-bind:min="minimumValue"
                   v-bind:max="maximumValue"
                   v-bind:placeholder="placeholder || $tc('numberInput.defaultPlaceholder')"
                   v-on:blur="emitBlur()"
                   v-on:click.stop
                   v-on:keydown="keyPressedHandler($event)"
                   v-model="inputValue" />
            <button v-if="!isDisabled && (submitButton || iconClass)"
                    data-testid="number-input-submit"
                    tabindex="-1"
                    class="c_number-input-submit"
                    v-bind:class="{'m--disabled': !submitButton}"
                    v-on:mousedown="$event.preventDefault()"
                    v-on:click="emitSubmit($event)">
                <span v-bind:class="`c_number-input-icon ${iconClass || 'fas fa-check'}`"></span>
            </button>
            <div v-if="isMinimumUndershot"
                 class="c_number-input-info-message m--limit-error">
                <span>{{$t('numberInput.maximumViolatedShort', [minimumValue])}}</span>
            </div>
            <div v-else-if="isMaximumExceeded"
                 class="c_number-input-info-message m--limit-error">
                <span>{{$t('numberInput.maximumViolatedShort', [maximumValue])}}</span>
            </div>
            <div v-else-if="isMaximumInputLengthReached"
                 class="c_number-input-info-message">
                <span>{{$t('numberInput.maximumReached', [inputValue.toString().length])}}</span>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_number-input-wrapper {

    .c_number-input-label {
    }

    .c_number-input-container {

        .c_number-input-estimation-icon {
            position: absolute;
            margin: 13px 0 0 4px;
            z-index: 1;
            color: var(--color-text-disabled);
            cursor: pointer;

            &:hover {
                color: var(--color-text-mid);
            }

            &.m--disabled {
                pointer-events: none;
            }

            &.m--active {
                color: var(--color-text-highlighted);
            }
        }

        .generals-input.c_number-input {
            &.m--estimate {
                padding-left: 30px;
            }
            &.m--limit-error {
                color: var(--color-error);
            }
        }

        .c_number-input-submit {

            .c_number-input-icon {
            }
        }

        .c_number-input-info-message {
            font-size: 11px;
            &.m--limit-error {
                color: var(--color-error);
            }
        }
    }
}
</style>
