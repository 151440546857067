<script>
/**
 * Product view
 *
 * @property {Object} supportedFeatures
 * @property {Boolean} isProductDetailsSupported
 * @property {Boolean} isMembersSupported
 * @property {Boolean} isContentSupported
 * @property {Boolean} isScheduleSupported
 * @property {Boolean} isAssetOrdersSupported
 * @property {Boolean} isContractsSupported
 * @property {Boolean} isProcessHistorySupported
 **/
import ContentHeader from '@/components/ContentHeader.vue'
import SubNavigation from '@/components/SubNavigation.vue'
import { axiosService } from '@/mixins/axiosService'
import { notificationHandler } from '@/mixins/notificationHandler'
import { userHandler } from '@/mixins/userHandler'
import { environmentHandler } from '@/mixins/environmentHandler'

export default {
    name: 'Product',
    mixins: [
        axiosService,
        notificationHandler,
        userHandler,
        environmentHandler
    ],
    components: {
        ContentHeader,
        SubNavigation
    },
    data () {
        return {
            product: {
                id: null,
                userRights: {
                    isAllowedToSeeSchedule: null,
                    isAllowedToEditAssetOrders: null
                }
            },
            materialTypes: {
                ZSTP: {
                    processable: true,
                    xmlCheckbox: true
                },
                ZUFE: {
                    processable: true,
                    xmlCheckbox: false
                },
                ZSTN: {
                    processable: true,
                    xmlCheckbox: true
                },
                ZUFN: {
                    processable: true,
                    xmlCheckbox: false
                },
                UNKNOWN: {
                    processable: false,
                    xmlCheckbox: false
                }
            },
            unknownMaterialType: 'UNKNOWN',
            lastProductsMaximum: 5
        }
    },
    methods: {
        getProductMetadata () {
            this.axiosGet(
                `product/${this.$route.params.productId}/state`,
                this.$tc('product.getProductStateError'))
                .then(productInformation => {
                    if (!productInformation.userRights) {
                        productInformation.userRights = {}
                    }
                    if (!Object.keys(this.materialTypes).includes(productInformation.materialType)) {
                        productInformation.materialType = this.unknownMaterialType
                    }
                    this.product = productInformation
                    this.markProductAsVisited()
                })
                .catch(() => {})
        },

        markProductAsVisited () {
            const lastProducts = this.getUserSettingsParameter('lastProducts')
            const lastProductIds = lastProducts.map(product => product.id)
            if (lastProductIds.includes(this.product.id)) {
                lastProducts.splice(lastProductIds.indexOf(this.product.id), 1)
            }
            lastProducts.unshift({
                id: this.product.id,
                label: `${this.product.productNumber}<br />${this.product.shortTitle}`,
                target: `/product-list/${this.product.id}`
            })
            if (lastProducts.length > this.lastProductsMaximum) {
                lastProducts.length = this.lastProductsMaximum
            }
            this.updateUserSettingsParameter('lastProducts', lastProducts)
        }
    },
    computed: {
        getSubNavigationData () {
            const navigationItems = [{
                id: 'product.cockpit',
                label: 'product.subNavigation.cockpit',
                visible: true,
                target: { name: 'ProductCockpit' }
            }, {
                id: 'product.info',
                label: 'product.subNavigation.info',
                visible: true,
                target: { name: 'ProductInformation' }
            }, {
                id: 'product.contents',
                label: 'product.subNavigation.contents',
                visible: this.product.supportedFeatures?.isContentSupported,
                target: { name: 'ProductContents' }
            }, {
                id: 'product.schedule',
                label: 'product.subNavigation.schedule',
                visible: this.product.userRights.isAllowedToSeeSchedule && this.product.supportedFeatures?.isScheduleSupported,
                target: { name: 'ProductSchedule' }
            }, {
                id: 'product.assetOrders',
                label: 'product.subNavigation.assetOrders',
                visible: this.product.supportedFeatures.isAssetOrdersSupported,
                target: { name: 'ProductAssetOrders' }
            }, {
                id: 'product.processHistory',
                label: 'product.subNavigation.processHistory',
                visible: this.product.supportedFeatures?.isProcessHistorySupported,
                target: { name: 'ProductProcessHistory' }
            }]
            return navigationItems.filter(item => item.visible)
        }
    },
    watch: {
        '$route.params.productId' () {
            this.getProductMetadata()
        }
    },
    mounted () {
        this.getProductMetadata()
    }
}
</script>

<template>
    <div class="generals-container">
        <ContentHeader v-bind:header-title="product.productNumber"
                       v-bind:header-sub-title="product.shortTitle"
                       v-bind:go-back-target="{name: 'ProductList', title: $tc('productList.headline')}"
                       v-bind:is-primary-header=true
                       v-bind:use-slot="true">
        </ContentHeader>
        <div v-if="product.id"
             v-bind:key="product.id"
             class="product-content-container">
            <SubNavigation v-bind:key="this.$route.params.productId"
                           v-bind:navigation-items="getSubNavigationData"
                           default-location-id="product.details">
            </SubNavigation>
            <router-view v-bind:key="`product_${this.$route.params.productId}`"
                         v-bind:productInfo="product"
                         class="product-content-view">
            </router-view>
        </div>
    </div>
</template>

<style scoped lang="less">
.product-content-container {
    width: 100%;
    height: calc(100% - var(--primary-headline-height));
    padding: var(--container-spacing) 0;
    overflow: hidden;

    .product-content-view {
        position: relative;
        width: 100%;
        height: calc(100% - var(--sub-navigation-height));
        padding: var(--container-spacing) var(--container-spacing) 0 var(--container-spacing);
        overflow: hidden;
    }
}
</style>
