<script>
/**
 * Component gallery / Component showcase
 **/
import Accordion from '@/components/Accordion.vue'
import AreaInput from '@/components/AreaInput.vue'
import BadgeInput from '@/components/BadgeInput.vue'
import Button from '@/components/Button.vue'
import Checkbox from '@/components/Checkbox.vue'
import Checklist from '@/components/Checklist.vue'
import ContentHeader from '@/components/ContentHeader.vue'
import CursorNotification from '@/components/CursorNotification.vue'
import DataRow from '@/components/DataRow.vue'

import Icon from '@/components/Icon.vue'
import IconButton from '@/components/IconButton.vue'
import ModalContainer from '@/components/ModalContainer.vue'
import FilesAndLinksUpload from '@/components/FilesAndLinksUpload.vue'
import FilesAndLinks from '@/services/FilesAndLinks'
import ToggleButton from '@/components/ToggleButton.vue'
import DynamicInput from '@/components/DynamicInput.vue'
import Table from '@/components/Table.vue'

export default {
    name: 'Gallery',
    mixins: [],
    components: {
        ToggleButton,
        DynamicInput,
        Accordion,
        AreaInput,
        BadgeInput,
        Button,
        Checkbox,
        Checklist,
        ContentHeader,
        CursorNotification,
        DataRow,
        Icon,
        IconButton,
        ModalContainer,
        FilesAndLinksUpload,
        Table
    },
    data () {
        return {
            pageIndex: 0,
            modalVisible: false,
            components: [
                'Accordion',
                'AreaInput',
                'BadgeInput',
                'Button',
                'Checkbox',
                'Checklist',
                'ContentHeader',
                'CursorNotification',
                'DataRow',
                'Table',
                'ToggleButton',
                'DataRow',
                'Custom: DynamicInput',
                'Custom: FilesAndLinksUpload'
            ],
            itemList: [{
                label: 'Label item 1',
                value: 'Value item 1'
            }, {
                label: 'Label item 2',
                value: 'Value item 2'
            }, {
                label: 'Label item 3',
                value: 'Value item 3'
            }, {
                label: 'Label item 4',
                value: 'Value item 4'
            }, {
                label: 'Label item 5',
                value: 'Value item 5'
            }],
            itemListWithDisabledItems: [{
                label: 'Label item 1',
                value: 'Value item 1'
            }, {
                label: 'Label item 2',
                disabled: true,
                value: 'Value item 2'
            }, {
                label: 'Label item 3',
                disabled: true,
                value: 'Value item 3'
            }, {
                label: 'Label item 4',
                value: 'Value item 4'
            }, {
                label: 'Label item 5',
                value: 'Value item 5'
            }],
            filesAndLinks: FilesAndLinks.from([
                { url: 'https://www.google.com', id: '1' },
                { url: 'https://www.microsoft.com', id: '2' }
            ], [
                { fileName: 'manuscript.jpg', id: '4' }
            ]),
            tableConfig: [
                {
                    key: 'id',
                    label: 'ID',
                    alignment: 'left',
                    width: 0
                },
                {
                    key: 'column1',
                    label: 'Column 1',
                    sortable: true,
                    filterable: true,
                    alignment: 'left',
                    width: 50
                }, {
                    key: 'column2',
                    label: 'Column 2',
                    sortable: true,
                    filterable: true,
                    alignment: 'left',
                    width: 50
                }],
            tableData: [
                {
                    id: 1,
                    column1: -1,
                    column2: 'line 1'
                },
                {
                    id: 2,
                    column1: 42,
                    column2: 'line 2 with a \n line break'
                },
                {
                    id: 3,
                    column1: 4711,
                    column2: 'line 3 with a " quote in it'
                }
            ]
        }
    },
    methods: {
        doAlertAction (message) {
            alert(message)
        },
        async asyncEchoAction (input) {
            return Promise.resolve(input)
        }
    }
}
</script>

<template>
    <div class="generals-container">
        <ContentHeader v-bind:header-title="'Galerie - Ausstellung der Frontend-Komponenten'"
                       v-bind:header-sub-title="components[pageIndex]"
                       v-bind:is-primary-header=true
                       v-bind:use-slot="true">
            <template v-slot>
                <IconButton icon-class="fas fa-bars"
                            @button-submit="modalVisible=true">
                </IconButton>
                <Button @button-submit="pageIndex++"
                        v-bind:is-disabled="pageIndex + 1 === components.length">
                    <template v-slot>Weiter</template>
                </Button>
                <Button @button-submit="pageIndex--"
                        v-bind:is-disabled="pageIndex === 0">
                    <template v-slot>Zurück</template>
                </Button>
            </template>
        </ContentHeader>
        <div class="gallery-content-container">
            <TransitionGroup name="show">
                <div v-if="components[pageIndex] === 'Accordion'"
                     key="Accordion">
                    <Accordion v-bind:items="itemList"
                               title="Accordion with item count"
                               v-bind:count="itemList.length">
                    </Accordion>
                    <Accordion v-bind:items="itemList"
                               title="Accordion with highlight"
                               color-code="#FF7599"
                               v-bind:expanded-by-default="false">
                    </Accordion>
                    <Accordion v-bind:items="itemList"
                               title="Disabled accordion without item count"
                               v-bind:is-disabled="true"
                               v-bind:expanded-by-default="false">
                    </Accordion>
                </div>
                <div v-else-if="components[pageIndex] === 'AreaInput'"
                     key="AreaInput">
                    <div class="gallery-component-container">
                        <AreaInput label="Static area input without submit button"></AreaInput>
                    </div>
                    <div class="gallery-component-container">
                        <AreaInput label="Dynamic area input with submit button"
                                   v-bind:is-expandable="true"
                                   icon-class="fas fa-check"
                                   v-bind:show-unsaved-changes="true"
                                   v-bind:submit-button="true"
                                   @input-submit="doAlertAction('Area submit')">
                        </AreaInput>
                    </div>
                    <div class="gallery-component-container">
                        <AreaInput label="Area input with max length"
                                   v-bind:max-length="200">
                        </AreaInput>
                    </div>
                    <div class="gallery-component-container">
                        <AreaInput label="Disabled area input"
                                   v-bind:is-disabled="true">
                        </AreaInput>
                    </div>
                    <div class="gallery-component-container">
                        <AreaInput label="Area input with hint"></AreaInput>
                    </div>
                    <div class="gallery-component-container">
                        <AreaInput v-bind:fill-available-space="true"
                                   placeholder="Area input fitting available space">
                        </AreaInput>
                    </div>
                </div>
                <div v-if="components[pageIndex] === 'BadgeInput'"
                     key="BadgeInput">
                    <div class="gallery-component-container">
                        <BadgeInput v-bind:is-disabled="true"
                                    value="Disabled badge">
                        </BadgeInput>
                    </div>
                    <div class="gallery-component-container">
                        <BadgeInput value="Active Badge"
                                    @badge-submit="doAlertAction('Badge submit')">
                        </BadgeInput>
                    </div>
                </div>
                <div v-if="components[pageIndex] === 'Button'"
                     key="Button">
                    <div class="gallery-component-container">
                        <Button @button-submit="doAlertAction('Button submit')">
                            <template v-slot>Submit button</template>
                        </Button>
                    </div>
                    <div class="gallery-component-container">
                        <Button v-bind:is-disabled="true">
                            <template v-slot>Disabled submit button</template>
                        </Button>
                    </div>
                    <div class="gallery-component-container">
                        <Button button-type="caution"
                                @button-submit="doAlertAction('Button submit')">
                            <template v-slot>Caution button</template>
                        </Button>
                    </div>
                    <div class="gallery-component-container">
                        <Button v-bind:is-disabled="true"
                                button-type="caution">
                            <template v-slot>Disabled caution button</template>
                        </Button>
                    </div>
                    <div class="gallery-component-container">
                        <Button button-type="cancel"
                                @button-submit="doAlertAction('Button submit')">
                            <template v-slot>Cancel button</template>
                        </Button>
                    </div>
                    <div class="gallery-component-container">
                        <Button v-bind:is-disabled="true"
                                button-type="cancel">
                            <template v-slot>Disabled cancel button</template>
                        </Button>
                    </div>
                    <div class="gallery-component-container">
                        <Button button-type="inline"
                                @button-submit="doAlertAction('Button submit')">
                            <template v-slot>Inline button</template>
                        </Button>
                    </div>
                    <div class="gallery-component-container">
                        <Button v-bind:is-disabled="true"
                                button-type="inline">
                            <template v-slot>Disabled inline button</template>
                        </Button>
                    </div>
                    <div class="gallery-component-container">
                        <Button @button-submit="doAlertAction('Button submit')">
                            <template v-slot>
                                <span class="fas fa-check-circle"></span>
                                <span> Submit button with icon</span>
                            </template>
                        </Button>
                    </div>
                </div>
                <div v-if="components[pageIndex] === 'Checkbox'"
                     key="Checkbox">
                    <div class="gallery-component-container">
                        <Checkbox label="Checkbox"
                                  @input-change="doAlertAction('Checkbox change')">
                        </Checkbox>
                    </div>
                    <div class="gallery-component-container">
                        <Checkbox label="Pre-checked checkbox"
                                  v-bind:default-value="true"
                                  @input-change="doAlertAction('Checkbox change')">
                        </Checkbox>
                    </div>
                    <div class="gallery-component-container">
                        <Checkbox label="Disabled checkbox"
                                  v-bind:is-disabled="true">
                        </Checkbox>
                    </div>
                    <div class="gallery-component-container">
                        <Checkbox label="Disabled pre-checked checkbox"
                                  v-bind:default-value="true"
                                  v-bind:is-disabled="true">
                        </Checkbox>
                    </div>
                    <div class="gallery-component-container">
                        <Checkbox label="Checkbox with hint"
                                  @input-change="doAlertAction('Checkbox change')">
                        </Checkbox>
                    </div>
                </div>
                <div v-if="components[pageIndex] === 'Checklist'"
                     key="Checklist">
                    <div class="gallery-component-container">
                        <Checklist label="Checklist"
                                   v-bind:options="itemList"
                                   @checklist-change="doAlertAction('Checklist change')">
                        </Checklist>
                    </div>
                    <div class="gallery-component-container">
                        <Checklist label="Checklist with single choice"
                                   v-bind:options="itemList"
                                   v-bind:single-choice="true"
                                   @checklist-change="doAlertAction('Checklist change')">
                        </Checklist>
                    </div>
                    <div class="gallery-component-container">
                        <Checklist label="Checklist with default choices"
                                   v-bind:options="itemList"
                                   v-bind:default-values="['Value item 3', 'Value item 5']"
                                   @checklist-change="doAlertAction('Checklist change')">
                        </Checklist>
                    </div>
                    <div class="gallery-component-container">
                        <Checklist label="Checklist with disabled items"
                                   v-bind:options="itemListWithDisabledItems"
                                   @checklist-change="doAlertAction('Checklist change')">
                        </Checklist>
                    </div>
                    <div class="gallery-component-container">
                        <Checklist label="Disabled checklist"
                                   v-bind:is-disabled="true"
                                   v-bind:options="itemList">
                        </Checklist>
                    </div>
                    <div class="gallery-component-container">
                        <Checklist label="Checklist with hint"
                                   v-bind:options="itemList"
                                   @checklist-change="doAlertAction('Checklist change')">
                        </Checklist>
                    </div>
                    <div class="gallery-component-container">
                        <Checklist label="Checklist with custom option"
                                   v-bind:options="itemList"
                                   v-bind:allow-custom-option="true"
                                   @checklist-change="doAlertAction('Checklist change')">
                        </Checklist>
                    </div>
                </div>
                <div v-if="components[pageIndex] === 'ContentHeader'"
                     key="ContentHeader">
                    <div class="gallery-component-container">
                        <ContentHeader header-title="Primary content header"
                                       v-bind:is-primary-header=true>
                        </ContentHeader>
                    </div>
                    <div class="gallery-component-container">
                        <ContentHeader header-title="Primary content header with count"
                                       header-count="20"
                                       v-bind:is-primary-header=true>
                        </ContentHeader>
                    </div>
                    <div class="gallery-component-container">
                        <ContentHeader header-title="Primary content header"
                                       header-sub-title="With subtitle"
                                       v-bind:is-primary-header=true>
                        </ContentHeader>
                    </div>
                    <div class="gallery-component-container">
                        <ContentHeader header-title="Primary content header"
                                       header-sub-title="With subtitle and count"
                                       header-count="20"
                                       v-bind:is-primary-header=true>
                        </ContentHeader>
                    </div>
                    <div class="gallery-component-container">
                        <ContentHeader header-title="Primary content header"
                                       header-sub-title="With additional content"
                                       v-bind:is-primary-header=true
                                       v-bind:use-slot="true">
                            <template v-slot>
                                <IconButton icon-class="fas fa-question-circle"
                                            tooltip="And more than one item">
                                </IconButton>
                                <Button>
                                    <template v-slot>This could be anything</template>
                                </Button>
                            </template>
                        </ContentHeader>
                    </div>
                    <div class="gallery-component-container">
                        <ContentHeader header-title="Primary content header"
                                       header-sub-title="With navigation and clickable title"
                                       v-bind:link-config="{target: {name: 'Gallery', tooltip: 'This does not do anything, too'}}"
                                       v-bind:go-back-target="{name: 'Gallery', title: 'This will not lead you anywhere'}"
                                       v-bind:is-primary-header=true>
                        </ContentHeader>
                    </div>
                    <div class="gallery-component-container">
                        <ContentHeader header-title="Secondary content header"></ContentHeader>
                    </div>
                    <div class="gallery-component-container">
                        <ContentHeader header-title="Secondary content header"
                                       header-sub-title="With subtitle">
                        </ContentHeader>
                    </div>
                    <div class="gallery-component-container">
                        <ContentHeader header-title="Secondary content header with count"
                                       header-count="20">
                        </ContentHeader>
                    </div>
                    <div class="gallery-component-container">
                        <ContentHeader header-title="Secondary content header"
                                       header-sub-title="With subtitle and count"
                                       header-count="20">
                        </ContentHeader>
                    </div>
                    <div class="gallery-component-container">
                        <ContentHeader header-title="Secondary content header"
                                       header-sub-title="With additional content"
                                       v-bind:use-slot=true>
                            <template v-slot>
                                <Icon icon-class="fas fa-question-circle"
                                      tooltip="And more than one item">
                                </Icon>
                                <Button>
                                    <template v-slot>This could be anything</template>
                                </Button>
                            </template>
                        </ContentHeader>
                    </div>
                    <div class="gallery-component-container">
                        <ContentHeader header-title="Secondary content header"
                                       header-sub-title="With clickable title"
                                       v-bind:link-config="{target: {name: 'Gallery', tooltip: 'This does not do anything, too'}}">
                        </ContentHeader>
                    </div>
                </div>
                <div v-if="components[pageIndex] === 'CursorNotification'"
                     key="CursorNotification">
                    <span>Kind of a riddle why there is such a huge gap between the cursor and the notifications... 🤷🏼‍♂️</span>
                    <CursorNotification id="cursor-notification_info"
                                        type="info"
                                        v-bind:duration="1000000"
                                        message="I am an info message following the cursor">
                    </CursorNotification>
                    <CursorNotification id="cursor-notification_success"
                                        type="success"
                                        v-bind:duration="1000000"
                                        message="I am a success message following the cursor">
                    </CursorNotification>
                    <CursorNotification id="cursor-notification_warning"
                                        type="warning"
                                        v-bind:duration="1000000"
                                        message="I am a warning following the cursor">
                    </CursorNotification>
                    <CursorNotification id="cursor-notification_error"
                                        type="error"
                                        v-bind:duration="1000000"
                                        message="I am an error message following the cursor">
                    </CursorNotification>
                </div>
                <div v-if="components[pageIndex] === 'DataRow'"
                     key="DataRow">
                    <DataRow v-bind:label-width-percentage="50"
                             label="This is a data row"
                             value="It can offer some information">
                    </DataRow>
                    <DataRow v-bind:label-width-percentage="50"
                             label="This is an info data row"
                             value="It is marked with the info color"
                             message-type="info">
                    </DataRow>
                    <DataRow v-bind:label-width-percentage="50"
                             label="This is a success data row"
                             value="It is marked with the success color"
                             message-type="success">
                    </DataRow>
                    <DataRow v-bind:label-width-percentage="50"
                             label="This is a warning data row"
                             value="It is marked with the warning color"
                             message-type="warning">
                    </DataRow>
                    <DataRow v-bind:label-width-percentage="50"
                             label="This is an error data row"
                             value="It is marked with the error color"
                             message-type="error">
                    </DataRow>
                    <DataRow v-bind:label-width-percentage="50"
                             label="This is a separated data row"
                             value="It has a bottom border"
                             v-bind:is-separated-by-bottom-border="true">
                    </DataRow>
                    <DataRow v-bind:label-width-percentage="50"
                             label="This is a clickable data row"
                             value="Its value can be clicked"
                             v-bind:is-clickable-value="true"
                             @value-click="doAlertAction('Data row click')">
                    </DataRow>
                    <DataRow v-bind:label-width-percentage="50"
                             label="This is a growing data row"
                             value="Imagine there is so much text in this info, that makes it impossible to keep all the information in a single line. This is what this data row is made for, because it will grow, depending on its content">
                    </DataRow>
                    <DataRow v-bind:label-width-percentage="50"
                             label="This is a growing data row"
                             tooltip="Imagine there is so much text in this info, that makes it impossible to keep all the information in a single line. But you do not have enough space to make it grow. This is what this data row is made for, which will use three dots, if the content is too long. Better use it in combination with a tooltip!"
                             value="Imagine there is so much text in this info, that makes it impossible to keep all the information in a single line. But you do not have enough space to make it grow. This is what this data row is made for, which will use three dots, if the content is too long. Better use it in combination with a tooltip!"
                             v-bind:no-line-break="true">
                    </DataRow>
                </div>
                <div v-if="components[pageIndex] === 'ToggleButton'"
                     key="ToggleButton">

                    <div class="gallery-component-container">
                        <ToggleButton v-bind:default-value="true">
                        </ToggleButton>
                    </div>
                    <div class="gallery-component-container">
                        <ToggleButton v-bind:default-value="false">
                        </ToggleButton>
                    </div>
                    <div class="gallery-component-container">
                        <ToggleButton v-bind:label-toggle-on="'Toggle on default'"
                                      v-bind:label-toggle-off="'Toggle off'"
                                      v-bind:default-value="true">
                        </ToggleButton>
                    </div>
                    <div class="gallery-component-container">
                        <ToggleButton v-bind:label-toggle-on="'Toggle on'"
                                      v-bind:label-toggle-off="'Toggle off default'"
                                      v-bind:default-value="false">
                        </ToggleButton>
                    </div>
                </div>
                <div v-if="components[pageIndex] === 'Custom: DynamicInput'"
                     key="CustomDynamicInput">
                    <div class="gallery-component-container">
                        <DynamicInput
                            id="dynamicInput-email"
                            :label="'Dynamic input - email only'"
                            :input-types="['email']"
                            @dynamic-submit="() => doAlertAction('Email set')"
                        />
                    </div>
                    <div class="gallery-component-container">
                        <DynamicInput
                            id="dynamicInput-all"
                            :label="'Dynamic input - all supported types'"
                            :input-types="['upload', 'link', 'text', 'number', 'email', 'date']"
                            :allow-multiple="true"
                            @dynamic-submit="() => doAlertAction('Link set/File uploaded')"
                        />
                    </div>
                    <div class="gallery-component-container">
                        <DynamicInput
                            id="dynamicInput-link-upload"
                            :label="'Dynamic input - legacy link and multi upload'"
                            :input-types="['upload', 'link']"
                            :allow-multiple="true"
                            @dynamic-submit="() => doAlertAction('Link set/File uploaded')"
                        />
                    </div>
                    <div class="gallery-component-container">
                        <DynamicInput
                            id="dynamicInput-link-upload"
                            :label="'Dynamic input - optimized link and multi upload'"
                            :input-types="['upload', 'link']"
                            :allow-multiple="true"
                            :legacy-mode="false"
                            @dynamic-submit="() => doAlertAction('Link set/File uploaded')"
                        />
                    </div>
                </div>
                <div v-if="components[pageIndex] === 'Custom: FilesAndLinksUpload'"
                     key="CustomFilesAndLinksUpload">
                    <div class="gallery-component-container--left">
                    <FilesAndLinksUpload
                        :label="'Dateien und Links anzeigen'"
                        :initial-files-and-links="filesAndLinks"
                        :download-file-action="() => doAlertAction ('Here we would download')"
                    ></FilesAndLinksUpload>
                    </div>
                    <div class="gallery-component-container--left">
                    <FilesAndLinksUpload
                        :label="'Dateien und Links hochladen'"
                        :initial-files-and-links="filesAndLinks"
                        :download-file-action="() => doAlertAction ('Here we would download')"
                        :save-file-action="async (formData) => {
                            const files = []
                            for (const [_, value] of formData.entries()) {
                                files.push({fileName: value.name})
                            }

                            return await asyncEchoAction(files)
                        }"
                        :save-link-action="async (url) => await asyncEchoAction ({url})"
                        :delete-file-action="asyncEchoAction"
                        :delete-link-action="asyncEchoAction"
                    ></FilesAndLinksUpload>
                    </div>
                </div>
                <div v-if="components[pageIndex] === 'Table'">
                    <div class="table-container">
                        <span class="table-container-heading">Table (default)</span>
                        <Table table-id="table-default"
                               v-bind:read-only=true
                               v-bind:table-data="tableData"
                               v-bind:table-config="tableConfig">
                        </Table>
                    </div>
                    <hr/>
                    <div class="table-container">
                        <span class="table-container-heading">Table with custom cell formatting</span>
                        <Table table-id="table-custom-cells"
                               v-bind:read-only=true
                               v-bind:table-data="tableData"
                               v-bind:table-config="tableConfig">
                            <template #cell(column1)="data">
                                <span>Cell content, but bold: <b>{{ data.row.column1 }}</b></span>
                            </template>
                            <template #cell(column2)="data">
                                <span>Cell content, but bold: <b>{{ data.row.column2 }}</b></span>
                            </template>
                        </Table>
                    </div>
                    <hr/>
                    <div class="table-container">
                        <span class="table-container-heading">Table with custom header and footer</span>
                        <Table table-id="table-custom-header-footer"
                           v-bind:read-only=true
                           v-bind:table-data="tableData"
                               v-bind:table-config="tableConfig"
                               :use-custom-first-element="true"
                               :use-table-footer="true"
                        >
                            <template #table-custom-header-footer_custom-element>
                                <div class="custom-table-header">
                                    <span>Custom header</span>
                                </div>
                            </template>
                            <template #table-custom-header-footer_footer>
                                <div class="custom-table-footer">
                                    <span>Custom footer</span>
                                </div>
                            </template>
                        </Table>
                    </div>
                </div>
            </TransitionGroup>
        </div>

        <ModalContainer class="gallery-table-of-content-container"
                        v-bind:is-expanded="modalVisible"
                        modal-title="Inhaltsverzeichnis"
                        v-bind:modal-width-percent="40"
                        @modal-close="modalVisible=false">
            <template v-slot>
                <div v-for="(component, index) in components"
                     v-bind:key="index"
                     class="gallery-table-of-content-entry"
                     v-on:click="pageIndex=index">
                    <span>{{component}}</span>
                </div>
            </template>
        </ModalContainer>
    </div>
</template>

<style lang="less">
.gallery-content-container {
    width: 100%;
    height: calc(100% - var(--primary-headline-height));
    padding: var(--container-spacing);
    overflow-y: auto;

    .gallery-component-container {
        position: relative;
        margin-bottom: var(--container-spacing);

        .generals-input-label {
            width: var(--smart-form-input-label-width);
        }
    }

    .gallery-component-container--left {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .table-container {
        margin: 20px;
        height: 275px;

        .table-container-heading {
            font-size: 1.5rem;
            font-weight: bolder;
        }

        .custom-table-header,
        .custom-table-footer {
            text-align: center;
            padding: 10px;
            font-size: 1.25rem;
            font-weight: bold;
            border: .1px solid;
            margin: 5px;
        }
    }

}

.gallery-table-of-content-container {
    position: relative;
    width: 100%;
    height: 100%;

    .gallery-table-of-content-entry {
        position: relative;
        width: 100%;
        padding: var(--container-spacing);
        font-size: 20px;
        font-family: "Source Sans Pro Bold", sans-serif;

        &:first-of-type {
            padding-top: 8px;
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid var(--color-border-light);
        }

        &:last-of-type {
            padding-bottom: 8px;
        }

        &:hover {
            cursor: pointer;
            color: var(--color-text-highlighted);
        }
    }
}
</style>
