<script>
/**
 * Administration task overview
 **/
import ContentHeader from '@/components/ContentHeader.vue'
import SubNavigation from '@/components/SubNavigation.vue'

export default {
    name: 'AdminTaskOverview',
    components: {
        ContentHeader,
        SubNavigation
    },
    data () {
        return {
            subNavigationItems: [{
                id: 'tasks.current',
                label: 'administration.taskOverview.subNavigation.currentTasks',
                target: { name: 'CurrentTasks' }
            }, {
                id: 'tasks.failed',
                label: 'administration.taskOverview.subNavigation.failedJobs',
                target: { name: 'FailedJobs' }
            }]
        }
    }
}
</script>

<template>
    <div class="generals-container">
        <ContentHeader v-bind:header-title="$tc('administration.taskOverview.headline')"
                       v-bind:is-primary-header=true>
        </ContentHeader>
        <div class="administration-content-container">
            <SubNavigation v-bind:navigation-items="subNavigationItems"
                           default-location-id="tasks.current">
            </SubNavigation>
            <router-view class="administration-content-view"></router-view>
        </div>
    </div>
</template>

<style scoped lang="less">
.administration-content-container {
    width: 100%;
    height: calc(100% - var(--primary-headline-height));
    padding: var(--container-spacing) 0;

    .administration-content-view {
        position: relative;
        width: 100%;
        height: calc(100% - var(--sub-navigation-height));
        padding-top: var(--container-spacing);
        overflow: hidden;
    }
}
</style>
