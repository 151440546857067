<script>
/**
 * VerticalLine is a span element with '|' separating elements from each other
 **/
export default {
    name: 'VerticalLine'
}
</script>

<template>
    <span class="c_vertical-line">|</span>
</template>

<style lang="less">
.c_vertical-line {
    margin: 0 4px;
    font-size: 25px;
    color: var(--color-border-light);
}
</style>
