<script>
/**
 * Product tasks sub view | Sub view of a Product
 *
 * @property {String} sapId
 * @property {String} oid
 * @property {String} editabilityStatus
 **/
import Accordion from '@/components/Accordion.vue'
import Table from '@/components/Table.vue'
import Icon from '@/components/Icon.vue'
import BadgeInput from '@/components/BadgeInput.vue'
import IconButton from '@/components/IconButton.vue'
import { axiosService } from '@/mixins/axiosService'
import { taskAssignmentHandler } from '@/mixins/taskAssignmentHandler'
import { eventBus } from '@/mixins/eventBus'

export default {
    name: 'ProductMembers',
    mixins: [
        axiosService,
        taskAssignmentHandler
    ],
    components: {
        Accordion,
        Icon,
        Table,
        BadgeInput,
        IconButton
    },
    props: {
        productId: [String, Number]
    },
    data () {
        return {
            activeTasks: []
        }
    },
    methods: {
        getCurrentTasksForProduct () {
            this.axiosGet(
                `product/${this.productId}`,
                this.$t('product.tasks.getTasksError'))
                .then(productDetails => {
                    productDetails.activeTasks.forEach(task => {
                        task.assigneeDisplayText = this.getAssigneeDisplayText(task)
                    })
                    this.activeTasks = productDetails.activeTasks
                })
                .catch(() => {})
        },
        onChangeEvent (event) {
            function updateTaskList (eventName, eventProductId, ctx) {
                console.debug('updateTaskList: Event: ' + eventName, eventProductId, ctx.productId)

                // Check, whether the task event really applies to the current view instance:
                // If and only if fetch the data again from the back end and re-render
                if (eventProductId === ctx.productId) {
                    console.debug('Update ProductTasks for productId:', ctx.productId)
                    ctx.getCurrentTasksForProduct()
                }
            }

            updateTaskList(event?.eventName, event?.productId, this)
        },
        getAssigneeDisplayText (task) {
            return this.getAssignmentDisplayText(task)
        },

        getIconType (task) {
            return this.getAssignmentIconType(task)
        },

        getIconClass (task) {
            return this.getAssignmentIconClass(task)
        },

        getIconTooltip (task) {
            return this.getAssignmentIconTooltip(task)
        },

        getResponsibleRole (task) {
            if (task.sapIds.length === 0) {
                return this.$tc('generals.roleLabels.unknown')
            }
            return task.sapIds.map(role => this.$tc(`generals.roleLabels.${role}`)).join(', ')
        },

        claimTask (task, openTask) {
            this.assignTask(task, openTask)
        }
    },
    computed: {
        getTableConfig () {
            return [{
                key: 'status',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: 5
            }, {
                key: 'name',
                label: this.$tc('product.tasks.table.taskName'),
                filterable: false,
                sortable: true,
                alignment: 'left',
                width: 30
            }, {
                key: 'context',
                label: this.$tc('product.tasks.table.taskContext'),
                filterable: false,
                sortable: true,
                alignment: 'left',
                width: 25
            }, {
                key: 'assigneeDisplayText',
                label: this.$tc('product.tasks.table.taskRole'),
                filterable: false,
                sortable: true,
                alignment: 'left',
                width: 30
            }, {
                key: 'action',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'right',
                width: 10
            }]
        }
    },
    created () {
        eventBus.$on('task-created', this.onChangeEvent)
        eventBus.$on('task-claimed', this.onChangeEvent)
        eventBus.$on('task-completed', this.onChangeEvent)
        eventBus.$on('task-unassigned', this.onChangeEvent)
    },
    mounted () {
        this.getCurrentTasksForProduct()
    },
    beforeUnmount () {
        // de-register all listening methods
        //
        // Attention: Do not call 'eventBus.$off('task-created')' without callback-function
        //            as this also would de-register listeners in other views!
        eventBus.$off('task-created', this.onChangeEvent)
        eventBus.$off('task-claimed', this.onChangeEvent)
        eventBus.$off('task-unassigned', this.onChangeEvent)
        eventBus.$off('task-completed', this.onChangeEvent)
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="product-tasks-container">
            <Accordion v-bind:title="$tc('product.tasks.headline')"
                       v-bind:count="activeTasks.length"
                       v-bind:is-disabled="true">
            </Accordion>
            <div class="product-tasks-table-container">
                <Table table-id="productTasks"
                       v-bind:table-config="getTableConfig"
                       v-bind:table-data="activeTasks"
                       v-bind:table-empty-message="$tc('product.tasks.table.tableEmpty')"
                       v-bind:read-only=true>
                    <template #cell(status)="data">
                        <Icon v-bind:key="data.row.id"
                              v-bind:icon-type="getIconType(data.row)"
                              v-bind:icon-class="getIconClass(data.row)"
                              v-bind:tooltip="getIconTooltip(data.row)"
                              v-bind:icon-glow="data.row.assignee === getUserName()">
                        </Icon>
                    </template>
                    <!-- name: default cell content -->
                    <!-- context: default cell content -->
                    <template #cell(assigneeDisplayText)="data">
                        <BadgeInput v-if="data.row.assigneeDisplayText"
                                    v-bind:is-disabled=true
                                    v-bind:value="data.row.assigneeDisplayText"
                                    class="product-tasks-assignee-badge"
                                    v-bind:class="{'m--currently-in-progress': data.row.assignee}"
                        >
                        </BadgeInput>
                        <span v-else>{{getResponsibleRole(data.row)}}</span>
                    </template>
                    <template #cell(action)="data">
                        <IconButton v-bind:icon-class="data.row.waitingForSignalRequests ? 'fas fa-spin fa-circle-notch' : 'fas fa-play-circle'"
                                    v-bind:tooltip="$tc('product.tasks.table.claimAndEditTask')"
                                    v-bind:is-disabled="data.row.waitingForSignalRequests || !data.row.isTaskExecutable"
                                    @button-submit="claimTask(data.row, true)">
                        </IconButton>
                    </template>
                </Table>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.product-tasks-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .product-tasks-table-container {
        padding-top: 10px;
        height: calc(100% - var(--accordion-head-height));

        .product-tasks-assignee-badge {
            margin: 0;

            &.m--currently-in-progress {
                background-color: var(--color-info);
                color: var(--color-text-bright);
            }
        }
    }
}
</style>
