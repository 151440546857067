<script>
/**
 * Product conflicts sub view | Sub view of controlling
 *
 * @property {String} productContentId
 * @property {String} productContent
 * @property {String} userEventType
 * @property {String} userEventName
 * @property {Date} userEventDate
 **/
import Table from '@/components/Table.vue'
import DataRow from '@/components/DataRow.vue'
import { axiosService } from '@/mixins/axiosService'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'ProductConflicts',
    mixins: [
        axiosService,
        dateTimeHelper
    ],
    components: {
        Table,
        DataRow
    },
    data () {
        return {
            productsWithConflicts: [],
            showSpinner: true
        }
    },
    methods: {
        getProductsWithConflicts () {
            this.axiosGet(
                'admin/product-conflicts',
                this.$tc('administration.controlling.conflicts.getProductConflictsError'))
                .then(productConflicts => {
                    productConflicts.forEach(conflict => this.setConflictInformation(conflict))
                    this.productsWithConflicts = productConflicts
                    this.showSpinner = false
                })
                .catch(() => {
                    this.showSpinner = false
                })
        },

        setConflictInformation (conflict) {
            conflict.updatedAtDisplayText = this.getFormattedLongDate(conflict.updatedAt, this.locale)
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getTableConfig () {
            return [{
                key: 'productNumber',
                label: this.$tc('administration.controlling.conflicts.table.productNumber'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 25
            }, {
                key: 'shortTitle',
                label: this.$tc('administration.controlling.conflicts.table.shortTitle'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 25
            }, {
                key: 'pspElement',
                label: this.$tc('administration.controlling.conflicts.table.pspElement'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 25
            }, {
                key: 'updatedAtDisplayText',
                sortKey: 'updatedAt',
                label: this.$tc('administration.controlling.conflicts.table.updatedAt'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 25
            }]
        }
    },
    watch: {
        '$global.localization.locale': {
            handler: function () {
                this.productsWithConflicts.forEach(conflict => this.setConflictInformation(conflict))
            },
            deep: true
        }
    },
    mounted () {
        this.getProductsWithConflicts()
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="conflicts_table-container">
            <Table table-id="adminProductConflicts"
                   v-bind:table-config="getTableConfig"
                   v-bind:table-data="productsWithConflicts"
                   v-bind:filter-placeholder="$tc('administration.controlling.conflicts.table.filterPlaceholder')"
                   v-bind:table-empty-message="$tc('administration.controlling.conflicts.table.tableEmpty')"
                   v-bind:filter-no-results-message="$tc('administration.controlling.conflicts.table.filterNoResults')"
                   v-bind:read-only=true
                   v-bind:show-spinner="showSpinner"
                   v-bind:expandable-rows="true">
                <!-- productNumber: default cell content -->
                <!-- shortTitle: default cell content -->
                <!-- pspElement: default cell content -->
                <!-- updatedAtDisplayText: default cell content -->
                <template #expandable-content="expandData">
                    <div class="conflicts-expandable-content">
                        <DataRow v-for="conflict in expandData.row.conflicts"
                                 v-bind:key="conflict.field"
                                 v-bind:is-separated-by-bottom-border="true"
                                 v-bind:label-width-percentage="20"
                                 v-bind:label="$tc(`product.details.fields.${conflict.field}`)"
                                 v-bind:value="$tc(`generals.errorCodes.${conflict.errorCode}`)"
                                 message-type="error">
                        </DataRow>
                    </div>
                </template>
            </Table>
        </div>
    </div>
</template>

<style scoped lang="less">
.conflicts_table-container {
    width: 100%;
    height: 100%;
    padding: 0 var(--container-spacing);

    .conflicts-expandable-content {
        width: 100%;
        display: block;
    }
}
</style>
