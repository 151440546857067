<script>
/**
 * Product cockpit sub view | Sub view of a Product
 *
 **/
import ProductServices from '@/views/products/product-portfolio/ProductServices.vue'
import ProductTasks from '@/views/products/product-portfolio/ProductTasks.vue'
import VerticalSplitter from '@/components/VerticalSplitter.vue'

export default {
    name: 'ProductCockpit',
    components: {
        ProductServices,
        ProductTasks,
        VerticalSplitter
    },
    data () {
        return {
            productInfo: this.$attrs.productInfo,
            productTasksUpdater: 0
        }
    },
    methods: {
        updateTaskList () {
            setTimeout(() => {
                this.productTasksUpdater++
            }, 1000)
        }
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="product-cockpit-container">
            <vertical-splitter>
                <template #left-pane>
                    <div class="product-services-wrapper">
                        <ProductServices v-bind:product-id="productInfo.id"
                                         v-bind:product-number="productInfo.productNumber"
                                         @service-started="updateTaskList()">
                        </ProductServices>
                    </div>
                </template>
                <template class="product-tasks-wrapper" #right-pane>
                    <ProductTasks v-bind:key="productTasksUpdater"
                                  v-bind:product-id="productInfo.id">
                    </ProductTasks>
                </template>
            </vertical-splitter>
        </div>
    </div>
</template>

<style lang="less">
.product-cockpit-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .product-services-wrapper {
        position: relative;
        float: left;
        width: 100%;
        height: 100%;
        padding-right: var(--container-spacing);
        overflow: hidden;
    }

    .product-tasks-wrapper {
        position: relative;
        float: left;
        width: calc(100% - var(--product-cockpit-serivces-width));
        height: 100%;
        padding-left: var(--container-spacing);
        overflow: hidden;
        border-left: 1px solid var(--color-border-light);
    }
}
</style>
