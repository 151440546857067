<script>
/**
 * SmartSelect is a custom-made select consisting of an input text field and a container with options as divs.
 * Navigation within the options is possible with arrow key up and down. Escape will close the input.
 * It will throw a @select-submit event on pressing enter or check button,a @select-change event when the select option changes, a @select-blurred event when losing focus and a @select-close event on pressing esc.
 * Available properties are:
 * - id: Should be provided in order to get full functionality. Without an id you won't get the ability to close on esc and instant focus the input field when opening or choosing an option.
 * - label (optional): If provided, there will be a label next to the input field.
 * - tinyLabel (optional): Small label displayed in the top border of the input. If set there will be a border around the whole input field.
 * - defaultOption (optional): If provided, the given option will be preselected per default.
 * - placeholder (optional): The placeholder shown if the input field is empty.
 * - isTypeAhead (default: false): If true, you can submit a value that is not included in options.
 * - allowInput (default: true): If true, you can type text in order to filter and select options. Otherwise, it will work as a simple select field.
 * - options (required): The list of options.
 * - optionLabelSpecifiers (required): Determines which properties of the objects in options will be used as label. The fields in the array will be concatenated.
 * - filterLabelSpecifiers (required): Determines which properties of the objects in options will be used to filter them when input value changes. The calculated value from optionLabelSpecifiers will be added automatically.
 * - sortOptions (default: true): If true, given options will be sorted alphabetically.
 * - submitButton (default: true): If true, you will be able to submit the form by pressing the check button.
 * - isDisabled (default: false): If true, the user can't change the input value.
 * - isAutoFocused (default: false): If true, the input field will get focused automatically when it is rendered
 * - clearInputOnSubmit (default: false): If true, the input will be cleared on submit.
 * - showUnsavedChanges (default: false): If true, the input will highlight if it's value differs from the last submitted one.
 **/

export default {
    name: 'SmartSelect',
    // @select-submit: Event emitted on submit | returns the event and selected option
    // @select-change: Event emitted on option change | returns the selected option
    // @select-blurred: Event emitted on blur | returns the selected option
    // @select-close: Event emitted on pressing esc key | no return
    // Note: We do not declare the emits here, because we want to have the registrations as part of $attrs
    //       emits: ['select-submit', 'select-change', 'select-blurred', 'select-close'],
    props: {
        id: [String, Number],
        label: String,
        tinyLabel: String,
        defaultOption: Object,
        placeholder: String,
        isTypeAhead: {
            default: false,
            type: Boolean
        },
        allowInput: {
            default: true,
            type: Boolean
        },
        options: Array,
        optionLabelSpecifiers: Array,
        filterLabelSpecifiers: Array,
        sortOptions: {
            default: true,
            type: Boolean
        },
        submitButton: {
            default: true,
            type: Boolean
        },
        isDisabled: {
            default: false,
            type: Boolean
        },
        isAutoFocused: {
            default: false,
            type: Boolean
        },
        clearInputOnSubmit: {
            default: false,
            type: Boolean
        },
        showUnsavedChanges: {
            default: false,
            type: Boolean
        }
    },
    data () {
        return {
            selectedOption: null,
            lastSubmittedOption: null,
            visibleOptions: this.options,
            inputValue: '',
            selectContainerElement: null,
            optionsContainerElement: null,
            optionsDisplayedOnTop: false,
            selectFocused: false,
            optionsFocused: false
        }
    },
    methods: {
        initSelect () {
            if (this.defaultOption) {
                this.selectedOption = this.defaultOption
                this.lastSubmittedOption = this.defaultOption
                this.inputValue = this.getDisplayName(this.defaultOption)
            }
            this.visibleOptions = this.options
        },

        getDisplayName (option) {
            let displayName = ''
            this.optionLabelSpecifiers.forEach(key => {
                displayName += `${option[key]} `
            })

            return displayName.trimEnd()
        },

        setSelectFocused (isFocused) {
            this.selectFocused = isFocused
            if (!isFocused) {
                this.selectFocusLost()
            }
        },

        setOptionsFocused (isFocused) {
            this.optionsFocused = isFocused
            if (!isFocused) {
                this.selectFocusLost()
            }
        },

        toggleOptionVisibility (event) {
            event.stopPropagation()
            this.optionsContainerElement.hidden
                ? this.setOptionsVisible()
                : this.setOptionsHidden()
        },

        setOptionsVisible () {
            this.selectFocused = true
            this.calculateOptionsContainerHeight()
            this.optionsContainerElement.removeAttribute('hidden')
        },

        setOptionsHidden () {
            this.optionsContainerElement.setAttribute('hidden', 'hidden')
            this.optionsContainerElement.style.left = '0'
            this.optionsContainerElement.style.top = '0'
        },

        calculateOptionsContainerHeight () {
            const selectContainerBoundingClientRect = this.selectContainerElement.getBoundingClientRect()
            const necessarySpace = (this.visibleOptions.length * 40) < 300
                ? this.visibleOptions.length * 40
                : 300
            const availableSpace = window.innerHeight - (selectContainerBoundingClientRect.y + selectContainerBoundingClientRect.height)
            this.optionsContainerElement.style.left = `${selectContainerBoundingClientRect.x}px`
            if (availableSpace > necessarySpace) {
                this.optionsContainerElement.style.top = `${selectContainerBoundingClientRect.y + selectContainerBoundingClientRect.height}px`
                this.optionsDisplayedOnTop = false
            } else {
                this.optionsContainerElement.style.top = `${selectContainerBoundingClientRect.y - necessarySpace}px`
                this.optionsDisplayedOnTop = true
            }
        },

        focusInputElement () {
            this.selectFocused = true
            document.getElementById(`c_smart-select_${this.id}`).focus()
        },

        inputChanged () {
            this.visibleOptions = this.getOptionsMatchingInputValue
            this.selectedOption = !this.isTypeAhead && this.visibleOptions.length === 1
                ? this.visibleOptions[0]
                : null
            this.setOptionsVisible()
        },

        selectFocusLost () {
            setTimeout(() => {
                if (!this.selectFocused && !this.optionsFocused) {
                    this.setOptionsHidden()
                    if (document.activeElement !== document.getElementById(`c_smart-select_${this.id}`)) {
                        this.emit('select-blurred')
                    }
                }
            }, 250)
        },

        isSelected (option) {
            return option === this.selectedOption
        },

        isOptionDisabled (option) {
            return option.disabled
        },

        setSelected (event, option) {
            if (event) {
                event.stopPropagation()
                this.setOptionsHidden()
            }
            this.selectedOption = option
            this.inputValue = this.getDisplayName(option)
            this.visibleOptions = this.options
            this.focusInputElement()
            this.emit('select-change', true)
        },

        keyPressedHandler (event) {
            let index = this.visibleOptions.indexOf(this.selectedOption)
            if (index === -1 && this.optionsDisplayedOnTop) {
                index = this.visibleOptions.length
            }

            // Find codes for a given key: https://www.toptal.com/developers/keycode
            switch (event.key) {
            case 'Enter':
                event.preventDefault()
                this.emitSubmit(event)
                break
            case 'Escape':
                event.preventDefault()
                if (this.$attrs.selectClose) {
                    this.setOptionsHidden()
                    this.$emit('select-close')
                }
                break
            case 'ArrowDown':
                event.preventDefault()
                if (index + 1 < this.visibleOptions.length) {
                    const nextEnabledOption = this.visibleOptions
                        .slice(index + 1)
                        .find(option => !this.isOptionDisabled(option))
                    if (nextEnabledOption) {
                        this.selectedOption = nextEnabledOption
                        this.inputValue = this.getDisplayName(this.selectedOption)
                    }
                }
                break
            case 'ArrowUp':
                event.preventDefault()
                if (index - 1 > -1) {
                    const previousEnabledOption = this.visibleOptions
                        .slice(0, index)
                        .reverse()
                        .find(option => !this.isOptionDisabled(option))
                    if (previousEnabledOption) {
                        this.selectedOption = previousEnabledOption
                        this.inputValue = this.getDisplayName(this.selectedOption)
                    }
                }
                break
            }
        },

        emitSubmit (event) {
            event.stopPropagation()
            if (this.$attrs.onSelectSubmit && this.hasUnsavedChanges) {
                if (!this.selectedOption) {
                    if (this.isTypeAhead) {
                        this.selectedOption = { value: this.inputValue }
                    } else if (this.visibleOptions.length === 1) {
                        this.selectedOption = this.visibleOptions[0]
                    } else {
                        return
                    }
                }
                this.$emit('select-submit', {
                    event: event,
                    option: this.selectedOption
                })
                if (this.allowInput) {
                    this.visibleOptions = this.options
                }
                if (this.clearInputOnSubmit) {
                    this.inputValue = ''
                    this.selectedOption = null
                } else {
                    this.inputValue = this.getDisplayName(this.selectedOption)
                }
                this.lastSubmittedOption = this.selectedOption
            }
        },

        emit (name, reset) {
            const eventName = 'on' + this.toPascalCase(name)
            if (this.$attrs[eventName] && this.hasUnsavedChanges) {
                this.$emit(name, {
                    option: this.selectedOption
                })
                if (this.clearInputOnSubmit && reset) {
                    this.inputValue = ''
                    this.selectedOption = null
                } else {
                    this.inputValue = this.getDisplayName(this.selectedOption)
                    this.lastSubmittedOption = this.selectedOption
                }
            }
        },

        toPascalCase (kebabCasedString) {
            return kebabCasedString.replace(/(^\w|-\w)/g, (g) => g.replace('-', '').toUpperCase())
        }
    },
    computed: {
        getOptionsMatchingInputValue () {
            const comparisonValue = this.inputValue.toLowerCase()
            return this.options.filter(option => {
                return this.getDisplayName(option).toLowerCase().includes(comparisonValue) ||
                    this.filterLabelSpecifiers.some(key => {
                        return option[key] && option[key].toString().toLowerCase().includes(comparisonValue)
                    })
            })
        },

        getSortedOptions () {
            // Never change reactive values (option-api:data) in computed methods!!!
            // else you risk an endless update loop like described in PAU-2789
            //
            // If you calculate computed values from reactive values use a copy if the original data must be mutated:
            // Here in case of the `visibleOptions` we sort these options but don't change the actual values, thus a
            // shallow copy is enough.
            const options = [...this.visibleOptions]

            return options.sort(function (a, b) {
                const valueA = this.getDisplayName(a)
                const valueB = this.getDisplayName(b)

                return ((valueA === valueB) ? 0 : valueA > valueB) ? 1 : -1
            }.bind(this))
        },

        showSubmitButton () {
            return this.submitButton && this.inputValue !== ''
        },

        hasUnsavedChanges () {
            return this.isTypeAhead
                ? this.inputValue !== ''
                : this.selectedOption !== this.lastSubmittedOption
        }
    },
    watch: {
        options (newOptions, oldOptions) {
            if (!oldOptions || newOptions.toString() !== oldOptions.toString()) {
                this.visibleOptions = this.options
                this.calculateOptionsContainerHeight()
            }
        },

        '$global.localization.locale' () {
            this.initSelect()
        }
    },
    mounted () {
        this.selectContainerElement = document.getElementById(`c_smart-select-container_${this.id}`)
        this.optionsContainerElement = document.getElementById(`c_smart-select-options-container_${this.id}`)
        this.wrapperElement = this.optionsContainerElement?.closest('div.c_table-wrapper') || document.getElementById('app')
        this.wrapperElement?.appendChild(this.optionsContainerElement)
        window.addEventListener('resize', this.calculateOptionsContainerHeight)
        this.initSelect()
        if (this.isAutoFocused) {
            this.focusInputElement()
        }
    },
    beforeUnmount () {
        this.wrapperElement?.removeChild(this.optionsContainerElement)
        window.removeEventListener('resize', this.calculateOptionsContainerHeight)
    }
}
</script>

<template>
    <div class="c_smart-select-wrapper generals-input-wrapper">
        <label v-if="label"
               class="c_smart-select-label generals-input-label">
            <span>{{label}}</span>
        </label>
        <div v-bind:id="`c_smart-select-container_${this.id}`"
             class="c_smart-select-container generals-input-container"
             v-on:mouseenter="isDisabled ? null : setSelectFocused(true)"
             v-on:mouseleave="setSelectFocused(false)">
            <div v-if="tinyLabel"
                 class="c_smart-select-tiny-label generals-input-tiny-label">
                <span>{{tinyLabel}}</span>
            </div>
            <input type="text"
                   v-bind:id="`c_smart-select_${this.id}`"
                   class="c_smart-select generals-input"
                   v-bind:class="{'m--unsaved': showUnsavedChanges && hasUnsavedChanges}"
                   v-bind:disabled="isDisabled"
                   v-bind:placeholder="placeholder || $tc('smartSelect.defaultPlaceholder')"
                   v-bind:readonly="!allowInput"
                   v-on:input="inputChanged()"
                   v-on:keyup="keyPressedHandler($event)"
                   v-on:click="toggleOptionVisibility($event)"
                   v-model="inputValue" />
            <button v-if="!showSubmitButton || !isDisabled"
                    tabindex="-1"
                    class="c_smart-select-submit"
                    v-bind:class="[showSubmitButton ? 'fas fa-check' : 'fas fa-caret-down', {'m--disabled': isDisabled}]"
                    v-on:mousedown="$event.preventDefault()"
                    v-on:click="showSubmitButton ? emitSubmit($event) : toggleOptionVisibility($event)">
                <span class="c_smart-select-icon"></span>
            </button>
            <div hidden="hidden"
                 v-bind:id="`c_smart-select-options-container_${this.id}`"
                 v-on:mouseenter="setOptionsFocused(true)"
                 v-on:mouseleave="setOptionsFocused(false)"
                 class="c_smart-select-options-container">
                <div v-for="(option, index) in sortOptions ? getSortedOptions : visibleOptions"
                     v-bind:key="index"
                     class="c_smart-select-option"
                     v-bind:class="{'m--selected': isSelected(option), 'm--disabled': isOptionDisabled(option)}"
                     v-on:click="setSelected($event, option)">
                    <span>{{getDisplayName(option)}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_smart-select-wrapper {

    .c_smart-select-label {
    }

    .c_smart-select-container {
        .c_smart-select-tiny-label {
        }

        .c_smart-select {
            cursor: default;
        }

        .c_smart-select-submit {

            &.m--disabled {
                pointer-events: none;
            }

            .c_smart-select-icon {
            }
        }
    }
}

.c_smart-select-options-container {
    position: fixed;
    background-color: var(--color-background-default);
    width: auto;
    min-width: var(--input-width);
    max-height: var(--smart-select-max-height);
    overflow-y: auto;
    z-index: var(--z-index-select-options-container);
    box-shadow: 0 6px 10px 0 var(--color-border-dark);

    .c_smart-select-option {
        white-space: nowrap;
        height: var(--input-height);
        cursor: pointer;
        padding: 9px 12px;

        &:not(.m--selected):hover {
            color: var(--color-text-highlighted);
        }

        &.m--selected {
            background-color: var(--color-background-highlighted);
            color: var(--color-text-bright);
        }

        &.m--disabled {
            background-color: var(--color-background-disabled);
            color: var(--color-text-disabled);
            pointer-events: none;
        }
    }
}
</style>
