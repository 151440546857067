<script>
/**
 * ToggleButton is an editable toggle button element.
 * It will throw a @toggle-change event on toggling the button.
 * - id: Should be provided in order to get full functionality.
 * - labelToggleOn: Text displayed as the label of the button when value is true.
 * - labelToggleOff: Text displayed as the label of the button when value is false.
 * - defaultValue (optional): The default value of the input.
 **/
export default {
    name: 'ToggleButton',
    emits: ['toggle-change'],
    props: {
        id: String,
        label: String,
        labelToggleOn: {
            type: String,
            default: 'Ja'
        },
        labelToggleOff: {
            type: String,
            default: 'Nein'
        },
        defaultValue: Boolean
    },
    data () {
        return {
            inputValue: this.defaultValue || false
        }
    },
    methods: {
        emitToggle (value) {
            this.$emit('toggle-change', {
                value
            })
        },
        toggleValue () {
            if (!this.isDisabled) {
                this.inputValue = !this.inputValue
            }
        }
    },
    watch: {
        defaultValue () {
            this.inputValue = this.defaultValue || false
        },

        inputValue () {
            this.emitToggle(this.inputValue)
        }
    }
}
</script>

<template>
    <div class="c_toggle-button-wrapper generals-input-wrapper">
        <label v-if="label" data-testid="toggle-button-label" class="generals-input-label">
            <span>{{label}}</span>
        </label>
        <button class="c_toggle-button"
                v-bind:id="id"
                v-bind:class="{'m--active': inputValue}"
                v-on:click="toggleValue"
                data-testid="toggle-button">
            <span data-testid="toggle-label-span">{{ inputValue ? labelToggleOn : labelToggleOff }}</span>
        </button>
    </div>
</template>

<style lang="less">
.c_toggle-button-wrapper {
    align-items: center;

    .generals-input-label {
        padding-top: 0;
    }

    .c_toggle-button {
        font-size: 16px;
        font-family: "Source Sans Pro", sans-serif;
        text-align: center;
        min-width: 90px;
        height: var(--button-height);
        padding: 0 16px;
        border-radius: 0;
        border: 0;
        cursor: pointer;
        outline: none;
        background-color: var(--color-button-disabled);
        color: var(--color-text-dark);

        &.m--active {
            background-color: var(--color-button-with-background);
            color: var(--color-text-bright);

            &:hover {
                background-color: var(--color-button-with-background-active);
                color:var(--color-text-bright)
            }
        }
    }
}
</style>
