<script>
/**
 * PageOverlay is a pre styled overlay at the bottom of the screen. When clicking the toggle it will show up with an animation, showing its content.
 * - expandedText: Text displayed in the clickable area while overlay is expanded.
 * - collapsedText: Text displayed in the clickable area while overlay is collapsed.
 * - slotSpecifier: Must be provided in order to select the slot. The name of the slot will look like this: slotSpecifier + '_overlay'
 * - useHeader (default: false): If true, the overlay will have separate header slot. Name of the slot will look like this: slotSpecifier + '_overlay-header'.
 * - headerSize (default: medium): Sets the height of the header. Possible values are: small, medium, large.
 **/
export default {
    name: 'PageOverlay',
    props: {
        expandedText: String,
        collapsedText: String,
        slotSpecifier: String,
        useHeader: {
            default: false,
            type: Boolean
        },
        headerSize: {
            default: 'medium',
            type: String
        }
    },
    data () {
        return {
            overlayExpanded: false
        }
    },
    methods: {
        toggleOverlay () {
            this.overlayExpanded = !this.overlayExpanded
        }
    },
    computed: {
        isOverlayExpanded () {
            return this.overlayExpanded
        }
    }
}
</script>

<template>
    <div class="c_page-overlay generals-animate"
         v-bind:class="{'m--expanded': isOverlayExpanded}">
        <div class="c_page-overlay-toggle"
             v-bind:class="{'m--expanded': isOverlayExpanded}"
             v-on:click="toggleOverlay()">
            <span class="c_page-overlay-toggle-icon"
                  v-bind:class="isOverlayExpanded ? 'fas fa-caret-down' : 'fas fa-caret-up'"></span>
            <span>{{isOverlayExpanded
                ? expandedText || $tc('pageOverlay.defaultCollapsePlaceholder')
                : collapsedText || $tc('pageOverlay.defaultExpandPlaceholder')}}</span>
        </div>
        <div v-if="useHeader"
             class="c_page-overlay-header"
             v-bind:class="`m--${headerSize}`">
            <!-- Pass a template to the slot when using the component in order to control the content -->
            <slot v-bind:name="slotSpecifier + '_overlay-header'"></slot>
        </div>
        <div class="c_page-overlay-body"
             v-bind:class="[{'m--has-header': useHeader, 'm--expanded': isOverlayExpanded}, `m--${headerSize}`]">
            <!-- Pass a template to the slot when using the component in order to control the content -->
            <slot v-bind:name="slotSpecifier + '_overlay'"></slot>
        </div>
    </div>
</template>

<style lang="less">
.c_page-overlay {
    position: absolute;
    bottom: 0;
    left: var(--container-spacing);
    padding-bottom: var(--container-spacing);
    width: calc(100% - (var(--container-spacing) * 2));
    height: var(--page-overlay-collapsed-height);
    background-color: var(--color-background-default);
    box-shadow: 0 15px 20px 0 var(--color-border-dark);
    overflow: hidden;
    z-index: var(--z-index-page-overlay);

    &.m--expanded {
        height: var(--page-overlay-expandeded-height);
    }

    &:not(.m--expanded):hover {
        height: calc(var(--page-overlay-collapsed-height) + 15px);
    }

    .c_page-overlay-toggle {
        position: relative;
        width: 100%;
        height: var(--page-overlay-toggle-height);
        text-align: center;
        background-color: var(--color-background-mid);
        padding-top: 7px;
        cursor: pointer;

        &:hover {
            color: var(--color-text-bright);
            background-color: var(--color-background-dark);
        }

        &.m--expanded {
            color: var(--color-text-bright);
            border-color: var(--color-border-active);
            background-color: var(--color-background-highlighted);
            &:hover {
                background-color: var(--color-link-active);
                border-color: var(--color-link-active);
            }
        }

        .c_page-overlay-toggle-icon {
            margin-right: 10px;
        }
    }

    .c_page-overlay-header {
        height: var(--page-overlay-headline-height);
        border-bottom: 1px solid var(--color-border-light);
        &.m--medium {
            height: var(--page-overlay-headline-height-medium);
        }
        &.m--large {
            height: var(--page-overlay-headline-height-large);
        }
    }

    .c_page-overlay-body {
        height: calc(100% - var(--page-overlay-toggle-height));
        padding: var(--container-spacing) var(--container-spacing) 0 var(--container-spacing);
        overflow-y: hidden;

        &.m--expanded {
            overflow-y: auto;
        }

        &.m--has-header {
            &.m--medium {
                height: calc(100% - var(--page-overlay-toggle-height) - var(--page-overlay-headline-height-medium));
            }
            &.m--large {
                height: calc(100% - var(--page-overlay-toggle-height) - var(--page-overlay-headline-height-large));
            }
        }
    }
}

</style>
