<script>
/**
 * InlineForm is a field type component of the SmartForm. It's not meant to be used separately. Created in order to outsource some code.
 * Throws a @data-applied event when selecting a result entry and a @data-reset event when pressing the optional reset button.
 * - field: The smart form field.
 * - fieldProperties: The information about visibility and if the fields are enabled.
 * - page: The current page of the section.
 * - pageIndex: The index of the current section page.
 *
 * @property {String} field.id
 * @property {Boolean} field.enableReset
 * @property {Boolean} field.isLoading
 * @property {Array} field.fields
 * @property {Object} field.request.desiredResponseFields
 *
 **/
import SmartFormSubheading from '@/components/SmartFormSubheading.vue'
import TextInput from '@/components/TextInput.vue'
import Button from '@/components/Button.vue'
import Table from '@/components/Table.vue'
import IconButton from '@/components/IconButton.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { notificationHandler } from '@/mixins/notificationHandler'
import HelpText from '@/components/HelpText.vue'

export default {
    name: 'InlineForm',
    mixins: [
        axiosService,
        userHandler,
        notificationHandler
    ],
    components: {
        HelpText,
        SmartFormSubheading,
        TextInput,
        Button,
        Table,
        IconButton
    },
    // @data-applied: Event emitted when choosing a row of the result | returns the updated page, changes and ids of changed fields
    emits: ['data-applied'],
    props: {
        field: Object,
        fieldProperties: Object,
        page: Object,
        pageIndex: Number
    },
    data () {
        return {
            temporarySearchFieldsInformation: [],
            currentPage: this.page,
            helpTextWidth: 340
        }
    },
    methods: {
        isFieldVisible (field) {
            const fieldProperties = this.fieldProperties[field.id]
            return fieldProperties
                ? fieldProperties.visible
                : false
        },

        isFieldDisabled (field) {
            const fieldProperties = this.fieldProperties[field.id]
            return fieldProperties
                ? fieldProperties.disabled
                : true
        },

        getFieldValue (field) {
            return this.currentPage[field.id]
        },

        executeSearch (searchField) {
            // Closures to simplify code structure
            function prepareRequest (appContext, field, requestData) {
                if (field.request.apiType.toLowerCase() === 'get') {
                    let url = field.request.api
                    Object.keys(requestData).forEach((key, index) => {
                        url += index === 0
                            ? `?${key}=${requestData[key]}`
                            : `&${key}=${requestData[key]}`
                    })
                    appContext.axiosGet(
                        url,
                        appContext.$tc('smartForm.searchError'),
                        null,
                        field.request.isExternalApi)
                        .then(response => {
                            field.isLoading = false
                            Array.isArray(response) && field.request.desiredResponseFields && field.request.desiredResponseFields.length > 0
                                ? handleSearchSuccess(appContext, field, response)
                                : appContext.applySearchData(field, response)
                        })
                        .catch(() => {
                            field.isLoading = false
                            appContext.$forceUpdate()
                        })
                } else {
                    appContext.axiosPost(
                        field.request.api,
                        requestData,
                        appContext.$tc('smartForm.searchError'),
                        null,
                        field.request.isExternalApi)
                        .then(response => {
                            field.isLoading = false
                            Array.isArray(response) && field.request.desiredResponseFields
                                ? handleSearchSuccess(appContext, field, response)
                                : appContext.applySearchData(field, response)
                        })
                        .catch(() => {
                            field.isLoading = false
                            appContext.$forceUpdate()
                        })
                }
            }
            function handleSearchSuccess (appContext, field, pickList) {
                appContext.setSearchResults(field, pickList)
                if (pickList.length > 0) {
                    appContext.openSearchResults(field)
                } else {
                    appContext.closeSearchResults(field)
                    const userPreferences = appContext.getUserSettingsParameter('userPreferences')
                    if (userPreferences.reducedNotifications) {
                        appContext.addCursorNotification({
                            type: 'info',
                            duration: 3000,
                            message: appContext.$tc('smartForm.emptySearchResult.short')
                        })
                    } else {
                        appContext.addNotification({
                            type: 'info',
                            duration: 5000,
                            message: appContext.$tc('smartForm.emptySearchResult.standard')
                        })
                    }
                }
            }

            const data = {}
            searchField.isLoading = true
            this.$forceUpdate()

            searchField.fields.forEach(field => {
                const input = document.getElementById(`c_text-input_${searchField.id}_${field.id}_${this.pageIndex}`)
                if (input) {
                    const key = field.apiParameter || field.id
                    data[key] = input.value || ''
                }
            })

            if (searchField.request.api && searchField.request.apiType) {
                prepareRequest(this, searchField, data)
            } else {
                searchField.isLoading = false
                this.addNotification({
                    type: 'error',
                    duration: 10000,
                    message: this.$tc('smartForm.noSearchDefined')
                })
            }
        },

        allowUserSelectionViaResultsTable (field) {
            return field.request.desiredResponseFields && field.request.desiredResponseFields.length > 0
        },

        getTemporarySearchFieldInfoById (fieldId) {
            return this.temporarySearchFieldsInformation.find(fieldInfo => fieldInfo.id === `${fieldId}_${this.pageIndex}`)
        },

        searchFieldExpanded (searchField) {
            const searchFieldInfo = this.getTemporarySearchFieldInfoById(searchField.id)
            return searchFieldInfo && searchFieldInfo.isOpen
        },

        getSearchResults (searchField) {
            const searchFieldInfo = this.getTemporarySearchFieldInfoById(searchField.id)
            return searchFieldInfo
                ? searchFieldInfo.results
                : []
        },

        setSearchResults (searchField, results) {
            const searchFieldInfo = this.getTemporarySearchFieldInfoById(searchField.id)
            if (searchFieldInfo) {
                searchFieldInfo.results = results
                searchFieldInfo.isOpen = true
            } else {
                this.temporarySearchFieldsInformation.push({
                    id: `${searchField.id}_${this.pageIndex}`,
                    results: results,
                    isOpen: true
                })
            }
        },

        openSearchResults (searchField) {
            const searchFieldInfo = this.getTemporarySearchFieldInfoById(searchField.id)
            if (searchFieldInfo) {
                searchFieldInfo.isOpen = true
            }
        },

        closeSearchResults (searchField) {
            const searchFieldInfo = this.getTemporarySearchFieldInfoById(searchField.id)
            if (searchFieldInfo) {
                searchFieldInfo.isOpen = false
            }
        },

        getTableConfig (searchField) {
            const tableConfig = []
            const columnWidth = 90 / searchField.request.desiredResponseFields.length

            searchField.request.desiredResponseFields.forEach(key => {
                tableConfig.push({
                    key: key,
                    label: null,
                    filterable: true,
                    sortable: false,
                    alignment: 'left',
                    width: columnWidth
                })
            })
            tableConfig.push({
                key: 'action',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'right',
                width: 10
            })
            tableConfig[0].label = this.$tc('smartForm.pickEntry')
            return tableConfig
        },

        applySearchData (searchField, dataObject) {
            const changedFieldIds = []
            const changes = {}
            Object.keys(searchField.request.formFieldMappings).forEach(mappingKey => {
                const fieldId = searchField.request.formFieldMappings[mappingKey]
                this.currentPage[fieldId] = dataObject[mappingKey] || null
                changes[fieldId] = dataObject[mappingKey] || null
                changedFieldIds.push(fieldId)
            })
            this.closeSearchResults(searchField)
            this.$emit(
                'data-applied',
                {
                    changedFieldIds: changedFieldIds,
                    changes: changes,
                    page: this.currentPage
                })
        },

        resetData (searchField) {
            const changedFieldIds = []
            const changes = {}
            Object.keys(searchField.request.formFieldMappings).forEach(mappingKey => {
                const fieldId = searchField.request.formFieldMappings[mappingKey]
                delete this.currentPage[fieldId]
                changes[fieldId] = null
                changedFieldIds.push(fieldId)
            })
            this.$emit(
                'data-reset',
                {
                    changedFieldIds: changedFieldIds,
                    changes: changes,
                    page: this.currentPage
                })
        },

        getTranslatedObjectProperty (object, property) {
            // Check for language and default to german
            const language = this.$global.localization.locale
            return object[`${property}_${language}`] || object[property]
        }
    },
    mounted () {
        if (!this.field.request) {
            // Convert old api configuration to request in order to have consistent logic
            convertLegacySearchField(this.field)
        }
    }
}

function convertLegacySearchField (field) {
    field.request = {
        api: field.apiCall,
        apiType: field.apiType,
        isExternalApi: field.isExternalApi,
        desiredResponseFields: field.desiredResponseFields,
        formFieldMappings: field.formFieldMappings
    }
}
</script>

<template>
    <div class="c_inline-form">
        <SmartFormSubheading v-bind:label="getTranslatedObjectProperty(field, 'label')" />
        <HelpText v-if="field.description"
                  class="c_inline-form-help-container m--header"
                  v-bind:text="getTranslatedObjectProperty(field, 'description')"
                  v-bind:width-px="helpTextWidth"
                  position="left">
        </HelpText>
        <div v-for="input in field.fields"
             v-bind:key="`${input.id}_${pageIndex}`">
            <TextInput v-show="isFieldVisible(input)"
                       v-bind:is-disabled="isFieldDisabled(input)"
                       v-bind:id="`${field.id}_${input.id}_${pageIndex}`"
                       v-bind:submit-button=false
                       v-bind:label="getTranslatedObjectProperty(input, 'label')"
                       v-bind:placeholder="getTranslatedObjectProperty(input, 'placeholder')"
                       v-bind:default-value="getFieldValue(input)"
                       class="c_inline-form-input"
                       icon-class="fas fa-pen">
            </TextInput>
            <HelpText v-if="input.description"
                      class="c_inline-form-help-container"
                      v-bind:text="getTranslatedObjectProperty(input, 'description')"
                      v-bind:width-px="helpTextWidth"
                      position="left">
            </HelpText>
        </div>
        <Button v-if="field.enableReset"
                class="c_inline-form-button"
                v-bind:is-disabled="isFieldDisabled(field)"
                button-type="cancel"
                @button-submit="resetData(field)">
            <template v-slot>
                <span>{{$tc('smartForm.resetData')}}</span>
            </template>
        </Button>
        <Button class="c_inline-form-button"
                v-bind:is-disabled="isFieldDisabled(field)"
                button-type="submit"
                @button-submit="executeSearch(field)">
            <template v-slot>
                <span>
                    <span v-if="field.isLoading"
                          class="fas fa-circle-notch fa-spin"></span>
                    {{$tc('smartForm.startSearch')}}
                </span>
            </template>
        </Button>
        <div v-if="allowUserSelectionViaResultsTable(field)"
             class="c_smart-form-field-subheading">
            <div class="c_inline-form-results">
                <div class="c_inline-form-toggle"
                     v-bind:class="{
                        'm--disabled': getSearchResults(field).length === 0,
                        'm--expanded': searchFieldExpanded(field)
                    }"
                     v-on:click="searchFieldExpanded(field) ? closeSearchResults(field) : openSearchResults(field)">
                    <span class="c_inline-form-toggle-icon"
                          v-bind:class="searchFieldExpanded(field) ? 'fas fa-caret-up' : 'fas fa-caret-down'"></span>
                    <span>{{searchFieldExpanded(field) ? $tc('smartForm.collapseSearchResults') : $tc('smartForm.expandSearchResults')}}</span>
                </div>
                <div class="c_inline-form-table generals-animate"
                     v-bind:class="{'m--height-expanded': searchFieldExpanded(field)}">
                    <Table v-if="getSearchResults(field).length > 0"
                           v-bind:table-id="`field-form-table_${field.id}`"
                           v-bind:table-data="getSearchResults(field)"
                           v-bind:read-only=true
                           v-bind:filter-placeholder="$tc('smartForm.tableFilter')"
                           v-bind:default-sorting-key="field.request.desiredResponseFields[0]"
                           v-bind:table-config="getTableConfig(field)">
                        <!-- responseFields: default cell content -->
                        <template #cell(action)="data">
                            <IconButton icon-class="fas fa-check"
                                        v-bind:tooltip="$tc('smartForm.selectData')"
                                        @button-submit="applySearchData(field, data.row)">
                            </IconButton>
                        </template>
                    </Table>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_inline-form {

    .c_inline-form-help-container {
        position: absolute;
        right: calc(var(--container-spacing) * -1);
        margin-top: -45px;

        &.m--header {
            margin-top: -40px;
        }
    }

    .c_inline-form-input {
        margin-bottom: 20px;
    }

    .c_inline-form-button {
        margin-left: var(--container-spacing);
        float: right;
    }

    .c_inline-form-results {

        .c_inline-form-toggle {
            font-size: 12px;
            text-align: center;
            cursor: pointer;
            padding: 1px 0;
            margin-bottom: -16px;
            background-color: var(--color-background-mid);

            &:hover {
                background-color: var(--color-background-dark);
                color: var(--color-text-bright);
            }

            &.m--expanded {
                background-color: var(--color-background-highlighted);
                color: var(--color-text-bright);

                &:hover {
                    background-color: var(--color-link-active);
                }
            }

            &.m--disabled {
                pointer-events: none;
                background-color: transparent;
                color: var(--color-text-disabled);
            }

            .c_inline-form-toggle-icon {
                margin-right: 4px;
            }
        }

        .c_inline-form-table {
            position: fixed;
            height: 0;
            margin-top: 16px;
            width: 100%;
            background-color: white;
            padding: 0;
            overflow: hidden;
            opacity: 0;
            z-index: var(--z-index-smart-form-field-search-table);
            box-shadow: 0 12px 15px 0 var(--color-border-dark);

            &.m--height-expanded {
                padding: 10px var(--container-spacing) var(--container-spacing) var(--container-spacing);
                height: 300px;
                opacity: 1;
            }
        }
    }
}
</style>
