<script>/**
 * DateInput is a pre styled input date element.
 * It will throw an @input-submit event on pressing enter or clicking the submit icon, an @input-change event when input value changes, an @input-blurred event when losing focus and an @input-close event on pressing esc.
 *
 * The following Props are supported:
 * - id: Should be provided in order to get full functionality. Without an id you won't get the ability to close on esc and instant focus the input field when opening.
 * - label (optional): Text displayed as the label of the input field.
 * - defaultValue (optional): The default value of the input. For editing purposes for example.
 * - minimumDate (optional): If set, this date will be the minimum value that can be picked in the calendar.
 * - maximumDate (optional): If set, this date will be the maximum value that can be picked in the calendar.
 * - placeholder (optional): Date picker default.
 * - submitButton (default: true): Determines whether there is a submit button.
 * - isDisabled (default: false): If true, the user can't change the input value.
 * - isAutoFocused (default: false): If true, the input field will get focused automatically when it is rendered.
 * - showUnsavedChanges (default: false): If true, the input will highlight if it's value differs from the last submitted one.
 * - noDays (default: false): If true, the date picker will only consist of year and month.
 *
 * Additionally, it is possible to set e.g. an Icon in front of the date-input field. For this, the
 * slot with name="input-prefix" can be used.
 **/
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'DateInput',
    mixins: [
        dateTimeHelper
    ],
    // @input-submit: Event emitted on submit | returns the event and current input value
    // @input-change: Event emitted on input | returns current input value
    // @input-blurred: Event emitted on blur | returns current input value
    // @input-close: Event emitted on pressing esc key | no return
    // Note: We do not declare the emits here, because we want to have the registrations as part of $attrs
    //       emits: ['input-submit', 'input-change', 'input-blurred', 'input-close'],
    props: {
        id: String,
        label: String,
        defaultValue: String,
        placeholder: String,
        minimumDate: Date,
        maximumDate: Date,
        submitButton: {
            default: true,
            type: Boolean
        },
        isDisabled: {
            default: false,
            type: Boolean
        },
        isAutoFocused: {
            default: false,
            type: Boolean
        },
        showUnsavedChanges: {
            default: false,
            type: Boolean
        },
        noDays: {
            default: false,
            type: Boolean
        }
    },
    data () {
        return {
            inputValue: '',
            lastSubmittedValue: '',
            defaultMaxDate: this.tryToParseToDate('9999-12-31T00:00:00')
        }
    },
    methods: {
        focusInputElement () {
            document.getElementById(`c_date-input_${this.id}`).focus()
        },

        setPreselection (event) {
            event.stopPropagation()
            if (this.inputValue === '') {
                this.inputValue = this.placeholder
            }
        },

        keyPressedHandler (event) {
            if (event.key === 'Enter') {
                this.emitSubmit(event)
            } else if (event.key === 'Escape') {
                this.$emit('input-close')
            }
        },

        emitSubmit (event) {
            // We actually need to check for listeners as the lastSubmittedValue must not be
            // updated if there is no listener which performs the actual submit
            //
            // see https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/593#note_316549
            //     for further information
            if (this.$attrs.onInputSubmit) {
                this.emit(event, 'input-submit')
                this.updateLastSubmittedValue()
            }
        },

        emitBlur () {
            // We actually need to check for listeners as the lastSubmittedValue must not be
            // updated if there is no listener which performs the actual submit
            //
            // see https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/593#note_316549
            //     for further information
            if (this.$attrs.onInputBlurred) {
                this.emit(null, 'input-blurred')
                this.updateLastSubmittedValue()
            }
        },

        emit (event, name) {
            if (event) {
                event.stopPropagation()
            }
            if (this.inputValue && this.hasUnsavedChanges) {
                const dateAsISOString = this.toDateAndTimeAsISOString(this.inputValue)
                if (dateAsISOString !== null) {
                    this.$emit(name, {
                        event: event,
                        value: dateAsISOString
                    })
                }
            }
        },

        updateLastSubmittedValue () {
            this.lastSubmittedValue = this.inputValue
        },

        setDefault () {
            if (this.defaultValue) {
                const value = this.toBerlinDateAsISOString(this.tryToParseToDate(this.defaultValue))
                this.inputValue = value
                this.lastSubmittedValue = value
            }
        }
    },
    computed: {
        hasUnsavedChanges () {
            return this.inputValue !== this.lastSubmittedValue
        }
    },
    watch: {
        inputValue () {
            this.emit(null, 'input-change')
        },
        defaultValue () {
            this.setDefault()
        }
    },
    mounted () {
        this.setDefault()
        if (this.isAutoFocused) {
            this.focusInputElement()
        }
    }
}
</script>

<template>
    <div class="c_date-input-wrapper generals-input-wrapper">
        <label v-if="label" class="c_date-input-label generals-input-label">
            <span>{{label}}</span>
        </label>
        <div class="c_date-input-container generals-input-container">
            <slot name="input-prefix"/>
            <input v-bind:id="id ? `c_date-input_${id}`: null"
                   data-testid="date-input"
                   v-bind:type="noDays ? 'month' : 'date'"
                   class="c_date-input generals-input"
                   v-bind:disabled="isDisabled"
                   v-bind:min="toBerlinDateAsISOString(minimumDate)"
                   v-bind:max="toBerlinDateAsISOString(maximumDate || defaultMaxDate)"
                   v-bind:class="{
                       'm--no-icon': !submitButton,
                       'm--unsaved': showUnsavedChanges && hasUnsavedChanges
                   }"
                   v-on:blur="emitBlur()"
                   v-on:click="setPreselection($event)"
                   v-on:keyup="keyPressedHandler($event)"
                   v-model="inputValue" />
            <button v-if="!isDisabled && submitButton"
                    data-testid="date-input-submit"
                    tabindex="-1"
                    class="c_date-input-submit"
                    v-on:mousedown="$event.preventDefault()"
                    v-on:click="emitSubmit($event)">
                <span class="c_number-input-icon fas fa-check"></span>
            </button>
        </div>
    </div>
</template>

<style lang="less">
.c_date-input-wrapper {

    .c_date-input-label {
    }

    .c_date-input-container {
        display: flex;
        align-items: center;

        .c_date-input {
        }

        .c_date-input-submit {

            .c_date-input-icon {
            }
        }
    }
}
</style>
