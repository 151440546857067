<script>
/**
 * Task view
 *
 * @property {String} formJson
 **/
import Button from '@/components/Button.vue'
import EssentialSmartForm from '@/components/EssentialSmartForm.vue'
import { axiosService } from '@/mixins/axiosService'
import { taskExecutionHandler } from '@/mixins/taskExecutionHandler'
import { notificationHandler } from '@/mixins/notificationHandler'
import { smartFormHelper } from '@/mixins/smartFormHelper'
import { downloadHandler } from '@/mixins/downloadHandler'

export default {
    name: 'CustomTaskTitleDoc',
    mixins: [
        axiosService,
        taskExecutionHandler,
        notificationHandler,
        smartFormHelper,
        downloadHandler
    ],
    components: {
        Button,
        EssentialSmartForm
    },
    // @task-completable-changed: Event emitted when the task-completable state changed
    emits: ['task-completable-changed'],
    props: {
        taskData: Object
    },
    data () {
        return {
            task: this.taskData,
            titleDocForm: null,
            formExists: false,
            productContentsChecklistOptions: [],
            productContentsChecklistField: 'assignment_product_component',
            formType: 'TitleDocForm',
            formIsValid: false,
            formWasSavedSuccessfully: true,
            fieldsToApplyFromForeignTitleDoc: [
                'contract_manager',
                'email_contract_manager',
                'fee_manager',
                'type_of_school',
                'curricula',
                'free_copies',
                'obligation_to_cooperate',
                'no_fee_based_contributions',
                'sales_fees_foreseen',
                'editor_share',
                'total_authors_share',
                'title',
                'forename',
                'surname',
                'adress',
                'housenumber',
                'place_name',
                'zipcode',
                'add_adress',
                'email',
                'GP_number',
                'contract_data_author',
                'fee_notes_AHM'
            ]
        }
    },
    methods: {
        async getTitleDoc (searchText) {
            async function getContractManager (appContext) {
                return appContext.axiosGet(
                    `contractmanager/find/${appContext.task.productNumber}`)
                    .then(licenseManager => {
                        return Promise.resolve(licenseManager)
                    })
                    .catch(() => {
                        const durations = {
                            short: 5000,
                            standard: 10000
                        }
                        const titleDocAppliedMessages = {
                            short: appContext.$tc('task.taskViews.titleDoc.getContractManagerInfo.short'),
                            standard: appContext.$t('task.taskViews.titleDoc.getContractManagerInfo.standard', [searchText])
                        }
                        appContext.configureNotification('info', durations, titleDocAppliedMessages)
                        return Promise.resolve(null)
                    })
            }
            function setupTitleDocForm (formConfig, values, appContext) {
                function applyProductContents (field, appContext) {
                    if (field.id === appContext.productContentsChecklistField &&
                        appContext.productContentsChecklistOptions.length > 0) {
                        delete field.invisible
                        field.options = appContext.productContentsChecklistOptions
                    }
                }
                if (!values) {
                    values = {}
                }
                if (contractManagerInfo) {
                    values.contract_manager = contractManagerInfo.contract_manager
                    values.email_contract_manager = contractManagerInfo.email_contract_manager
                }
                const changes = appContext.applyValuesToFormConfigurationAndReturnChanges(null, values, true, formConfig, applyProductContents, appContext)
                appContext.formExists = true
                appContext.titleDocForm = formConfig
                // save once on load, so that all applied default values will get stored in custom_form_data
                appContext.saveForm({ formConfig: appContext.titleDocForm, changes: changes })
            }

            const contractManagerInfo = await getContractManager(this)
            const form = this.loadFormConfiguration('TITLEDOC')
            const productContents = await this.axiosGet(`contents/v2/${this.task.productId}`)
            productContents.contents.forEach(content => this.productContentsChecklistOptions.push({
                label: content.name,
                value: content.name
            }))

            this.axiosGet(`forms/search/${this.formType}/${this.task.productNumber}`)
                .then(formData => {
                    setupTitleDocForm(form, formData.values, this)
                })
                .catch(errorResponse => {
                    if (errorResponse.status === 500) {
                        this.addNotification({
                            type: 'error',
                            duration: 10000,
                            message: this.$tc('task.taskViews.titleDoc.getTitleDocError')
                        })
                    } else if (errorResponse.status === 422) {
                        this.saveForm({ formConfig: form, changes: {} })
                            .then(formData => {
                                setupTitleDocForm(form, formData.values, this)
                            })
                            .catch(() => {})
                    }
                })
        },

        getAndApplyForeignTitleDoc (productNumberOrIsbn) {
            this.axiosGet(`forms/search/TitleDocForm/${productNumberOrIsbn}`,
                this.$tc('task.taskViews.titleDoc.getForeignTitleDocError'))
                .then(formData => {
                    delete formData.values.contract_manager
                    delete formData.values.email_contract_manager
                    const changes = this.applyValuesToFormConfigurationAndReturnChanges(null, formData.values, true, this.titleDocForm)
                    this.saveForm({ formConfig: this.titleDocForm, changes: changes })
                        .then(() => {
                            this.formExists = true
                            const durations = {
                                short: 3000,
                                standard: 5000
                            }
                            const titleDocAppliedMessages = {
                                short: this.$tc('task.taskViews.titleDoc.getForeignTitleDocSuccess.short'),
                                standard: this.$t('task.taskViews.titleDoc.getForeignTitleDocSuccess.standard', [productNumberOrIsbn])
                            }
                            this.configureNotification('success', durations, titleDocAppliedMessages)
                        })
                        .catch(() => {})
                })
                .catch(() => {})
        },

        getTitleDocForForeignProduct () {
            this.$swal({
                title: this.$tc('task.taskViews.titleDoc.getForeignTitleDocPopup.title'),
                html: this.$tc('task.taskViews.titleDoc.getForeignTitleDocPopup.text'),
                icon: 'info',
                input: 'text',
                inputPlaceholder: this.$tc('task.taskViews.titleDoc.getForeignTitleDocPopup.inputPlaceholder'),
                showConfirmButton: true,
                confirmButtonText: this.$tc('task.taskViews.titleDoc.getForeignTitleDocPopup.confirmText'),
                showCancelButton: true,
                cancelButtonText: this.$tc('generals.cancel'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.isConfirmed) {
                    const searchText = result.value.replaceAll('-', '')
                    this.getAndApplyForeignTitleDoc(searchText)
                }
            })
        },

        setFormValid (isValid) {
            this.formIsValid = isValid
        },

        saveForm (formSubmitEventData) {
            return this.saveSmartForm(
                this.formType,
                this.task.productNumber,
                formSubmitEventData.formConfig,
                formSubmitEventData.changes,
                this.formExists,
                this.$tc('task.taskViews.titleDoc.updateTitleDocError'))
                .then(formData => {
                    this.formExists = true
                    this.formWasSavedSuccessfully = true
                    return Promise.resolve(formData)
                })
                .catch(() => {
                    this.formWasSavedSuccessfully = false
                    return Promise.reject(new Error())
                })
        },
        downloadTitleDocFile () {
            this.downloadFileFromUrl(`titledocform/${this.task.productNumber}`)
        }
    },
    computed: {
        isTaskCompletable () {
            return this.formIsValid && this.formWasSavedSuccessfully
        }
    },
    watch: {
        isTaskCompletable (isCompletable) {
            this.$emit('task-completable-changed', {
                isCompletable: isCompletable
            })
        }
    },
    mounted () {
        this.getTitleDoc(this.task.productNumber)
    }
}
</script>

<template>
    <div class="custom-task-content-container">
        <div class="custom-task-header-button-bar">
            <Button  class="custom-task-button"
                     button-type="submit"
                    v-bind:is-disabled="!isTaskCompletable"
                    @button-submit="downloadTitleDocFile()">
                <template v-slot>
                    <span class="fas fa-download custom-task-button-icon"></span>
                    <span>{{$tc('task.taskViews.titleDoc.getPreviewButtonText')}}</span>
                </template>
            </Button>
            <Button class="custom-task-button"
                    button-type="submit"
                    @button-submit="getTitleDocForForeignProduct()">
                <template v-slot>
                    <span class="fas fa-search custom-task-button-icon"></span>
                    <span> {{$tc('task.taskViews.titleDoc.getForeignTitleDocButtonText')}}</span>
                </template>
            </Button>
        </div>
        <EssentialSmartForm v-if="titleDocForm"
                            id="titleDocForm"
                            v-bind:hide-submit-button="true"
                            v-bind:form-config="titleDocForm"
                            @form-change="saveForm($event)"
                            @form-submittable-change="setFormValid($event)">
        </EssentialSmartForm>
    </div>
</template>

<style scoped lang="less">
.custom-task-content-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    position: relative; /* Ensure child absolute positioning works within this container */

    .custom-task-header-button-bar {
        position: absolute;
        right: var(--container-spacing);
        z-index: var(--z-index-header-button);

        display: flex;
        column-gap: 1rem;
        align-items: center;

        .custom-task-button {
            height: var(--smart-form-line-height);

            .custom-task-button-icon {
                margin-right: 0.5rem;
            }
        }
    }
}
</style>
