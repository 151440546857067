/**
 * The router of the application.
 * Determines the paths and properties of the different views and how they are related to each other.
 **/
import { createRouter, createWebHashHistory } from 'vue-router'

import ProductList from '../views/products/ProductList.vue'
import Product from '../views/products/Product.vue'
import ProductCockpit from '../views/products/product-portfolio/ProductCockpit.vue'
import ProductInformation from '@/views/products/product-portfolio/ProductInformation.vue'
import ProductContents from '@/views/products/product-portfolio/ProductContents.vue'
import ProductSchedule from '@/views/products/product-portfolio/ProductSchedule.vue'
import ProductAssetOrders from '@/views/products/product-portfolio/ProductAssetOrders.vue'
import ProductProcessHistory from '@/views/products/product-portfolio/ProductProcessHistory.vue'
import TasksListOverview from '@/views/tasks/TasksListOverview.vue'
import TaskList from '@/views/tasks/TaskList.vue'
import HiddenTasksList from '@/views/tasks/HiddenTasksList.vue'
import Task from '@/views/tasks/Task.vue'
import AdminTaskOverview from '@/views/administration/AdminTaskOverview.vue'
import CurrentTasks from '@/views/administration/admin-task-views/CurrentTasks.vue'
import FailedJobs from '@/views/administration/admin-task-views/FailedJobs.vue'
import Controlling from '@/views/administration/Controlling.vue'
import ProcessModels from '@/views/administration/controlling-views/ProcessModels.vue'
import MigrationsOverview from '@/views/administration/controlling-views/MigrationsOverview.vue'
import ProductCompletion from '@/views/administration/controlling-views/ProductCompletion.vue'
import ProductConflicts from '@/views/administration/controlling-views/ProductConflicts.vue'
import Operation from '@/views/administration/controlling-views/Operation.vue'
import CreateTestUser from '@/views/administration/controlling-views/CreateTestUser.vue'
import ProcessMigration from '@/views/administration/controlling-views/ProcessMigration.vue'
import Playground from '@/views/administration/Playground.vue'
import Gallery from '@/views/administration/Gallery.vue'

const routes = [
    {
        path: '/product-list',
        name: 'ProductList',
        component: ProductList
    },
    {
        path: '/product-list/:productId',
        name: 'Product',
        component: Product,
        props: true,
        children: [
            {
                path: 'cockpit',
                name: 'ProductCockpit',
                component: ProductCockpit,
                props: true
            },
            {
                path: 'info',
                name: 'ProductInformation',
                component: ProductInformation,
                props: true
            },
            {
                path: 'contents',
                name: 'ProductContents',
                component: ProductContents,
                props: true
            },
            {
                path: 'schedule',
                name: 'ProductSchedule',
                component: ProductSchedule,
                props: true
            },
            {
                path: 'assetOrders',
                name: 'ProductAssetOrders',
                component: ProductAssetOrders,
                props: true
            },
            {
                path: 'processHistory',
                name: 'ProductProcessHistory',
                component: ProductProcessHistory,
                props: true
            }
        ]
    },
    {
        path: '/task-list',
        name: 'TasksListOverview',
        component: TasksListOverview,
        children: [
            {
                path: 'visible',
                name: 'TaskList',
                component: TaskList
            },
            {
                path: 'hidden',
                name: 'HiddenTasksList',
                component: HiddenTasksList
            }
        ]
    },
    {
        path: '/task-list/:taskId',
        name: 'Task',
        component: Task,
        props: true
    },
    {
        path: '/administration',
        children: [
            {
                path: 'task-overview',
                name: 'AdminTaskOverview',
                component: AdminTaskOverview,
                children: [
                    {
                        path: 'current-tasks',
                        name: 'CurrentTasks',
                        component: CurrentTasks
                    },
                    {
                        path: 'failed-jobs',
                        name: 'FailedJobs',
                        component: FailedJobs
                    }
                ]
            },
            {
                path: 'controlling',
                name: 'Controlling',
                component: Controlling,
                children: [
                    {
                        path: 'process-models',
                        name: 'ProcessModels',
                        component: ProcessModels
                    },
                    {
                        path: 'process-migrations',
                        name: 'MigrationsOverview',
                        component: MigrationsOverview
                    },
                    {
                        path: 'process-migrations/:processId',
                        name: 'ProcessMigration',
                        component: ProcessMigration,
                        props: true
                    },
                    {
                        path: 'product-completion',
                        name: 'ProductCompletion',
                        component: ProductCompletion
                    },
                    {
                        path: 'conflicts',
                        name: 'ProductConflicts',
                        component: ProductConflicts
                    },
                    {
                        path: 'operation',
                        name: 'Operation',
                        component: Operation
                    },
                    {
                        path: 'test-user',
                        name: 'CreateTestUser',
                        component: CreateTestUser
                    }
                ]
            },
            {
                path: 'playground',
                name: 'Playground',
                component: Playground
            },
            {
                path: 'gallery',
                name: 'Gallery',
                component: Gallery
            },
            {
                path: '/',
                redirect: 'product-list'
            }
        ]
    }

]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

// HACK this is a workaround: when using createWebHashHistory, the "to" route needs to be rewritten
// see https://stackoverflow.com/questions/73903119/keycloak-js-appending-session-data-to-url-in-vue3 and
//     https://github.com/gnieser/vue-keycloak/blob/master/src/router/index.js
router.beforeEach((to) => {
    if (to.path.includes('&state')) {
        return {
            path: to.path.substring(0, to.path.indexOf('&state')) // remove the query params which keycloak appended
        }
    }
})

export default router
