<script>
/**
 * EntryList is a custom-made list entries with a label and an optional icon button.
 * It will throw an @entry-click event when clicking the label and an @entry-submit event when pressing the icon button.
 * Available properties are:
 * - label (optional): If provided, there will be a label next to the input field.
 * - entries (required): The list of labels which represents the entries.
 * - iconClass (optional): Determines what icon shall be used for action button.
 * - enableClick (default: true): Enable the click functionality of the label. Be aware that if your expression evaluates to null or undefined, this will be set true!
 * - enableSubmit (default: true): Show and enable the icon button. Be aware that if your expression evaluates to null or undefined, this will be set true!
 * - entryLabelKey (default: label): The key of the entry object which represents the label.
 * - entryTooltipKey (default: tooltip): The key of the entry object which represents the tooltip.
 * - entryDisabledKey (optional): If provided, the entry will be disabled if the given key is set to true.
 **/
import IconButton from '@/components/IconButton.vue'

export default {
    name: 'EntryList',
    components: {
        IconButton
    },
    // @entry-click: Event emitted on pressing the label | returns the entry and its index within the list.
    // @entry-submit: Event emitted on pressing icon button | returns the entry and its index within the list.
    emits: ['entry-click', 'entry-submit'],
    props: {
        label: String,
        entries: Array,
        iconClass: String,
        enableClick: {
            default: true,
            type: Boolean
        },
        enableSubmit: {
            default: true,
            type: Boolean
        },
        entryLabelKey: {
            default: 'label',
            type: String
        },
        entryTooltipKey: {
            default: 'tooltip',
            type: String
        },
        entryDisabledKey: {
            default: 'isDisabled',
            type: String
        }
    },
    data () {
        return {
            icon: this.iconClass || 'fas fa-question-circle'
        }
    },
    methods: {
        getEntryLabel (entry) {
            return entry[this.entryLabelKey] || this.$tc('entryList.defaultLabel')
        },

        /**
         *  returns a tooltip, if set at the entry
         *  note, that line breaks can be achieved with '\n', e.g. 'line1\nline2\nline...'
         */
        getEntryTooltip (entry) {
            return entry[this.entryTooltipKey] || this.getEntryLabel(entry)
        },

        isEntryDisabled (entry) {
            return entry[this.entryDisabledKey]
        },

        emitEvent (eventName, entry, index) {
            this.$emit(eventName, {
                entry: entry,
                index: index
            })
        }
    }
}
</script>

<template>
    <div class="c_entry-list-wrapper generals-input-wrapper">
        <label v-if="label"
               class="c_entry-list-label generals-input-label">
            <span>{{label}}</span>
        </label>
        <div class="c_entry-list-container generals-input-container">
            <div class="c_entry-list-entries-container">
                <div v-if="entries.length === 0"
                     class="c_entry-list-entry">
                    <div class="c_entry-list-empty">{{$tc('entryList.defaultText')}}</div>
                </div>
                <div v-for="(entry, index) in entries"
                     v-bind:key="index"
                     class="c_entry-list-entry">
                    <div class="c_entry-list-entry-label generals-text-ellipsis"
                         v-bind:class="{'m--disabled': !enableClick || isEntryDisabled(entry)}"
                         v-bind:title="getEntryTooltip(entry)">
                        <span v-on:click="emitEvent('entry-click', entry, index)">{{getEntryLabel(entry)}}</span>
                    </div>
                    <div v-if="enableSubmit && !isEntryDisabled(entry)"
                         class="c_entry-list-entry-icon">
                        <IconButton v-bind:icon-class="icon"
                                    @button-submit="emitEvent('entry-submit', entry, index)">
                        </IconButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_entry-list-wrapper {

    .c_entry-list-label {
    }

    .generals-input-container.c_entry-list-container {
        padding-top: 12px;
        height: auto;

        .c_entry-list-entries-container {
            position: relative;
            width: 100%;

            .c_entry-list-entry {
                width: 100%;
                display: flex;
                height: 30px;

                &:not(:first-child) {
                    margin-top: 5px;
                }

                .c_entry-list-empty {
                    width: 100%;
                    pointer-events: none;
                    color: var(--color-text-disabled);
                }

                .c_entry-list-entry-label {
                    width: 100%;
                    color: var(--color-link);

                    &.m--disabled {
                        pointer-events: none;
                        color: var(--color-text-mid);
                    }

                    span {
                        &:hover {
                            width: fit-content;
                            cursor: pointer;
                            color: var(--color-link-active);
                        }
                    }
                }

                .c_entry-list-entry-icon {
                    display: flex;
                    align-content: flex-end;
                    margin-top: -10px
                }
            }
        }
    }
}
</style>
