<script>
/**
 * TimeSpanInput is a combination of two pre styled input time elements. It's only possible to submit, if both inputs have a value. Otherwise, there will be an error message.
 * It will throw an @input-change when values change.
 * - id: Should be provided in order to get full functionality. Without an id you won't get the ability to close on esc and instant focus the input field when opening.
 * - label (optional): Text displayed as the label of the input field.
 * - tinyLabel (optional): Small label displayed in the top border of the input. If set there will be a border around the whole input field.
 * - defaultValue (optional): The default value of the input. For editing purposes for example.
 * - isDisabled (default: false): If true, the user can't change the input value.
 * - isAutoFocused (default: false): If true, the input field will get focused automatically when it is rendered.
 * - showUnsavedChanges (default: false): If true, the input will highlight if it's value differs from the last submitted one.
 * - useClockTime (default: true): If true, it is only possible to select an hour value between 0 and 23. Otherwise, it will be 0 and 99.
 **/
import TimeCodeInput from '@/components/TimeCodeInput.vue'

export default {
    name: 'TimeSpanInput',
    components: {
        TimeCodeInput
    },
    // @input-change: Event emitted on change | returns current input value
    // Note: We do not declare the emits here, because we want to have the registrations as part of $attrs
    //       emits: ['input-change'],
    props: {
        id: String,
        label: String,
        tinyLabel: String,
        defaultValue: Array,
        isDisabled: {
            default: false,
            type: Boolean
        },
        isAutoFocused: {
            default: false,
            type: Boolean
        },
        showUnsavedChanges: {
            default: false,
            type: Boolean
        },
        useClockTime: {
            default: true,
            type: Boolean
        }
    },
    data () {
        return {
            startTimeValue: this.defaultValue ? this.defaultValue[0] : null,
            endTimeValue: this.defaultValue ? this.defaultValue[1] : null,
            lastSubmittedValues: this.defaultValue ? [...this.defaultValue] : []
        }
    },
    methods: {
        focusInputElement () {
            document.getElementById(`c_time-span-input-start-${this.id}`).focus()
        },

        setStartTimeValue (timeCodeEvent) {
            this.startTimeValue = timeCodeEvent.value
            this.emit()
        },

        setEndTimeValue (timeCodeEvent) {
            this.endTimeValue = timeCodeEvent.value
            this.emit()
        },

        emit () {
            // We actually need to check for listeners as the lastSubmittedValue must not be
            // updated if there is no listener which performs the actual submit
            //
            // see https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/593#note_316549
            //     for further information
            if (this.$attrs.onInputChange && this.hasUnsavedChanges) {
                this.$emit(
                    'input-change',
                    { value: [this.startTimeValue, this.endTimeValue] })
                this.updateLastSubmittedValues()
            }
        },

        updateLastSubmittedValues () {
            this.lastSubmittedValues = [this.startTimeValue, this.endTimeValue]
        }
    },
    computed: {
        isInputValid () {
            return this.startTimeValue && this.endTimeValue &&
                this.startTimeValue < this.endTimeValue
        },

        hasUnsavedChanges () {
            return this.isInputValid &&
                (this.startTimeValue !== this.lastSubmittedValues[0] ||
                this.endTimeValue !== this.lastSubmittedValues[1])
        },

        showInputMessage () {
            return this.startTimeValue || this.endTimeValue
        },

        getInputMessage () {
            if (this.isInputValid) {
                return this.$t('timeSpanInput.calculatedTimeSpan', [this.getTimeSpan])
            }
            return !this.startTimeValue || !this.endTimeValue
                ? this.$tc('timeSpanInput.missingValue')
                : this.$tc('timeSpanInput.negativeTimeSpan')
        },

        getTimeSpan () {
            const startTimeInSeconds = this.startTimeValue.split(':').reduce((previous, current) => previous * 60 + +current, 0)
            const endTimeInSeconds = this.endTimeValue.split(':').reduce((previous, current) => previous * 60 + +current, 0)
            const difference = Math.abs(endTimeInSeconds - startTimeInSeconds)
            let hours = Math.floor(difference / 3600)
            if (hours < 10) {
                hours = `0${hours}`
            }
            let minutes = Math.floor(difference % 3600 / 60)
            if (minutes < 10) {
                minutes = `0${minutes}`
            }
            let seconds = difference % 60
            if (seconds < 10) {
                seconds = `0${seconds}`
            }

            return `${hours}:${minutes}:${seconds}`
        }
    },
    watch: {
        defaultValue () {
            this.startTimeValue = this.defaultValue ? this.defaultValue[0] : null
            this.endTimeValue = this.defaultValue ? this.defaultValue[1] : null
            this.lastSubmittedValues = this.defaultValue ? [...this.defaultValue] : []
        }
    },
    mounted () {
        if (this.isAutoFocused) {
            this.focusInputElement()
        }
    }
}
</script>

<template>
    <div class="c_time-span-input-wrapper generals-input-wrapper"
         v-bind:class="{'m--message': showInputMessage}">
        <label v-if="label"
               class="c_time-span-input-label generals-input-label">
            <span>{{label}}</span>
        </label>
        <div class="c_time-span-input-container generals-input-container">
            <div v-if="tinyLabel"
                 class="c_time-span-input-tiny-label generals-input-tiny-label">
                <span>{{tinyLabel}}</span>
            </div>
            <TimeCodeInput v-bind:id="`c_time-span-input-start-${id}`"
                           class="c_time-span-input-time-code m--start"
                           placeholder="- - : - - : - -"
                           v-bind:defaultValue="startTimeValue"
                           v-bind:showUnsavedChanges="showUnsavedChanges"
                           v-bind:useClockTime="useClockTime"
                           v-bind:show-error-message="false"
                           @input-submit="setStartTimeValue($event)"
                           @input-blurred="setStartTimeValue($event)">
            </TimeCodeInput>
            <TimeCodeInput v-bind:id="`c_time-span-input-end-${id}`"
                           class="c_time-span-input-time-code m--end"
                           placeholder="- - : - - : - -"
                           v-bind:defaultValue="endTimeValue"
                           v-bind:showUnsavedChanges="showUnsavedChanges"
                           v-bind:useClockTime="useClockTime"
                           v-bind:show-error-message="false"
                           @input-submit="setEndTimeValue($event)"
                           @input-blurred="setEndTimeValue($event)">
            </TimeCodeInput>
            <div v-if="showInputMessage"
                 class="c_time-span-input-message"
                 v-bind:class="{'m--error': !isInputValid}">
                <span>{{getInputMessage}}</span>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_time-span-input-wrapper {

    &.m--message {
        height: calc(var(--input-height) + 15px);
    }

    .c_time-span-input-label {
    }

    .c_time-span-input-container {

        .c_time-span-input-tiny-label {
        }

        .c_time-span-input-time-code {
            width: calc(50% - 4px);
            padding-right: 0;

            &.m--start {
                margin-right: 8px;
            }

            // As there is less space, due to the halved width, modify width of rollers of TimeCode component
            .c_time-code-input-time-picker-roller {
                width: 48px;
            }
        }

        .c_time-span-input-submit {

            .c_time-span-input-icon {
            }
        }

        .c_time-span-input-message {
            font-size: 11px;

            &.m--error {
                color: var(--color-error);
            }
        }
    }
}
</style>
