/**
 * A reusable service for handling file downloads.
 **/
import { axiosService } from '@/mixins/axiosService'

export const downloadHandler = {
    mixins: [
        axiosService
    ],
    methods: {

        /**
         * @returns {Promise<boolean>}
         */
        downloadFileFromUrl (downloadUrl, isExternalApi, downloadFilename, openPdfInBrowser) {
            function extractFileNameFromResponse (appContext, response) {
                const contentDisposition = response.headers['content-disposition']
                let filename = appContext.$tc('downloadHandler.untitled')
                if (contentDisposition && contentDisposition.includes('attachment')) {
                    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                    const matches = filenameRegex.exec(contentDisposition)
                    if (matches !== null && matches[1]) {
                        filename = matches[1].replace(/['"]/g, '')
                    }
                }
                return filename
            }

            return this.axiosGet(
                downloadUrl,
                this.$tc('downloadHandler.downloadError'),
                null,
                isExternalApi,
                { responseType: 'blob' },
                true)
                .then(response => {
                    const filename = downloadFilename || extractFileNameFromResponse(this, response)
                    let objectUrl
                    if (openPdfInBrowser && response.data.type === 'application/pdf') {
                        objectUrl = URL.createObjectURL(response.data)
                        window.open(objectUrl, '_blank')
                    }
                    objectUrl = URL.createObjectURL(new Blob([response.data]))
                    this.saveToFileSystem(objectUrl, filename)
                    return Promise.resolve(true)
                })
                .catch(response => {
                    return Promise.reject(response)
                })
        },

        downloadAllFilesFromUrl (productId, productFileReferenceIds, isExternalApi, downloadName) {
            const downloadUrl = `product/file/${productId}/zip/?productFileReferenceIds=${productFileReferenceIds.join(',')}`

            return this.axiosGet(
                downloadUrl,
                this.$tc('downloadHandler.downloadError'),
                null,
                isExternalApi,
                { responseType: 'blob' },
                true
            )
                .then(response => {
                    const objectUrl = URL.createObjectURL(new Blob([response.data]))
                    this.saveToFileSystem(objectUrl, downloadName ?? 'files.zip')
                    return Promise.resolve(true)
                })
                .catch(response => {
                    return Promise.reject(response)
                })
        },

        saveToFileSystem (objectUrl, filename) {
            const link = document.createElement('a')
            link.download = filename
            link.href = objectUrl
            document.body.appendChild(link)
            link.click()
            setTimeout(function () {
                window.URL.revokeObjectURL(objectUrl)
                document.body.removeChild(link)
            }, 100)
        }
    }
}
