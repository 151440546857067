<script>
/**
 * Developer playground / SmartForm testing
 **/
import ContentHeader from '@/components/ContentHeader.vue'
import Icon from '@/components/Icon.vue'
import EssentialSmartForm from '@/components/EssentialSmartForm.vue'
import PageOverlay from '@/components/PageOverlay.vue'
import DropInput from '@/components/DropInput.vue'
import { userHandler } from '@/mixins/userHandler'
import { notificationHandler } from '@/mixins/notificationHandler'

export default {
    name: 'Playground',
    mixins: [
        userHandler,
        notificationHandler
    ],
    components: {
        ContentHeader,
        Icon,
        EssentialSmartForm,
        PageOverlay,
        DropInput
    },
    data () {
        return {
            updateCount: 0,
            isUploading: false,
            formIsValid: false,
            smartFormJson:
            {
                id: 'playground_form',
                headline: this.$tc('administration.playground.formHeadline'),
                sections: [{
                    id: 'exampleSection1',
                    title: 'Beispiel für eine Section',
                    allowMultiple: false,
                    fields: [{
                        id: 'exampleField1',
                        label: 'Beispiel für ein Textfeld',
                        type: 'Text',
                        required: true,
                        description: 'Beispiel für einen Hilfetext',
                        placeholder: 'Anzeige bei leerem Wert'
                    }, {
                        id: 'exampleField2',
                        label: 'Feld mit !equals Abhängigkeit',
                        type: 'Number',
                        required: true,
                        description: 'Dieses Feld ist nur sichtbar, wenn das Feld "Beispiel für ein Textfeld" nicht den Wert "hidden" hat.',
                        visibleIf: '!equals(exampleField1, hidden)'
                    }, {
                        id: 'exampleField3',
                        label: 'Feld mit exists && equals Abhängigkeit',
                        type: 'Checkbox',
                        visibleIf: 'exists(exampleField1) && equals(exampleField2, 200)'
                    }, {
                        id: 'exampleField4',
                        label: 'Feld mit !true || equals Abhänigkeit',
                        type: 'Text',
                        required: true,
                        visibleIf: '!isTrue(exampleField3) || equals(exampleField1, special)'
                    }, {
                        id: 'exampleField5',
                        label: 'Feld mit isTrue || equals && !exists Abhängigkeit',
                        type: 'Date',
                        required: true,
                        visibleIf: 'isTrue(exampleField3) || equals(exampleField2, 2) && !exists(exampleField4)'
                    }],
                    pages: [{}]
                }, {
                    id: 'exampleSection2',
                    title: 'Beispiel einer weiteren Section',
                    allowMultiple: true,
                    isOpen: false,
                    pageTitle: 'Mehrfach-Vorkommen',
                    fields: [{
                        id: 'exampleField6',
                        label: 'Beispiel für Währungsangaben',
                        type: 'Currency',
                        required: true
                    }, {
                        id: 'exampleField7',
                        label: 'Beispiel für Checkboxen',
                        type: 'Checkbox',
                        required: true
                    }, {
                        id: 'exampleField8',
                        label: 'Beispiel für Disabled-Field',
                        type: 'Text',
                        required: true,
                        enableIf: 'isTrue(exampleField7)'
                    }],
                    pages: [{}]
                }]
            }
        }
    },
    methods: {
        eventCatcher () {
            console.log('Noticed form changes.')
        },

        setFormValid (isValid) {
            this.formIsValid = isValid
        },

        uploadFormConfiguration (inputSubmitEventData) {
            const vm = this
            const reader = new FileReader()

            vm.isUploading = true
            reader.onload = function (event) {
                vm.isUploading = false
                vm.applyChanges(event.target.result.toString())
            }
            reader.readAsText(inputSubmitEventData.value.get('file'))
        },

        editFormConfiguration (event) {
            this.applyChanges(event.target.innerText)
        },

        applyChanges (jsonString) {
            let json

            try {
                json = JSON.parse(jsonString)
            } catch (exception) {
                const userPreferences = this.getUserSettingsParameter('userPreferences')
                const notification = {
                    type: 'warning',
                    duration: userPreferences.reducedNotifications ? 3000 : 5000,
                    message: userPreferences.reducedNotifications
                        ? this.$tc('administration.playground.invalidJson.short')
                        : this.$tc('administration.playground.invalidJson.standard')
                }
                userPreferences.reducedNotifications
                    ? this.addCursorNotification(notification)
                    : this.addNotification(notification)

                return
            }

            this.smartFormJson = json
            this.updateCount++
        }
    }
}
</script>

<template>
    <div class="generals-container">
        <ContentHeader v-bind:header-title="$tc('administration.playground.headline')"
                       v-bind:is-primary-header=true
                       v-bind:use-slot="true">
            <template v-slot>
                <Icon class="playground-header-icon"
                      v-bind:icon-class="formIsValid ? 'fas fa-check-circle' : 'fas fa-exclamation-triangle'"
                      v-bind:icon-type="formIsValid ? 'success' : 'warning'"
                      v-bind:tooltip="formIsValid ? $tc('administration.playground.formValid') : $tc('administration.playground.formInvalid')"
                      v-bind:icon-large="true">
                </Icon>
            </template>
        </ContentHeader>
        <div class="playground-content-container">
            <EssentialSmartForm v-bind:id="smartFormJson.id"
                                v-bind:key="updateCount"
                                v-bind:hide-submit-button="true"
                                v-bind:hide-table-of-contents="smartFormJson.sections.length <= 1"
                                v-bind:form-config="smartFormJson"
                                @form-change="eventCatcher()"
                                @form-submittable-change="setFormValid($event)">
            </EssentialSmartForm>
        </div>
        <PageOverlay slot-specifier="playground"
                     class="playground-overlay">
            <template v-slot:playground_overlay>
                <div class="playground-overlay-upload-wrapper">
                    <DropInput v-bind:is-drop-zone="true"
                               v-bind:is-uploading="isUploading"
                               v-bind:label="$tc('administration.playground.uploadJson')"
                               v-bind:file-types="['.json']"
                               @input-submit="uploadFormConfiguration($event)">
                    </DropInput>
                </div>
                <pre class="playground-overlay-code-wrapper">
                    <code class="playground-overlay-code"
                          contenteditable="true"
                          v-on:focusout="editFormConfiguration($event)">{{smartFormJson}}
                    </code>
                </pre>
            </template>
        </PageOverlay>
    </div>
</template>

<style lang="less">
.playground-header-icon {
    margin: 4px 8px 0 0;
}

.playground-content-container {
    width: 100%;
    height: calc(100% - var(--primary-headline-height) - var(--page-overlay-collapsed-height));
    padding: var(--container-spacing);
}

.playground-overlay {

    &.m--expanded {
        height: 75%;
    }

    .playground-overlay-upload-wrapper {
        position: absolute;
        top: 50px;
        right: var(--container-spacing);
        width: 300px;
    }

    .playground-overlay-code-wrapper {
        width: calc(100% - 300px - var(--container-spacing));
        display: flex;
        margin: 0;
        padding: var(--container-spacing);
        overflow: auto;
        background-color: var(--color-background-light);

        .playground-overlay-code {
            cursor: text;

            &:focus {
                outline: none;
            }
        }
    }
}
</style>
