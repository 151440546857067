<script>
/**
 * Process models sub view | Sub view of admin controlling
 **/
import Table from '@/components/Table.vue'
import RouteLink from '@/components/RouteLink.vue'
import SmartSelect from '@/components/SmartSelect.vue'
import IconButton from '@/components/IconButton.vue'
import DropInput from '@/components/DropInput.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'ProcessModels',
    mixins: [
        axiosService,
        userHandler,
        dateTimeHelper
    ],
    components: {
        Table,
        RouteLink,
        SmartSelect,
        IconButton,
        DropInput
    },
    data () {
        return {
            processModels: [],
            selectedModelIds: [],
            isUploading: false,
            isSelectedColumnVisible: true
        }
    },
    methods: {
        getProcessModels () {
            this.axiosGet(
                'admin/models',
                this.$tc('administration.controlling.processModels.getProcessModelsError'))
                .then(processModels => {
                    processModels.forEach(model => this.setModelInformation(model, processModels))
                    this.processModels = processModels
                })
                .catch(() => {})
        },

        setModelInformation (model, processModels) {
            model.status = this.getStatus(model, processModels)
            model.displayName = this.getDisplayName(model)
            model.updatedBy = this.getUserNameFromModel(model)
            model.updatedAt = this.getUpdatedInfo(model)
            model.updatedAtDisplayText = this.getFormattedLongDate(model.updatedAt, this.locale)
            model.deployedAtDisplayText = model.deployedAt ? this.getFormattedLongDate(model.deployedAt, this.locale) : ''
        },

        getProcessName (model) {
            return model.processName || this.$tc('administration.controlling.processModels.unknownProcessModel')
        },

        getStatus (model, processModels) {
            if (!model.deployedAt) {
                return this.$tc('administration.controlling.processModels.inactive')
            }
            const models = processModels || this.processModels
            const modelDate = this.tryToParseToDate(model.deployedAt)
            const newModelExists = models.some(processModel => {
                return processModel.processKey === model.processKey &&
                    processModel.id !== model.id &&
                    processModel.deployedAt &&
                    this.tryToParseToDate(processModel.deployedAt) > modelDate
            })
            return newModelExists
                ? this.$tc('administration.controlling.processModels.inactive')
                : this.$tc('administration.controlling.processModels.active')
        },

        isModelSelected (model) {
            return this.selectedModelIds.includes(model.id)
        },

        areAllSelected () {
            return this.$refs.processModelsTable
                ? this.$refs.processModelsTable.getTableRows.every(row => this.isModelSelected(row))
                : false
        },

        toggleSelected (model) {
            if (this.isModelSelected(model)) {
                const index = this.selectedModelIds.indexOf(model.id)
                this.selectedModelIds.splice(index, 1)
            } else {
                this.selectedModelIds.push(model.id)
            }
        },

        toggleAllSelected () {
            this.areAllSelected()
                ? this.resetSelectedProcesses()
                : this.selectedModelIds = this.$refs.processModelsTable.getTableRows.map(row => row.id)
            this.$forceUpdate()
        },

        resetSelectedProcesses () {
            this.selectedModelIds = []
        },

        isActive (model) {
            return model.status === this.$tc('administration.controlling.processModels.active')
        },

        getDisplayName (model) {
            return `${this.getProcessName(model)} - ${model.processKey}`
        },

        getUserNameFromModel (model) {
            if (!model.userName || model.userName === 'system') {
                return this.$tc('generals.systemInformation')
            }
            return model.userName
        },

        getUpdatedInfo (model) {
            return model.updatedAt || model.createdAt
        },

        openProcessMigration (model) {
            this.$router.push({
                name: 'ProcessMigration',
                params: {
                    processId: model.processKey
                }
            })
        },

        getEditorUrl (model) {
            return `${this.$global.editorUrl}?url=${this.$global.backendPath}/bpmn/model/${model.id}`
        },

        activateSelectedProcesses () {
            this.processModels.forEach(model => {
                if (this.isModelSelected(model)) {
                    this.activateProcess(model)
                }
            })
            this.resetSelectedProcesses()
        },

        activateProcess (model) {
            this.axiosPost(
                `admin/models/${model.id}/deploy`,
                null,
                this.$t('administration.controlling.processModels.activateProcessModelError', [this.getProcessName(model)]),
                {
                    standard: this.$t('administration.controlling.processModels.activateProcessModelSuccess.standard', [this.getProcessName(model)]),
                    short: this.$t('administration.controlling.processModels.activateProcessModelSuccess.short', [this.getProcessName(model)])
                })
                .then(updatedProcess => {
                    model.updatedBy = this.getUserNameFromModel(updatedProcess)
                    model.updatedAt = this.getUpdatedInfo(updatedProcess)

                    model.updatedAtDisplayText = this.getFormattedLongDate(updatedProcess.updatedAt, this.locale)
                    model.deployedAt = updatedProcess.deployedAt

                    model.deployedAtDisplayText = this.getFormattedLongDate(updatedProcess.deployedAt, this.locale)
                    model.instanceCount = updatedProcess.instanceCount
                    model.processVersion = updatedProcess.processVersion
                    this.processModels.forEach(processModel => {
                        if (processModel.processKey === model.processKey) {
                            processModel.status = this.getStatus(processModel)
                        }
                    })
                })
                .catch(() => {})
        },

        secureRemoveSelectedProcesses () {
            this.$swal({
                title: this.$t('administration.controlling.processModels.deleteSelectedProcessModels.title', [this.selectedModelIds.length]),
                text: this.$tc('administration.controlling.processModels.deleteSelectedProcessModels.text'),
                icon: 'warning',
                showConfirmButton: true,
                confirmButtonText: this.$tc('administration.controlling.processModels.deleteSelectedProcessModels.confirmText'),
                showCancelButton: true,
                cancelButtonText: this.$tc('generals.cancel'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.isConfirmed) {
                    this.removeSelectedProcesses()
                }
            })
        },

        removeSelectedProcesses () {
            const promises = []
            const selectedModels = this.processModels.filter(model => this.isModelSelected(model))
            selectedModels.forEach(model => promises.push(
                this.axiosDelete(
                    `admin/models/${model.id}`,
                    null,
                    this.$t('administration.controlling.processModels.deleteProcessModelError', [this.getProcessName(model)]),
                    {
                        standard: this.$t('administration.controlling.processModels.deleteProcessModelSuccess.standard', [this.getProcessName(model)]),
                        short: this.$t('administration.controlling.processModels.deleteProcessModelSuccess.short', [this.getProcessName(model)])
                    }))
            )
            Promise.all(promises)
                .then(() => {
                    const modelIds = this.processModels.map(model => model.id)
                    selectedModels.forEach(model => {
                        const index = modelIds.indexOf(model.id)
                        modelIds.splice(index, 1)
                        this.processModels.splice(index, 1)
                    })
                    this.resetSelectedProcesses()
                })
        },

        executeSelectedModelsAction (selectSubmitEventData) {
            const action = selectSubmitEventData.option.action
            if (this[action]) {
                this[action]()
            }
        },

        saveProcess (requestBody) {
            this.axiosPost(
                'admin/models',
                requestBody,
                this.$tc('administration.controlling.processModels.createProcessModelError'),
                {
                    standard: this.$tc('administration.controlling.processModels.createProcessModelSuccess.standard'),
                    short: this.$tc('administration.controlling.processModels.createProcessModelSuccess.short')
                })
                .then(addedProcesses => {
                    addedProcesses.forEach(process => {
                        this.setModelInformation(process)
                        this.processModels.push(process)
                    })
                    this.isUploading = false
                })
                .catch(() => {
                    this.isUploading = false
                })
        },

        uploadProcess (inputSubmitEventData) {
            const requestBody = inputSubmitEventData.value
            this.isUploading = true
            this.saveProcess(requestBody)
        },

        handleColumnVisibilityChange (settings) {
            this.isSelectedColumnVisible = settings.find(c => c.key === 'selected').visible
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getTableConfig () {
            return [{
                key: 'selected',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: 5
            }, {
                key: 'status',
                label: this.$tc('administration.controlling.processModels.table.status'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 10
            }, {
                key: 'displayName',
                label: this.$tc('administration.controlling.processModels.table.name'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 15
            }, {
                key: 'processVersion',
                label: this.$tc('administration.controlling.processModels.table.version'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 7.5
            }, {
                key: 'instanceCount',
                label: this.$tc('administration.controlling.processModels.table.instanceCount'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 7.5
            }, {
                key: 'updatedBy',
                label: this.$tc('administration.controlling.processModels.table.updatedBy'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 17.5
            }, {
                key: 'updatedAtDisplayText',
                sortKey: 'updatedAt',
                label: this.$tc('administration.controlling.processModels.table.updatedAt'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 15
            }, {
                key: 'deployedAtDisplayText',
                sortKey: 'deployedAt',
                label: this.$tc('administration.controlling.processModels.table.deployedAt'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 15
            }, {
                key: 'action',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'right',
                width: 7.5
            }]
        },

        getSelectedModelsActions () {
            const multiActionsDisabled = this.selectedModelIds.length === 0
            return [{
                label: this.$tc('administration.controlling.processModels.table.deploySelectedProcessModels'),
                action: 'activateSelectedProcesses',
                disabled: multiActionsDisabled
            }, {
                label: this.$tc('administration.controlling.processModels.table.deleteSelectedProcessModels'),
                action: 'secureRemoveSelectedProcesses',
                disabled: multiActionsDisabled
            }]
        }
    },
    watch: {
        '$global.localization.locale': {
            handler: function () {
                this.processModels.forEach(model => this.setModelInformation(model))
            },
            deep: true
        }
    },
    mounted () {
        this.getProcessModels()

        // Watch the length of currently visible rows, in order to unselect rows that have been filtered after selection
        this.$watch(() => this.$refs.processModelsTable.getTableRows.length, () => {
            const visibleRowsIds = this.$refs.processModelsTable.getTableRows.map(row => row.id)
            this.processModels.forEach(model => {
                if (this.isModelSelected(model) && !visibleRowsIds.includes(model.id)) {
                    this.toggleSelected(model)
                }
            })
        })
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="process-models-table-container">
            <div class="process-models-header">
                <SmartSelect id="process-models-actions"
                             v-bind:key="`process-models-actions_${selectedModelIds.length}`"
                             v-bind:options="getSelectedModelsActions"
                             v-bind:option-label-specifiers="['label']"
                             v-bind:placeholder="$tc('administration.controlling.processModels.table.selectAction')"
                             v-bind:clear-input-on-submit="true"
                             v-bind:sort-options="false"
                             v-bind:allow-input=false
                             v-bind:submit-button="false"
                             @select-change="executeSelectedModelsAction($event)">
                </SmartSelect>
                <div class="process-models-header-button-wrapper">
                    <IconButton v-bind:icon-class="areAllSelected() ? 'fas fa-check-square' : 'far fa-square'"
                                v-bind:tooltip="areAllSelected()
                                    ? this.$tc('administration.controlling.processModels.table.unselectAllModels')
                                    : this.$tc('administration.controlling.processModels.table.selectAllModels')"
                                    v-if="this.isSelectedColumnVisible"
                                @button-submit="toggleAllSelected()">
                    </IconButton>
                </div>
            </div>
            <Table table-id="processModels"
                   v-bind:table-config="getTableConfig"
                   v-bind:table-data="processModels"
                   v-bind:filter-placeholder="$tc('administration.controlling.processModels.table.filterPlaceholder')"
                   v-bind:table-empty-message="$tc('administration.controlling.processModels.table.tableEmpty')"
                   v-bind:filter-no-results-message="$tc('administration.controlling.processModels.table.filterNoResults')"
                   v-bind:expandable-rows="true"
                   default-sorting-key="deployedAt"
                   default-sorting-direction="desc"
                   v-bind:custom-user-filter="true"
                   v-bind:read-only="false"
                   @row-click="toggleSelected($event.row)"
                   @column-visibility-change="handleColumnVisibilityChange"
                   @row-double-click="openProcessMigration($event.row)"
                   ref="processModelsTable">
                <template #cell(selected)="data">
                    <IconButton v-bind:icon-class="isModelSelected(data.row) ? 'fas fa-check-square' : 'far fa-square'"
                                @button-submit="toggleSelected(data.row)">
                    </IconButton>
                </template>
                <template #cell(status)="data">
                    <div class="generals-badge-text process-models-status"
                         v-bind:class="{'m--inactive': !isActive(data.row)}">
                        <span>{{data.row.status}}</span>
                    </div>
                </template>
                <!-- displayName: default cell content -->
                <!-- processVersion: default cell content -->
                <template #cell(instanceCount)="data">
                    <div class="process-models-instances"
                         v-bind:title="$tc('administration.controlling.processModels.table.openProcessMigration')"
                         v-bind:class="{'m--disabled': data.row.instanceCount === 0}"
                         v-on:click="openProcessMigration(data.row)">
                        <span>{{data.row.instanceCount || '?'}}</span>
                    </div>
                </template>
                <!-- updatedBy: default cell content -->
                <!-- updatedAtDisplayText: default cell content -->
                <!-- deployedAtDisplayText: default cell content -->
                <template #cell(action)="data">
                    <RouteLink v-bind:tooltip="$tc('administration.controlling.processModels.table.editProcess')"
                               icon-class="fas fa-edit"
                               v-bind:is-external-link=true
                               v-bind:target="getEditorUrl(data.row)">
                    </RouteLink>
                </template>
                <template #expandable-content="expandData">
                    <div class="process-models-expandable-content">
                        <h3>{{$t('administration.controlling.processModels.expandableContent.headline', [getProcessName(expandData.row)])}}</h3>
                        <hr />
                        <div v-dompurify-html="expandData.row.description || $tc('administration.controlling.processModels.expandableContent.noProcessDescription')"></div>
                    </div>
                </template>
            </Table>
        </div>
        <div class="process-models_upload-container">
            <DropInput v-bind:allow-multiple=true
                       v-bind:is-drop-zone=true
                       v-bind:is-uploading="isUploading"
                       v-bind:file-types="['.bpmn']"
                       v-bind:label="$tc('administration.controlling.processModels.dropZoneInfo')"
                       @input-submit="uploadProcess($event)">
            </DropInput>
        </div>
    </div>
</template>

<style scoped lang="less">
.process-models-table-container {
    width: 100%;
    padding: 0 var(--container-spacing) var(--container-spacing) var(--container-spacing);
    height: calc(100% - var(--drop-zone-height));

    .process-models-header {
        position: absolute;
        top: var(--container-spacing);
        left: var(--container-spacing);
        z-index: var(--z-index-header-button);

        ::placeholder {
            color: var(--color-text-mid);
        }

        .process-models-header-button-wrapper {
            width: 5%;
            position: absolute;
            top: 45px;
            left: 0;
            padding-left: 11px;
        }
    }

    .process-models-status {
        color: var(--color-success);
        border-color: var(--color-success);

        &.m--inactive {
            color: var(--color-text-disabled);
            border-color: var(--color-border-light);
        }
    }

    .process-models-instances {

        &.m--disabled {
            // pointer-events: none;
        }

        &:hover {
            cursor: pointer;
            color: var(--color-text-highlighted);
        }
    }

    .process-models-expandable-content {
        display: block;
        padding: 0 var(--container-spacing) var(--container-spacing) var(--container-spacing);
    }
}

.process-models_upload-container {
    width: 100%;
    padding: 0 var(--container-spacing);
}
</style>
