<script>
/**
 * InlineConfirmButton is an icon button element that will result in a confirmation button pair, if you click on it.
 * - tooltip (required): The text displayed as tooltip
 * - iconClass (required): A font awesome icon class. If no class is provided, a question mark icon will be displayed as default.
 * - hoverIconClass (optional): A font awesome icon class. If provided, this icon will show up on hover.
 * - isDisabled (default: false): If true, the button won't be clickable.
 * - confirmMessage (optional): Text display as confirmation message before executing the action.
 **/
import IconButton from '@/components/IconButton.vue'
import VerticalLine from '@/components/VerticalLine.vue'

export default {
    name: 'InlineConfirmButton',
    components: {
        IconButton,
        VerticalLine
    },
    // @button-submit: Event emitted on click | returns the event
    emits: ['button-submit'],
    props: {
        tooltip: String,
        iconClass: {
            default: 'fas fa-question-circle',
            type: String
        },
        hoverIconClass: String,
        isDisabled: {
            default: false,
            type: Boolean
        },
        confirmMessage: String
    },
    data () {
        return {
            iconButtonVisible: true,
            confirmDialogueVisible: false
        }
    },
    methods: {
        showConfirmDialogue (event) {
            event.stopPropagation()
            this.iconButtonVisible = false
            setTimeout(() => {
                this.confirmDialogueVisible = true
            }, 100)
        },

        hideConfirmDialogue (event) {
            event.stopPropagation()
            this.confirmDialogueVisible = false
            setTimeout(() => {
                this.iconButtonVisible = true
            }, 100)
        },

        emitSubmit (event) {
            this.hideConfirmDialogue(event)
            this.$emit('button-submit', event)
        }
    }
}
</script>

<template>
    <div class="c_inline-confirm-wrapper">
        <transition name="show">
            <IconButton v-if="iconButtonVisible"
                        class="c_inline-confirm-icon-button"
                        v-bind:icon-class="iconClass"
                        v-bind:hover-icon-class="hoverIconClass"
                        v-bind:tooltip="tooltip"
                        v-bind:is-disabled=isDisabled
                        @button-submit="showConfirmDialogue($event)">
            </IconButton>
        </transition>
        <transition name="show">
            <div v-if="confirmDialogueVisible" class="c_inline-confirm-dialogue">
                <div class="c_inline-confirm-message">
                    <span>{{confirmMessage || $tc('inlineConfirmButton.defaultConfirmText')}}</span>
                </div>
                <div class="c_inline-confirm-buttons">
                    <IconButton class="c_inline-confirm-buttons-proceed"
                                icon-class="fas fa-check-circle"
                                v-bind:tooltip="$tc('inlineConfirmButton.confirm')"
                                @button-submit="emitSubmit($event)">
                    </IconButton>
                    <VerticalLine></VerticalLine>
                    <IconButton class="c_inline-confirm-buttons-cancel"
                                icon-class="fas fa-times-circle"
                                v-bind:tooltip="$tc('inlineConfirmButton.cancel')"
                                @button-submit="hideConfirmDialogue($event)">
                    </IconButton>
                </div>
            </div>
        </transition>
    </div>
</template>

<style lang="less">
.c_inline-confirm-wrapper {
    width: max-content;
    display: inline-block;

    .c_inline-confirm-icon-button {
    }

    .c_inline-confirm-dialogue {

        .c_inline-confirm-message {
            margin-bottom: -4px;
        }

        .c_inline-confirm-buttons {
            display: table;
            margin: 0 auto -4px auto;

            .c_inline-confirm-buttons-proceed {
                color: var(--color-success);
            }

            .c_inline-confirm-buttons-cancel {
                color: var(--color-error);
            }
        }
    }
}
</style>
