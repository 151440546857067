<script>
/**
 * Product details sub view | Sub view of a Product
 *
 * @property { String } corFlowStatus
 * @property { Array } INCOMING
 * @property { Array } OUTGOING
 **/
import Accordion from '@/components/Accordion.vue'
import DataRow from '@/components/DataRow.vue'
import Icon from '@/components/Icon.vue'
import VerticalLine from '@/components/VerticalLine.vue'
import { axiosService } from '@/mixins/axiosService'
import { urlHelper } from '@/mixins/urlHelper'
import { environmentHandler } from '@/mixins/environmentHandler'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'ProductDetails',
    mixins: [
        axiosService,
        environmentHandler,
        dateTimeHelper
    ],
    components: {
        Accordion,
        DataRow,
        Icon,
        VerticalLine
    },
    // @product-details-loaded: Event emitted when the product details are loaded from the backend
    emits: ['product-details-loaded'],
    props: {
        productId: [String, Number],
        productNumber: [String, Number]
    },
    data () {
        return {
            productInfo: this.$attrs.productInfo || { id: this.productId, productNumber: this.productNumber },
            product: {
                id: null,
                productMetadata: {},
                activeTasks: [],
                errors: []
            },
            customKeys: {
                productApprovalData: {
                    key: 'productApprovalData',
                    group: 'productApprovals'
                },
                productRelationsByTypeAndDirection: {
                    key: 'productRelationsByTypeAndDirection',
                    group: 'relationData',
                    isIncoming: 'INCOMING'
                }
            },
            einreichungCompletedKeys: ['Genehmigung', 'Genehmigung mit Einschränkung'],
            einreichungFailedKeys: ['Ablehnung'],
            einreichungNeutralKeys: ['Storno'],
            colorSuccess: '#75b836',
            colorWarning: '#F7A600',
            colorError: '#A2271C',
            colorNeutral: '#333333',
            measurementUnits: [
                'retailPrice',
                'breite',
                'hoehe',
                'rueckenStaerke',
                'gesamtUmfang',
                'totalPageCount'
            ],
            fieldsWithBooleanValue: [
                'isEinreichungNecessary',
                'testEditionPlanned'
            ],
            fieldsWithDateValue: [
                'timeStamp',
                'lastStatusUpdate',
                'dateOfEinreichung',
                'plannedEinreichungDate',
                'plannedReleaseDate',
                'releaseDate',
                'testEditionDataDeliveryDate'
            ],
            fieldsWithLinkValue: [
                'linkToStyleGuide'
            ],
            fieldsWithCustomValue: {
                productState: {
                    key: 'productStateValues',
                    values: [
                        'CREATED',
                        'IN_DEVELOPMENT',
                        'FINALIZED',
                        'PRODUCTION_DATA_PROVIDED',
                        'IMPRIMATUR_FINISHED',
                        'PUBLISHED',
                        'ALL_FINISHED',
                        'FINISHED_MANUALLY'
                    ]
                },
                materialType: {
                    key: 'materialTypeValues',
                    values: [
                        'ZSTP', 'ZSTN', 'ZUFE',
                        'ZUFN', 'ZZSA', 'ZMPR',
                        'ZMPF', 'ZWMI', 'ZWMN',
                        'ZSON'
                    ]
                },
                poolProduction: {
                    key: 'poolProductionValues',
                    values: [
                        'no', '50pct', '80pct'
                    ]
                }
            }
        }
    },
    methods: {
        getProductDetails () {
            this.axiosGet(
                `product/${this.productInfo.id}`,
                this.$tc('product.details.getProductInformationError'))
                .then(productDetails => {
                    productDetails.productNumber = productDetails.productMetadata.baseInformation.fields.productNumber
                    productDetails.shortTitle = productDetails.productMetadata.baseInformation.fields.shortTitle
                    this.product = productDetails
                    this.$emit('product-details-loaded')
                })
                .catch(() => {})
        },

        isCustomConfig (config) {
            return Object.keys(config.fields).some(key => this.customKeys[key])
        },

        getMetadataGroupItems (fields) {
            const metadataItems = []
            for (const [fieldName, value] of Object.entries(fields)) {
                let isClickableValue = false
                let fieldValue = this.getDisplayValue(value)
                if (fieldValue && this.measurementUnits.includes(fieldName) && fieldValue !== '–') {
                    fieldValue += this.$tc(`product.details.fields.measurementUnits.${fieldName}`)
                }
                if (fieldValue && this.fieldsWithCustomValue[fieldName]) {
                    const fieldConfig = this.fieldsWithCustomValue[fieldName]
                    const messagePropertyKey = fieldConfig.values.includes(fieldValue)
                        ? fieldValue
                        : 'UNKNOWN'
                    fieldValue = this.$tc(`generals.${fieldConfig.key}.${messagePropertyKey}`)
                }
                if (this.fieldsWithDateValue.includes(fieldName)) {
                    const dateOrNull = this.tryToParseToDate(fieldValue)
                    if (dateOrNull) {
                        fieldValue = this.getFormattedShortDate(dateOrNull, this.locale)
                    }
                }
                if (this.fieldsWithLinkValue.includes(fieldName) && urlHelper.methods.isUrlValue(fieldValue)) {
                    isClickableValue = true
                }
                if (this.fieldsWithBooleanValue.includes(fieldName)) {
                    if (fieldValue === 'true' || fieldValue === 'false') {
                        fieldValue = this.$tc(`generals.booleanValues.${fieldValue}`)
                    }
                }
                metadataItems.push({
                    label: this.$tc(`product.details.fields.${fieldName}`),
                    value: fieldValue,
                    isClickableValue: isClickableValue
                })
            }
            return metadataItems
        },

        getDisplayValue (value) {
            if (Array.isArray(value) && value !== null) {
                return value.join(', ')
            }
            if (typeof value === 'object' && value !== null) {
                return value.name
            }
            return value
        },

        getProductDetailsErrorsColorCode (product) {
            return product.errors.length > 0 ? this.colorError : this.colorSuccess
        },

        getGroupColorCode (group, config) {
            if (!this.isCustomConfig(config)) {
                return group.color
            }
            switch (group) {
            case 'productApprovals':
                if (config.fields.productApprovalData.length === 0) {
                    return null
                }
                if (config.fields.productApprovalData.some(approval =>
                    this.getEinreichungColorCode(approval) === this.colorError)) {
                    return this.colorError
                }
                if (config.fields.productApprovalData.some(approval =>
                    this.getEinreichungColorCode(approval) === this.colorWarning)) {
                    return this.colorWarning
                }
                return this.colorSuccess
            }
        },

        getEinreichungColorCode (group) {
            if (this.einreichungFailedKeys.includes(group.status)) {
                return this.colorError
            }
            if (this.einreichungNeutralKeys.includes(group.status)) {
                return this.colorNeutral
            }
            if (this.einreichungCompletedKeys.includes(group.status)) {
                return this.colorSuccess
            }
            return this.colorWarning
        },

        getGroupCount (group, config) {
            // switch functions declaration
            function getProductApprovalsCount (appContext) {
                const einreichungCount = config.fields.productApprovalData.length
                if (einreichungCount === 0) {
                    return null
                }
                const einreichungCompletedCount = config.fields.productApprovalData.filter(
                    approval => appContext.einreichungCompletedKeys.includes(approval.status)).length
                return appContext.$t('product.details.approvedEinreichungCount', [einreichungCompletedCount, einreichungCount])
            }

            switch (group) {
            case this.customKeys.productRelationsByTypeAndDirection.group:
                return this.getRelationItemsCountOfRelationTypeOrAllRelations()
            case this.customKeys.productApprovalData.group:
                return getProductApprovalsCount(this)
            default:
                return null
            }
        },

        getRelationItemsForRelationType (relationsOfRelationType) {
            const incomingRelations = relationsOfRelationType.INCOMING || []
            const outgoingRelations = relationsOfRelationType.OUTGOING || []
            return incomingRelations.concat(outgoingRelations)
        },

        getErrorMessage (errors) {
            if (errors.length === 0) return ''
            const errorMessages = errors.map(error => error.message).join('<br/>')
            return errorMessages + '<br/>' + this.$tc('product.details.errorMessage')
        },

        getRelationItemsCountOfRelationTypeOrAllRelations (relationsOfRelationType) {
            let incomingRelations = []
            let outgoingRelations = []

            if (relationsOfRelationType) {
                incomingRelations = relationsOfRelationType.INCOMING || []
                outgoingRelations = relationsOfRelationType.OUTGOING || []
            } else {
                Object
                    .entries(this.product.productMetadata.relationData.fields.productRelationsByTypeAndDirection)
                    .forEach(relationsTypeAndRelations => {
                        const relations = relationsTypeAndRelations[1]
                        incomingRelations = incomingRelations.concat(relations.INCOMING || [])
                        outgoingRelations = outgoingRelations.concat(relations.OUTGOING || [])
                    })
            }

            return incomingRelations.length > 0 || outgoingRelations.length > 0
                ? this.$t('product.details.relationsCount', [incomingRelations.length, outgoingRelations.length])
                : null
        },

        relationIsIncoming (relation) {
            return relation.direction === this.customKeys.productRelationsByTypeAndDirection.isIncoming
        },

        redirectToWoodWingAssets () {
            let woodWingAssetsPath = `${this.$global.wwaUrl}/app/#/search//relevance,name/?path=`
            this.axiosGet(
                `wwa-path/${this.productInfo.id}`,
                this.$tc('product.details.getProductWwaPathError'))
                .then(wwaPath => {
                    woodWingAssetsPath += wwaPath
                    window.open(woodWingAssetsPath, '_blank', 'noopener,noreferrer')
                })
                .catch(() => {})
        },

        redirectToContractStatusDashboard () {
            const powerBiUrl = 'https://app.powerbi.com/reportEmbed' +
                '?reportId=20093559-26e3-4e22-9074-cfc79cf59060' +
                '&pageName=ReportSection7119b919d1d64aed247b' +
                '&autoAuth=true' +
                '&ctid=0be0d70f-f404-4497-9fa7-3a7b7c98630d' +
                '&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1nLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D' +
                '&filter=Produkt%2FISBN%20eq%20'
            const powerBiUrlWithParameter = `${powerBiUrl}'${this.productInfo.productNumber}'`
            window.open(powerBiUrlWithParameter, '_blank')
        },

        redirectToRmsComplianceView () {
            const rmsBaseUrl = this.isProductionEnvironment() ? 'https://rms.cornelsen.io/validate' : 'https://rms-test.cornelsen.io/validate'
            const rmsUrlWithParameter = `${rmsBaseUrl}/${this.productInfo.productNumber}/`
            window.open(rmsUrlWithParameter, '_blank')
        },

        redirectToAiPortal () {
            window.open('https://portal.cornelsen.ai/employee-login', '_blank')
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        }
    },
    mounted () {
        this.getProductDetails()
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="product-details-container">
            <Accordion v-bind:title="$tc('product.details.titles.links')"
                       v-bind:use-custom-content="true"
                       v-bind:expanded-by-default="true">
                <template v-slot>
                    <DataRow key="wwa"
                             v-bind:label="$tc('product.details.fields.linkToWwaLabel')"
                             v-bind:value="$tc('product.details.fields.linkToWwaValue')"
                             v-bind:label-width-percentage="40"
                             v-bind:is-clickable-value="true"
                             @value-click="redirectToWoodWingAssets()">
                    </DataRow>
                    <DataRow key="dashboard"
                             v-bind:label="$tc('product.details.fields.linkToDashboardLabel')"
                             v-bind:value="$tc('product.details.fields.linkToDashboardValue')"
                             v-bind:label-width-percentage="40"
                             v-bind:is-clickable-value="true"
                             @value-click="redirectToContractStatusDashboard()">
                    </DataRow>
                    <DataRow key="rms"
                             v-bind:label="$tc('product.details.fields.linkToRmsLabel')"
                             v-bind:value="$tc('product.details.fields.linkToRmsValue')"
                             v-bind:label-width-percentage="40"
                             v-bind:is-clickable-value="true"
                             @value-click="redirectToRmsComplianceView()">
                    </DataRow>
                    <DataRow key="aiPortal"
                             v-bind:label="$tc('product.details.fields.linkToAiPortalLabel')"
                             v-bind:value="$tc('product.details.fields.linkToAiPortalValue')"
                             v-bind:label-width-percentage="40"
                             v-bind:is-clickable-value="true"
                             @value-click="redirectToAiPortal()">
                    </DataRow>
                </template>
            </Accordion>
            <template v-for="(config, group) in product.productMetadata" v-bind:key="group">
                <Accordion v-bind:title="$tc(`product.details.titles.${group}`)"
                           v-bind:items="isCustomConfig(config) ? null : getMetadataGroupItems(config.fields)"
                           v-bind:use-custom-content="isCustomConfig(config)"
                           v-bind:count="isCustomConfig(config) ? getGroupCount(group, config) : null"
                           v-bind:color-code="getGroupColorCode(group, config)"
                           v-bind:expanded-by-default=config.isOpen>
                    <template v-if="isCustomConfig(config)" v-slot>
                        <template v-for="(groups, key) in config.fields">
                            <DataRow v-if="groups.length === 0"
                                     v-bind:key="key"
                                     v-bind:label="$tc('product.details.noGroupInformationPlaceholder')"
                                     v-bind:label-width-percentage="100">
                            </DataRow>
                            <template v-else-if="key === customKeys.productApprovalData.key">
                                <Accordion v-for="(productApproval, $index) in groups"
                                           class="product-details-subgroup-accordion"
                                           v-bind:key="$index"
                                           v-bind:title="`${productApproval.federalState} | ${productApproval.schoolType}`"
                                           v-bind:items="getMetadataGroupItems(productApproval)"
                                           v-bind:color-code="getEinreichungColorCode(productApproval)"
                                           v-bind:expanded-by-default=false>
                                </Accordion>
                            </template>
                            <template v-else-if="key === customKeys.productRelationsByTypeAndDirection.key">
                                <Accordion v-for="(relations, relationType) in groups"
                                           class="product-details-subgroup-accordion"
                                           v-bind:key="relationType"
                                           v-bind:title="$tc(`product.details.relationTypes.${relationType}`)"
                                           color-code="#757575"
                                           v-bind:count="getRelationItemsCountOfRelationTypeOrAllRelations(relations)"
                                           v-bind:expanded-by-default="false"
                                           v-bind:use-custom-content="true">
                                    <template v-slot>
                                        <div v-for="(productRelation, $index) in getRelationItemsForRelationType(relations)"
                                             class="product-details-relation"
                                             v-bind:key="$index">
                                            <Icon class="product-details-relation-icon"
                                                  v-bind:class="{'m--incoming': relationIsIncoming(productRelation)}"
                                                  v-bind:icon-class="relationIsIncoming(productRelation) ? 'fas fa-share' : 'fas fa-reply'"
                                                  v-bind:icon-type="relationIsIncoming(productRelation) ? 'bright' : 'default'"
                                                  v-bind:tooltip="relationIsIncoming(productRelation) ? $tc('product.details.incomingRelation') : $tc('product.details.outgoingRelation')">
                                            </Icon>
                                            <div class="product-details-relation-title">
                                                <router-link v-if="productRelation.productId"
                                                             class="product-details-relation-title-link"
                                                             v-bind:title="$tc('generals.goToProduct')"
                                                             v-bind:to="{name: 'Product', params: {productId: productRelation.productId}}">
                                                    <span>{{productRelation.productNumber}}</span>
                                                </router-link>
                                                <span v-else>{{productRelation.productNumber}}</span>
                                                <VerticalLine></VerticalLine>
                                                <span>{{productRelation.shortTitle}}</span>
                                            </div>
                                            <div v-if="productRelation.corFlowStatus !== 'UNKNOWN'"
                                                 class="generals-badge-text product-details-relation-status">
                                                <span>{{$tc(`generals.productStateValues.${productRelation.corFlowStatus}`)}}</span>
                                            </div>
                                        </div>
                                    </template>
                                </Accordion>
                            </template>
                        </template>
                    </template>
                </Accordion>
            </template>
          <Accordion v-bind:title="$tc('product.details.titles.errors')"
                     v-bind:expanded-by-default="false"
                     v-bind:color-code="getProductDetailsErrorsColorCode(product)"
                     v-bind:useCustomContent="true">
            <DataRow
                if="product.errors.length > 0"
                v-bind:key="'errorsMessage'"
                v-bind:value="getErrorMessage(product.errors)"
                v-bind:label-width-percentage="0"/>
          </Accordion>

        </div>
    </div>
</template>

<style lang="less">
.product-details-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .product-details-subgroup-accordion {
        margin: 1px 0 0 0;
        padding: 0;

        .c_accordion-header {
            background-color: transparent;

            &:hover {
                background-color: var(--color-background-mid);
            }

            .c_accordion-header-badge {
                background-color: var(--color-background-light);
            }
        }

        .c_accordion-body {
            padding-left: 8px;
        }

        .product-details-relation {
            position: relative;
            display: flex;
            width: 100%;
            height: var(--data-row-height);

            .product-details-relation-icon {
                width: 26px;
                height: 26px;
                margin: 12px 0 0 10px;
                padding: 0;
                border-radius: 6px;
                background-color: transparent;
                border: 1px solid var(--color-border-dark);

                &.m--incoming {
                    background-color: var(--color-background-dark);
                    border: 0;
                }
            }

            .product-details-relation-title {
                padding: 6px 12px;
                cursor: default;
                display: flex;
                align-items: center;
                height: 100%;

                span:first-child {
                    font-family: "Source Sans Pro Bold", sans-serif;
                }
            }

            .product-details-relation-title-link {
                color: var(--color-link);

                &:hover {
                    color: var(--color-link-active);
                }
            }

            .product-details-relation-status {
                position: absolute;
                right: var(--container-spacing);
                top: 13px;
            }
        }
    }
}
</style>
