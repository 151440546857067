/**
 * A reusable service for the exchange of product resp. product content related files with the backend.
 **/
import { axiosService } from '@/mixins/axiosService'
import { notificationHandler } from '@/mixins/notificationHandler'

export const productFileHandler = {
    mixins: [
        axiosService,
        notificationHandler
    ],
    data () {
        return {
            basePathFile: 'product/file'
        }
    },
    methods: {
        getProductFiles (productId, contentId, fieldName, round) {
            const urlParameterString = `productId=${productId}&productContentId=${contentId}&formField=${fieldName}&round=${round}`
            return this.axiosGet(
                `${this.basePathFile}?${urlParameterString}`,
                this.$t('task.taskViews.files.fetchFilesError'))
        },
        uploadProductFilesByIds (productId, contentId, fieldName, files) {
            const urlPath = `${productId}?productContentId=${contentId}&formField=${fieldName}`
            return this.uploadProductFiles(urlPath, files)
        },
        uploadProductFiles (uploadUrlPath, files) {
            return this.axiosPost(
                `${this.basePathFile}/${uploadUrlPath}`,
                files,
                this.$tc('task.taskViews.files.uploadFileError'),
                {
                    standard: this.$tc('task.taskViews.files.uploadFileSuccess.standard'),
                    short: this.$tc('task.taskViews.files.uploadFileSuccess.short')
                })
        },
        deleteProductFile (fileId) {
            return this.axiosDelete(
                `${this.basePathFile}/${fileId}`,
                null,
                this.$t('task.taskViews.files.removeFileError'),
                {
                    standard: this.$t('task.taskViews.files.removeFileSuccess.standard'),
                    short: this.$tc('task.taskViews.files.removeFileSuccess.short')
                })
        }
    }
}
