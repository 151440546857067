<script>
/**
 * Current tasks sub view | Sub view of admin tasks overview
 *
 * @property {String} productContextDescription
 * @property {Date} uploadDate
 **/
import Table from '@/components/Table.vue'
import Icon from '@/components/Icon.vue'
import RouteLink from '@/components/RouteLink.vue'
import BadgeInput from '@/components/BadgeInput.vue'
import IconButton from '@/components/IconButton.vue'
import Accordion from '@/components/Accordion.vue'
import InteractionLink from '@/components/InteractionLink.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { taskAssignmentHandler } from '@/mixins/taskAssignmentHandler'
import { downloadHandler } from '@/mixins/downloadHandler'
import { regexHelper } from '@/mixins/regexHelper'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'CurrentTasks',
    mixins: [
        axiosService,
        userHandler,
        taskAssignmentHandler,
        downloadHandler,
        regexHelper,
        dateTimeHelper
    ],
    components: {
        Table,
        Icon,
        RouteLink,
        BadgeInput,
        IconButton,
        Accordion,
        InteractionLink
    },
    data () {
        return {
            currentTasksList: [],
            username: this.getUserName(),
            dataContentTypes: {
                document: 'DOCUMENT',
                text: 'TEXT',
                form: 'FORM',
                link: 'HYPERLINK',
                multi: 'MULTIPLE',
                choice: ['CONDITIONAL_FLOWS', 'GATEWAY']
            },
            showSpinner: true
        }
    },
    methods: {
        getCurrentTasks () {
            this.axiosGet(
                'admin/tasks',
                this.$tc('administration.taskOverview.activeTasks.getCurrentTasksError'))
                .then(taskList => {
                    taskList.forEach(task => {
                        this.enrichTaskAndSetCreationDate(task)
                    })
                    this.currentTasksList = taskList
                    this.showSpinner = false
                })
                .catch(() => {
                })
        },
        enrichTaskAndSetCreationDate (task) {
            task.assignmentDisplayText = this.getAssignmentDisplayText(task)
            this.setFormattedCreationDate(task)
        },

        setFormattedCreationDate (task) {
            task.creationDateDisplayText = this.getFormattedLongDate(task.creationDate, this.locale)
        },

        unClaimTask (task) {
            this.unAssignTask(task, false, false, true)
        },

        getTaskDetails (rowClickEventData) {
            this.axiosGet(
                `task/${rowClickEventData.row.id}`,
                this.$tc('administration.taskOverview.activeTasks.getTaskInfoError'))
                .then(taskDetails => {
                    taskDetails.inputs.forEach(input => {
                        input.id = `input_${input.id || input.name}`
                        input.isInput = true
                    })
                    const outputs = taskDetails.outputs.filter(output => output.dataContentTypes[0] !== this.dataContentTypes.form)
                    if (taskDetails.choice) {
                        taskDetails.choice.normalizedName = taskDetails.choice.name || this.$tc('administration.taskOverview.activeTasks.expandableContent.dataAssociationTable.defaultChoiceName')
                        outputs.push(taskDetails.choice)
                    }
                    outputs.forEach(output => {
                        output.id = `output_${output.id || output.name}`
                        output.isInput = false
                    })
                    rowClickEventData.row.dataAssociations = taskDetails.inputs.concat(outputs)
                    this.$forceUpdate()
                })
                .catch(() => {
                })
        },

        getTaskDataAssociationUploadInfo (dataAssociation) {
            if (this.isDataAssociationDecision(dataAssociation)) {
                return dataAssociation.userName
            }

            const nullValue = dataAssociation.isInput
                ? this.$tc('generals.systemInformation')
                : null

            return dataAssociation.dataValues.length > 0 && dataAssociation.dataValues.at(-1).userName
                ? dataAssociation.dataValues.at(-1).userName
                : nullValue
        },

        getTaskDataAssociationUploadDate (dataAssociation) {
            if (this.isDataAssociationDecision(dataAssociation)) {
                return dataAssociation.selectionDate
                    ? this.getFormattedLongDate(dataAssociation.selectionDate, this.locale)
                    : null
            }

            return dataAssociation.dataValues.length > 0 && dataAssociation.dataValues.at(-1).userName
                ? this.getFormattedLongDate(dataAssociation.dataValues.at(-1).uploadDate, this.locale)
                : null
        },

        dataAssociationIsTypeMulti (dataAssociation) {
            return dataAssociation.dataContentTypes.length > 1 &&
                dataAssociation.dataValues.length === 0
        },

        getTaskDataAssociationIconClass (dataAssociation) {
            if (this.isDataAssociationDecision(dataAssociation)) {
                return 'far fa-plus-square'
            }
            if (this.dataAssociationIsTypeMulti(dataAssociation)) {
                return 'fas fa-user-cog'
            }
            const contentType = dataAssociation.dataContentTypes[0]
            switch (contentType) {
            case this.dataContentTypes.document:
                return 'fas fa-file-contract'
            case this.dataContentTypes.text:
                return 'fas fa-pen'
            case this.dataContentTypes.link:
                return 'fas fa-external-link-alt'
            case this.dataContentTypes.form:
                return 'fas fa-file-invoice'
            }
        },

        getDataAssociationIconType (dataAssociation) {
            if (dataAssociation.isInput) {
                return null
            }
            if (this.isDataAssociationDecision(dataAssociation)) {
                return dataAssociation.currentSelection || dataAssociation.defaultSelection
                    ? 'success'
                    : 'warning'
            }
            return dataAssociation.dataValues.length === 0 && (!dataAssociation.defaultValue || this.dataAssociationIsTypeMulti(dataAssociation))
                ? 'warning'
                : 'success'
        },

        openUrl (dataValue) {
            const url = this.isValidUrl(dataValue.textContent)
                ? dataValue.textContent
                : `https://${dataValue.textContent}`

            window.open(url, '_blank')
        },

        downloadDocumentFile (dataValue) {
            this.downloadFileFromUrl(
                `dataexchange/document/download/${dataValue.id}`,
                false,
                dataValue.fileName
            )
        },
        // A task is considered "in progress" when a name is associated with the task, either in gray (...hadAnAssignee) or actively is working on a task with a blue-highlighted name(...hasAssignee).
        // The gray indication means that someone was working on the task, but is currently not actively doing so. They have unclaimed the task but still want to "reserve" it for future work.
        getTaskIsInProgress (task) {
            return this.getTaskHasOrHadAnAssignee(task)
        },

        getTaskDataAssociationTextValue (dataAssociation) {
            if (this.isDataAssociationDecision(dataAssociation)) {
                const selection = dataAssociation.currentSelection || dataAssociation.defaultSelection
                return selection
                    ? dataAssociation.options.find(option => option.id === selection).name
                    : null
            }

            const textContent = dataAssociation.dataValues.length > 0
                ? dataAssociation.dataValues.at(-1).textContent
                : dataAssociation.defaultValue

            return dataAssociation.dataContentTypes[0] === this.dataContentTypes.form
                ? `${textContent.substring(0, 100)}...`
                : textContent
        },

        isDataAssociationDecision (dataAssociation) {
            return this.dataContentTypes.choice.includes(dataAssociation.type)
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getTableConfig () {
            return [{
                key: 'name',
                label: this.$tc('administration.taskOverview.activeTasks.table.taskName'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                width: 15
            }, {
                key: 'processName',
                label: this.$tc('administration.taskOverview.activeTasks.table.processName'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                width: 20
            }, {
                key: 'productNumber',
                label: this.$tc('administration.taskOverview.activeTasks.table.productNumber'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                width: 10
            }, {
                key: 'productContextDescription',
                label: this.$tc('administration.taskOverview.activeTasks.table.taskContext'),
                filterable: true,
                sortable: true,
                exportable: true,
                breakAnyWhere: true,
                alignment: 'left',
                width: 20
            }, {
                key: 'creationDateDisplayText',
                sortKey: 'creationDate',
                label: this.$tc('administration.taskOverview.activeTasks.table.creationDate'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                width: 10
            }, {
                key: 'assignee',
                label: this.$tc('administration.taskOverview.activeTasks.table.assignee'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                width: 20
            }, {
                key: 'action',
                label: null,
                filterable: false,
                sortable: false,
                exportable: false,
                alignment: 'right',
                width: 5
            }]
        },

        getTaskDetailsTableConfig () {
            return [{
                key: 'icon',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: 5
            }, {
                key: 'normalizedName',
                label: this.$tc('administration.taskOverview.activeTasks.expandableContent.dataAssociationTable.name'),
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: 30
            }, {
                key: 'uploadInfo',
                label: this.$tc('administration.taskOverview.activeTasks.expandableContent.dataAssociationTable.uploadInfo'),
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: 30
            }, {
                key: 'data',
                label: this.$tc('administration.taskOverview.activeTasks.expandableContent.dataAssociationTable.data'),
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: 35
            }]
        }
    },
    watch: {
        '$global.localization.locale' () {
            this.currentTasksList.forEach(task => {
                this.setFormattedCreationDate(task)
            })
        }
    },
    mounted () {
        this.getCurrentTasks()
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="current-tasks_table-container">
            <Table table-id="adminCurrentTasks"
                   v-bind:table-config="getTableConfig"
                   v-bind:table-data="currentTasksList"
                   v-bind:filter-placeholder="$tc('administration.taskOverview.activeTasks.table.filterPlaceholder')"
                   v-bind:table-empty-message="$tc('administration.taskOverview.activeTasks.table.tableEmpty')"
                   v-bind:filter-no-results-message="$tc('administration.taskOverview.activeTasks.table.filterNoResults')"
                   v-bind:read-only="true"
                   v-bind:show-spinner="showSpinner"
                   v-bind:expandable-rows="true"
                   @row-expand="getTaskDetails($event)">
                <!-- name: default cell content -->
                <!-- processName: default cell content -->
                <template #cell(productNumber)="data">
                    <RouteLink v-bind:displayed-text="data.row.productNumber"
                               v-bind:target="{name: 'Product', params: {productId: data.row.productId}}"
                               v-bind:tooltip="$tc('generals.goToProduct')"
                               v-bind:is-highlighted-link=true>
                    </RouteLink>
                </template>
                <!-- productContextDescription: default cell content -->
                <!-- creationDateDisplayText: default cell content -->
                <template #cell(assignee)="data">
                    <BadgeInput v-if="getTaskIsInProgress(data.row)"
                                v-bind:is-disabled=true
                                v-bind:value="data.row.assignmentDisplayText"
                                class="current-tasks-assignee-badge"
                                v-bind:class="{'m--currently-in-progress': data.row.assignee}">
                    </BadgeInput>
                    <span v-else>{{ data.row.assignmentDisplayText }}</span>
                </template>
                <template #cell(action)="data">
                    <IconButton v-bind:icon-class="data.row.assignee ? 'fas fa-lock' : 'fas fa-unlock'"
                                v-bind:tooltip="$tc('administration.taskOverview.activeTasks.table.unClaimTask')"
                                v-bind:is-disabled="!data.row.assignee"
                                @button-submit="unClaimTask(data.row)">
                    </IconButton>
                </template>
                <template #expandable-content="data">
                    <div class="current-tasks_table-expanded-content">
                        <Accordion
                            v-bind:title="$tc('administration.taskOverview.activeTasks.expandableContent.heading')"
                            v-bind:is-disabled="true">
                        </Accordion>
                        <Table v-bind:table-id="`${data.row.id}_taskInputs`"
                               v-bind:table-config="getTaskDetailsTableConfig"
                               v-bind:table-data="data.row.dataAssociations || []"
                               v-bind:read-only=true
                               v-bind:is-scrollable="false"
                               v-bind:show-spinner="!data.row.dataAssociations"
                               v-bind:table-empty-message="$tc('administration.taskOverview.activeTasks.expandableContent.dataAssociationTable.tableEmpty')">
                            <template #cell(icon)="expandData">
                                <Icon class="task-data-associations-table-icon"
                                      v-bind:class="{
                                          'm--input': expandData.row.isInput,
                                          'm--choice': isDataAssociationDecision(expandData.row)
                                      }"
                                      v-bind:icon-type="getDataAssociationIconType(expandData.row)"
                                      v-bind:icon-class="getTaskDataAssociationIconClass(expandData.row)"
                                      v-bind:icon-large=true>
                                </Icon>
                            </template>
                            <!-- normalizedName: default cell content -->
                            <template #cell(uploadInfo)="expandData">
                                <span>{{ getTaskDataAssociationUploadInfo(expandData.row) }}</span>
                                <div>
                                    <span>{{ getTaskDataAssociationUploadDate(expandData.row) }}</span>
                                </div>
                            </template>
                            <template #cell(data)="expandData">
                                <InteractionLink v-if="isDataAssociationDecision(expandData.row) ||
                                                       expandData.row.dataContentTypes[0] === dataContentTypes.text ||
                                                       expandData.row.dataContentTypes[0] === dataContentTypes.form"
                                                 class="generals-table-input"
                                                 v-bind:is-disabled="true"
                                                 v-bind:value="getTaskDataAssociationTextValue(expandData.row)">
                                </InteractionLink>
                                <template v-else>
                                    <InteractionLink v-for="value in expandData.row.dataValues"
                                                     class="task-data-associations-table-value"
                                                     v-bind:key="value.id"
                                                     v-bind:is-highlighted="true"
                                                     v-bind:icon-class="expandData.row.dataContentTypes[0] === dataContentTypes.document ? 'fas fa-download' : 'fas fa-external-link-alt'"
                                                     v-bind:value="expandData.row.dataContentTypes[0] === dataContentTypes.document ? value.fileName : value.textContent"
                                                     @input-click="expandData.row.dataContentTypes[0] === dataContentTypes.document ? downloadDocumentFile(value) : openUrl(value)">
                                    </InteractionLink>
                                </template>
                            </template>
                        </Table>
                    </div>
                </template>
            </Table>
        </div>
    </div>
</template>

<style scoped lang="less">
.current-tasks_table-container {
    width: 100%;
    height: 100%;
    padding: 0 var(--container-spacing);

    .current-tasks-assignee-badge {
        margin: 0;

        &.m--currently-in-progress {
            background-color: var(--color-info);
            color: var(--color-text-bright);
        }
    }

    .current-tasks_table-expanded-content {
        width: 100%;
        height: auto;

        .task-data-associations-table-icon {

            &.m--choice {
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);

                &:after {
                    -ms-transform: rotate(-45deg);
                    transform: rotate(-45deg);
                    margin: 0;
                }
            }

            &:after {
                position: absolute;
                margin: 12px 0 0 -2px;
                background-color: var(--color-background-highlighted);
                border-radius: 50%;
                width: 18px;
                height: 14px;
                padding-top: 4px;
                color: var(--color-text-bright);
                content: "\f3e5";
                font-family: "Font Awesome 5 Free", serif;
                font-size: 10px;
                font-weight: 900;
            }

            &.m--input:after {
                content: "\f064";
            }
        }

        .task-data-associations-table-value {
            margin-top: -4px;
            display: block;
        }
    }
}
</style>
