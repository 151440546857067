<script>
/**
 * DynamicInput allows to select the type of input
 * and provides the corresponding input field.
 *
 * If two input types are provided, the first one is used as default and the user can switch directly between both types,
 * no selection is needed.
 *
 * Props:
 * - id: Should be provided in order to get full functionality. Without an id you won't get the ability to close on esc and instant focus the input field when opening.
 * - label (optional): Text displayed as the label of the input field.
 * - inputTypes: Possible input types to provide data. Valid values are: text, link email, number, date, upload
 * - legacyMode (default: true): If explicitly set to 'false', the input field will be optimized for exactly 2 input types:
 *                                   The first defined inputType is used as default. No selection needed, but the user can
 *                                   switch between both types directly via a link.
 *                               The legacyMode guarantees that DynamicInput executed in the old 'SmartForm' in arbitrary
 *                                   combinations does not break.
 * - submitButton (default: true): Determines whether there is a submit button.
 * - resetOnSubmit (default: false): If true, the value of the selected input field will be reset to default or null after submit.
 * - isDisabled (default: false): If true, the user can't change the input value.
 * - allowMultiple (default: false): only applies to DropInput:
 *                                   If true, multiple values may be provided (if false, only one).
 * - isUploading (default: false): only applies to DropInput:
 *                                   While true, the input field will be disabled and show a spinner to indicate an upload in progress.
 *
 * Emits:
 * - @dynamic-submit event on pressing enter or clicking the submit icon.
 **/
import SmartSelect from '@/components/SmartSelect.vue'
import TextInput from '@/components/TextInput.vue'
import NumberInput from '@/components/NumberInput.vue'
import DateInput from '@/components/DateInput.vue'
import EmailInput from '@/components/EmailInput.vue'
import DropInput from '@/components/DropInput.vue'
import { defineComponent } from 'vue' // to enable type inference (typescript)

export default defineComponent({
    name: 'DynamicInput',
    components: {
        SmartSelect,
        TextInput,
        NumberInput,
        DateInput,
        EmailInput,
        DropInput
    },
    // @dynamic-submit: Event emitted on submit | returns the event and current input value
    emits: ['dynamic-submit'],
    props: {
        id: [String, Number],
        label: String,
        inputTypes: {
            type: Array,
            default: () => []
        },
        legacyMode: {
            default: true,
            type: Boolean
        },
        submitButton: {
            default: false,
            type: Boolean
        },
        resetOnSubmit: {
            default: false,
            type: Boolean
        },
        isDisabled: {
            default: false,
            type: Boolean
        },
        allowMultiple: {
            default: false,
            type: Boolean
        },
        isUploading: {
            default: false,
            type: Boolean
        }
    },
    data () {
        return {
            typeKeys: {
                TEXT: 'text',
                LINK: 'link',
                EMAIL: 'email',
                NUMBER: 'number',
                DATE: 'date',
                UPLOAD: 'upload'
            },
            selectedType: !this.legacyMode && this.inputTypes.length === 2
                ? this.inputTypes[0]
                : null
        }
    },
    methods: {
        setSelectedType (selectChangeEventData) {
            this.selectedType = selectChangeEventData
                ? selectChangeEventData.option.value
                : null
        },

        setSelectedTypeValue (value) {
            this.selectedType = value
        },

        resetSelectionOrToggle () {
            this.setSelectedTypeValue(this.otherInputType)
        },

        getTypeLabel (type) {
            return this.$tc(`dynamicInput.inputTypeLabels.${type}`)
        },

        emitSubmit (event) {
            this.$emit('dynamic-submit', {
                event: event,
                inputType: this.selectedType,
                value: event.value
            })
            if (!this.hasExactly2InputTypes) {
                // only switch back to selection if we have more than 2 input types
                this.setSelectedTypeValue(null)
            } // else: keep the selected type and reset the input field (done by the actual input component)
        }
    },
    computed: {

        typeSelectOptions () {
            const validTypes = Object.keys(this.typeKeys).map(key => this.typeKeys[key])
            const options = []
            this.inputTypes?.forEach(type => {
                if (validTypes.includes(type)) {
                    options.push({
                        label: this.$tc(`dynamicInput.inputTypeLabels.${type}`),
                        value: type
                    })
                }
            })
            return options
        },

        // -- handling if there only exists 2 inputTypes and the optimization is enabled

        hasExactly2InputTypes () {
            return !this.legacyMode && this.inputTypes.length === 2
        },
        otherInputType () {
            return this.hasExactly2InputTypes ? this.inputTypes.find(type => type !== this.selectedType) : null
        },
        backToSelectionText () {
            if (!this.hasExactly2InputTypes) {
                return this.$tc('dynamicInput.backToSelection')
            }
            return this.$tc(`dynamicInput.inputTypeLabels.${(this.otherInputType)}`)
        }
    },
    watch: {
    },
    mounted () {
    }
})
</script>

<template>
    <div class="c_dynamic-input-wrapper generals-input-wrapper">
        <label v-if="label" class="c_dynamic-input-label generals-input-label">
            <span>{{label}}</span>
        </label>
        <div class="c_dynamic-input-container generals-input-container">
            <SmartSelect v-if="!selectedType"
                         v-bind:id="`c_dynamic-input_${id}_type-select`"
                         v-bind:submit-button="false"
                         v-bind:placeholder="$tc('dynamicInput.placeholder')"
                         v-bind:options="typeSelectOptions"
                         v-bind:sort-options="false"
                         v-bind:allow-input="false"
                         v-bind:option-label-specifiers="['label']"
                         v-bind:filter-label-specifiers="['label']"
                         v-bind:is-disabled="isDisabled"
                         @select-change="setSelectedType($event)">
            </SmartSelect>
            <TextInput v-if="selectedType === typeKeys.TEXT || selectedType === typeKeys.LINK"
                       v-bind:submit-button="true"
                       icon-class="fas fa-check"
                       v-bind:reset-on-submit="resetOnSubmit"
                       v-bind:show-unsaved-changes="true"
                       v-bind:placeholder="getTypeLabel(selectedType)"
                       v-bind:is-disabled="isDisabled"
                       @input-submit="emitSubmit($event)">
            </TextInput>
            <NumberInput v-else-if="selectedType === typeKeys.NUMBER"
                         v-bind:submit-button="true"
                         v-bind:show-unsaved-changes="true"
                         v-bind:placeholder="getTypeLabel(selectedType)"
                         v-bind:minimum-value="0"
                         v-bind:is-disabled="isDisabled"
                         @input-submit="emitSubmit($event)">
            </NumberInput>
            <EmailInput v-else-if="selectedType === typeKeys.EMAIL"
                        v-bind:submit-button="true"
                        icon-class="fas fa-check"
                        v-bind:show-unsaved-changes="true"
                        v-bind:placeholder="getTypeLabel(selectedType)"
                        :reset-on-submit="resetOnSubmit"
                        v-bind:is-disabled="isDisabled"
                        @input-submit="emitSubmit($event)">
            </EmailInput>
            <DateInput v-else-if="selectedType === typeKeys.DATE"
                       v-bind:submit-button="true"
                       v-bind:show-unsaved-changes="true"
                       v-bind:placeholder="getTypeLabel(selectedType)"
                       v-bind:is-disabled="isDisabled"
                       @input-submit="emitSubmit($event)">
            </DateInput>
            <DropInput v-else-if="selectedType === typeKeys.UPLOAD"
                       v-bind:id="`c_dynamic-input_${id}_drop`"
                       v-bind:label="getTypeLabel(selectedType)"
                       v-bind:is-uploading="isUploading"
                       v-bind:is-drop-zone="false"
                       v-bind:allow-multiple="allowMultiple"
                       v-bind:is-disabled="isDisabled"
                       @input-submit="emitSubmit($event)">
            </DropInput>
            <div v-if="selectedType"
                 class="c_dynamic-input-back-arrow"
                 v-on:click="resetSelectionOrToggle">
                <span class="fas fa-arrow-right"></span>
                <span>&nbsp;{{ backToSelectionText }}</span>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_dynamic-input-wrapper {

    .c_dynamic-input-label {
    }

    .generals-input-container.c_dynamic-input-container {
        min-height: var(--input-height);
        height: unset;

        .c_dynamic-input-back-arrow {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            font-size: 13px;
            margin-top: 6px;
            cursor: pointer;

            &:hover {
                color: var(--color-text-highlighted);
            }
        }
    }
}
</style>
