<script>
/**
 * EssentialSmartForm is a styled customizable and extendable form with fields that include lots of logic.
 * It will throw a @form-submit event on pressing submit, a @form-change event when input value changes and a @form-submittable-change when the submittable state of the form changes to true or false.
 * - id: Should be provided in order to get full functionality. Without an id you won't get the ability to close on esc and instant focus the input field when opening.
 * - allowRepetition (default: false): If true, the user will be redirected to the first page of the pre-filled form, after the form has been sent successfully, in order to modify it and send it again.
 * - repeatFormButtonLabel (optional): If set, the button to repeat the form will show the given text. Otherwise, the button will show a default.
 * - hideTableOfContents (default: false): If true, there will be no table of contents on the left side of the form, which also acts as a form navigation.
 * - hideSubmitButton (default: false): If true, there will be no submit button on the last page of the form.
 * - formConfig (required): Object with form specific information. Determines the functionality of the form. The following information should be passed:
 *      - id: The form id.
 *      - headline: Text displayed in the form header.
 *      - sections: List of form sections. The following information should be passed:
 *          - id: The section id.
 *          - title: The page title. Will be displayed in the table of content.
 *          - allowMultiple: If true, the form allows adding new pages with the same section fields. At least one page must exist. Additional pages can be deleted.
 *          -> add isOpen: false to your section, if allowMultiple is true
 *          - pageTitle: Necessary if allowMultiple is true. Defines the default name of the pages in the section
 *          - pageTitleKey: Enter the ID of a field in the section here to specify its value as the page title when it is changed.
 *          - pages: List of pages within the section. At least on page must exist. Pages only contain key values pairs of each field of the section.
 *          - fields: List of form fields within the section. The following information should be provided for each field:
 *              - id: The field is. Must be provided in order to make the form work as intended.
 *              - label: The text displayed on the left side of the input field.
 *              - required: If true, the field must not be empty in order to continue to the next form page.
 *              - description: Text displayed in order to give the user some extra information about the field. Displayed in a clickable light bulb.
 *              - placeholder: The placeholder text in the input field. If not provided, a default text will be displayed.
 *              - disabled: If true, the field will always be disabled. Notice: A disabled field can be required. Make sure that you only use required property for disabled fields, if the disabled flag can be removed by filling other form fields or if you edit the field automatically.
 *              - invisible: If true, the field will always be invisible. Notice: A field cannot be required as long as it is not visible! Required will be overwritten when checking the correctness of the form.
 *              - quantity: A number which results in the possibility to add the same field until the quantity is reached. The user can add and remove fields by pressing the generated plus and minus buttons. The id of additional fields will have the current field count as suffix. For example: Original-ID 'myFieldId' results in 'myFieldId_2', 'myFieldId_3' and so on.
 *              - minimum: The minimum value. Can be used for Date or Number values.
 *              - maximum: The maximum value. Can be used for Date or Number values.
 *              - maxLength: The maximum character length: Can be used for Text and Textarea.
 *              - uploadLimit: The maximum number of files that can be uploaded. Can be used for Upload and UploadInput.
 *              - options: Only for type Select and Checklist. List of objects with label and value. Label will be displayed, value will be saved.
 *              - typeAhead: Only for Checklist. If true, there will be an 'Other' option, allowing the user to enter a value different from the provided ones.
 *              - pattern: Currently only available for Textarea. Regex pattern as string to prevent undesired user inputs.
 *              - singleChoice: Only for Checklist. If true, only a single option can be selected.
 *              - multiUpload: Only for Upload(Input). True enables multiselect in file explorer.
 *              - visibleIf: Ruleset which determines whether the field will be displayed or not. It is an expression that contains conditions chained by || and &&. Conditions can be:
 *                  - exists(fieldId) | !exists(fieldId)
 *                  - equals(fieldId, value) | !equals(fieldId, value)
 *                  - isTrue(fieldId) | !isTrue(fieldId)
 *              - enableIf: Ruleset which determines whether the field will be displayed or not. See "visibleIf" for further information.
 *              - submitButton: Show a submit button alongside the field (e.g. for text input).
 *              - type: Determines which kind of input field will be used. Valid types are:
 *                  - Text (String)
 *                  - Textarea (String)
 *                  - Email (String): Same as Text, but with a '@' icon and with validation.
 *                  - Number (Integer)
 *                  - Date (Date String)
 *                  - Checkbox (Boolean)
 *                  - Checklist (String Array)
 *                  - Select (String)
 *                  - ReadOnly (No save functionality): Works like a disabled text input, but it looks like a static text without greyed out background.x
 *                  - Paragraph (No save functionality): Renders HTML snippets. Can be used in order to display error messages etc. Displays the content within 'label' property.
 *                    Paragraph can have the special property 'subType', which determines the look of the rendered paragraph. Possible subTypes are: info, success, warning, error
 *                  - WebLink/DownloadLink (String)
 *                  - TimeSpan: Two time picker to specify a time span.
 *                  - TimeCode: A single time picker to specify a duration or time code.
 *                  - DualText: Like text, but with two separated text input fields.
 *                  - Currency (Integer): Same as Number, but with a '€' icon to make clear it's a currency.
 *                  - Estimate (Object with Integer and Boolean): renders an input type number with a checkbox next to it, which allows to declare a value as estimation. Value will be an object e.g. { value: 5, isEstimate: true }
 *                  - Subheading (No save functionality): Used to cluster field in a single section
 *                  - Slot: Simple div providing a Slot with name `field(${field.id})`. Access the slot withing the EssentialSmartForm element by its name and define some completely custom structure and logic.
 *                  - Form (No save functionality): Used to display a sub form. Provides search functionality and an expandable results list in which the user can choose the correct set of data from the backend response. Automatically fills out specified input fields.
 *                    Important: The backend response items must have an id!
 *                    Form has the following special attributes:
 *                      - fields: The fields of the sub form. Attributes are id, label, description, placeholder. See fields of section for further information. The values of the sub form fields will be sent as request parameters with the field's id as parameter name. If property apiParameter is provided, it will be used instead of the id.
 *                      - enableReset: There will be an additional reset button which will reset all values of 'formFieldMappings'.
 *                      - request: Configure a request that will be executed once the input value changed. The request has the following properties:
 *                          - api: Rest-Api that will be requested on submit with the entered value(s). The response can be automatically applied to other form fields,
 *                          - apiType: Rest API type. GET or POST,
 *                          - apiParameter: This value will be used as api parameter key with the inputs value as api parameter value. If not provided, the value will be added as body,
 *                          - isExternalApi: If true, a complete api url must be provided in 'api' (https://someUrl). If false, 'api' must be the name of the desired api. The path to the CorFlow rest service will be added automatically.
 *                          - formFieldMappings: Maps values of the JSON-object backend response to the correct form field of the section. Key value pairs. The key specifies a property of the backend response. The value is an id of a field within the section.
 * **/
import HelpText from '@/components/HelpText.vue'
import Icon from '@/components/Icon.vue'
import IconButton from '@/components/IconButton.vue'
import Button from '@/components/Button.vue'
import ContentHeader from '@/components/ContentHeader.vue'
import SmartFormSubheading from '@/components/SmartFormSubheading.vue'
import InlineForm from '@/components/InlineForm.vue'
import TextInput from '@/components/TextInput.vue'
import EmailInput from '@/components/EmailInput.vue'
import AreaInput from '@/components/AreaInput.vue'
import NumberInput from '@/components/NumberInput.vue'
import DateInput from '@/components/DateInput.vue'
import SmartSelect from '@/components/SmartSelect.vue'
import Checkbox from '@/components/Checkbox.vue'
import Checklist from '@/components/Checklist.vue'
import InteractionLink from '@/components/InteractionLink.vue'
import TimeSpanInput from '@/components/TimeSpanInput.vue'
import TimeCodeInput from '@/components/TimeCodeInput.vue'
import DualTextInput from '@/components/DualTextInput.vue'
import VerticalLine from '@/components/VerticalLine.vue'
import { notificationHandler } from '@/mixins/notificationHandler'
import { downloadHandler } from '@/mixins/downloadHandler'
import { urlHelper } from '@/mixins/urlHelper'
import EssentialSmartFormService from '@/services/EssentialSmartFormService'
import ToggleButton from '@/components/ToggleButton.vue'
import PageRangeInput from '@/components/PageRangeInput.vue'

export default {
    name: 'EssentialSmartForm',
    mixins: [
        notificationHandler,
        downloadHandler,
        urlHelper
    ],
    components: {
        PageRangeInput,
        ToggleButton,
        HelpText,
        Icon,
        IconButton,
        Button,
        ContentHeader,
        SmartFormSubheading,
        InlineForm,
        TextInput,
        EmailInput,
        AreaInput,
        NumberInput,
        DateInput,
        SmartSelect,
        Checkbox,
        Checklist,
        InteractionLink,
        TimeSpanInput,
        TimeCodeInput,
        DualTextInput,
        VerticalLine
    },
    // @form-submit: Event emitted on submit | returns the event and the formConfig
    // @form-change: Event emitted on input | returns the formConfig
    // @form-submittable-change: Event emitted when form submittable state changes / returns the submittable state
    emits: ['form-submit', 'form-change', 'form-submittable-change'],
    props: {
        id: String,
        allowRepetition: {
            default: false,
            type: Boolean
        },
        repeatFormButtonLabel: String,
        hideTableOfContents: {
            default: false,
            type: Boolean
        },
        hideSubmitButton: {
            default: false,
            type: Boolean
        },
        formConfig: Object
    },
    data () {
        return {
            // if true: on submit, some data remains and the user can reuse it
            repeatOnSubmit: false,

            // whether the submit button is locked (because the form is incomplete) - we use it to debounce the submit button
            submitLocked: false,

            // shows which section of the form definition we are on
            // see GitLab for a visual example: https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/610#note_322517
            sectionSelectionIndex: 0,

            // which page in the section's data is selected
            // (if the current section is "allow-multiple", there can be multiple - otherwise, it's just one at index 0)
            // see GitLab for a visual example: https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/610#note_322517
            pageSelectionIndex: 0,

            // whether to show the table of contents
            tableOfContentExpanded: true,

            // fields that are being saved; we use it for displaying the green hook next to values that are being saved
            // (may be removed in the future)
            savingFields: {},

            // if true, no form sections are incomplete - the form can be submitted by the user
            isFormSubmittable: null,

            // helper class
            essentialSmartFormService: new EssentialSmartFormService(this.formConfig, this.$global.localization.locale)
        }
    },
    methods: {
        toggleTableOfContent () {
            this.tableOfContentExpanded = !this.tableOfContentExpanded
        },

        toggleSection (section) {
            section.isOpen = !section.isOpen
        },

        isSectionSelected (sectionIndex) {
            return this.essentialSmartFormService.isSectionSelected(sectionIndex, this.sectionSelectionIndex)
        },

        setSectionSelected (section, index) {
            if (section.allowMultiple) {
                this.toggleSection(section)
            } else if (!this.isSectionSelected(index)) {
                this.setPageSelected(0, index)
            }
        },

        isPageSelected (pageIndex, sectionIndex) {
            return this.essentialSmartFormService.isPageSelected(pageIndex, sectionIndex, this.pageSelectionIndex, this.sectionSelectionIndex)
        },

        setPageSelected (pageIndex, sectionIndex) {
            if (!this.isPageSelected(pageIndex, sectionIndex)) {
                this.pageSelectionIndex = pageIndex

                if (!this.isSectionSelected(sectionIndex)) {
                    this.sectionSelectionIndex = sectionIndex
                }
                this.generateTemporaryFields()
                document.getElementById(`c_smart-form-body_${this.formConfig.id}`).scrollTop = 0
            }
        },

        setDefaultValuesOnEmptyPages () {
            this.formConfig.sections.forEach(section => {
                if (section.defaultValues) {
                    section.pages.forEach((page, index) => {
                        if (Object.keys(page).length === 0) {
                            section.pages[index] = this.essentialSmartFormService.getClone(section.defaultValues)
                        }
                    })
                }
            })
        },

        // TODO: Check, if logic can be improved: Generate temporary fields within the form structure, based on the field's quantity
        generateTemporaryFields () {
            // originalId = ID of original field without index - indicator that it is a field with temporary fields
            this.getSelectedSection.fields = this.getSelectedSection.fields.filter(field => !field.originalId)
            for (const field of this.getSelectedSection.fields) {
                // when a new temp field is added, the actual current index changes
                // => We have to retrieve it for each loop again
                const index = this.getSelectedSection.fields.indexOf(field)
                // quantity = maximum number of fields including temporary fields
                if (field.quantity && field.quantity > 1) {
                    // hasChild = if falsy, indicates that it is the last field
                    delete field.hasChild
                    const childFieldCount = this.getChildFieldCount(field)
                    const tempFieldId = `${field.originalId || field.id}_${childFieldCount + 1}`
                    const valueExists = this.getSelectedPage[tempFieldId]
                    if (valueExists) {
                        this.addChildFieldToSelectedSelection(field, index)
                    }
                }
            }
        },

        setFormSubmitStatus () {
            this.isFormSubmittable = !this.essentialSmartFormService.hasIncompleteSections()
        },

        getSectionTitle (section) {
            return this.essentialSmartFormService.getSectionTitle(section)
        },

        getPageTitle (page, section, pageIndex) {
            return this.essentialSmartFormService.getPageTitle(page, section, pageIndex)
        },

        getHeaderTitle () {
            return this.essentialSmartFormService.getHeaderTitle(this.getSelectedSection, this.getSelectedPage, this.pageSelectionIndex)
        },

        setPageTitle (event, field, sectionIndex, page, pageIndex) {
            this.setPageSelected(pageIndex, sectionIndex)
            this.setPageInEditMode(page, false)
            this.setFieldValue(event, field)
        },

        setPageInEditMode (page, inEditMode) {
            inEditMode
                ? page.isInEditMode = true
                : delete page.isInEditMode
            this.$forceUpdate()
        },

        setRepeatOnSubmit (doRepeat) {
            this.repeatOnSubmit = doRepeat
        },

        addFormPage (section, index) {
            const newPage = section.defaultValues
                ? this.essentialSmartFormService.getClone(section.defaultValues)
                : {}
            section.pages.push(newPage)
            if (index) {
                this.setPageSelected(section.pages.length - 1, index)
            }
            this.setFormSubmitStatus()
        },

        isDeletePageDisabled (section) {
            return this.essentialSmartFormService.isDeletePageDisabled(section)
        },

        copyFormPageFromSection (section, page) {
            section.pages.push(this.essentialSmartFormService.getClone(page))
            this.setPageSelected(section.pages.length - 1, this.sectionSelectionIndex)
            this.setFormSubmitStatus()
        },

        deleteFormPageFromSection (section, sectionIndex, page, pageIndex) {
            const changes = {}
            Object.keys(page).forEach(key => {
                changes[key] = null
            })
            section.pages.splice(pageIndex, 1)

            const pageIsPartOfTheSelectedSection = sectionIndex === this.sectionSelectionIndex
            const isPageOnAPositionBeforeTheSelectedOne = pageIndex < this.pageSelectionIndex
            const isTheSelectedPageOnLastPosition = pageIndex === this.pageSelectionIndex && pageIndex === section.pages.length
            if (pageIsPartOfTheSelectedSection &&
                (isPageOnAPositionBeforeTheSelectedOne || isTheSelectedPageOnLastPosition)) {
                this.setPageSelected(this.pageSelectionIndex - 1, this.sectionSelectionIndex)
            }
            this.emitChange(changes)
        },

        navigateForwards () {
            const selectedSection = this.getSelectedSection
            if (this.pageSelectionIndex + 1 < selectedSection.pages.length) {
                this.setPageSelected(this.pageSelectionIndex + 1, this.sectionSelectionIndex)
            } else {
                this.setPageSelected(0, this.sectionSelectionIndex + 1)
                this.getSelectedSection.isOpen = this.getSelectedSection.allowMultiple
            }
        },

        navigateBackwards () {
            if (this.pageSelectionIndex - 1 >= 0) {
                this.setPageSelected(this.pageSelectionIndex - 1, this.sectionSelectionIndex)
            } else {
                const newSectionIndex = this.sectionSelectionIndex - 1
                const pages = this.formConfig.sections[newSectionIndex].pages
                this.setPageSelected(pages.length - 1, newSectionIndex)
            }
        },

        showSectionAsComplete (index) {
            return !this.essentialSmartFormService.isSectionIncompleteInclCheckboxes(index)
        },

        showPageAsComplete (pageIndex, sectionIndex) {
            return !this.essentialSmartFormService.isPageIncompleteInclusiveCheckboxes(pageIndex, sectionIndex)
        },

        showAsMissing (field) {
            return this.essentialSmartFormService.isRequiredButFalsyIncludingCheckboxes(this.getSelectedPage, field)
        },

        isFieldDisabled (field, sectionId, pageIndex) {
            return this.essentialSmartFormService.isFieldDisabled(field, sectionId, pageIndex)
        },

        isFieldVisible (field, sectionId, pageIndex) {
            return this.essentialSmartFormService.isFieldVisible(field, sectionId, pageIndex)
        },

        isFieldSaving (field) {
            return this.essentialSmartFormService.isFieldSaving(field, this.savingFields)
        },

        isChildFieldButtonPairVisible (field) {
            return this.essentialSmartFormService.isChildFieldButtonPairVisible(field)
        },

        getChildFieldCount (field) {
            return this.essentialSmartFormService.getChildFieldCount(this.getSelectedSection, field)
        },

        addChildFieldToSelectedSelection (field, index) {
            const childField = this.essentialSmartFormService.generateChildField(this.getSelectedSection, field)
            field.hasChild = childField.id
            this.getSelectedSection.fields.splice(index + 1, 0, childField)
        },

        removeChildField (field, index) {
            this.setFieldValue({ value: undefined }, field)
            delete this.getSelectedSection.fields[index - 1].hasChild
            this.getSelectedSection.fields.splice(index, 1)
        },

        setFieldValue (submitEventData, field) {
            const value = field.type === 'Select'
                ? submitEventData.option.value
                : submitEventData.value

            this.getSelectedPage[field.id] = field.type === 'Estimate'
                ? { value: value, isEstimate: submitEventData.isEstimate }
                : value

            const changes = {}
            changes[field.id] = value
            this.emitChange(changes, [field.id])
        },

        getSelectLabelSpecifier (options) {
            return this.essentialSmartFormService.getSelectOptionsLabelSpecifier(options)
        },

        getChecklistOptions (field) {
            return this.essentialSmartFormService.getChecklistOptions(field)
        },

        getFieldLabel (field) {
            return this.essentialSmartFormService.getFieldLabel(field)
        },

        getFieldDescription (field) {
            return this.essentialSmartFormService.getTranslatedObjectProperty(field, 'description')
        },

        getFieldPlaceholder (field) {
            return this.essentialSmartFormService.getTranslatedObjectProperty(field, 'placeholder')
        },

        getFieldValue (field) {
            return this.essentialSmartFormService.getFieldValue(field, this.getSelectedPage)
        },

        getEstimateValue (field) {
            return this.essentialSmartFormService.getEstimateValue(field, this.getSelectedPage)
        },

        getEstimateBool (field) {
            return this.essentialSmartFormService.getEstimateBool(field, this.getSelectedPage)
        },

        getValueAsDate (value) {
            return this.essentialSmartFormService.getValueAsDate(value)
        },

        getOptionByFieldValue (field) {
            return this.essentialSmartFormService.getOptionByFieldValue(field, this.getSelectedPage)
        },

        getParagraphIconClass (paragraphSubType) {
            return this.essentialSmartFormService.getParagraphIconClass(paragraphSubType)
        },

        getInlineFormFieldProperties (inlineFormField) {
            return this.essentialSmartFormService.getInlineFormFieldProperties(inlineFormField)
        },

        applySearchData (dataAppliedEventData) {
            this.emitChange(dataAppliedEventData.changes, Object.keys(dataAppliedEventData.changes), false)
        },

        resetAppliedSearchData (dataResetEventData) {
            this.emitChange(dataResetEventData.changes, Object.keys(dataResetEventData.changes), false)
        },

        downloadFile (field) {
            const value = this.getFieldValue(field)
            this.downloadFileFromUrl(value)
        },

        openUrl (field) {
            const value = this.getFieldValue(field)
            this.openUrlForValue(value)
        },

        emitChange (changes, savedFieldsIds, resetDependentFields = true) {
            // TODO: Check, if there is a better way to detect cycles within dependencies
            if (savedFieldsIds) {
                const processedFieldIds = []
                let fieldsInProcessing = Array.from(savedFieldsIds)
                while (fieldsInProcessing.length > 0) {
                    const processFieldId = fieldsInProcessing.pop()
                    if (processedFieldIds.includes(processFieldId)) {
                        continue
                    }
                    processedFieldIds.push(processFieldId)
                    if (savedFieldsIds.includes(processFieldId)) {
                        if (this.savingFields[processFieldId]) {
                            clearTimeout(this.savingFields[processFieldId].runningTimeout)
                        }
                        this.savingFields[processFieldId] = {
                            showSaveIndicator: true,
                            runningTimeout: setTimeout(() => {
                                delete this.savingFields[processFieldId]
                                this.$forceUpdate()
                            }, 3000)
                        }
                    } else {
                        changes[processFieldId] = null
                    }
                    if (resetDependentFields) {
                        function deleteFieldDataOnPage (appContext, page, dependentField) {
                            if ((!appContext.isFieldVisible(dependentField) || appContext.isFieldDisabled(dependentField)) && page[dependentField.id]) {
                                const changes = {}
                                changes[dependentField.id] = null
                                resetFields.push(dependentField.id)
                                delete page[dependentField.id]
                            }
                        }
                        const resetFields = []
                        this.formConfig.sections.forEach(section => {
                            const dependentFields = section.fields.filter(field => {
                                return this.essentialSmartFormService.fieldHasVisibleOrEnableDependency(field, processFieldId) &&
                                    !this.essentialSmartFormService.fieldFulfillsSpecialConditionNotToBeReset(field)
                            })
                            dependentFields.forEach(dependentField => {
                                section.id === this.getSelectedSection.id
                                    ? deleteFieldDataOnPage(this, this.getSelectedPage, dependentField)
                                    : section.pages.forEach(page => deleteFieldDataOnPage(this, page, dependentField))
                            })
                        })
                        fieldsInProcessing = fieldsInProcessing.concat(resetFields)
                    }
                }
            }
            this.$emit('form-change', {
                formConfig: this.formConfig,
                changes: changes
            })
            this.setFormSubmitStatus()
            this.$forceUpdate()
        },

        submitForm (event) {
            event.stopPropagation()

            this.$emit('form-submit', {
                event: event,
                formConfig: this.formConfig,
                repeat: this.repeatOnSubmit
            })
            if (this.repeatOnSubmit) {
                this.setPageSelected(0, 0)
                this.setRepeatOnSubmit(false)
            } else {
                this.submitLocked = true
                setTimeout(() => {
                    this.submitLocked = false
                }, 7500)
            }
        }
    },
    computed: {
        getFormHeadline () {
            return this.essentialSmartFormService.getTranslatedObjectProperty(this.formConfig, 'headline')
        },

        isAddAnotherPageVisible () {
            const selectedSection = this.getSelectedSection
            return selectedSection.allowMultiple &&
                selectedSection.pages.length - 1 === this.pageSelectionIndex
        },

        isFirstPageSelected () {
            return this.sectionSelectionIndex === 0 &&
                this.pageSelectionIndex === 0
        },

        isLastPageSelected () {
            return this.formConfig.sections.length - 1 === this.sectionSelectionIndex &&
                this.getSelectedSection.pages.length - 1 === this.pageSelectionIndex
        },

        getSelectedSection () {
            return this.formConfig.sections[this.sectionSelectionIndex]
        },

        getSelectedPage () {
            const selectedSection = this.getSelectedSection
            return selectedSection.pages[this.pageSelectionIndex]
        }
    },
    watch: {
        isFormSubmittable () {
            this.$emit('form-submittable-change', this.isFormSubmittable)
        }
    },
    beforeMount () {
        this.setFormSubmitStatus()
        this.generateTemporaryFields()
        this.setDefaultValuesOnEmptyPages()
    },
    beforeUnmount () {
        // clear all timeouts on fields which still are checked green to show that they just have been changed
        Object.keys(this.savingFields).forEach(key => clearTimeout(this.savingFields[key].runningTimeout))
    }
}
</script>

<template>
    <div class="c_essential-smart-form-wrapper">
        <div v-if="!this.hideTableOfContents"
             class="c_smart-form-content-container generals-animate"
             v-bind:class="{'m--collapsed': !tableOfContentExpanded}">
            <div class="c_smart-form-content-header">
                <span>{{$tc('smartForm.tableOfContents')}}</span>
            </div>
            <div class="c_smart-form-content">
                <!-- STRUCTURE: Left SECTIONS: Loop over sections -->
                <div v-for="(section, sectionIndex) in formConfig.sections"
                     v-bind:key="sectionIndex"
                     class="c_smart-form-content-section"
                     data-testid="toc-section"
                     v-bind:class="{
                         'm--selected': isSectionSelected(sectionIndex),
                         'm--multiple': section.allowMultiple,
                         'm--complete': showSectionAsComplete(sectionIndex)}">
                    <div class="c_smart-form-content-section-title"
                          v-on:click="setSectionSelected(section, sectionIndex)">
                        <span>{{getSectionTitle(section)}}</span>
                    </div>
                    <IconButton v-if="section.allowMultiple"
                                class="c_smart-form-content-section-button"
                                data-testid="toc-show-or-hide-section-pages-button"
                                v-bind:icon-class="section.isOpen ? 'fas fa-caret-down' : 'fas fa-caret-left'"
                                @button-submit="toggleSection(section)">
                    </IconButton>
                    <transition name="show" tag="div">
                        <div v-if="section.isOpen"
                             v-bind:key="sectionIndex"
                             class="c_smart-form-content-section-pages">
                            <!-- STRUCTURE: Left SECTIONS: Loop over pages of current section -->
                            <div v-for="(page, pageIndex) in section.pages"
                                 v-bind:key="pageIndex"
                                 data-testid="toc-section-page"
                                 class="c_smart-form-content-section-page"
                                 v-bind:class="{
                                    'm--selected': isPageSelected(pageIndex, sectionIndex),
                                    'm--complete': showPageAsComplete(pageIndex, sectionIndex)
                                 }"
                                 v-on:click="setPageSelected(pageIndex, sectionIndex)">
                                <div class="c_smart-form-content-section-page-title-wrapper">
                                    <span v-if="!page.isInEditMode"
                                          tabindex="1"
                                          class="c_smart-form-content-section-page-title"
                                          data-testid="toc-section-page-title"
                                          v-on:click="setPageInEditMode(page,true)">{{getPageTitle(page, section, pageIndex)}}
                                    </span>
                                    <TextInput v-else
                                               v-bind:id="`page-title-input_${sectionIndex}_${pageIndex}`"
                                               data-testid="toc-section-page-title-input"
                                               v-bind:default-value="getPageTitle(page, section, pageIndex)"
                                               v-bind:submit-button="true"
                                               icon-class="fas fa-check"
                                               v-bind:is-auto-focused="true"
                                               @input-lost-focus="setPageInEditMode(page,false)"
                                               @input-close="setPageInEditMode(page,false)"
                                               @input-submit="setPageTitle($event, { id: section.pageTitleKey || 'title', type: 'Text'}, sectionIndex, page, pageIndex)">
                                    </TextInput>
                                </div>
                                <div class="c_smart-form-content-section-page-icon-buttons">
                                    <IconButton class="c_smart-form-content-section-page-copy-button"
                                                data-testid="section-page-copy-button"
                                                icon-class="far fa-copy"
                                                v-bind:font-size="15"
                                                v-bind:tooltip="$t('smartForm.copyPage', [page.title ? `'${page.title}'` : section.pageTitle]).toString()"
                                                @button-submit="copyFormPageFromSection(section, page)">
                                    </IconButton>
                                    <VerticalLine />
                                    <IconButton class="c_smart-form-content-section-page-delete-button"
                                                data-testid="section-page-delete-button"
                                                icon-class="fas fa-trash"
                                                v-bind:font-size="15"
                                                v-bind:tooltip="$t('smartForm.deletePage', [page.title ? `'${page.title}'` : section.pageTitle]).toString()"
                                                v-bind:is-disabled="isDeletePageDisabled(section)"
                                                @button-submit="deleteFormPageFromSection(section, sectionIndex, page, pageIndex)">
                                    </IconButton>
                                </div>
                            </div>
                            <div class="c_smart-form-content-section-pages-button"
                                 data-testid="toc-add-section-page-button"
                                 v-on:click="addFormPage(section, sectionIndex)">
                                <span class="fas fa-plus"> </span>
                                <span>{{$t('smartForm.addPage', [section.pageTitle])}}</span>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>
        <div class="c_smart-form-container generals-animate"
             v-bind:class="{'m--full-width': hideTableOfContents || !tableOfContentExpanded}">
            <div class="c_smart-form-headline">
                <div v-if="!this.hideTableOfContents"
                     class="c_smart-form-header-toggle"
                     v-on:click="toggleTableOfContent()">
                    <span v-bind:class="[tableOfContentExpanded ? 'fas fa-caret-left' : 'fas fa-caret-right']"></span>
                </div>
                <span>{{getFormHeadline || $tc('smartForm.defaultHeadline')}}</span>
            </div>
            <div class="c_smart-form">
                <!-- STRUCTURE: FORM Header of selected SECTION -->
                <ContentHeader v-bind:header-title="getHeaderTitle()"
                               v-bind:use-slot="true">
                    <template v-slot>
                        <IconButton icon-class="fas fa-trash"
                                    v-bind:tooltip="$t('smartForm.deletePage', [getSelectedPage.title ? `'${getSelectedPage.title}'` : getSelectedSection.pageTitle]).toString()"
                                    v-bind:is-disabled="isDeletePageDisabled(getSelectedSection)"
                                    @button-submit="deleteFormPageFromSection(getSelectedSection, sectionSelectionIndex, getSelectedPage, pageSelectionIndex)">
                        </IconButton>
                    </template>
                </ContentHeader>
                <!-- STRUCTURE: FORM of selected SECTION -->
                <div v-bind:id="`c_smart-form-body_${formConfig.id}`"
                     class="c_smart-form-body">
                    <div class="c_smart-form-fields">
                        <transition-group name="show" tag="div">
                            <!-- STRUCTURE: FORM fields of selected SECTION -->
                            <template v-for="(field, fieldIndex) in getSelectedSection.fields">
                                <div v-if="isFieldVisible(field, getSelectedSection.id, pageSelectionIndex)"
                                     v-bind:key="`${field.id}_${pageSelectionIndex}`"
                                     class="c_smart-form-field"
                                     v-bind:class="{
                                         'm--missing': showAsMissing(field),
                                         'm--no-margin': field.originalId
                                     }">
                                    <div v-if="field.type === 'Subheading'">
                                        <SmartFormSubheading v-bind:label="getFieldLabel(field)" />
                                    </div>
                                    <InlineForm v-if="field.type === 'Form'"
                                                v-bind:field="field"
                                                v-bind:field-properties="getInlineFormFieldProperties(field)"
                                                v-bind:page="getSelectedPage"
                                                v-bind:page-index="pageSelectionIndex"
                                                @data-applied="applySearchData($event)"
                                                @data-reset="resetAppliedSearchData($event)">
                                    </InlineForm>
                                    <TextInput v-if="field.type === 'Text'"
                                               v-bind:label="getFieldLabel(field)"
                                               v-bind:submit-button="field.submitButton || false"
                                               v-bind:show-unsaved-changes=true
                                               v-bind:is-disabled=isFieldDisabled(field)
                                               v-bind:placeholder="getFieldPlaceholder(field)"
                                               v-bind:default-value="getFieldValue(field)"
                                               v-bind:max-length="field.maxLength"
                                               v-bind:pattern="field.pattern"
                                               v-bind:patternKey="field.patternKey"
                                               v-bind:initially-valid="field.initiallyValid"
                                               icon-class="fas fa-pen"
                                               @input-blurred="setFieldValue($event, field)"
                                               @input-submit="setFieldValue($event, field)">
                                    </TextInput>
                                    <EmailInput v-else-if="field.type === 'Email'"
                                                v-bind:label="getFieldLabel(field)"
                                                v-bind:submit-button="field.submitButton || false"
                                                v-bind:show-unsaved-changes=true
                                                v-bind:is-disabled=isFieldDisabled(field)
                                                v-bind:placeholder="getFieldPlaceholder(field)"
                                                v-bind:default-value="getFieldValue(field)"
                                                icon-class="fas fa-at"
                                                @input-blurred="setFieldValue($event, field)"
                                                @input-submit="setFieldValue($event, field)">
                                    </EmailInput>
                                    <AreaInput v-else-if="field.type === 'Textarea'"
                                               v-bind:label="getFieldLabel(field)"
                                               v-bind:submit-button="field.submitButton || false"
                                               v-bind:show-unsaved-changes=true
                                               v-bind:is-disabled=isFieldDisabled(field)
                                               v-bind:placeholder="getFieldPlaceholder(field)"
                                               v-bind:default-value="getFieldValue(field)"
                                               v-bind:is-expandable="true"
                                               v-bind:max-length="field.maxLength"
                                               v-bind:pattern="field.pattern"
                                               v-bind:patternKey="field.patternKey"
                                               icon-class="far fa-sticky-note"
                                               @input-blurred="setFieldValue($event, field)"
                                               @input-submit="setFieldValue($event, field)">
                                    </AreaInput>
                                    <div v-else-if="field.type === 'Paragraph'"
                                         class="c_smart-form-field-paragraph"
                                         v-bind:class="`m--${field.subType}`">
                                        <span v-if="field.subType"
                                              class="c_smart-form-field-paragraph-icon"
                                              v-bind:class="getParagraphIconClass(field.subType)">
                                        </span>
                                        <div v-dompurify-html="getFieldLabel(field)"></div>
                                    </div>
                                    <NumberInput v-else-if="field.type === 'Number' || field.type === 'Currency' || field.type === 'Estimate'"
                                                 v-bind:label="getFieldLabel(field)"
                                                 v-bind:submit-button="field.submitButton || false"
                                                 v-bind:show-unsaved-changes=true
                                                 v-bind:icon-class="field.type === 'Currency' ? 'fas fa-euro-sign' : null"
                                                 v-bind:allow-estimate="field.type === 'Estimate'"
                                                 v-bind:is-disabled=isFieldDisabled(field)
                                                 v-bind:placeholder="getFieldPlaceholder(field)"
                                                 v-bind:default-value="field.type === 'Estimate' ? getEstimateValue(field) : getFieldValue(field)"
                                                 v-bind:minimum-value="field.minimum || 0"
                                                 v-bind:maximum-value="field.maximum"
                                                 @input-blurred="setFieldValue($event, field)"
                                                 @input-submit="setFieldValue($event, field)">
                                    </NumberInput>
                                    <DateInput v-else-if="field.type === 'Date'"
                                               v-bind:label="getFieldLabel(field)"
                                               v-bind:submit-button="field.submitButton || false"
                                               v-bind:show-unsaved-changes=true
                                               v-bind:is-disabled=isFieldDisabled(field)
                                               v-bind:placeholder="getFieldPlaceholder(field)"
                                               v-bind:default-value="getFieldValue(field)"
                                               v-bind:minimum-date="getValueAsDate(field.minimum)"
                                               v-bind:maximum-date="getValueAsDate(field.maximum)"
                                               @input-blurred="setFieldValue($event, field)"
                                               @input-submit="setFieldValue($event, field)">
                                    </DateInput>
                                    <Checkbox v-else-if="field.type === 'Checkbox'"
                                              v-bind:notify-more="field.notifyMore"
                                              v-bind:label="getFieldLabel(field)"
                                              v-bind:is-disabled=isFieldDisabled(field)
                                              v-bind:default-value="getFieldValue(field)"
                                              @input-change="setFieldValue($event, field)">
                                    </Checkbox>
                                    <ToggleButton v-else-if="field.type === 'ToggleButton'"
                                                  v-bind:id="field.id"
                                                  v-bind:label="getFieldLabel(field)"
                                                  v-bind:label-toggle-off="field.labelToggleOff"
                                                  v-bind:label-toggle-on="field.labelToggleOn"
                                                  v-bind:default-value="field.default"
                                                  @toggle-change="setFieldValue($event, field)">
                                    </ToggleButton>
                                    <Checklist v-else-if="field.type === 'Checklist'"
                                               v-bind:label="getFieldLabel(field)"
                                               v-bind:is-disabled=isFieldDisabled(field)
                                               v-bind:single-choice="field.singleChoice === true"
                                               v-bind:allow-custom-option="field.typeAhead"
                                               v-bind:default-values="getFieldValue(field) || []"
                                               v-bind:options="getChecklistOptions(field)"
                                               @checklist-change="setFieldValue($event, field)">
                                    </Checklist>
                                    <SmartSelect v-else-if="field.type === 'Select'"
                                                 v-bind:key="`${field.id}_${pageSelectionIndex}_${getFieldValue(field)}`"
                                                 v-bind:id="`${field.id}_${pageSelectionIndex}`"
                                                 v-bind:label="getFieldLabel(field)"
                                                 v-bind:submit-button=false
                                                 v-bind:show-unsaved-changes=true
                                                 v-bind:is-disabled=isFieldDisabled(field)
                                                 v-bind:placeholder="getFieldPlaceholder(field)"
                                                 v-bind:sort-options=false
                                                 v-bind:is-auto-focused=false
                                                 v-bind:allow-input=false
                                                 v-bind:options="field.options || []"
                                                 v-bind:filter-label-specifiers="getSelectLabelSpecifier(field.options)"
                                                 v-bind:option-label-specifiers="getSelectLabelSpecifier(field.options)"
                                                 v-bind:default-option="getOptionByFieldValue(field)"
                                                 @select-submit="setFieldValue($event, field)"
                                                 @select-change="setFieldValue($event, field)">
                                    </SmartSelect>
                                    <InteractionLink v-else-if="field.type === 'WebLink' || field.type === 'DownloadLink'"
                                                     v-bind:id="field.id"
                                                     v-bind:label="getFieldLabel(field)"
                                                     v-bind:is-highlighted="true"
                                                     v-bind:is-disabled=isFieldDisabled(field)
                                                     v-bind:value="getFieldPlaceholder(field)"
                                                     @input-click="field.type === 'DownloadLink' ? downloadFile(field) : openUrl(field)">
                                    </InteractionLink>
                                    <InteractionLink v-else-if="field.type === 'ReadOnly'"
                                                     v-bind:id="field.id"
                                                     v-bind:label="getFieldLabel(field)"
                                                     v-bind:value="getFieldValue(field)"
                                                     v-bind:placeholder="getFieldPlaceholder(field)"
                                                     v-bind:is-disabled="true">
                                    </InteractionLink>
                                    <TimeSpanInput v-else-if="field.type === 'TimeSpan'"
                                                   v-bind:label="getFieldLabel(field)"
                                                   v-bind:submit-button=false
                                                   v-bind:show-unsaved-changes=true
                                                   v-bind:is-disabled=isFieldDisabled(field)
                                                   v-bind:default-value="getFieldValue(field) || []"
                                                   @input-change="setFieldValue($event, field)">
                                    </TimeSpanInput>
                                    <TimeCodeInput v-else-if="field.type === 'TimeCode'"
                                                   v-bind:id="field.id"
                                                   v-bind:label="getFieldLabel(field)"
                                                   v-bind:submit-button=false
                                                   v-bind:show-unsaved-changes=true
                                                   v-bind:is-disabled=isFieldDisabled(field)
                                                   v-bind:default-value="getFieldValue(field)"
                                                   @input-blurred="setFieldValue($event, field)"
                                                   @input-submit="setFieldValue($event, field)">
                                    </TimeCodeInput>
                                    <DualTextInput v-else-if="field.type === 'DualText'"
                                                   v-bind:label="getFieldLabel(field)"
                                                   v-bind:submit-button=false
                                                   v-bind:show-unsaved-changes=true
                                                   v-bind:placeholder="field.placeholder || []"
                                                   v-bind:is-disabled=isFieldDisabled(field)
                                                   v-bind:max-length="field.maxLength"
                                                   v-bind:default-value="getFieldValue(field) || []"
                                                   icon-class="fas fa-pen"
                                                   @input-blurred="setFieldValue($event, field)"
                                                   @input-submit="setFieldValue($event, field)">
                                    </DualTextInput>
                                    <PageRangeInput v-else-if="field.type === 'PageRange'"
                                                    v-bind:label="getFieldLabel(field)"
                                                    v-bind:placeholder="field.placeholder || []"
                                                    v-bind:is-disabled="isFieldDisabled(field)"
                                                    v-bind:max-length="field.maxLength"
                                                    v-bind:default-value="getFieldValue(field) || []"
                                                    @input-submit="setFieldValue($event, field)"
                                                    @input-blurred="setFieldValue($event, field)">
                                    </PageRangeInput>
                                    <div v-else-if="field.type === 'Slot'">
                                        <slot v-bind:name="`field(${field.id})`"
                                              v-bind:field="field">
                                        </slot>
                                    </div>
                                    <transition name="fade">
                                        <Icon v-if="isFieldSaving(field)"
                                              class="c_smart-form-field-changes-saved"
                                              icon-type="success"
                                              icon-class="far fa-check-circle"
                                              v-bind:icon-large=true>
                                        </Icon>
                                    </transition>
                                    <div v-if="isChildFieldButtonPairVisible(field)"
                                         class="c_smart-form-field-button-pair"
                                         v-bind:class="[(field.type === 'Text' || field.type === 'DualText') && field.maxLength > 0 ? `m--field-has-max-length-indicator` : '']">
                                        <IconButton v-bind:is-disabled="isFieldDisabled(field) || getChildFieldCount(field) === 1"
                                                    icon-class="fas fa-minus"
                                                    @button-submit="removeChildField(field, fieldIndex)">
                                        </IconButton>
                                        <VerticalLine></VerticalLine>
                                        <IconButton v-bind:is-disabled="isFieldDisabled(field) || getChildFieldCount(field) >= field.quantity"
                                                    icon-class="fas fa-plus"
                                                    @button-submit="addChildFieldToSelectedSelection(field, fieldIndex)">
                                        </IconButton>
                                    </div>
                                    <HelpText v-if="field.description && field.type !== 'Form'"
                                              class="c_smart-form-field-help-container"
                                              v-bind:class="`m--${field.type}`"
                                              v-bind:width-px="340"
                                              v-bind:text="getFieldDescription(field)"
                                              position="left">
                                    </HelpText>
                                </div>
                            </template>
                        </transition-group>
                    </div>
                </div>
                <!-- STRUCTURE: FORM Footer with BACK/NEXT|SEND Button of selected SECTION -->
                <div class="c_smart-form-footer">
                    <span class="c_smart-form-footer-info">{{$tc('smartForm.requiredFieldsInfo')}}</span>
                    <!-- STRUCTURE: FORM Footer with NEXT Button of selected SECTION -->
                    <Button v-if="!isLastPageSelected || !hideSubmitButton"
                            class="c_smart-form-body-button-margin"
                            data-testid="form-submit-or-next-button"
                            button-type="submit"
                            v-bind:is-disabled="submitLocked || (isLastPageSelected && !isFormSubmittable)"
                            @button-submit="isLastPageSelected ? submitForm($event) : navigateForwards()">
                        <template v-slot>
                            <span>
                                <span v-if="submitLocked"
                                      class="fas fa-circle-notch fa-spin"></span>
                                {{isLastPageSelected ? $tc('smartForm.submit') : $tc('smartForm.next')}}
                            </span>
                        </template>
                    </Button>
                    <!-- STRUCTURE: FORM Footer with BACK Button of selected SECTION -->
                    <Button button-type="cancel"
                            class="c_smart-form-body-button-margin"
                            v-bind:is-disabled="isFirstPageSelected"
                            @button-submit="navigateBackwards()">
                        <template v-slot>
                            <span>{{$tc('smartForm.back')}}</span>
                        </template>
                    </Button>
                    <!-- STRUCTURE: FORM Footer with Multipage ADD Button of selected SECTION -->
                    <Button v-if="isAddAnotherPageVisible"
                            button-type="inline"
                            @button-submit="addFormPage(getSelectedSection, sectionSelectionIndex)">
                        <template v-slot>
                            <span>{{$t('smartForm.addPage', [getSelectedSection.pageTitle])}}</span>
                        </template>
                    </Button>
                    <!-- STRUCTURE: FORM Footer of selected SECTION with Checkbox whether to start on first page (Assetbestellung) -->
                    <Checkbox v-if="allowRepetition && isLastPageSelected"
                              v-bind:id="`smartForm-repetition-checkbox${id}`"
                              v-bind:label="repeatFormButtonLabel || $tc('smartForm.repeatFormDefault')"
                              v-bind:default-value="repeatOnSubmit"
                              @input-change="setRepeatOnSubmit($event.value)">
                    </Checkbox>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_essential-smart-form-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-border-light);

    .c_smart-form-content-container {
        position: relative;
        float: left;
        height: 100%;
        width: var(--smart-form-table-of-content-width);

        &.m--collapsed {
            width: 0;
            opacity: 0;
        }

        .c_smart-form-content-header {
            position: relative;
            height: var(--smart-form-line-height);
            width: 100%;
            background-color: var(--color-background-mid);
            text-align: center;
            padding-top: 6px;
            overflow: hidden;
        }

        .c_smart-form-content {
            position: relative;
            height: calc(100% - var(--smart-form-line-height));
            width: 100%;
            border-right: 1px solid var(--color-border-light);
            padding-bottom: var(--container-spacing);
            overflow-y: auto;
            overflow-x: hidden;

            .c_smart-form-content-section {
                width: 100%;
                min-height: var(--smart-form-line-height);
                border-bottom: 1px solid var(--color-border-light);
                border-left: 4px solid var(--color-warning);
                padding: var(--container-spacing);

                &.m--complete {
                    border-left-color: var(--color-success);
                }

                &.m--selected:not(.m--multiple) {
                    font-family: "Source Sans Pro Bold", sans-serif;
                    .c_smart-form-content-section-title {
                        &:hover {
                            cursor: default;
                            color: var(--color-text-mid);
                        }
                    }
                }

                .c_smart-form-content-section-title {
                    display: inline-block;

                    &:hover {
                        cursor: pointer;
                        color: var(--color-text-highlighted);
                    }
                }

                .c_smart-form-content-section-button {
                    position: absolute;
                    right: 16px;
                    margin-top: -10px;
                }

                .c_smart-form-content-section-pages {
                    margin-top: 12px;
                    width: 100%;

                    .c_smart-form-content-section-page {
                        padding: 12px 0 12px var(--container-spacing);
                        border-top: 1px solid var(--color-border-light);
                        border-left: 4px solid var(--color-warning);
                        cursor: default;

                        display: flex;
                        align-items: center; // Center items vertically
                        justify-content: space-between; // Ensure space between title and icon buttons

                        &.m--complete {
                            border-left-color: var(--color-light-grey);
                        }

                        &.m--selected {
                            font-family: "Source Sans Pro Bold", sans-serif;
                        }

                        &:hover:not(.m--selected) {
                            cursor: pointer;
                            color: var(--color-text-highlighted);
                        }

                        .c_smart-form-content-section-page-title-wrapper {
                            display: flex;

                            .c_smart-form-content-section-page-title{
                                &:hover {
                                    cursor: pointer;
                                    color: var(--color-text-highlighted);

                                    &:after {
                                        font-weight: 500;
                                        content: "\f044";
                                        color: var(--color-info);
                                        margin-left: 2px;
                                        font-family: "Font Awesome 5 Free", serif;
                                    }
                                }
                            }
                        }

                        .c_smart-form-content-section-page-icon-buttons {
                            display: flex;
                            align-items: center; // Center icon buttons vertically

                            .c_smart-form-content-section-page-copy-button {}
                            .c_smart-form-content-section-page-delete-button {}
                        }
                    }

                    .c_smart-form-content-section-pages-button {
                        width: 100%;
                        height: 16px;
                        font-size: 12px;
                        background-color: var(--color-button-with-border);
                        color: var(--color-text-bright);
                        text-align: center;

                        &:hover {
                            cursor: pointer;
                            background-color: var(--color-button-with-background);
                        }

                        span {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

    .c_smart-form-container {
        position: relative;
        float: left;
        height: 100%;
        width: calc(100% - var(--smart-form-table-of-content-width));

        &.m--full-width {
            width: 100%;
        }

        .generals-input-container .generals-input {
            border-top: 0;
            border-left: 0;
            border-right: 0;
        }

        .c_smart-form-headline {
            position: relative;
            height: var(--smart-form-line-height);
            width: 100%;
            border-bottom: 1px solid var(--color-border-light);
            text-align: center;
            padding-top: 6px;

            .c_smart-form-header-toggle {
                position: absolute;
                height: 100%;
                width: var(--smart-form-line-height);
                border-right: 1px solid var(--color-border-light);
                padding: 7px 1px 0 0;
                cursor: pointer;
                top: 0;
                left: 0;

                &:hover {
                    color: var(--color-text-highlighted);
                }
            }
        }

        .c_smart-form {
            position: relative;
            height: calc(100% - var(--smart-form-line-height));
            width: 100%;
            padding: var(--container-spacing);
            padding-top: 6px;

            .c_smart-form-body {
                position: relative;
                height: calc(100% - var(--headline-height) - var(--button-height));
                width: 100%;
                overflow-y: auto;
                overflow-x: hidden;

                .c_smart-form-fields {
                    position: absolute;
                    left: 50%;
                    width: 666px;
                    -ms-transform: translate(-50%, 0);
                    transform: translate(-50%, 0);
                    padding-bottom: var(--container-spacing);

                    .c_smart-form-field {
                        position: relative;
                        margin-top: 20px;

                        &.m--no-margin {
                            margin-top: 0;
                        }

                        .c_smart-form-field-changes-saved {
                            position: absolute;
                            top: 10px;
                            right: -35px;
                            background-color: var(--color-background-default);
                            z-index: var(--z-index-page-overlay);
                        }

                        .c_smart-form-field-button-pair {
                            width: 100%;
                            text-align: end;

                            &.m--field-has-max-length-indicator{
                                margin-top: 10px;
                            }
                        }

                        .c_smart-form-field-subheading {
                            border-top: 1px solid var(--color-border-light);
                            margin: 50px 0 20px 0;

                            .c_smart-form-field-subheading-label {
                                text-align: center;
                                margin-top: -12px;

                                span {
                                    background-color: var(--color-background-default);
                                    text-transform: uppercase;
                                    padding: 0 10px;
                                }
                            }
                        }

                        .c_smart-form-field-paragraph {
                            position: relative;

                            :is(h1, h2, h3, h4, h5, h6) {
                                font-family: "Source Sans Pro Bold", sans-serif;
                                margin: -4px 0 8px 0;
                            }

                            &.m--info,
                            &.m--success,
                            &.m--warning,
                            &.m--error {
                                border: 1px solid;
                                border-left: 25px solid;
                                padding: var(--container-spacing);
                            }

                            &.m--info {
                                border-color: var(--color-info);

                                :is(h1, h2, h3, h4, h5, h6) {
                                    color: var(--color-info);
                                }
                            }

                            &.m--success {
                                border-color: var(--color-success);

                                :is(h1, h2, h3, h4, h5, h6) {
                                    color: var(--color-success);
                                }
                            }

                            &.m--warning {
                                border-color: var(--color-warning);

                                :is(h1, h2, h3, h4, h5, h6) {
                                    color: var(--color-warning);
                                }
                            }

                            &.m--error {
                                border-color: var(--color-error);

                                :is(h1, h2, h3, h4, h5, h6) {
                                    color: var(--color-error);
                                }
                            }

                            .c_smart-form-field-paragraph-icon {
                                position: absolute;
                                font-size: 17px;
                                left: -21px;
                                top: 50%;
                                color: var(--color-text-bright);
                                transform: translateY(-50%);
                                z-index: 1;
                            }
                        }

                        .generals-input-wrapper {

                            &:not(.m--dropzone) {

                                .generals-input-label {
                                    &:not(.c_drop-input-label) {
                                        width: var(--smart-form-input-label-width);
                                    }
                                }

                                .generals-input-container {
                                    width: var(--smart-form-input-width);
                                }
                            }
                        }

                        .c_checklist-options-container {
                            width: var(--smart-form-input-width);
                        }

                        &.m--missing {
                            .generals-input-label {
                                color: var(--color-error);
                            }
                        }
                    }

                    .c_smart-form-field-help-container {
                        position: absolute;
                        right: calc(-1 * var(--container-spacing));
                        top: 10px;

                        &.m--Textarea {
                            top: 0;
                        }
                    }
                }
            }

            .c_smart-form-footer {
                position: relative;
                width: 100%;
                margin-top: 10px;
                height: var(--button-height);

                .c_smart-form-footer-info {
                    position: absolute;
                    bottom: 0;
                }

                button,
                .c_checkbox-wrapper {
                    float: right;
                }

                .c_checkbox-label,
                .c_checkbox-container {
                    width: auto;
                }

                .c_smart-form-body-button-margin {
                    margin-left: var(--container-spacing);
                }
            }
        }
    }
}
</style>
