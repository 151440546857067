<script>
/**
 * FilesAndLinksUpload combines a Drag&Drop field for a file upload with
 * an input field for link creation.
 *
 * The uploaded files/saved links are shown in lists, from which they
 * can be downloaded/opened or deleted.
 *
 * Supported Properties:
 * - id: Should be provided in order to get full functionality. Without an id you won't get the ability to close on esc and instant focus the input field when opening.
 * - label (optional): Text displayed as the label of the input field.
 * - initialFilesAndLinks: {@link FilesAndLinks} object with the initially existing files and links, default: empty
 * - saveLinkAction: callback, which performs the actual save action, signature: `(urlString: string) => Promise(Link)`
 *                             if not set, save of links is not possible
 * - saveFileAction: callback, which performs the actual save action, signature: `(file: File) => Promise(File)`
 *                             if not set, upload of files is not possible
 * - deleteLinkAction: callback, which performs the actual delete action, signature: `(id: string) => Promise`
 *                             if not set, trash icon is not shown and deletion of links is not possible
 * - deleteFileAction: callback, which performs the actual delete action, signature: `(id: string) => Promise`
 *                             if not set, trash icon is not shown and deletion of files is not possible
 * - downloadFileAction: callback, which performs the actual download action, signature: `(id: string) => Promise`
 *                             if not set, download is not supported
 * - downloadAllFilesAction: callback, which performs the actual download all action, signature: (productFileReferenceIds: string) => Promise'
 *                             if not set, "Download All" button is not shown and download all functionality is not possible
 *
 * Emitted Events:
 * - 'input-changed' with payload
 *    ```
 *    {
 *        id: string, // the same id as passed in the props
 *        allLinksAndFiles: FilesAndLinks // all collected {@link FilesAndLinks}
 *    }
 *    ```
 **/
import EntryList from '@/components/EntryList.vue'
import DynamicInput from '@/components/DynamicInput.vue'
import { notificationHandler } from '@/mixins/notificationHandler'
import { regexHelper } from '@/mixins/regexHelper'
import FilesAndLinks from '@/services/FilesAndLinks'
import IconButton from '@/components/IconButton.vue'

const DROP_INPUT_UPLOAD_TYPE = 'upload'
const DROP_INPUT_LINK_TYPE = 'link'

export default {
    name: 'FilesAndLinksUpload',
    mixins: [
        notificationHandler,
        regexHelper
    ],
    components: {
        IconButton,
        DynamicInput,
        EntryList
    },
    emits: ['input-changed'],
    props: {
        id: String,
        label: String,
        initialFilesAndLinks: {
            type: Object,
            default: new FilesAndLinks()
        },
        saveLinkAction: Function, // (urlString: string) => Promise(Link)
        saveFileAction: Function, // (file: File) => Promise(File)
        deleteLinkAction: Function, // (id: string) => Promise
        deleteFileAction: Function, // (id: string) => Promise
        downloadFileAction: Function, // (id: string) => Promise
        downloadAllFilesAction: Function // (productFileReferenceIds: Array<string>) => Promise
    },
    data () {
        return {
            /* props should be immutable (see: https://vuejs.org/guide/components/props.html#one-way-data-flow),
             * thus we have to make a copy here */
            filesAndLinks: this.initialFilesAndLinks.copy(),
            states: {
                showSpinner: false,
                isUploading: false
            },
            listUpdateCounter: 0
        }
    },
    methods: {
        saveLinkOrFile (uploadEvent) {
            if (uploadEvent.inputType === DROP_INPUT_LINK_TYPE) {
                this.saveLink(uploadEvent.value)
            } else {
                this.uploadFile(uploadEvent.value)
            }
        },

        saveLink (urlString) {
            if (this.filesAndLinks.containsLink(urlString)) {
                this.configureNotification(
                    'info',
                    {
                        standard: 3000,
                        short: 3000
                    },
                    {
                        standard: this.$t('task.taskViews.links.alreadyExistsMessage.standard'),
                        short: this.$t('task.taskViews.links.alreadyExistsMessage.short')
                    })

                return
            }

            if (this.saveLinkAction) {
                this.states.isUploading = true
                this.saveLinkAction(
                    urlString
                )
                    .then(
                        uploadedLink => {
                            if (this.filesAndLinks.addLink(uploadedLink)) {
                                this.states.isUploading = false
                                this.$emit('input-changed', {
                                    id: this.id,
                                    allLinksAndFiles: this.filesAndLinks.copy()
                                })
                                this.listUpdateCounter++
                            }
                        })
                    .catch(error => {
                        console.error(error)
                        this.states.isUploading = false
                    })
            }
        },

        deleteLink (entryListSubmitEventData) {
            const link = entryListSubmitEventData.entry
            const linkIndex = entryListSubmitEventData.index
            if (this.deleteLinkAction) {
                this.deleteLinkAction(link.id)
                    .then(() => {
                        if (this.filesAndLinks.removeLink(linkIndex)) {
                            this.$emit('input-changed', {
                                id: this.id,
                                allLinksAndFiles: this.filesAndLinks.copy()
                            })
                            this.listUpdateCounter++
                        }
                    })
                    .catch(error => console.error(error))
            }
        },

        openLink (event) {
            const url = event.entry.url
            const validUrl = this.isValidUrl(url)
                ? url
                : `https://${url}`
            window.open(validUrl, '_blank')
        },

        uploadFile (files) {
            this.states.isUploading = true
            if (this.saveFileAction) {
                this.saveFileAction(files)
                    .then(uploadedFiles => {
                        if (this.filesAndLinks.addFiles(uploadedFiles)) {
                            this.states.isUploading = false
                            this.$emit('input-changed', {
                                id: this.id,
                                allLinksAndFiles: this.filesAndLinks.copy()
                            })
                            this.listUpdateCounter++
                        } else {
                            this.configureNotification(
                                'info',
                                {
                                    standard: 3000,
                                    short: 3000
                                },
                                {
                                    standard: this.$t('task.taskViews.files.alreadyExistsMessage.standard'),
                                    short: this.$t('task.taskViews.files.alreadyExistsMessage.short')
                                })
                        }
                        this.states.isUploading = false
                    })
                    .catch(error => {
                        console.error(error)
                        this.states.isUploading = false
                    })
            }
        },

        async downloadFile (dynamicInputClickEventData) {
            this.states.showSpinner = true
            const file = dynamicInputClickEventData.entry
            if (this.downloadFileAction) {
                try {
                    await this.downloadFileAction(file.id)
                    this.states.showSpinner = false
                } catch (error) {
                    this.states.showSpinner = false
                    console.error('The file could not be downloaded: ', error)
                }
            }
        },

        async downloadAllFiles () {
            this.states.showSpinner = true
            if (this.downloadAllFilesAction) {
                try {
                    await this.downloadAllFilesAction(this.filesAndLinks.files.map(file => file.id))
                    this.states.showSpinner = false
                } catch (error) {
                    this.states.showSpinner = false
                    console.error('The files could not be downloaded: ', error)
                }
            }
        },

        deleteFile (entryListSubmitEventData) {
            const file = entryListSubmitEventData.entry
            const fileIndex = entryListSubmitEventData.index
            if (this.deleteFileAction) {
                this.deleteFileAction(file.id)
                    .then(() => {
                        if (this.filesAndLinks.removeFile(fileIndex)) {
                            this.$emit('input-changed', {
                                id: this.id,
                                allLinksAndFiles: this.filesAndLinks.copy()
                            })
                            this.listUpdateCounter++
                        }
                    })
                    .catch(error => console.error(error))
            }
        },

        getDownloadAllButtonIcon () {
            return this.states.isUploading
                ? 'fas fa-circle-notch fa-spin'
                : 'fas fa-download'
        }
    },
    computed: {
        supportedInputTypes () {
            const uploadTypes = []
            if (this.saveFileAction) {
                // first type is default
                uploadTypes.push(DROP_INPUT_UPLOAD_TYPE)
            }
            if (this.saveLinkAction) {
                uploadTypes.push(DROP_INPUT_LINK_TYPE)
            }
            return uploadTypes
        },

        isUploadSupported () {
            return this.saveFileAction || this.saveLinkAction
        },

        isFileDeleteSupported () {
            return !!this.deleteFileAction
        },

        isLinkDeleteSupported () {
            return !!this.deleteLinkAction
        },

        isDownloadSupported () {
            return !!this.downloadFileAction || !!this.downloadAllFilesAction
        }
    }
}
</script>

<template>
    <div class="generals-input-wrapper">
        <label v-if="label"
               class="generals-input-label">
            <span>{{label}}</span>
        </label>
        <div class="custom-task-upload-files-container">
            <DynamicInput data-testid="upload-field" v-if="isUploadSupported"
                :id="id"
                :input-types="supportedInputTypes"
                :legacy-mode="false"
                :reset-on-submit="true"
                :allow-multiple=true
                :is-uploading="states.isUploading"
                @dynamic-submit="saveLinkOrFile($event)">
            </DynamicInput>
            <!-- Files -->
            <EntryList data-testid="file-list" v-if="filesAndLinks.files.length > 0"
                       class="custom-task-form-files"
                       entry-label-key="fileName"
                       icon-class="fas fa-trash"
                       :entries="filesAndLinks.files"
                       :enable-click="isDownloadSupported && !states.showSpinner"
                       :enable-submit="isFileDeleteSupported"
                       @entry-click="downloadFile($event)"
                       @entry-submit="deleteFile($event)"
                       :key="`files-${listUpdateCounter}`"
            >
            </EntryList>
            <!-- Links -->
            <EntryList data-testid="link-list" v-if="filesAndLinks.links.length > 0"
                       class="custom-task-form-files"
                       entry-label-key="url"
                       icon-class="fas fa-trash"
                       :entries="filesAndLinks.links"
                       :enable-click="true"
                       :enable-submit="isLinkDeleteSupported"
                       @entry-click="openLink($event)"
                       @entry-submit="deleteLink($event)"
                       :key="`links-${listUpdateCounter}`"
            >
            </EntryList>

            <IconButton :icon-class="getDownloadAllButtonIcon()"
                        @button-submit="downloadAllFiles"
                        v-if="isDownloadSupported && filesAndLinks.files.length > 0 && !states.isUploading"
                        :text="this.$t('task.taskViews.files.downloadAllFiles')">
            </IconButton>

            <div data-test-id="spinner" v-if="states.showSpinner">
                <span class="fas fa-circle-notch fa-spin"></span>
                <span>{{this.$t('task.taskViews.files.spinnerText')}}</span>
                <span class="generals-walking-dots"></span>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.custom-task-upload-files-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .custom-task-form-files {
        margin-bottom: var(--container-spacing);
    }
}
</style>
