<script>
/**
 * Product members sub view | Sub view of a Product
 *
 * @property {String} sapId
 * @property {String} oid
 * @property {String} editabilityStatus
 **/
import Accordion from '@/components/Accordion.vue'
import Table from '@/components/Table.vue'
import IconButton from '@/components/IconButton.vue'
import BadgeInput from '@/components/BadgeInput.vue'
import SmartSelect from '@/components/SmartSelect.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'

export default {
    name: 'ProductMembers',
    mixins: [
        axiosService,
        userHandler
    ],
    components: {
        Accordion,
        Table,
        IconButton,
        BadgeInput,
        SmartSelect
    },
    props: {
        productId: [Number, String]
    },
    data () {
        return {
            productInfo: this.$attrs.productInfo || { id: this.productId },
            activeRoleUserSelectId: '',
            roles: [{
                id: '',
                participants: [],
                labels: {}
            }],
            editableStatus: {
                FULL: 'FULL',
                LIMITED: 'LIMITED',
                NONE: 'NO'
            }
        }
    },
    methods: {
        getRoles () {
            this.axiosGet(
                `roles/${this.productInfo.id}`,
                this.$tc('product.members.getRolesError'))
                .then(roles => {
                    const validRights = Object.values(this.editableStatus)
                    roles.forEach(role => {
                        if (!validRights.includes(role.editabilityStatus)) {
                            role.editabilityStatus = this.editableStatus.NONE
                        }
                        role.displayName = this.getDisplayNameForRole(role)
                    })
                    this.roles = roles
                })
                .catch(() => {
                })
        },

        getDisplayNameForRole (role) {
            return role.sapId
                ? this.$tc(`generals.roleLabels.${role.sapId}`)
                : this.$tc('generals.roleLabels.unknown')
        },

        getDisplayNameForMember (member) {
            const displayName = `${member.firstName} ${member.lastName}`
            return member.serviceProviderCompany
                ? `${displayName} (${member.serviceProviderCompany})`
                : displayName
        },

        resetMemberAndActiveRoleSelection () {
            this.activeRoleUserSelectId = null
        },

        userHasEditRightsForRole (role, editLevel) {
            return role.editabilityStatus === editLevel
        },

        isUserAllowedToRemoveParticipant (role, member) {
            const hasFullAccess = this.userHasEditRightsForRole(role, this.editableStatus.FULL)
            const hasLimitedAccess = this.userHasEditRightsForRole(role, this.editableStatus.LIMITED)
            const itsMe = member.username === this.getUserName()
            const isNotLastMember = role.participants.length > 1

            return hasFullAccess ||
                (hasLimitedAccess && itsMe && isNotLastMember)
        },

        isRoleUserSelectActive (role) {
            return this.activeRoleUserSelectId === role.id
        },

        activateRoleUserSelect (role) {
            this.activeRoleUserSelectId = role.id
        },

        getAvailableOptions (role) {
            const participantUsernames = role.participants.map(participant => participant.username)
            return this.getListOfUsers().filter(user => !participantUsernames.includes(user.userName))
        },

        userIsParticipantForRole (role) {
            return role.participants.some(participant => participant.username === this.getUserName())
        },

        addMyselfToRole (role) {
            const itsMe = this.getListOfUsers().find(user => user.userName === this.getUserName())
            this.addMemberToRole({ option: itsMe }, role)
        },

        addMemberToRole (selectSubmitEventData, role) {
            const data = new FormData()
            if (selectSubmitEventData.option.oid) {
                data.append('oid', selectSubmitEventData.option.oid)
            }
            data.append('username', selectSubmitEventData.option.userName)
            data.append('roleId', role.id)

            const memberName = this.getDisplayNameForMember(selectSubmitEventData.option)
            const roleName = this.getDisplayNameForRole(role)

            this.axiosPost(
                `roles/${this.productInfo.id}`,
                data,
                this.$t('product.members.addUserError', [memberName, roleName]),
                {
                    standard: this.$t('product.members.addUserSuccess.standard', [memberName, roleName]),
                    short: this.$t('product.members.addUserSuccess.short', [memberName])
                })
                .then(addedMember => {
                    role.participants.push(addedMember)
                })
                .catch(() => {
                })
        },

        redirectToEmail (member) {
            if (!member.email || member.email.trim() === '') {
                const errorMessage = this.$t('product.members.getEmailError', [member.username || 'Unknown'])
                this.addNotification({
                    type: 'error',
                    duration: 5000,
                    message: errorMessage
                })
            } else {
                const successMessage = this.$t('product.members.getEmailSuccess.standard', [member.email])
                const shortSuccessMessage = this.$t('product.members.getEmailSuccess.short')
                const userPreferences = this.getUserSettingsParameter('userPreferences')
                const message = userPreferences.reducedNotifications ? shortSuccessMessage : successMessage

                if (message) {
                    this.addNotification({
                        type: 'success',
                        duration: 3000,
                        message: message
                    })
                }
                window.location.href = `mailto:${member.email}`
            }
        },

        deleteMemberFromRole (member, role, index) {
            const data = new FormData()
            data.append('participantId', member.id)
            data.append('roleId', role.id)

            const memberName = this.getDisplayNameForMember(member)
            const roleName = this.getDisplayNameForRole(role)

            this.axiosDelete(
                `roles/${this.productInfo.id}`,
                data,
                this.$t('product.members.removeMemberError', [memberName, roleName]),
                {
                    standard: this.$t('product.members.removeMemberSuccess.standard', [memberName, roleName]),
                    short: this.$t('product.members.removeMemberSuccess.short', [memberName])
                })
                .then(() => {
                    this.resetMemberAndActiveRoleSelection()
                    role.participants.splice(index, 1)
                })
                .catch(error => {
                    if (error.status === 422) {
                        this.$swal({
                            title: this.$t('product.members.removeMemberPopup.popupTitle', [memberName]),
                            html: this.$t('product.members.removeMemberPopup.popupText', [memberName, roleName]),
                            icon: 'error',
                            showConfirmButton: true,
                            confirmButtonText: this.$tc('product.members.removeMemberPopup.popupConfirmText'),
                            allowOutsideClick: false,
                            allowEscapeKey: false
                        })
                    }
                })
        }
    },
    computed: {
        getTableConfig () {
            return [{
                key: 'displayName',
                label: this.$tc('product.members.table.role'),
                filterable: false,
                sortable: true,
                alignment: 'left',
                width: 35
            }, {
                key: 'member',
                label: this.$tc('product.members.table.members'),
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: 65
            }]
        }
    },
    mounted () {
        this.getRoles()
    }
}
</script>

<template>
    <div class="generals-container"
         v-on:click="resetMemberAndActiveRoleSelection()">
        <div class="product-members-container">
            <Accordion v-bind:title="$tc('product.members.headline')"
                       v-bind:is-disabled="true">
            </Accordion>
            <div class="product-members-table-container">
                <Table table-id="productMembers"
                       v-bind:key="roles.toString()"
                       v-bind:table-config="getTableConfig"
                       v-bind:table-data="roles"
                       v-bind:table-empty-message="$tc('product.members.table.tableEmpty')"
                       v-bind:read-only=true>
                    <!-- displayName: default cell content -->
                    <template #cell(member)="data">
                        <transition-group name="fade"
                                          tag="div">
                            <BadgeInput v-for="(participant, index) in data.row.participants"
                                        v-bind:key="participant.id"
                                        v-bind:tooltip="participant.email"
                                        v-bind:is-disabled="!isUserAllowedToRemoveParticipant(data.row, participant)"
                                        v-bind:value="getDisplayNameForMember(participant)"
                                        @badge-submit="deleteMemberFromRole(participant, data.row, index)"
                                        @badge-email="redirectToEmail(participant)">
                            </BadgeInput>
                        </transition-group>
                        <template v-if="userHasEditRightsForRole(data.row, editableStatus.LIMITED)">
                            <IconButton class="product-members-add-icon m--span"
                                        v-bind:is-disabled="userIsParticipantForRole(data.row)"
                                        v-bind:tooltip="$tc('product.members.table.addMyself')"
                                        v-bind:icon-class="userIsParticipantForRole(data.row) ? 'fas fa-user-slash' : 'fas fa-user-plus'"
                                        @button-submit="addMyselfToRole(data.row)">
                            </IconButton>
                            <span v-if="!userIsParticipantForRole(data.row)">ME</span>
                        </template>
                        <template v-else>
                            <IconButton v-if="!isRoleUserSelectActive(data.row)"
                                        class="product-members-add-icon"
                                        v-bind:is-disabled="userHasEditRightsForRole(data.row, editableStatus.NONE) || getAvailableOptions(data.row).length === 0"
                                        v-bind:tooltip="$tc('product.members.table.addMember')"
                                        v-bind:icon-class="userHasEditRightsForRole(data.row, editableStatus.NONE) ? 'fas fa-user-slash' : 'fas fa-user-plus'"
                                        @button-submit="activateRoleUserSelect(data.row)">
                            </IconButton>
                            <SmartSelect v-else
                                         class="product-members-add-select"
                                         v-bind:id="data.row.id"
                                         v-bind:submit-button=true
                                         v-bind:placeholder="$tc('product.members.table.selectMemberPlaceholder')"
                                         v-bind:options="getAvailableOptions(data.row)"
                                         v-bind:sort-options=true
                                         v-bind:option-label-specifiers="['fullNameWithUsername']"
                                         v-bind:filter-label-specifiers="['userName', 'firstName', 'lastName', 'email']"
                                         v-bind:allow-input=true
                                         v-bind:is-auto-focused=true
                                         v-bind:clear-input-on-submit=true
                                         v-bind:show-unsaved-changes=false
                                         @select-close="resetMemberAndActiveRoleSelection()"
                                         @select-blurred="addMemberToRole($event, data.row)"
                                         @select-submit="addMemberToRole($event, data.row)">
                            </SmartSelect>
                        </template>
                    </template>
                </Table>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.product-members-container {
    position: relative;
    width: 100%;
    height: 100%;

    .product-members-table-container {
        padding-top: 10px;
        height: calc(100% - var(--accordion-head-height));

        .product-members-add-icon {
            margin-left: -8px;

            &.m--span {
                &:hover {
                    + span {
                        color: var(--color-text-highlighted);
                    }
                }

                + span {
                    position: absolute;
                    font-family: "Source Sans Pro Bold", sans-serif;
                    font-size: 10px;
                    margin: 20px 0 0 -12px;
                }
            }
        }

        .product-members-add-select {
            margin: 8px 0 4px 0;
        }
    }
}
</style>
