/**
 * A reusable service for the exchange of product content related data with the backend.
 **/
import { axiosService } from '@/mixins/axiosService'
import { defineComponent } from 'vue'

export const productContentHandler = defineComponent({
    mixins: [
        axiosService
    ],
    data () {
        return {
            basePathContent: 'product/file'
        }
    },
    methods: {
        fetchProductContent (productId, contentId) {
            return this.axiosGet(
                `${this.basePathContent}/${productId}/${contentId}`,
                this.$t('task.taskViews.content.fetchError'))
        }
    }
})
