<script>
/**
 * Failed jobs sub view | Sub view of admin tasks overview
 **/
import Table from '@/components/Table.vue'
import RouteLink from '@/components/RouteLink.vue'
import IconButton from '@/components/IconButton.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'FailedJobs',
    mixins: [
        axiosService,
        userHandler,
        dateTimeHelper
    ],
    components: {
        Table,
        RouteLink,
        IconButton
    },
    data () {
        return {
            failedJobs: [],
            username: this.getUserName(),
            showSpinner: true
        }
    },
    methods: {
        getFailedJobs () {
            this.axiosGet(
                'admin/failedJobs',
                this.$tc('administration.taskOverview.failedJobs.getFailedJobsError'))
                .then(jobList => {
                    jobList.forEach(job => {
                        job.timeStampDisplayText = this.getFormattedLongDate(job.timeStamp, this.locale)
                    })
                    this.failedJobs = jobList
                    this.showSpinner = false
                })
                .catch(() => {
                    this.showSpinner = false
                })
        },

        retryJob (job) {
            this.axiosPost(
                `admin/failedJobs/${job.id}/retry`,
                null,
                this.$t('administration.taskOverview.failedJobs.retryJobError', [job.taskName]),
                {
                    standard: this.$t('administration.taskOverview.failedJobs.retryJobSuccess.standard', [job.taskName]),
                    short: this.$tc('administration.taskOverview.failedJobs.retryJobSuccess.short')
                })
                .then(() => {
                    this.failedJobs.splice(this.failedJobs.indexOf(job), 1)
                })
                .catch(() => {})
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getTableConfig () {
            return [{
                key: 'taskName',
                label: this.$tc('administration.taskOverview.failedJobs.table.taskName'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                width: 15
            }, {
                key: 'processName',
                label: this.$tc('administration.taskOverview.failedJobs.table.processName'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                width: 15
            }, {
                key: 'productNumber',
                label: this.$tc('administration.taskOverview.failedJobs.table.productNumber'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                width: 10
            }, {
                key: 'context',
                label: this.$tc('administration.taskOverview.failedJobs.table.taskContext'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                width: 20
            }, {
                key: 'timeStampDisplayText',
                sortKey: 'timeStamp',
                label: this.$tc('administration.taskOverview.failedJobs.table.timeStamp'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                width: 10
            }, {
                key: 'message',
                label: this.$tc('administration.taskOverview.failedJobs.table.message'),
                filterable: true,
                sortable: true,
                exportable: true,
                alignment: 'left',
                breakAnywhere: true,
                width: 20
            }, {
                key: 'action',
                label: null,
                filterable: false,
                sortable: false,
                exportable: false,
                alignment: 'right',
                width: 10
            }]
        }
    },
    watch: {
        '$global.localization.locale' () {
            this.failedJobs.forEach(job => {
                job.timeStampDisplayText = this.getFormattedLongDate(job.timeStamp, this.locale)
            })
        }
    },
    mounted () {
        this.getFailedJobs()
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="failed-jobs_table-container">
            <Table table-id="adminFailedJobs"
                   v-bind:table-config="getTableConfig"
                   v-bind:table-data="failedJobs"
                   v-bind:filter-placeholder="$tc('administration.taskOverview.failedJobs.table.filterPlaceholder')"
                   v-bind:table-empty-message="$tc('administration.taskOverview.failedJobs.table.tableEmpty')"
                   v-bind:filter-no-results-message="$tc('administration.taskOverview.failedJobs.table.filterNoResults')"
                   v-bind:show-spinner="showSpinner"
                   default-sorting-key="timeStamp"
                   v-bind:read-only=true>
                <!-- taskName: default cell content -->
                <!-- processName: default cell content -->
                <template #cell(productNumber)="data">
                    <RouteLink v-if="data.row.productNumber && data.row.productId"
                               v-bind:displayed-text="data.row.productNumber"
                               v-bind:target="{name: 'Product', params: {productId: data.row.productId}}"
                               v-bind:tooltip="$tc('generals.goToProduct')"
                               v-bind:is-highlighted-link=true>
                    </RouteLink>
                    <span v-else>{{$tc('administration.taskOverview.failedJobs.table.notCreated')}}</span>
                </template>
                <!-- context: default cell content -->
                <!-- timeStampDisplayText: default cell content -->
                <!-- message: default cell content -->
                <template #cell(action)="data">
                    <IconButton icon-class="fas fa-redo"
                                v-bind:tooltip="$tc('administration.taskOverview.failedJobs.table.retryJob')"
                                @button-submit="retryJob(data.row)">
                    </IconButton>
                </template>
            </Table>
        </div>
    </div>
</template>

<style scoped lang="less">
.failed-jobs_table-container {
    width: 100%;
    height: 100%;
    padding: 0 var(--container-spacing);
}
</style>
