<script>
/**
 * Task view
 *
 * @property {String} formJson
 * @property {String} reihenausgabeSchluessel
 * @property {String} sameContentSearchField
 * @property {Array} federalStatesList
 * @property {Array} schoolTypeList
 **/
import EssentialSmartForm from '@/components/EssentialSmartForm.vue'
import { axiosService } from '@/mixins/axiosService'
import { taskExecutionHandler } from '@/mixins/taskExecutionHandler'
import { smartFormHelper } from '@/mixins/smartFormHelper'
import EntryList from '@/components/EntryList.vue'
import DropInput from '@/components/DropInput.vue'
import { downloadHandler } from '@/mixins/downloadHandler'
import { customFormKeyHandler } from '@/mixins/customFormKeyHandler'
import { einreichungHelper } from '@/mixins/einreichungHelper'
import EssentialSmartFormService from '@/services/EssentialSmartFormService'
import DisplayList from '@/components/DisplayList.vue'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'CustomTaskEinreichungEssential',
    mixins: [
        axiosService,
        taskExecutionHandler,
        smartFormHelper,
        downloadHandler,
        customFormKeyHandler,
        einreichungHelper,
        dateTimeHelper
    ],
    components: {
        DropInput,
        DisplayList,
        EntryList,
        EssentialSmartForm
    },
    // @task-completable-changed: Event emitted when the task-completable state changed
    emits: ['task-completable-changed'],
    props: {
        taskData: Object
    },
    data () {
        return {
            customForm: null,
            formExists: false,
            federalState: null,
            schoolType: null,
            listUpdateCounter: 0,
            uploadFieldConfigsAndStates: {
                conceptDescriptionUpload: {
                    isUploading: false,
                    showSpinner: false,
                    slotName: 'field(conceptDescriptionUpload)'
                },
                bindingOverallConceptUpload: {
                    isUploading: false,
                    showSpinner: false,
                    slotName: 'field(bindingOverallConceptUpload)'
                },
                previewOfContinuedEditionUpload: {
                    isUploading: false,
                    showSpinner: false,
                    slotName: 'field(previewOfContinuedEditionUpload)'
                },
                foreignLanguageOverallConceptUpload: {
                    isUploading: false,
                    showSpinner: false,
                    slotName: 'field(foreignLanguageOverallConceptUpload)'
                },
                transcriptUpload: {
                    isUploading: false,
                    showSpinner: false,
                    slotName: 'field(transcriptUpload)'
                },
                noticeOfApprovalUpload: {
                    isUploading: false,
                    showSpinner: false,
                    slotName: 'field(noticeOfApprovalUpload)'
                },
                assessmentUpload: {
                    isUploading: false,
                    showSpinner: false,
                    slotName: 'field(assessmentUpload)'
                },
                stornoUpload: {
                    isUploading: false,
                    showSpinner: false,
                    slotName: 'field(stornoUpload)'
                },
                changedPages: {
                    isUploading: false,
                    showSpinner: false,
                    slotName: 'field(changedPages)'
                },
                editorialStaffStatement: {
                    isUploading: false,
                    showSpinner: false,
                    slotName: 'field(editorialStaffStatement)'
                }
            },

            searchFieldConfigs: {
                integratedLearningEnvironmentResult: {
                    slotName: 'field(integratedLearningEnvironmentResult)'
                },
                additionalRequiredEditionsForStandardProductTitle: {
                    slotName: 'field(additionalRequiredEditionsForStandardProductTitle)'
                },
                predecessorProductTitle: {
                    slotName: 'field(predecessorProductTitles)'
                }
            },

            // Corresponds to back end's CustomFormKey values / bean names.
            // (i) Corresponds to the BPMN formKey value (used for lookup + loading of this Vue component).
            // (i) May differ from config.X.customFormKey (used for loading form data JSON from files here in the front end).
            formType: this.componentKeyBy(this.taskData.formKey),
            formParameters: this.parametersFrom(this.taskData.formKey),
            formIsValid: false,
            formWasSavedSuccessfully: true,

            // entry in config that is relevant for the current task
            formTypeConfig: null,
            config: {
                PrepareEinreichung: {
                    customFormKey: 'PREPARE_EINREICHUNG',
                    searchFieldMapping: {
                        sameContentSearchField: {
                            projection: {
                                shortTitle: 'sameContentResult'
                            }
                        },
                        predecessorProduct: {
                            projection: {
                                shortTitle: 'predecessorProductTitles'
                            },
                            accumulateResults: true
                        },
                        additionalRequiredEditionsForStandardSearchField: {
                            projection: {
                                shortTitle: 'additionalRequiredEditionsForStandardProductTitle'
                            },
                            accumulateResults: true
                        },
                        integratedLearningEnvironmentSearchField: {
                            projection: {
                                shortTitle: 'integratedLearningEnvironmentResult'
                            },
                            accumulateResults: true
                        }
                    }
                },
                ArrangeEinreichung: {
                    customFormKey: 'ARRANGE_EINREICHUNG',
                    mandatoryCheckboxFieldIds: ['requiredEinreichungCheck', 'coverAndBackspineCheck']
                },
                PerformEinreichung: {
                    customFormKey: 'PERFORM_EINREICHUNG'
                },
                CommunicateResultEinreichung: {
                    customFormKey: 'COMMUNICATE_RESULT_EINREICHUNG'
                },
                CommunicateResultResubmission: {
                    customFormKey: 'COMMUNICATE_RESULT_RESUBMISSION'
                },
                ArrangeResubmission: {
                    customFormKey: 'ARRANGE_RESUBMISSION',
                    mandatoryCheckboxFieldIds: ['requiredEinreichungCheck', 'coverAndBackspineCheck']
                },
                PerformResubmission: {
                    customFormKey: 'PERFORM_RESUBMISSION'
                }
            }
        }
    },
    methods: {
        async getCustomForm () {
            this.formTypeConfig = this.config[this.formType]
            const form = this.loadFormConfiguration(this.formTypeConfig.customFormKey)

            // Is called everytime data comes from BE.
            function setupForm (appContext, form, formData) {
                const parametersAsMap = appContext.asMap(['federalState', 'schoolType', 'resubmissionRound'], appContext.formParameters)
                formData.values.federalState = parametersAsMap.federalState
                formData.values.schoolType = parametersAsMap.schoolType
                // resubmissionRound is not needed in the form logic, but for telling apart data for each resubmission loop in the back end
                formData.values.resubmissionRound = parametersAsMap.resubmissionRound
                appContext.parseFederalStatesAndSchoolTypesKeyValuesToSmartFormFields(formData)
                formData.values.einreichungDeadlineDate = appContext.parseDateString(formData.values.einreichungDeadlineDate)
                formData.values.publishingDate = appContext.parseDateString(formData.values.publishingDate)
                appContext.applyValuesToFormConfigurationAndReturnChanges(null, formData.values, true, form, null, this)
                appContext.formExists = true
                appContext.customForm = form
            }

            const urlParameters = this.getUrlParamsFromFormParameters(['federalState', 'schoolType', 'resubmissionRound'])
            this.axiosGet(`forms/search/${this.formType}/${this.taskData.productNumber}?${urlParameters}`)
                .then(formData => {
                    // search found something
                    setupForm(this, form, formData)
                })
                .catch(errorResponse => {
                    if (errorResponse.status === 500) {
                        this.addNotification({
                            type: 'error',
                            duration: 10000,
                            message: this.$tc('task.taskViews.einreichung.getEinreichungFormError')
                        })
                    } else if (errorResponse.status === 422) {
                        // search didn't find anything. Create a new form.
                        this.saveForm({
                            formConfig: form,
                            changes: {}
                        }).then(formData => {
                            setupForm(this, form, formData)
                        }).catch(() => {})
                    }
                })
        },

        getUrlParamsFromFormParameters (parameterNames) {
            if (this.formParameters) {
                const parameterMap = this.asMap(parameterNames, this.formParameters)
                return `${this.toQueryParameters(parameterMap)}`
            } else {
                return ''
            }
        },

        setFormIsValid (isValid) {
            this.formIsValid = isValid
        },

        saveFormChanges (formConfig, changes, successCallback) {
            return this.saveSmartFormWithParameters(
                this.formType,
                this.asMap(['federalState', 'schoolType', 'resubmissionRound'], this.formParameters),
                this.taskData.productNumber,
                formConfig,
                changes,
                this.formExists,
                this.$tc('task.taskViews.einreichung.updateEinreichungFormError'))
                .then(formData => {
                    successCallback()
                    this.formWasSavedSuccessfully = true
                    this.forceListRender()
                    return Promise.resolve(formData)
                })
                .catch(() => {
                    this.formWasSavedSuccessfully = false
                    return Promise.reject(new Error())
                })
        },

        saveForm (formSubmitEventData) {
            function updateFormWithSearchResults (lookupResponse, searchOptions, searchFieldId, searchString, property, appContext) {
                const resultFieldId = searchOptions.projection[property]
                let searchValue = lookupResponse?.metadata[property] || ''
                // Check if the search Value is a date which should be parsed.
                // Checking if a string is a date is not trivial https://stackoverflow.com/questions/7445328/check-if-a-string-is-a-date-value/30870755#30870755
                // So assume that the search value is a date if the property name contains 'Date'
                if (property.includes('Date')) {
                    const parsedDate = appContext.parseDateString(searchValue)
                    searchValue = parsedDate || searchValue
                }
                // Some fields should be overwritten with the search result, others should accumulate result
                // accumulate as proper list if flag is set, as the target field should then be a list not a readonly field
                let newValue
                const accumulateResults = searchOptions.accumulateResults
                if (accumulateResults) {
                    const existingValues = appContext.getFormFieldValue(appContext.customForm, resultFieldId)
                    const res = existingValues || []
                    res.push({ isbn: searchString, labelName: searchValue })
                    newValue = res
                } else {
                    newValue = searchValue
                }
                // Update the form values which will be sent to the backend as well as the form with the search result and render it again
                formSubmitEventData.changes[resultFieldId] = newValue
                appContext.setFormFieldValue(appContext.customForm, resultFieldId, newValue)
            }

            // Get the config for the current form to check search fields
            const searchFieldMapping = this.config[this.formType].searchFieldMapping ?? {}
            for (const [searchFieldId, searchOptions] of Object.entries(searchFieldMapping)) {
                // Check if the user changed a field that triggers a search
                const searchString = formSubmitEventData?.changes[searchFieldId]
                if (searchString) {
                    const requestBody = {
                        productNumber: searchString.replaceAll('-', ''),
                        properties: Object.keys(searchOptions?.projection)
                    }
                    // The lookup can be done with a single property, such as shortTitle, or with multiple properties
                    this.axiosPost('product/lookup', requestBody, this.$tc('task.taskViews.einreichung.getProductLookupError'))
                        .then(lookupResponse => {
                            // Update form with search results
                            requestBody.properties.forEach(property => {
                                updateFormWithSearchResults(lookupResponse, searchOptions, searchFieldId, searchString, property, this)
                                return this.saveFormChanges(formSubmitEventData.formConfig, formSubmitEventData.changes, () => {
                                    this.formExists = true
                                })
                            })
                            this.setFormFieldValue(this.customForm, searchFieldId, searchString)
                        })
                        .catch(() => {
                            // If the lookup fails, set the field to the search string and render the form again
                            requestBody.properties.forEach(property => this.setFormFieldValue(this.customForm, searchOptions.projection[property], ''))
                            this.setFormFieldValue(this.customForm, searchFieldId, searchString)
                        })
                }
            }
            const workBook = formSubmitEventData?.changes.workBook
            if (workBook) {
                const productNumber = workBook.replaceAll('-', '')
                const requestBody = {
                    productNumber: productNumber,
                    properties: ['shortTitle', 'einreichungDate']
                }
                this.axiosPost('product/lookup', requestBody, this.$tc('task.taskViews.einreichung.getProductLookupError'))
                    .then(lookupResponse => {
                        const existingWorkBookTitlesValues = this.getFormFieldValue(this.customForm, 'workBookTitles')
                        const existingEinreichungDeadlineForWorkBookValues = this.getFormFieldValue(this.customForm, 'einreichungDeadlinesForWorkBooks')
                        const workbookTitleRes = existingWorkBookTitlesValues || []
                        const einreichungDeadlineForWorkBookRes = existingEinreichungDeadlineForWorkBookValues || []
                        const einreichungDate = this.tryToParseToDate(lookupResponse.metadata.einreichungDate)
                            ? this.getFormattedShortDate(lookupResponse.metadata.einreichungDate, this.locale)
                            : lookupResponse.metadata.einreichungDate
                        workbookTitleRes.push({
                            isbn: productNumber,
                            labelName: `${lookupResponse.metadata.shortTitle}`
                        })
                        einreichungDeadlineForWorkBookRes.push({
                            isbn: productNumber,
                            labelName: `${einreichungDate}`
                        })
                        formSubmitEventData.changes.workBookTitles = workbookTitleRes
                        formSubmitEventData.changes.einreichungDeadlinesForWorkBooks = einreichungDeadlineForWorkBookRes
                        this.setFormFieldValue(this.customForm, 'workBookTitles', workbookTitleRes)
                        this.setFormFieldValue(this.customForm, 'einreichungDeadlinesForWorkBooks', einreichungDeadlineForWorkBookRes)
                        this.setFormFieldValue(this.customForm, 'workBook', productNumber)
                        return this.saveFormChanges(formSubmitEventData.formConfig, formSubmitEventData.changes, () => {
                            this.formExists = true
                        })
                    })
                    .catch(() => {
                        // If the lookup fails, set the field to the search string and render the form again
                        this.setFormFieldValue(this.customForm, 'workBook', '')
                    })
            }
            return this.saveFormChanges(formSubmitEventData.formConfig, formSubmitEventData.changes, () => {
                this.formExists = true
            })
        },

        uploadEinreichungFile (dropInputSubmitEventData, fieldId) {
            function updateFileList (files, fieldId, appContext) {
                appContext.setFormFieldValue(appContext.customForm, fieldId, files)
                appContext.uploadFieldConfigsAndStates[fieldId].isUploading = false
            }

            const urlParameters = this.getUrlParamsFromFormParameters(['federalState', 'schoolType', 'resubmissionRound'])
            this.uploadFieldConfigsAndStates[fieldId].isUploading = true
            this.axiosPost(
                `einreichung/file/${this.taskData.productId}?formField=${fieldId}&${urlParameters}`,
                dropInputSubmitEventData.value,
                this.$tc('task.taskViews.einreichung.uploadFileError'),
                {
                    standard: this.$tc('task.taskViews.einreichung.uploadFileSuccess.standard'),
                    short: this.$tc('task.taskViews.einreichung.uploadFileSuccess.short')
                })
                .then(uploadedFiles => {
                    updateFileList(uploadedFiles, fieldId, this)
                    this.saveFormChanges(this.customForm, { [fieldId]: uploadedFiles }, () => {
                        this.formExists = true
                    })
                    this.updateFormIsValid()
                    this.forceListRender()
                })
                .catch(error => {
                    console.error(error)
                    this.uploadFieldConfigsAndStates[fieldId].isUploading = false
                })
        },

        downloadEinreichungFile (entryListClickEventData, fieldId) {
            this.uploadFieldConfigsAndStates[fieldId].showSpinner = true
            const file = entryListClickEventData.entry
            this.downloadFileFromUrl(`einreichung/file/${file.id}`).then(() => {
                this.uploadFieldConfigsAndStates[fieldId].showSpinner = false
            })
        },

        deleteEinreichungFile (entryListSubmitEventData, fieldId) {
            const file = entryListSubmitEventData.entry
            const fileIndex = entryListSubmitEventData.index
            this.axiosDelete(
                `einreichung/file/${file.id}`,
                null,
                this.$t('task.taskViews.einreichung.removeFileError'),
                {
                    standard: this.$t('task.taskViews.einreichung.removeFileSuccess.standard'),
                    short: this.$tc('task.taskViews.einreichung.removeFileSuccess.short')
                })
                .then(() => {
                    const fileList = this.getFormFieldValue(this.customForm, fieldId)
                    fileList.splice(fileIndex, 1)
                    this.saveFormChanges(this.customForm, { [fieldId]: fileList }, () => {
                        this.updateFormIsValid()
                    })
                })
                .catch(error => console.error(error))
        },

        getEntryListItems (fieldId) {
            return this.getFormFieldValue(this.customForm, fieldId) || []
        },
        getFieldLabel (field) {
            let essentialSmartFormService = new EssentialSmartFormService(this.customForm, this.locale)
            const fieldLabel = essentialSmartFormService.getFieldLabel(field)

            // we want to save memory with this. not sure if it is needed
            essentialSmartFormService = null

            return fieldLabel
        },
        removeListItem (entryListSubmitData, fieldId) {
            const itemIndex = entryListSubmitData.index
            const itemList = this.getFormFieldValue(this.customForm, fieldId)
            itemList.splice(itemIndex, 1)
            this.saveFormChanges(this.customForm, { [fieldId]: itemList }, () => {
                this.updateFormIsValid()
            })
        },
        removeWorkBookListItem (entryListSubmitData) {
            const itemIndex = entryListSubmitData.index
            const workBookTitles = this.getFormFieldValue(this.customForm, 'workBookTitles')
            workBookTitles.splice(itemIndex, 1)
            const einreichungDeadlines = this.getFormFieldValue(this.customForm, 'einreichungDeadlinesForWorkBooks')
            einreichungDeadlines.splice(itemIndex, 1)
            this.saveFormChanges(this.customForm, { workBookTitles: workBookTitles, einreichungDeadlinesForWorkBooks: einreichungDeadlines }, () => {
                this.updateFormIsValid()
            })
        },
        updateFormIsValid () {
            let essentialSmartFormService = new EssentialSmartFormService(this.customForm, this.locale)
            this.formIsValid = !essentialSmartFormService.hasIncompleteSections()

            // we want to save memory with this. not sure if it is needed
            essentialSmartFormService = null
        },
        forceListRender () {
            this.listUpdateCounter++
        },
        // use listUpdateCounter as a dummy parameter so that vue.js thinks "I need to re-calculate this value if the update counter changes"
        allMandatoryCheckboxFieldIdsCheckedTrue (listUpdateCounter) {
            const essentialSmartFormService = new EssentialSmartFormService(this.customForm, this.locale)
            return (listUpdateCounter + 1) && essentialSmartFormService.areAllCheckboxesSetTrue(this.formTypeConfig.mandatoryCheckboxFieldIds)
        },
        formatPrintedCopiesEntries () {
            const fieldValue = this.getFormFieldValue(this.customForm, 'numberOfFullPrintedCopies') || []
            const intermediateResults = fieldValue.intermediateResults
            const sum = fieldValue.sum

            // Format intermediateResults entries
            const formattedEntries = intermediateResults?.map(entry => {
                return `${entry.federalState}: ${entry.sum}`
            })

            // Add the "Insgesamt: sum" entry in bold
            formattedEntries.push(`<b>Insgesamt: ${sum}</b>`)

            return formattedEntries
        },
        formatEinreichungDeadlinesForWorkBooks (fieldId) {
            const einreichungDeadlines = this.getFormFieldValue(this.customForm, fieldId) || []
            return einreichungDeadlines.map(entry => {
                return `${entry.labelName}`
            })
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        isTaskCompletable () {
            return this.formIsValid && this.formWasSavedSuccessfully && this.allMandatoryCheckboxFieldIdsCheckedTrue(this.listUpdateCounter)
        }
    },
    watch: {
        isTaskCompletable (isCompletable) {
            this.$emit('task-completable-changed', {
                isCompletable: isCompletable
            })
        }
    },
    mounted () {
        this.getCustomForm()
    }
}
</script>

<template>
    <div class="custom-task-content-container">
        <EssentialSmartForm v-if="customForm"
                            id="customForm"
                            v-bind:form-config="customForm"
                            v-bind:hide-submit-button="true"
                            @form-change="saveForm($event)"
                            @form-submittable-change="setFormIsValid($event)">

            <template :key=key v-for="(configIterator, key) in uploadFieldConfigsAndStates" #[configIterator.slotName]="data">
                <div class="custom-task-upload-files-container">
                    <DropInput v-if="data.field.upload"
                               v-bind:label="getFieldLabel(data.field)"
                               v-bind:placeholder="data.field.placeholder"
                               v-bind:allow-multiple="true"
                               v-bind:use-default-label="true"
                               v-bind:is-uploading="uploadFieldConfigsAndStates[data.field.id].isUploading"
                               @input-submit="uploadEinreichungFile($event, data.field.id)"
                               :key="`${key}_upload`">
                    </DropInput>
                    <EntryList v-if="!data.field.upload || getEntryListItems(data.field.id).length > 0"
                               v-bind:label="!data.field.upload ? data.field.label : null"
                               class="custom-task-form-files"
                               entry-label-key="fileName"
                               icon-class="fas fa-trash"
                               v-bind:entries="getEntryListItems(data.field.id)"
                               v-bind:enable-click="!uploadFieldConfigsAndStates[data.field.id].showSpinner"
                               v-bind:enable-submit="data.field.upload"
                               @entry-click="downloadEinreichungFile($event, data.field.id)"
                               @entry-submit="deleteEinreichungFile($event, data.field.id)"
                               :key="`${key}_list_${listUpdateCounter}`">
                    </EntryList>
                    <div v-if="uploadFieldConfigsAndStates[data.field.id].showSpinner">
                        <span class="fas fa-circle-notch fa-spin"></span>
                        <span>{{$tc('task.taskViews.einreichung.spinnerText')}}</span>
                        <span class="generals-walking-dots"></span>
                    </div>
                </div>
            </template>
            <!-- due to a vuejs bug, the iterator must have a different name, or some things won't show up: https://stackoverflow.com/q/78453395/1143126 -->
            <template v-for="(configIterator2, key) in searchFieldConfigs" #[configIterator2.slotName]="data">
                <EntryList v-if="!data.field.isReadOnly"
                           v-bind:label="data.field.label"
                           v-bind:entries="getEntryListItems(data.field.id)"
                           v-bind:entry-label-key="data.field.keyLabel"
                           v-bind:enable-click="false"
                           v-bind:enable-submit="true"
                           icon-class="fas fa-times"
                           @entry-submit="removeListItem($event, data.field.id)"
                           :key="`${key}_entrylist_${listUpdateCounter}`">
                </EntryList>
                <DisplayList v-if="data.field.isReadOnly"
                             v-bind:label="data.field.label"
                             v-bind:entries="getEntryListItems(data.field.id).map(entry => `${entry.isbn} ${entry.labelName}`)"
                             icon-class="fas"
                             :key="`${key}_displaylist_${listUpdateCounter}`">
                </DisplayList>
            </template>
            <template #field(numberOfFullPrintedCopies)="data">
                <DisplayList v-bind:label="data.field.label"
                             v-bind:entries="formatPrintedCopiesEntries()"
                             icon-class="fas">
                </DisplayList>
            </template>
            <template #field(workBookTitles)="data">
                <EntryList v-if="!data.field.isReadOnly"
                           icon-class="fas fa-times"
                           v-bind:enable-click="false"
                           v-bind:enable-submit="true"
                           v-bind:entries="getEntryListItems(data.field.id)"
                           v-bind:entry-label-key="data.field.keyLabel"
                           v-bind:label="data.field.label"
                           @entry-submit="removeWorkBookListItem($event, data.field.id)">
                </EntryList>
                <DisplayList v-if="data.field.isReadOnly"
                             v-bind:label="data.field.label"
                             v-bind:entries="getEntryListItems(data.field.id).map(entry => `${entry.isbn} ${entry.labelName}`)"
                             icon-class="fas"
                             :key="`${key}_displaylist_${listUpdateCounter}`">
                </DisplayList>
            </template>
            <template #field(einreichungDeadlinesForWorkBooks)="data">
                <DisplayList v-bind:entries="formatEinreichungDeadlinesForWorkBooks(data.field.id)"
                           v-bind:label="data.field.label">
                </DisplayList>
            </template>
        </EssentialSmartForm>
    </div>
</template>

<style lang="less" scoped>
.custom-task-content-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .custom-task-upload-files-container {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .custom-task-form-files {
            margin-bottom: var(--container-spacing);
        }
    }
}

</style>
