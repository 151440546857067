<script>
/**
 * DualTextInput is a combination of two pre styled input text elements. The second input field is the leading input which has to have a valid value. Otherwise, there will be an error message.
 * It will throw an @input-submit event on pressing enter or clicking the submit icon, an @input-blurred event when losing focus and an @input-close event on pressing esc.
 * - id: Should be provided in order to get full functionality. Without an id you won't get the ability to close on esc and instant focus the input field when opening.
 * - label (optional): Text displayed as the label of the input field.
 * - tinyLabel (optional): Small label displayed in the top border of the input. If set there will be a border around the whole input field.
 * - defaultValue (optional): The default value of the input. For editing purposes for example.
 * - placeholder (optional): Text shown if the input is empty. Type array in order to set different placeholder for each input.
 * - submitButton (default: true): Determines whether there is a submit button.
 * - resetOnSubmit (default: false): If true, the value will be reset to default or null after submit.
 * - iconClass (required if submitButton): A font awesome icon class.
 * - isDisabled (default: false): If true, the user can't change the input value.
 * - isAutoFocused (default: false): If true, the input field will get focused automatically when it is rendered.
 * - showUnsavedChanges (default: false): If true, the input will highlight if it's value differs from the last submitted one.
 * - maxLength: Set the maximum char length of the input.
 **/

export default {
    name: 'DualTextInput',
    // @input-submit: Event emitted on submit | returns the event and current input value
    // @input-blurred: Event emitted on blur | returns current input value
    // @input-close: Event emitted on pressing esc key | no return
    // Note: We do not declare the emits here, because we want to have the registrations as part of $attrs
    //       emits: ['input-submit', 'input-blurred', 'input-close'],
    props: {
        id: String,
        label: String,
        tinyLabel: String,
        defaultValue: Array,
        placeholder: Array,
        submitButton: {
            default: true,
            type: Boolean
        },
        resetOnSubmit: {
            default: false,
            type: Boolean
        },
        iconClass: String,
        isDisabled: {
            default: false,
            type: Boolean
        },
        isAutoFocused: {
            default: false,
            type: Boolean
        },
        showUnsavedChanges: {
            default: false,
            type: Boolean
        },
        maxLength: Number
    },
    data () {
        return {
            inputValues: this.defaultValue ? [...this.defaultValue] : [],
            lastSubmittedValues: this.defaultValue ? [...this.defaultValue] : []
        }
    },
    methods: {
        focusInputElement () {
            document.getElementById(`c_dual-text-input_start_${this.id}`).focus()
        },

        isInputValid (position) {
            return this.inputValues[position] &&
                this.inputValues[position] !== ''
        },

        getInputLength (input) {
            return input
                ? input.length
                : 0
        },

        keyPressedHandler (event) {
            if (event.key === 'Enter') {
                this.emitSubmit(event)
            } else if (event.key === 'Escape') {
                this.$emit('input-close')
            }
        },

        emitSubmit (event) {
            // We actually need to check for listeners as the lastSubmittedValue must not be
            // updated if there is no listener which performs the actual submit
            //
            // see https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/593#note_316549
            //     for further information
            if (this.$attrs.onInputSubmit) {
                this.emit(event, 'input-submit')
                this.updateLastSubmittedValueAndReset(true)
            }
        },

        emitBlur () {
            // We actually need to check for listeners as the lastSubmittedValue must not be
            // updated if there is no listener which performs the actual submit
            //
            // see https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/593#note_316549
            //     for further information
            if (this.$attrs.onInputBlurred) {
                this.emit(null, 'input-blurred')
                this.updateLastSubmittedValueAndReset(false)
            }
        },

        emit (event, name) {
            if (event) {
                event.stopPropagation()
            }
            if (this.hasUnsavedChanges) {
                this.$emit(name, {
                    event: event,
                    value: this.inputValues
                })
            }
        },

        updateLastSubmittedValueAndReset (doResetInput) {
            this.lastSubmittedValues = [...this.inputValues]
            if (doResetInput && this.resetOnSubmit) {
                this.inputValues = this.defaultValue || []
            }
        }
    },
    computed: {
        hasUnsavedChanges () {
            return !this.showErrorMessage &&
                (this.inputValues[0] !== this.lastSubmittedValues[0] ||
                this.inputValues[1] !== this.lastSubmittedValues[1])
        },

        showErrorMessage () {
            return this.isInputValid(0) && !this.isInputValid(1)
        },

        getMaxLengthCount () {
            const lengthFirstInput = this.getInputLength(this.inputValues[0])
            const lengthSecondInput = this.getInputLength(this.inputValues[1])
            return this.$t('dualTextInput.maxLengthCounter', [lengthFirstInput, lengthSecondInput, this.maxLength])
        },

        getMaxLengthStyleModifier () {
            const lengthFirstInput = this.getInputLength(this.inputValues[0])
            const lengthSecondInput = this.getInputLength(this.inputValues[1])
            const ninetyPercentMax = this.maxLength * 0.9
            return {
                'm--close-to-max': lengthFirstInput >= ninetyPercentMax || lengthSecondInput >= ninetyPercentMax,
                'm--max-reached': lengthFirstInput === this.maxLength || lengthSecondInput === this.maxLength
            }
        }
    },
    watch: {
        inputValues () {
            this.emit(null, 'input-change')
        },
        defaultValue () {
            this.inputValues = this.defaultValue ? [...this.defaultValue] : []
            this.lastSubmittedValues = this.defaultValue ? [...this.defaultValue] : []
        }
    },
    mounted () {
        if (this.isAutoFocused) {
            this.focusInputElement()
        }
    }
}
</script>

<template>
    <div class="c_dual-text-input-wrapper generals-input-wrapper"
         v-bind:class="{'m--message': showErrorMessage}">
        <label v-if="label"
               class="c_dual-text-input-label generals-input-label">
            <span>{{label}}</span>
        </label>
        <div class="c_dual-text-input-container generals-input-container">
            <div v-if="tinyLabel"
                 class="c_dual-text-input-tiny-label generals-input-tiny-label">
                <span>{{tinyLabel}}</span>
            </div>
            <input v-bind:id="id ? `c_dual-text-input_start_${id}`: null"
                   type="text"
                   class="c_dual-text-input m--start generals-input"
                   v-bind:disabled="isDisabled"
                   v-bind:class="{'m--unsaved': showUnsavedChanges && hasUnsavedChanges}"
                   v-bind:placeholder="placeholder[0] || $tc('dualTextInput.defaultPlaceholder')"
                   v-bind:maxlength="maxLength"
                   v-on:blur="emitBlur()"
                   v-on:click="$event.stopPropagation()"
                   v-on:keyup="keyPressedHandler($event)"
                   v-model="inputValues[0]" />
            <input v-bind:id="id ? `c_dual-text-input_end_${id}`: null"
                   type="text"
                   class="c_dual-text-input m--end generals-input"
                   v-bind:disabled="isDisabled"
                   v-bind:class="{
                       'm--no-icon': !submitButton && !iconClass,
                       'm--unsaved': showUnsavedChanges && hasUnsavedChanges
                   }"
                   v-bind:placeholder="placeholder[1] || $tc('dualTextInput.defaultPlaceholder')"
                   v-bind:maxlength="maxLength"
                   v-on:blur="emitBlur()"
                   v-on:click="$event.stopPropagation()"
                   v-on:keyup="keyPressedHandler($event)"
                   v-model="inputValues[1]" />
            <button v-if="!isDisabled && (submitButton || iconClass)"
                    tabindex="-1"
                    class="c_dual-text-input-submit"
                    v-bind:class="{'m--disabled': !submitButton || showErrorMessage}"
                    v-on:mousedown="$event.preventDefault()"
                    v-on:click="emitSubmit($event)">
                <span class="c_dual-text-input-icon"
                      v-bind:class="iconClass">
                </span>
            </button>
            <div v-if="showErrorMessage"
                 class="c_dual-text-error-message">
                <span>{{$tc('dualTextInput.missingValue')}}</span>
            </div>
            <div v-if="maxLength"
                 class="c-dual-text-max-length-indicator generals-input-max-length-indicator"
                 v-bind:class="getMaxLengthStyleModifier">
                <span>{{getMaxLengthCount}}</span>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_dual-text-input-wrapper {

    &.m--message {
        height: calc(var(--input-height) + 15px);
    }

    .c_dual-text-input-label {
    }

    .c_dual-text-input-container {

        .c_dual-text-input-tiny-label {
        }

        .c_dual-text-input {
            padding-right: 0;

            &.m--start {
                width: calc(45% - var(--container-spacing) / 4);
                margin-right: calc(var(--container-spacing) / 2);
            }

            &.m--end {
                width: calc(55% - var(--container-spacing) / 4);
                padding-right: 30px;
            }
        }

        .c_dual-text-input-submit {

            .c_dual-text-input-icon {
            }
        }

        .c_dual-text-error-message {
            font-size: 11px;
            color: var(--color-error);
        }

        .c-dual-text-max-length-indicator {
        }
    }
}
</style>
