/**
 * A reusable service for handling general task execution.
 **/
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { notificationHandler } from '@/mixins/notificationHandler'

export const taskExecutionHandler = {
    mixins: [
        axiosService,
        userHandler,
        notificationHandler
    ],
    data () {
        return {
            contentTypes: {
                document: 'DOCUMENT',
                text: 'TEXT',
                form: 'FORM',
                link: 'HYPERLINK',
                multi: 'MULTIPLE'
            }
        }
    },
    methods: {
        saveOrUpdateForm (task, form) {
            const formOutputDataObject = getRequiredFormFromTask(this, task)
            const data = new FormData()
            data.append('textContent', JSON.stringify(form))
            data.append('dataObjectName', formOutputDataObject.name)

            const isCreate = formOutputDataObject.dataValues.length === 0 && formOutputDataObject.defaultValue
            const errorMessage = this.$t('taskExecutionHandler.saveFormError', [formOutputDataObject.name])
            const axiosFunction = isCreate
                ? 'axiosPost'
                : 'axiosPut'
            const url = isCreate
                ? `task/${this.task.id}/text`
                : `task/${this.task.id}/data/${formOutputDataObject.dataValues[0].id}`

            return this[axiosFunction](url, data, errorMessage)
                .then(updatedData => {
                    formOutputDataObject.dataValues = [updatedData]
                    return Promise.resolve(task)
                })
                .catch(error => {
                    return Promise.reject(error)
                })
        },

        getDataObjectContentType (dataObject, dataObjectValue) {
            return dataObjectValue
                ? getTypeOfDataObjectValue(this, dataObject, dataObjectValue)
                : getTypeOfDataObject(this, dataObject)
        }
    }
}

function getTypeOfDataObjectValue (appContext, dataObject, dataObjectValue) {
    if (dataObjectValue.fileName) {
        return appContext.contentTypes.document
    }
    let url
    try {
        url = new URL(dataObjectValue.textContent)
    } catch (_) {
        return dataObject.dataContentTypes.includes(appContext.contentTypes.text)
            ? appContext.contentTypes.text
            : appContext.contentTypes.link
    }
    return url.protocol === 'http:' || url.protocol === 'https:'
        ? appContext.contentTypes.link
        : appContext.contentTypes.text
}

function getTypeOfDataObject (appContext, dataObject) {
    if (dataObject.dataContentTypes.length > 1 && dataObject.dataValues.length === 0) {
        return dataObject.temporaryType || appContext.contentTypes.multi
    }
    if (dataObject.dataContentTypes.length === 1) {
        return dataObject.dataContentTypes[0]
    }
    const dataValue = dataObject.dataValues[0]
    return getTypeOfDataObjectValue(appContext, dataObject, dataValue)
}

function getRequiredFormFromTask (appContext, task) {
    return task.outputs.find(output => appContext.getDataObjectContentType(output) === appContext.contentTypes.form)
}
