<script>
/**
 * SmartFormSubheading is a child component of the SmartForm component. Not meant to be used outside the SmartForm component.
 * It is separating different spaces withing a form section.
 * label (required): The displayed title in the heading.
 **/

export default {
    name: 'SmartFormSubheading',
    props: {
        label: String
    }
}
</script>

<template>
    <div class="c_smart-form-subheading">
        <div class="c_smart-form-field-subheading-title"
             v-bind:class="{'m--empty': !label}">
            <span data-testid="subheading-title">{{label}}</span>
        </div>
    </div>
</template>

<style lang="less">
.c_smart-form-subheading {
    border-top: 1px solid var(--color-border-light);
    margin: 50px 0 20px 0;

    .c_smart-form-field-subheading-title {
        text-align: center;
        margin-top: -12px;

        &.m--empty span {
            padding: 0;
        }

        span {
            background-color: var(--color-background-default);
            text-transform: uppercase;
            padding: 0 10px;
        }
    }
}
</style>
