<script>
/**
 * Create test user sub view | Sub view of controlling
 **/
import EssentialSmartForm from '@/components/EssentialSmartForm.vue'
import { axiosService } from '@/mixins/axiosService'
import { notificationHandler } from '@/mixins/notificationHandler'

export default {
    name: 'CreateTestUser',
    mixins: [
        axiosService,
        notificationHandler
    ],
    components: {
        EssentialSmartForm
    },
    data () {
        return {
            formConfig: {
                id: 'create-test-user_form',
                headline: 'Formular für die Anlage von Testnutzern',
                headline_en: 'Form for test user creation',
                sections: [
                    {
                        id: 'testUserInfo',
                        title: 'Nutzerinformationen',
                        title_en: 'User information',
                        allowMultiple: false,
                        fields: [{
                            id: 'username',
                            label: 'Nutzername',
                            label_en: 'Username',
                            placeholder: 'Nutzername eintragen',
                            placeholder_en: 'Enter username',
                            type: 'Text',
                            required: true
                        }, {
                            id: 'role',
                            label: 'Rolle',
                            label_en: 'Role',
                            placeholder: 'Rolle auswählen',
                            placeholder_en: 'Choose user role',
                            type: 'Select',
                            required: true,
                            options: [{
                                label: 'Admin',
                                label_en: 'Admin',
                                value: 'ADMIN'
                            }, {
                                label: 'Contentmanagement',
                                label_en: 'Content management',
                                value: 'CONTENT_MANAGER'
                            }, {
                                label: 'Redakteur',
                                label_en: 'Editor',
                                value: 'EDITOR'
                            }, {
                                label: 'Externer Redakteur',
                                label_en: 'External editor',
                                value: 'EXTERNAL_EDITOR'
                            }, {
                                label: 'Externer Dienstleister',
                                label_en: 'External service provider',
                                value: 'EXTERNAL_SERVICE_PROVIDER'
                            }, {
                                label: 'Projektkoordinator',
                                label_en: 'Project coordinator',
                                value: 'PROJECT_COORDINATOR'
                            }, {
                                label: 'Einreichung',
                                label_en: 'Einreichung (submission for ministry approval)',
                                value: 'EINREICHUNG'
                            }]
                        }, {
                            id: 'creationSuccess',
                            invisible: true
                        }, {
                            id: 'createdUserInfo',
                            label: '<h3>Die Anlage des Testnutzers war erfolgreich!</h3><div>Bitte speichere dir die unten aufgeführten Anmeldeinformationen, da diese nicht erneut abrufbar sind! Wenn du weitere Nutzer anlegen möchtest, kannst du die obigen Informationen jetzt editieren und das Formular erneut abschicken.</div>',
                            label_en: '<h3>The test user creation was successful!</h3><div>Please save the login information below as it will not be retrievable again! If you want to create more users, you can edit the above information now and submit the form again.</div>',
                            type: 'Paragraph',
                            subType: 'success',
                            visibleIf: 'isTrue(creationSuccess)'
                        }, {
                            id: 'createdUserUsername',
                            label: 'Angelegter Nutzer',
                            label_en: 'Created user',
                            type: 'ReadOnly',
                            visibleIf: 'isTrue(creationSuccess)'
                        }, {
                            id: 'createdUserPassword',
                            label: 'Passwort des angelegten Nutzers',
                            label_en: 'Password of created user',
                            type: 'ReadOnly',
                            visibleIf: 'isTrue(creationSuccess)'
                        }],
                        pages: [{}]
                    }
                ]
            }
        }
    },
    methods: {
        createTestUser () {
            const formPageData = this.formConfig.sections[0].pages[0]
            this.axiosPost(`admin/user?username=${formPageData.username}&role=${formPageData.role}`)
                .then(createdTestUser => {
                    formPageData.createdUserUsername = createdTestUser.username
                    formPageData.createdUserPassword = createdTestUser.password
                    formPageData.creationSuccess = true
                })
                .catch(errorResponse => {
                    formPageData.creationSuccess = false
                    delete formPageData.createdUserUsername
                    delete formPageData.createdUserPassword
                    this.addNotification({
                        type: 'error',
                        duration: 10000,
                        message: `${this.$tc('administration.controlling.testUser.createTestUserError')} ${errorResponse.data.error}`
                    })
                })
        }
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="create-test-user_form-container">
            <EssentialSmartForm id="testUserCreateForm"
                                v-bind:hide-table-of-contents="true"
                                v-bind:form-config="formConfig"
                                @form-submit="createTestUser()">
            </EssentialSmartForm>
        </div>
    </div>
</template>

<style scoped lang="less">
.create-test-user_form-container {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 var(--container-spacing);
}
</style>
