<script>
/**
 * BadgeInput is a text element looking like a pill.
 * It has a submit button which emits a @badge-submit event on click.
 * - isDisabled (default: false): If true, no hover or click functionality will be available.
 * - value: The text displayed in the badge.
 * - tooltip: Text shown on hover.
 **/
export default {
    name: 'BadgeInput',
    // @badge-submit: Event emitted on submit | returns the event
    emits: ['badge-submit', 'badge-email'],
    props: {
        isDisabled: {
            default: false,
            type: Boolean
        },
        value: [String, Number],
        tooltip: String
    },
    data () {
        return {
            textTruncated: false,
            deleteInFocus: false,
            textInFocus: false
        }
    },
    methods: {
        toggleDeleteFocused () {
            this.deleteInFocus = !this.deleteInFocus
        },
        toggleTextFocused () {
            this.textInFocus = !this.textInFocus
        },
        emitEmail (event) {
            event.stopPropagation()
            this.$emit('badge-email', event)
        },
        emitSubmit (event) {
            event.stopPropagation()
            this.$emit('badge-submit', event)
        }
    }
}
</script>

<template>
    <div
        class="c_badge-input"
        tabindex="-1"
        v-bind:title="tooltip"
        v-bind:class="{
            'm--disabled': isDisabled,
            'm--child-hover': deleteInFocus,
            'm--text-hover': textInFocus,
        }"
    >
        <span
            data-testid="email-text"
            v-on:mouseenter="toggleTextFocused()"
            v-on:mouseleave="toggleTextFocused()"
            v-on:click="emitEmail($event)"
            v-bind:title="textTruncated ? value : null"
        >
            {{ value }}
        </span>
        <button
            data-testid="delete-button"
            v-if="!isDisabled"
            tabindex="-1"
            v-on:mouseenter="toggleDeleteFocused()"
            v-on:mouseleave="toggleDeleteFocused()"
            v-on:click="emitSubmit($event)"
            class="c_badge-input-delete"
        >
            <span class="fas fa-times"></span>
        </button>
    </div>
</template>

<style lang="less">
.c_badge-input {
    display: inline-flex;
    width: max-content;
    height: 25px;
    padding: 2px 8px;
    margin: 8px 4px 0 0;
    border-radius: 10px;
    font-size: 15px;
    background-color: var(--color-background-dark);
    color: var(--color-text-bright);
    outline: none;

    &.m--child-hover {
        background-color: var(--color-error);
        color: var(--color-text-bright);
    }

    &.m--text-hover {

        &:hover {
            cursor: pointer;
        }
    }

    &.m--disabled {
        background-color: var(--color-background-mid);
        color: var(--color-text-mid);
    }

    .c_badge-input-delete {
        color: var(--color-text-bright);
        font-size: 13px;
        height: 25px;
        width: 25px;
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        margin: -2px -8px 0 8px;
        border: 0;
        outline: none;
        background-color: transparent;

        &:hover {
            padding-right: 6px;
            background-color: var(--color-error);
            cursor: pointer;
        }
    }
}
</style>
