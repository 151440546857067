/**
 * A reusable service for handling debounce.
 **/

export const debounceHandler = {
    data () {
        return {
            timeout: null,
            isDebouncing: false
        }
    },
    methods: {

        async debounceAsync (func) {
            if (this.isDebouncing) {
                return
            }
            this.isDebouncing = true
            await func()
            this.isDebouncing = false
        },

        delayedExecution (func, delay) {
            if (this.isDelayedExecutionRunning()) {
                this.clearDelayedExecutionTimeout()
            }
            this.timeout = setTimeout(() => {
                func()
                this.clearDelayedExecutionTimeout()
            }, delay)
        },

        isDelayedExecutionRunning () {
            return this.timeout !== null
        },

        clearDelayedExecutionTimeout () {
            clearTimeout(this.timeout)
            this.timeout = null
        }
    }
}
