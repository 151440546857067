<script>
/**
 * A list to display readonly entries.
 * Available properties are:
 * - label (optional): If provided, there will be a label next to the list.
 * - entries (required): The list of entries (Strings) to be displayed.
 *                       May also be plain HTML strings like '<a href=http://a.de>a link </a>'
 **/

export default {
    name: 'DisplayList',
    props: {
        label: String,
        entries: Array
    }
}
</script>

<template>
    <div class="c_display-list-wrapper generals-input-wrapper">
        <div v-if="label"
               class="c_display-list-label generals-input-label">
            <span>{{ label }}</span>
        </div>
        <div class="c_display-list-container generals-input-container">
            <div class="c_display-list-entries-container">
                <div v-if="!entries || entries.length === 0"
                     class="c_display-list-entry">
                    <div class="c_display-list-empty">{{ $tc('entryList.defaultText') }}</div>
                </div>
                <div v-for="(entry, index) in entries"
                     v-bind:key="index"
                     v-bind:title="entry"
                     class="c_display-list-entry">
                    <div class="c_display-list-entry-label generals-text-ellipsis">
                        <span v-dompurify-html="entry"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
.c_display-list-wrapper {
    .c_display-list-label {
        pointer-events: none;
    }

    .generals-input-container.c_display-list-container {
        padding-top: 12px;
        height: auto;

        .c_display-list-entries-container {
            position: relative;
            width: 100%;

            .c_display-list-entry {
                width: 100%;
                display: flex;
                height: 30px;

                &:not(:first-child) {
                    margin-top: 5px;
                }

                .c_display-list-empty {
                    width: 100%;
                    pointer-events: none;
                    color: var(--color-text-disabled);
                }

                .c_display-list-entry-label {
                    width: 100%;
                    color: var(--color-text-mid);
                    cursor: default;
                }
            }
        }
    }
}
</style>
