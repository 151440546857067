<script>
/**
 * Operation sub view | Sub view of controlling
 *
 **/
import Table from '@/components/Table.vue'
import IconButton from '@/components/IconButton.vue'
import { axiosService } from '@/mixins/axiosService'

export default {
    name: 'Operation',
    mixins: [
        axiosService
    ],
    components: {
        Table,
        IconButton
    },
    data () {
        return {
            operations: [],
            showSpinner: true
        }
    },
    methods: {
        getOperations () {
            this.axiosGet(
                'admin/operations',
                this.$tc('administration.controlling.operation.getOperationsError'))
                .then(operations => {
                    this.operations = operations
                    this.showSpinner = false
                })
                .catch(() => {
                    this.showSpinner = false
                })
        },

        getOperationState (operation) {
            return operation.enabled
                ? this.$tc('administration.controlling.operation.operationStates.active')
                : this.$tc('administration.controlling.operation.operationStates.inactive')
        },

        getOperationButtonIconClass (operation) {
            return operation.enabled
                ? 'fas fa-pause-circle'
                : 'fas fa-play-circle'
        },

        getOperationButtonTooltip (operation) {
            return operation.enabled
                ? this.$tc('administration.controlling.operation.table.stopOperation')
                : this.$tc('administration.controlling.operation.table.startOperation')
        },

        toggleOperationState (operation) {
            const data = {
                id: operation.id,
                configKey: operation.configKey,
                description: operation.description,
                enabled: !operation.enabled
            }
            this.axiosPut(
                'admin/operations',
                data,
                this.$t('administration.controlling.operation.toggleOperationError', [operation.description]),
                {
                    standard: this.$t(`administration.controlling.operation.${data.enabled ? 'activate' : 'deactivate'}OperationSuccess.standard`, [operation.description]),
                    short: this.$tc(`administration.controlling.operation.${data.enabled ? 'activate' : 'deactivate'}OperationSuccess.short`)
                })
                .then(() => {
                    operation.enabled = data.enabled
                })
                .catch(() => {})
        }
    },
    computed: {
        getTableConfig () {
            return [{
                key: 'enabled',
                label: this.$tc('administration.controlling.operation.table.state'),
                sortable: true,
                alignment: 'left',
                width: 10
            }, {
                key: 'configKey',
                label: this.$tc('administration.controlling.operation.table.configKey'),
                sortable: true,
                alignment: 'left',
                width: 30
            }, {
                key: 'description',
                label: this.$tc('administration.controlling.operation.table.description'),
                sortable: true,
                alignment: 'left',
                width: 50
            }, {
                key: 'action',
                label: null,
                sortable: false,
                alignment: 'right',
                width: 10
            }]
        }
    },
    mounted () {
        this.getOperations()
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="operations-table-container">
            <Table table-id="adminProductOperation"
                   v-bind:table-config="getTableConfig"
                   v-bind:table-data="operations"
                   v-bind:filter-placeholder="$tc('administration.controlling.operation.table.filterPlaceholder')"
                   v-bind:table-empty-message="$tc('administration.controlling.operation.table.tableEmpty')"
                   v-bind:filter-no-results-message="$tc('administration.controlling.operation.table.filterNoResults')"
                   v-bind:read-only=true
                   default-sorting-key="description"
                   default-sorting-direction="asc"
                   v-bind:show-spinner="showSpinner">
                <template #cell(enabled)="data">
                    <span class="generals-badge-text operation-state"
                          v-bind:class="{'m--inactive': !data.row.enabled}">{{getOperationState(data.row)}}</span>
                </template>
                <!-- configKey: default cell content -->
                <!-- description: default cell content -->
                <template #cell(action)="data">
                    <IconButton v-bind:icon-class="getOperationButtonIconClass(data.row)"
                                v-bind:font-size="20"
                                v-bind:tooltip="getOperationButtonTooltip(data.row)"
                                @button-submit="toggleOperationState(data.row)">
                    </IconButton>
                </template>
            </Table>
        </div>
    </div>
</template>

<style scoped lang="less">
.operations-table-container {
    width: 100%;
    height: 100%;
    padding: 0 var(--container-spacing);

    .operation-state {
        color: var(--color-success);
        border-color: var(--color-success);

        &.m--inactive {
            color: var(--color-text-disabled);
            border-color: var(--color-border-light);
        }
    }
}
</style>
