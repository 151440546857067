<script>
/**
 * Task view
 *
 * @property {String} referenceProductNumberForLayout
 **/
import SmartSelect from '@/components/SmartSelect.vue'
import EssentialSmartForm from '@/components/EssentialSmartForm.vue'
import ModalContainer from '@/components/ModalContainer.vue'
import ProductDetails from '@/views/products/product-portfolio/ProductDetails.vue'
import ProductMembers from '@/views/products/product-portfolio/ProductMembers.vue'
import ProductContents from '@/views/products/product-portfolio/ProductContents.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { taskExecutionHandler } from '@/mixins/taskExecutionHandler'
import { smartFormHelper } from '@/mixins/smartFormHelper'
import { notificationHandler } from '@/mixins/notificationHandler'

export default {
    name: 'CustomTaskBriefing',
    mixins: [
        axiosService,
        userHandler,
        taskExecutionHandler,
        smartFormHelper,
        notificationHandler
    ],
    components: {
        SmartSelect,
        EssentialSmartForm,
        ModalContainer,
        ProductDetails,
        ProductMembers,
        ProductContents
    },
    // @task-completable-changed: Event emitted when the task-completable state changed
    emits: ['task-completable-changed'],
    props: {
        taskData: Object
    },
    data () {
        return {
            task: this.taskData,
            briefingForm: null,
            formExists: false,
            formIsValid: false,
            updateCounter: 0,
            formWasSavedSuccessfully: true,
            productState: {},
            selectedView: {},
            nonRequiredFields: [
                'shortTitle',
                'correctionHours',
                'creativeHours',
                'specialties',
                'linkToStyleGuide',
                'linkToInDesignTemplate',
                'linkToStructureDefinition',
                'linkToPagePlan',
                'imageLinkValidation',
                'releaseReferencePagesInAdvance',
                'referenceProductNumberForLayout',
                'referenceProductTitleForLayout',
                'testEditionPlanned',
                'implementationType',
                'furtherNotes',
                'designCategoryPlain',
                'designCategoryElementary',
                'designCategoryIntermediate',
                'designCategoryComplex',
                'designCategoryLidProduction',
                'designCategoryDivider',
                'designCategoryHeadline',
                'designCategoryPlainNumberOfPages',
                'designCategoryElementaryNumberOfPages',
                'designCategoryIntermediateNumberOfPages',
                'designCategoryComplexNumberOfPages',
                'designCategoryLidProductionNumberOfPages',
                'totalPageCount',
                'plannedDateOfEinreichung',
                'additionalWordFormatUsedToSeriesWordTemplate',
                'containsSeriesWordTemplateFormulas',
                'serviceProviderReference',
                'isServiceProviderSplitMergeAllowed'
            ],
            fieldsToApplyFromForeignBriefing: [
                'poolProduction',
                'design_category',
                'manuscriptType',
                'language',
                'specifyMixed',
                'whatOtherLanguage',
                'specialties',
                'specifyOtherInSpecialties',
                'referenceProductNumberForLayout',
                'referenceProductTitleForLayout',
                'linkToStyleGuide',
                'linkToInDesignTemplate',
                'imageLinkValidation',
                'releaseReferencePagesInAdvance',
                'implementationType',
                'gridsRequired',
                'pdfResolution',
                'furtherNotes',
                'isServiceProviderSplitMergeAllowed'
            ],
            finalBriefingKey: 'FinalBriefingForm',
            isFinalBriefing: false
        }
    },
    methods: {
        getProductState () {
            this.axiosGet(
                `product/${this.task.productId}/state`,
                this.$tc('task.taskViews.briefing.getProductStateError'))
                .then(productInformation => {
                    if (!productInformation.userRights) {
                        productInformation.userRights = {}
                    }
                    this.productState = productInformation
                })
                .catch(() => {})
        },

        forceRerender () {
            this.updateCounter++
        },

        initializeForm (formConfig, formValues) {
            function setRequired (field, appContext) {
                // some fields are optional in the preliminary briefing, but required in the final briefing form
                field.required = appContext.isFinalBriefing && !appContext.nonRequiredFields.includes(field.id)
            }
            this.applyValuesToFormConfigurationAndReturnChanges(null, formValues, true, formConfig, setRequired, this)
            this.formExists = true
            this.briefingForm = formConfig
            this.forceRerender()
        },

        getBriefingForm () {
            this.briefingForm = this.loadFormConfiguration('BRIEFING')
            this.axiosGet(`forms/search/${this.taskData.formKey}/${this.task.productNumber}`)
                .then(formData => {
                    this.initializeForm(this.briefingForm, formData.values)
                })
                .catch(errorResponse => {
                    if (errorResponse.status === 500) {
                        this.addNotification({
                            type: 'error',
                            duration: 10000,
                            message: this.$tc('task.taskViews.briefing.getBriefingError')
                        })
                    } else if (errorResponse.status === 422) {
                        this.saveForm({ formConfig: this.briefingForm, changes: {} })
                    }
                })
        },

        loadForeignBriefingForm (productNumberOrIsbn) {
            this.axiosGet(
                `forms/search/${this.finalBriefingKey}/${productNumberOrIsbn}`,
                this.$tc('task.taskViews.briefing.getForeignBriefingError'))
                .then(formData => {
                    const durations = {
                        short: 3000,
                        standard: 5000
                    }
                    if (formData.isFinished) {
                        const changes = this.applyValuesToFormConfigurationAndReturnChanges(this.fieldsToApplyFromForeignBriefing, formData.values, true, this.briefingForm)
                        this.saveForm({ formConfig: this.briefingForm, changes: changes })
                            .then(() => {
                                const briefingFoundMessages = {
                                    short: this.$tc('task.taskViews.briefing.getForeignBriefingSuccess.short'),
                                    standard: this.$t('task.taskViews.briefing.getForeignBriefingSuccess.standard', [productNumberOrIsbn])
                                }
                                this.configureNotification('success', durations, briefingFoundMessages)
                            })
                    } else {
                        const briefingNotExistingMessages = {
                            short: this.$tc('task.taskViews.briefing.foreignBriefingNotExisting.short'),
                            standard: this.$t('task.taskViews.briefing.foreignBriefingNotExisting.standard', [productNumberOrIsbn])
                        }
                        this.configureNotification('info', durations, briefingNotExistingMessages)
                    }
                })
                .catch(() => {})
        },

        getBriefingForForeignProduct () {
            this.$swal({
                title: this.$tc('task.taskViews.briefing.getForeignBriefingPopup.title'),
                html: this.$tc('task.taskViews.briefing.getForeignBriefingPopup.text'),
                icon: 'info',
                input: 'text',
                inputPlaceholder: this.$tc('task.taskViews.briefing.getForeignBriefingPopup.inputPlaceholder'),
                showConfirmButton: true,
                confirmButtonText: this.$tc('task.taskViews.briefing.getForeignBriefingPopup.confirmText'),
                showCancelButton: true,
                cancelButtonText: this.$tc('generals.cancel'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.isConfirmed) {
                    const searchText = result.value.replaceAll('-', '')
                    this.loadForeignBriefingForm(searchText)
                }
            })
        },

        setFormValid (isValid) {
            this.formIsValid = isValid
        },

        async saveForm (formSubmitEventData) {
            async function lookupReferenceProductForLayout (appContext) {
                return appContext.axiosPost(
                    'product/lookup',
                    {
                        productNumber: referenceProductNumberForLayout.replaceAll('-', ''),
                        properties: ['shortTitle']
                    },
                    appContext.$tc('task.taskViews.briefing.getReferenceError'))
                    .then(referenceProductLookUpResult => Promise.resolve(referenceProductLookUpResult))
                    .catch(() => Promise.resolve(null))
            }

            const referenceProductNumberForLayout = formSubmitEventData?.changes?.referenceProductNumberForLayout
            let referenceProductTitleForLayout
            if (referenceProductNumberForLayout) {
                const referenceProductLookupResult = await lookupReferenceProductForLayout(this)
                referenceProductTitleForLayout = referenceProductLookupResult?.metadata?.shortTitle
                formSubmitEventData.changes.referenceProductTitleForLayout = referenceProductTitleForLayout || ''
            }
            if (referenceProductNumberForLayout === '') {
                // search field changed back to "empty"
                formSubmitEventData.changes.referenceProductTitleForLayout = ''
            }
            return this.saveSmartForm(
                this.taskData.formKey,
                this.task.productNumber,
                this.briefingForm,
                formSubmitEventData.changes,
                this.formExists,
                this.$tc('task.taskViews.briefing.updateBriefingError'))
                .then(formData => {
                    if (this.formExists) {
                        if (referenceProductNumberForLayout || referenceProductNumberForLayout === '') {
                            this.setFormFieldValue(this.briefingForm, 'referenceProductTitleForLayout', referenceProductTitleForLayout)
                        }
                        this.formWasSavedSuccessfully = true
                    } else {
                        this.initializeForm(this.briefingForm, formData.values || {})
                    }
                    return Promise.resolve(true)
                })
                .catch(() => {
                    this.formWasSavedSuccessfully = false
                    return Promise.reject(new Error())
                })
        },

        setSelection (selectChangeEventData) {
            selectChangeEventData.option.id === 'loadBriefing'
                ? this.getBriefingForForeignProduct()
                : this.selectedView = selectChangeEventData.option
        },
        resetSelectionAndReload () {
            this.selectedView = {}
            // reload when the modal closes, as its content component may have changed some form values
            this.getBriefingForm()
        }
    },
    computed: {
        getSelectOptions () {
            const loadBriefing = {
                label: this.$tc('task.taskViews.briefing.getForeignBriefingOptionLabel'),
                id: 'loadBriefing'
            }
            const productDetails = {
                label: this.$tc('task.taskViews.briefing.showProductDetailsOptionLabel'),
                id: 'ProductDetails'
            }
            const productContents = {
                label: this.$tc('task.taskViews.briefing.showProductContentsOptionLabel'),
                id: 'ProductContents'
            }
            const productMembers = {
                label: this.$tc('task.taskViews.briefing.showProductMembersOptionLabel'),
                id: 'ProductMembers'
            }
            return this.isFinalBriefing
                ? [productDetails, productMembers, productContents]
                : [loadBriefing, productDetails, productMembers, productContents]
        },

        isTaskCompletable () {
            return this.formIsValid && this.formWasSavedSuccessfully
        }
    },
    watch: {
        isTaskCompletable (isCompletable) {
            this.$emit('task-completable-changed', {
                isCompletable: isCompletable
            })
        }
    },
    mounted () {
        this.isFinalBriefing = this.taskData.formKey === this.finalBriefingKey
        this.getProductState()
        this.getBriefingForm()
    }
}
</script>

<template>
    <div class="custom-task-content-container">
        <SmartSelect id="briefingTaskActions"
                     class="custom-task-select"
                     v-bind:is-disabled="!productState.id"
                     v-bind:options="getSelectOptions"
                     v-bind:option-label-specifiers="['label']"
                     v-bind:placeholder="$tc('task.taskViews.briefing.chooseAction')"
                     v-bind:clear-input-on-submit="true"
                     v-bind:sort-options="false"
                     v-bind:allow-input=false
                     @select-change="setSelection($event)">
        </SmartSelect>
        <EssentialSmartForm v-if="briefingForm"
                            id="briefingForm"
                            v-bind:hide-submit-button="true"
                            v-bind:form-config="briefingForm"
                            @form-change="saveForm($event)"
                            @form-submittable-change="setFormValid($event)"
                            :key="updateCounter">
        </EssentialSmartForm>
        <ModalContainer v-bind:is-expanded="!!selectedView.id"
                        v-bind:disable-screen="true"
                        v-bind:modal-title="task.productNumber"
                        v-bind:modal-subtitle="selectedView.label"
                        v-bind:modal-width-percent="90"
                        @modal-close="resetSelectionAndReload()">
            <component v-bind:is="selectedView.id"
                       v-bind:product-id="productState.id"
                       v-bind:product-state="productState">
            </component>
        </ModalContainer>
    </div>
</template>

<style lang="less">
.custom-task-content-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .custom-task-select {
        position: absolute;
        right: var(--container-spacing);
        z-index: var(--z-index-header-button);

        .c_smart-select-container {
            height: var(--smart-form-line-height);

            .c_smart-select {
                padding: 0 10px;
                border-bottom: 0;

                &::placeholder {
                    color: var(--color-text-mid);
                }
            }

            .c_smart-select-submit {
                margin-top: -4px;
            }
        }
    }
}
</style>
