/**
 * A reusable service for the exchange of turnaround time data with the backend.
 **/
import { axiosService } from '@/mixins/axiosService'
import { notificationHandler } from '@/mixins/notificationHandler'
import { defineComponent } from 'vue'

export const turnaroundTimesHandler = defineComponent({
    mixins: [
        axiosService,
        notificationHandler
    ],
    data () {
        return {
            basePathTaTs: 'turnaroundtimes'
        }
    },
    methods: {
        /**
         * Fetches turnaround time data for a given product and content.
         * @param {string} productId - The ID of the product.
         * @param {string} contentId - The ID of the content.
         *
         * @returns {Promise<Object|null>} The turnaround time data or null if not found.
         *
         * @example
         * const productId = '12345';
         * const contentId = '67890';
         * fetchTurnaroundTimeData(productId, contentId)
         *   .then(data => {
         *     console.log(data);
         *     // {
         *     //   calculatedTurnaroundTime: '2025-02-17',
         *     //   turnaroundTime: '2029-05-11',
         *     //   wasSetManually: false,
         *     //   pageCount: 105
         *     // }
         *   })
         *   .catch(error => {
         *     console.error('Error fetching turnaround time data:', error);
         *   });
         */
        async fetchTurnaroundTimeData (productId, contentId) {
            try {
                return await this.axiosGet(
                    `${this.basePathTaTs}/${productId}/${contentId}`)
            } catch (errorResponse) {
                if (errorResponse.status !== 404) {
                    this.addNotification({
                        type: 'error',
                        duration: 10000,
                        message: this.$tc('task.taskViews.turnaroundTimes.fetchError')
                    })
                    console.warn(`Turnaround Times could not be retrieved for productId=${productId}, contentId=${contentId}`, errorResponse)
                }
                return null
            }
        },

        /**
         * Fetches turnaround time data for a given product and content in dependance of the pageCount
         * @param {string} productId - The ID of the product.
         * @param {string} contentId - The ID of the content.
         * @param {string} pageCount - The number of pages for which the turnaround times should be calculated.
         *
         * @returns {Promise<Object|null>} The turnaround time data or null if not found.
         *
         * @example
         * const productId = '12345';
         * const contentId = '67890';
         * const pageCount = '90';
         * fetchTurnaroundTimeDataByPageCount(productId, contentId, pageCount)
         *   .then(data => {
         *     console.log(data);
         *     // {
         *     //   calculatedTurnaroundTime: '2025-02-17',
         *     //   turnaroundTime: '2029-05-11',
         *     //   wasSetManually: false,
         *     //   pageCount: 90
         *     // }
         *   })
         *   .catch(error => {
         *     console.error('Error fetching turnaround time data:', error);
         *   });
         */
        async fetchTurnaroundTimeDataByPageCount (productId, contentId, pageCount) {
            try {
                return await this.axiosGet(
                    `${this.basePathTaTs}/calculate/${productId}/${contentId}?pageCount=${pageCount}`)
            } catch (errorResponse) {
                if (errorResponse.status === 404) {
                    return null
                } else {
                    this.addNotification({
                        type: 'error',
                        duration: 10000,
                        message: this.$tc('task.taskViews.turnaroundTimes.fetchError')
                    })
                    throw errorResponse
                }
            }
        },

        /**
         * Updates the turnaround time data for a given product and content.
         *
         * @param {string} productId - The ID of the product.
         * @param {string} contentId - The ID of the content.
         * @param {Object} payload - The data to update the turnaround time with.
         *
         * @returns {Promise<Object>}  The updated turnaround time data.
         *
         * @example
         * const productId = '12345';
         * const contentId = '67890';
         * const payload = {
         *   calculatedTurnaroundTime: '2025-03-17',
         *   turnaroundTime: '2025-03-17',
         *   wasSetManually: false,
         *   pageCount: 30
         * };
         *
         * const updatedData = await updateTurnaroundTimeData(productId, contentId, payload);
         * console.log(updatedData);
         * // {
         * //   calculatedTurnaroundTime: '2025-03-17',
         * //   turnaroundTime: '2025-03-17',
         * //   wasSetManually: false,
         * //   pageCount: 30
         * // }
         */
        updateTurnaroundTimeData (productId, contentId, payload) {
            const pathParams = `${productId}/${contentId}`
            return this.axiosPut(
                `${this.basePathTaTs}/${pathParams}`,
                payload,
                this.$tc('task.taskViews.turnaroundTimes.updateError'))
        }
    }
})
