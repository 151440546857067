<script>
/**
 * VerticalSplitter renders two panes that can be resized by dragging a splitter between them.
 *
 * The left pane has a slot named 'left-pane' and the right pane has a slot named 'right-pane'.
 *
 * Props:
 * - leftMinPercent: The minimum percentage of the left pane's width.
 * - rightMinPercent: The minimum percentage of the right pane's width.
 * - defaultPercent: The default percentage of the left pane's width.
 *
 * Slots:
 * - left-pane: The content of the left pane.
 * - right-pane: The content of the right pane.
 *
 * Events:
 * - resize: Emitted when the splitter is moved.
 */
export default {
    name: 'VerticalSplitter',
    // @resize: Event emitted when the splitter is moved
    emits: ['resize'],
    props: {
        leftMinPercent: {
            type: Number,
            default: 25
        },
        rightMinPercent: {
            type: Number,
            default: 25
        },
        defaultPercent: {
            type: Number,
            default: 50
        }
    },
    data () {
        return {
            active: false,
            percent: this.defaultPercent,
            hasMoved: false
        }
    },
    computed: {
        leftPaneStyle () {
            return { width: this.percent + '%' }
        },
        rightPaneStyle () {
            return { width: 100 - this.percent + '%' }
        },
        userSelect () {
            return this.active ? 'none' : ''
        },
        cursor () {
            return this.active ? 'ew-resize' : ''
        }
    },
    created () {
        this.percent = this.defaultPercent
    },
    methods: {
        onClick () {
            if (!this.hasMoved) {
                this.percent = this.defaultPercent
                this.$emit('resize')
            }
        },
        onDown () {
            this.active = true
            this.hasMoved = false
        },
        onUp () {
            this.active = false
        },
        onMove (e) {
            let percent = 0
            if (this.active) {
                const divAroundThisComponent = e.currentTarget
                const mousePointerOffsetX = e.pageX
                const componentOffsetXOnScreen = divAroundThisComponent.getBoundingClientRect().left
                const relativeOffsetX = mousePointerOffsetX - componentOffsetXOnScreen
                const totalWith = divAroundThisComponent.offsetWidth
                percent = (relativeOffsetX / totalWith * 100).toFixed(2)
                if (percent < this.leftMinPercent) {
                    this.percent = this.leftMinPercent
                } else if (percent > 100 - this.rightMinPercent) {
                    this.percent = 100 - this.rightMinPercent
                } else {
                    this.percent = percent
                }
                this.$emit('resize')
                this.hasMoved = true
            }
        },
        onMouseMove (e) {
            if (e.buttons === 0 || e.which === 0) {
                this.active = false
            }
            this.onMove(e)
        }
    }
}
</script>

<template>
  <div
    :style="{ cursor, userSelect }"
    class="vertical-splitter"
    @mouseup="onUp"
    @mousemove="onMouseMove"
    @touchmove="onMove"
    @touchend="onUp"
  >
    <div :style="leftPaneStyle" class="left-pane splitter-pane">
      <slot name="left-pane"></slot>
    </div>
    <div
      class="splitter"
      :class="{ active }"
      @mousedown="onDown"
      @click="onClick"
      @touchstart.prevent="onDown"
    ></div>
    <div :style="rightPaneStyle" class="right-pane splitter-pane">
      <slot name="right-pane"></slot>
    </div>
  </div>
</template>

<style lang="less">
.vertical-splitter {
  height: inherit;
  display: flex;
  flex-direction: row;

  .splitter-pane {
    height: inherit;
    overflow-y: auto;
  }

  .splitter {
    background-color: #9e9e9e;
    width: 5px;
    cursor: ew-resize;
  }
}
</style>
