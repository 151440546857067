// disable rule that restricts the use of Date.parse(...) and new Date(...)
/* eslint-disable no-restricted-properties */
/* eslint-disable no-restricted-syntax */

// cf. https://tc39.es/ecma402/#datetimeformat-objects
const shortOptions: Intl.DateTimeFormatOptions = {
    timeZone: 'Europe/Berlin',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
}
const longOptions: Intl.DateTimeFormatOptions = {
    timeZone: 'Europe/Berlin',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
}
const longOptionsWithTimeZone: Intl.DateTimeFormatOptions = {
    timeZone: 'Europe/Berlin',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    timeZoneName: 'shortGeneric'
}
const timeOptions: Intl.DateTimeFormatOptions = {
    timeZone: 'Europe/Berlin',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
}
const timeOptionsWithTimeZone: Intl.DateTimeFormatOptions = {
    timeZone: 'Europe/Berlin',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    timeZoneName: 'shortGeneric'
}

// A bit of a hack using "Swedish" as a locale, as "ISO-8601" is not a built-in formatter.
// But it's much shorter using this and a bit less hacky than our previous home-made calculation :)
// Cf. https://stackoverflow.com/a/58633686
const shortFormatterISO : Intl.DateTimeFormat = new Intl.DateTimeFormat('sv', shortOptions)

const shortFormatterEn : Intl.DateTimeFormat = new Intl.DateTimeFormat('en', shortOptions)
const shortFormatterDe : Intl.DateTimeFormat = new Intl.DateTimeFormat('de', shortOptions)

const longFormatterEn : Intl.DateTimeFormat = new Intl.DateTimeFormat('en', longOptions)
const longFormatterDe : Intl.DateTimeFormat = new Intl.DateTimeFormat('de', longOptions)

const longFormatterEnWithTimeZone : Intl.DateTimeFormat = new Intl.DateTimeFormat('en', longOptionsWithTimeZone)
const longFormatterDeWithTimeZone : Intl.DateTimeFormat = new Intl.DateTimeFormat('de', longOptionsWithTimeZone)

const timeFormatterEn : Intl.DateTimeFormat = new Intl.DateTimeFormat('en', timeOptions)
const timeFormatterDe : Intl.DateTimeFormat = new Intl.DateTimeFormat('de', timeOptions)

const timeFormatterEnWithTimeZone : Intl.DateTimeFormat = new Intl.DateTimeFormat('en', timeOptionsWithTimeZone)
const timeFormatterDeWithTimeZone : Intl.DateTimeFormat = new Intl.DateTimeFormat('de', timeOptionsWithTimeZone)

const milliSecondsInADay: number = 1000 * 3600 * 24

export const dateTimeHelper = {
    methods: {

        toBerlinDateAsISOString (dateAsString: string): string | null {
            const date = this.tryToParseToDate(dateAsString)
            if (date == null) {
                return null
            }
            return shortFormatterISO.format(date)
        },

        toDateAndTimeAsISOString (dateAsString: string): string | null {
            try {
                return new Date(dateAsString).toISOString()
            } catch (error) {
                console.error(error)
                return null
            }
        },

        calculateDaysLeftToDeadlineRoundedDown (dateAsString: string): null | number {
            const deadline = this.tryToParseToDate(dateAsString)
            if (!deadline) {
                return null
            }
            return this._calculateDaysLeftToDeadlineRoundedDown(this.getNowDate(), deadline)
        },

        _calculateDaysLeftToDeadlineRoundedDown (nowDate: Date, deadline: Date): number {
            // The date from the back end comes with a time zone.
            // For non-Berlin timezones, the UI showed a lot of decimals.
            // So, let's just round down to full days.
            const milliSecondsDifference = deadline.getTime() - nowDate.getTime()
            const daysLeft = milliSecondsDifference / milliSecondsInADay
            return Math.floor(daysLeft)
        },

        tryToParseToDate (dateAsString: string | null): Date | null {
            if (dateAsString == null) {
                return null
            }
            try {
                const d : Date = new Date(dateAsString)
                if (this.isInvalidDate(d)) {
                    console.debug(`Invalid date: ${dateAsString}`)
                    return null
                }
                return d
            } catch (error) {
                console.debug(error)
                return null
            }
        },

        isInvalidDate (date: any) {
            return !(date instanceof Date) || Number.isNaN(date.getTime())
        },

        // shallow wrapper so that we can make sure there are no "homemade" Date hacks anywhere in the rest of the software
        getNowDate () {
            // internally, it's a UTC-based date already, no need to make adjustments
            return new Date()
        },

        getFormattedTime (rawDate: any, locale: string) {
            const timeZoneIsBerlin = Intl.DateTimeFormat().resolvedOptions().timeZone === 'Europe/Berlin'
            return this._getFormattedTime(rawDate, locale, timeZoneIsBerlin)
        },

        _getFormattedTime (rawDate: any, locale: string, timeZoneIsBerlin: boolean) {
            const date = this.tryToParseToDate(rawDate)
            if (date == null) {
                return null
            }
            if (timeZoneIsBerlin) {
                if (locale === 'en') {
                    // en: 01:14:15 PM
                    return timeFormatterEn.format(date)
                } else {
                    // de: 13:14:15
                    return timeFormatterDe.format(date)
                }
            } else {
                if (locale === 'en') {
                    // en: 01:14:15 PM Germany Time
                    return timeFormatterEnWithTimeZone.format(date)
                } else {
                    // de: 13:14:15 MEZ
                    return timeFormatterDeWithTimeZone.format(date)
                }
            }
        },

        getFormattedShortDate (rawDate: any, locale: string) {
            const date = this.tryToParseToDate(rawDate)
            if (date == null) {
                return null
            }
            if (locale === 'en') {
                // en: 01/15/2025
                return shortFormatterEn.format(date)
            } else {
                // de: 15.01.2025
                return shortFormatterDe.format(date)
            }
        },

        getFormattedLongDate (rawDate: any, locale: string) {
            const timeZoneIsBerlin = Intl.DateTimeFormat().resolvedOptions().timeZone === 'Europe/Berlin'
            return this._getFormattedLongDate(rawDate, locale, timeZoneIsBerlin)
        },

        _getFormattedLongDate (rawDate: any, locale: string, timeZoneIsBerlin: boolean) {
            const date = this.tryToParseToDate(rawDate)
            if (date == null) {
                return null
            }
            if (timeZoneIsBerlin) {
                if (locale === 'en') {
                    // en: 11/20/2024, 12:42:10 PM
                    return longFormatterEn.format(date)
                } else {
                    // de: 20.11.2024, 12:42:12
                    return longFormatterDe.format(date)
                }
            } else {
                if (locale === 'en') {
                    // en: 01/20/2025, 12:54:23 AM Germany Time
                    return longFormatterEnWithTimeZone.format(date)
                } else {
                    // de: 20.01.2025, 00:54:23 MEZ
                    return longFormatterDeWithTimeZone.format(date)
                }
            }
        },

        isDateRegex (dateAsString: string): boolean {
            if (this.tryToParseToDate(dateAsString) == null) {
                return false
            }
            const regex = /^\d{4}-\d{2}-\d{2}$/
            return !!dateAsString.match(regex)
        }
    }
}
