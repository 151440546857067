<script>
/**
 * Column Configuration Modal
 *
 * - Configurable modal for table columns with drag and drop functionality.
 * - Emits events for modal close and column updates.
 *
 * - Properties:
 *   - isExpanded: boolean indicating if the modal is expanded
 *   - columns: array of column objects with key and visibility status
 *   - tableConfig: array of table configuration objects with column info
 *   - settingsKey: string representing the key for user's column config settings
**/

import Button from '@/components/Button.vue'
import ModalContainer from '@/components/ModalContainer.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { notificationHandler } from '@/mixins/notificationHandler'
import { downloadHandler } from '@/mixins/downloadHandler'
import { debounceHandler } from '@/mixins/debounceHandler'
import { copyHandler } from '@/mixins/copyHandler'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'
import Checkbox from '@/components/Checkbox.vue'

export default {
    mixins: [
        axiosService,
        userHandler,
        notificationHandler,
        downloadHandler,
        debounceHandler,
        copyHandler,
        dateTimeHelper
    ],
    components: { Checkbox, ModalContainer, Button },
    props: {
        isExpanded: {
            type: Boolean,
            required: true
        },
        columns: {
            type: Array,
            required: true
        },
        tableConfig: {
            type: Array,
            required: true
        },
        settingsKey: {
            type: String,
            required: true
        }
    },
    data () {
        return {
            isDragging: false,
            currentIndex: null,
            dropIndex: null,
            isDropTarget: false,
            localColumns: []
        }
    },
    methods: {
        closeModal () {
            this.$emit('close-modal')
        },
        dragStart (event, index) {
            this.isDragging = true
            this.currentIndex = index
        },
        drop (event, index) {
            const fromIndex = this.currentIndex
            this.isDragging = false
            this.currentIndex = null
            this.dropIndex = index
            this.moveColumn(fromIndex, this.dropIndex)
            this.isDropTarget = false
        },
        dragOver (event, index) {
            this.dropIndex = index
            this.isDropTarget = true
        },
        dragLeave () {
            this.isDropTarget = false
        },
        moveColumn (fromIndex, toIndex) {
            const column = this.localColumns.splice(fromIndex, 1)[0]
            this.localColumns.splice(toIndex, 0, column)
        },
        getColumnLabel (key) {
            const column = this.tableConfig.find(c => c.key === key)
            return column?.label || key
        },
        async saveColumnConfig () {
            try {
                await this.updateUserSettingsParameter(this.settingsKey, this.localColumns)
                this.$emit('close-modal')
            } catch (error) {
                console.error('Error saving column configuration:', error)
            }
        },
        setDefaultColumnConfig () {
            this.localColumns = this.tableConfig.map(column => ({ key: column.key, visible: column.visible })).filter(column => this.tableConfig.find(c => c.key === column.key)?.width > 0)
            this.saveColumnConfig()
            this.$emit('columns-updated', this.localColumns)
        },
        updateColumnVisibility (event, column) {
            column.visible = event.value
            this.$emit('columns-updated', this.localColumns)
        }
    },
    created () {
        this.localColumns = this.columns.filter(column => this.tableConfig.find(c => c.key === column.key)?.width > 0)
        this.$emit('columns-updated', this.localColumns)
    }
}
</script>

<template>
    <ModalContainer
        class="c_column-config"
        :isExpanded="isExpanded"
        @modal-close="closeModal"
        :fit-content="true"
        :modal-title="$t('table.tableManipulationModal.title')"
    >
        <template v-slot>
            <div class="column-config-container">
                <div class="column-config-explanation">
                    {{ $t('table.tableManipulationModal.explanation') }}
                </div>
                <ul class="drag-list">
                    <li
                        v-for="(column, index) in localColumns"
                        :key="column.key"
                        draggable="true"
                        @dragstart="dragStart($event, index)"
                        @dragover.prevent
                        @drop="drop($event, index)"
                        @dragover="dragOver($event, index)"
                        @dragleave="dragLeave($event, index)"
                        :class="{ 'dragging': isDragging && currentIndex === index, 'drop-target': isDropTarget && dropIndex === index }"
                    >
                        <div class="c_column-config-item" v-if="this.tableConfig.find(c => c.key === column.key).width > 0">
                            <Checkbox
                                :defaultValue="column.visible"
                                class="generals-input-container"
                                @input-change="updateColumnVisibility($event, column)"
                            />
                            <span :style="{ textTransform: 'capitalize', marginLeft: '10px' }">{{ getColumnLabel(column.key) }}</span>
                        </div>
                    </li>
                </ul>
                <div class="c_column-config-buttons">
                    <Button
                        button-type="submit"
                        class="c_button"
                        @button-submit="saveColumnConfig"
                    >
                        {{ $t('table.tableManipulationModal.save') }}
                    </Button>
                    <Button
                        class="c_button"
                        @click="setDefaultColumnConfig"
                    >
                        {{ $t('table.tableManipulationModal.default') }}
                    </Button>
                </div>
            </div>
        </template>
    </ModalContainer>
</template>

<style>
.c_column-config {
    .column-config-container {
        max-width: 500px;

        & > .column-config-explanation {
            padding: 10px;
            background-color: #f7f7f7;
            border: 1px solid #ddd;
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            font-size: 14px;
            color: #666;
            margin-bottom: 10px;
            text-align: center;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;

            li {
                padding: 0.5em 0;
                cursor: move;
                background-color: #E8FAFF;

                &:nth-child(odd) {
                    background-color: #F5FDFF;
                }

                &.drop-target {
                    border-top: 4px solid #ccc;
                    margin-top: 5px;
                }

                input[type="checkbox"] {
                    cursor: pointer;
                }

                .c_column-config-item {
                    display: flex;
                    align-items: center;
                    padding-left: 10px;

                    span {
                        margin-right: auto;
                    }

                    .generals-input-container {
                        width:auto;
                        height: auto;
                        padding-top: 0;
                    }
                }
            }
        }

        .c_column-config-buttons {
            margin-top: 2em;

            button {
                margin-right: 10px;
            }
        }
    }
}
</style>
