<script>
/**
 * Product services sub view | Sub view of a Product
 *
 * @property { Boolean } isProcess
 * @property { Number } executionCount
 **/
import IconButton from '@/components/IconButton.vue'
import Table from '@/components/Table.vue'
import Icon from '@/components/Icon.vue'
import DataRow from '@/components/DataRow.vue'
import LoadingScreen from '@/components/LoadingScreen.vue'
import ModalContainer from '@/components/ModalContainer.vue'
import Accordion from '@/components/Accordion.vue'
import SortableList from '@/components/SortableList.vue'
import { axiosService } from '@/mixins/axiosService'
import { generatePdfPreviewHandler } from '@/mixins/productServices/generatePdfPreviewHandler'
import { userHandler, userParameterKeyServiceList } from '@/mixins/userHandler'
import { eventBus } from '@/mixins/eventBus'

export default {
    name: 'ProductServices',
    mixins: [
        axiosService,
        userHandler,
        generatePdfPreviewHandler
    ],
    components: {
        IconButton,
        Table,
        Icon,
        DataRow,
        LoadingScreen,
        ModalContainer,
        Accordion,
        SortableList
    },
    // @service-started: Event emitted after the backend confirms the successful start of a service
    emits: ['service-started'],
    props: {
        productId: [String, Number],
        productNumber: [String, Number]
    },
    data () {
        return {
            services: [],
            serviceConfigs: {
                PDF_PREVIEW: {
                    props: {
                        isGeneratePreviewPdfExpanded: false,
                        previewPdfConflicts: null,
                        previewPdfsList: null,
                        showLoadingScreen: false
                    },
                    action: this.getContentPdfs
                }
            },
            knownInfoMessageKeys: [
                'generatePdfInfo',
                'commissioningInfo6',
                'commissioningInfo12',
                'commissioningOverdue',
                'commissioningImpossible',
                'contractOverdue',
                'contractImpossible',
                'executePriorToDevelopment',
                'createXmlData'
            ]
        }
    },
    methods: {
        getServicesList () {
            this.axiosGet(
                `services/list/${this.productId}`,
                this.$tc('product.services.getServicesError'))
                .then(servicesList => {
                    servicesList.forEach(service => {
                        service.flipped = false
                    })
                    this.services = servicesList
                })
                .catch(() => {})
        },
        onChangeEvent (event) {
            function updateServiceList (eventName, eventProductId, ctx) {
                console.debug('updateServiceList: Event: ' + eventName, eventProductId, ctx.productId)

                // fetch the current service list only if the event applies to the current product
                // Note: We could constraint further to services supported in de.cornelsen.corflow.service.cockpit.CockpitModule only,
                //       but as fetching the service list is quite fast we currently don't
                if (eventProductId === ctx.productId) {
                    if (eventName === 'task-completed') {
                        // wait a bit, as it may take a while until the process is also finished after task completion
                        // and the status is returned correctly
                        setTimeout(() => {
                            console.debug('Update Service Tiles for productId:', ctx.productId)
                            ctx.getServicesList()
                        }, 5000)
                    } else {
                        ctx.getServicesList()
                    }
                }
            }
            updateServiceList(event?.eventName, event?.productId, this)
        },
        getServiceTitle (service) {
            return this.$tc(`product.services.titles.${service.id}`)
        },

        getServiceDescription (service) {
            return this.$tc(`product.services.descriptions.${service.id}`)
        },

        getServiceInfoMessage (service) {
            return this.knownInfoMessageKeys.includes(service.info)
                ? this.$tc(`product.services.infoMessages.${service.info}`)
                : null
        },

        setIsListView (isListView) {
            this.updateUserSettingsParameter(userParameterKeyServiceList, { viewMode: isListView ? 'list' : 'cards' })
        },

        toggleViewType () {
            this.setIsListView(!this.isListView)
        },

        flipService (service) {
            service.flipped = !service.flipped
        },

        executeService (service) {
            if (service.locked) {
                return
            }
            if (service.isProcess) {
                // Lock service to prevent unwanted multiple activation
                service.locked = true
                this.axiosPost(
                    `services/${this.productId}/start/${service.id}`,
                    null,
                    this.$tc('product.services.executeServiceError'),
                    {
                        standard: this.$t('product.services.executeServiceSuccess.standard', [this.getServiceTitle(service)]),
                        short: this.$tc('product.services.executeServiceSuccess.short')
                    })
                    .then(updatedService => {
                        service.locked = updatedService.locked
                        service.running = updatedService.running
                        service.info = updatedService.info
                        service.executionCount = updatedService.executionCount
                        this.$emit('service-started')
                    })
                    .catch(() => {})
            } else {
                this.serviceConfigs[service.id].action()
            }
        },

        getContentPdfs () {
            this.collectContentPdfs(this.productId)
                .then(pdfPreviewResponse => {
                    this.serviceConfigs.PDF_PREVIEW.props.previewPdfsList = pdfPreviewResponse.previewParts
                    this.serviceConfigs.PDF_PREVIEW.props.previewPdfConflicts = pdfPreviewResponse.issuesPerProductContent
                    this.setGeneratePreviewPdfExpanded(true)
                })
                .catch(() => {})
        },

        generatePdf (sortedPdfList) {
            this.serviceConfigs.PDF_PREVIEW.props.showLoadingScreen = true
            this.generatePreviewPdf(sortedPdfList, this.productId, this.productNumber)
                .then(() => {
                    this.serviceConfigs.PDF_PREVIEW.props.showLoadingScreen = false
                    this.setGeneratePreviewPdfExpanded(false)
                })
                .catch(() => {
                    this.serviceConfigs.PDF_PREVIEW.props.showLoadingScreen = false
                })
        },

        setGeneratePreviewPdfExpanded (expanded) {
            if (!expanded) {
                this.serviceConfigs.PDF_PREVIEW.props.previewPdfConflicts = null
                this.serviceConfigs.PDF_PREVIEW.props.previewPdfsList = null
            }
            this.serviceConfigs.PDF_PREVIEW.props.isGeneratePreviewPdfExpanded = expanded
        }
    },
    computed: {
        getSortedServices () {
            return this.services
        },

        isListView () {
            const serviceListParameter = this.getUserSettingsParameter(userParameterKeyServiceList)
            return serviceListParameter?.viewMode === 'list'
        },

        getTableConfig () {
            return [{
                key: 'running',
                label: this.$tc('product.services.table.status'),
                filterable: false,
                sortable: true,
                alignment: 'left',
                width: 25
            }, {
                key: 'title',
                label: this.$tc('product.services.table.title'),
                filterable: false,
                sortable: true,
                alignment: 'left',
                width: 65
            }, {
                key: 'action',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: 10
            }]
        },

        pdfPreviewHasConflicts () {
            const conflicts = this.serviceConfigs.PDF_PREVIEW.props.previewPdfConflicts
            return conflicts && Object.keys(conflicts).length > 0
        },

        getPdfPreviewConflicts () {
            const items = []
            Object.entries(this.serviceConfigs.PDF_PREVIEW.props.previewPdfConflicts).forEach(([contentName, issues]) => {
                items.push({
                    label: contentName,
                    value: issues
                        .map(issue => `- ${this.$tc(`generals.errorCodes.${issue.errorCode}`)} ${issue.parameters[0] ? `(${issue.parameters.join(', ')})` : ''}`)
                        .join('<br />')
                })
            })
            return items
        },

        previewHasPdfs () {
            const pdfList = this.serviceConfigs.PDF_PREVIEW.props.previewPdfsList
            return pdfList && pdfList.length > 0
        }
    },
    created () {
        // An update of the state of a service tile only happens if behind the service is a process
        // and only if this process and its corresponding tasks are started or completed.
        //
        // Thus, we here just listen for these events.
        eventBus.$on('task-created', this.onChangeEvent)
        eventBus.$on('task-completed', this.onChangeEvent)
    },
    mounted () {
        this.getServicesList()

        // HACK
        // wait a bit, as it may take a while until the process is also finished after task completion and
        // the state updates are visible. Then fetch the data again
        setTimeout(() => {
            this.getServicesList()
        }, 5000)
    },
    beforeUnmount () {
        // de-register all listening methods
        //
        // Attention: Do not call 'eventBus.$off('task-created')' without callback-function
        //            as this also would de-register listeners in other views!
        eventBus.$off('task-created', this.onChangeEvent)
        eventBus.$off('task-completed', this.onChangeEvent)
    }
}
</script>

<template>
    <div class="generals-container">
        <LoadingScreen v-if="serviceConfigs.PDF_PREVIEW.props.showLoadingScreen"
                       v-bind:display-text="$tc('product.services.generatePdfPreview.generatingPreviewPdf')">
        </LoadingScreen>
        <div class="product-services-header">
            <IconButton v-bind:font-size="24"
                        v-bind:is-disabled="services.length === 0"
                        v-bind:icon-class="isListView ? 'fas fa-grip-vertical' : 'fas fa-list'"
                        v-bind:tooltip="isListView
                            ? $tc('product.services.toggleToIndexCardView')
                            : $tc('product.services.toggleToListView')"
                        @button-submit="toggleViewType()">
            </IconButton>
        </div>
        <div class="product-services-container">
            <transition-group name="show">
                <Table v-if="isListView || services.length === 0"
                       key="table"
                       table-id="productServices"
                       class="product-services-table"
                       v-bind:table-config="getTableConfig"
                       v-bind:table-data="services"
                       v-bind:table-empty-message="$tc('product.services.table.tableEmpty')"
                       v-bind:read-only=true
                       v-bind:expandable-rows="true"
                       default-sorting-key="title">
                    <template #cell(running)="data">
                        <Icon v-if="data.row.isProcess"
                              icon-class="fas fa-circle"
                              v-bind:icon-type="data.row.running ? 'success' : 'inactive'"
                              v-bind:icon-glow="data.row.running"
                              v-bind:tooltip="data.row.running
                                  ? $tc('product.services.serviceIsActive')
                                  : $tc('product.services.serviceIsInactive')">
                        </Icon>
                    </template>
                    <template #cell(title)="data">
                        <span>{{getServiceTitle(data.row)}}</span>
                    </template>
                    <template #cell(action)="data">
                        <IconButton icon-class="fas fa-play-circle"
                                    v-bind:tooltip="$tc('product.services.executeService')"
                                    v-bind:is-disabled="data.row.locked"
                                    @button-submit="executeService(data.row)">
                        </IconButton>
                    </template>
                    <template #expandable-content="data">
                        <div class="product-services_table-expanded-content">
                            <DataRow v-if="data.row.info"
                                     v-bind:label="$tc('product.services.hint')"
                                     v-bind:value="getServiceInfoMessage(data.row)"
                                     v-bind:label-width-percentage="30"
                                     v-bind:message-type="'info'">
                            </DataRow>
                            <DataRow v-bind:label="$tc('product.services.description')"
                                     v-bind:value="getServiceDescription(data.row)"
                                     v-bind:label-width-percentage="30">
                            </DataRow>
                            <DataRow v-if="data.row.isProcess"
                                     v-bind:label="$tc('product.services.execution')"
                                     v-bind:value="data.row.executionCount === 0
                                         ? $tc('product.services.serviceNotExecuted')
                                         : $t('product.services.serviceExecutedXTimes', [data.row.executionCount])"
                                     v-bind:label-width-percentage="30">
                            </DataRow>
                        </div>
                    </template>
                </Table>
                <div v-else
                     v-for="service in services"
                     v-bind:key="service.id"
                     class="product-service-card-container">
                    <div class="product-service-card"
                         v-bind:class="{
                             'm--disabled': service.locked,
                             'm--flipped': service.flipped
                         }"
                         v-on:click="executeService(service)">
                        <template v-if="!service.flipped">
                            <span v-if="service.isProcess"
                                  class="product-service-card-state fas fa-circle"
                                  v-bind:class="{'m--active': service.running}"
                                  v-on:hover.stop
                                  v-bind:title="service.running
                                      ? $tc('product.services.serviceIsActive')
                                      : $tc('product.services.serviceIsInactive')"
                                  v-on:click.stop>
                            </span>
                            <span class="product-service-card-flip fas fa-info"
                                  v-on:hover.stop
                                  v-on:click.stop="flipService(service)">
                            </span>
                            <span class="product-service-card-title">{{getServiceTitle(service)}}</span>
                            <span class="product-service-card-info"
                                  v-bind:title="getServiceInfoMessage(service)">{{getServiceInfoMessage(service)}}</span>
                        </template>
                        <template v-else>
                            <span class="product-service-card-flip fas fa-arrow-left"
                                  v-bind:class="{'m--flipped': service.flipped}"
                                  v-on:hover.stop
                                  v-on:click.stop="flipService(service)">
                            </span>
                            <div class="product-service-card-description"
                                 v-bind:class="{'m--process': service.isProcess}">
                                <div v-bind:title="getServiceDescription(service)">{{getServiceDescription(service)}}</div>
                                <div v-if="service.isProcess">
                                    <span v-if="service.executionCount === 0">{{$tc('product.services.serviceNotExecuted')}}</span>
                                    <span v-else>{{$t('product.services.serviceExecutedXTimes', [service.executionCount])}}</span>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </transition-group>
        </div>
        <ModalContainer v-bind:is-expanded="serviceConfigs.PDF_PREVIEW.props.isGeneratePreviewPdfExpanded"
                        v-bind:modal-title="$tc('product.services.generatePdfPreview.modalTitle')"
                        v-bind:modal-width-percent="50"
                        @modal-close="setGeneratePreviewPdfExpanded(false)">
            <template v-slot>
                <Accordion v-if="pdfPreviewHasConflicts"
                           class="product-services-pdf-conflicts-container"
                           v-bind:title="$tc('product.services.generatePdfPreview.pdfConflictWarning')"
                           color-code="#F7A600"
                           v-bind:items="getPdfPreviewConflicts">
                </Accordion>
                <SortableList v-if="previewHasPdfs"
                              v-bind:items="serviceConfigs.PDF_PREVIEW.props.previewPdfsList"
                              class="product-services-pdf-list-container"
                              v-bind:class="{'m--with-conflicts': pdfPreviewHasConflicts}"
                              title-key="productContentName"
                              subtitle-key="fileName"
                              v-bind:enable-remove="true"
                              v-bind:submit-tooltip="$tc('product.services.generatePdfPreview.startPdfGeneration')"
                              @list-submit="generatePdf($event.list)">
                </SortableList>
            </template>
        </ModalContainer>
    </div>
</template>

<style lang="less">
.product-services-header {
    position: relative;
    width: 100%;
    height: var(--accordion-head-height);
    margin-bottom: 11px;
}

.product-services-container {
    position: relative;
    width: 100%;
    height: calc(100% - var(--accordion-head-height));
    overflow-y: auto;

    .product-services-table {
        height: calc(100% - var(--container-spacing));
    }

    .product-services_table-expanded-content {
        width: 100%;
        height: auto;
    }

    .product-service-card-container {
        position: relative;
        float: left;
        width: var(--product-services-card-width);
        height: var(--product-services-card-height);
        padding: 10px;
        perspective: 1000px;

        .product-service-card {
            position: relative;
            width: 100%;
            height: 100%;
            border: 2px solid var(--color-border-light);
            border-radius: 15px;
            text-align: center;
            font-size: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 15px;
            transition: background-color 0.7s ease, border-color 0.7s ease, box-shadow 0.7s ease, margin 0.7s ease, transform 0.4s;

            &.m--disabled {
                background-color: var(--color-background-disabled);

                &:hover {
                    cursor: not-allowed;
                    background-color: var(--color-background-dark);
                    border-color: var(--color-border-dark);
                    box-shadow: none;
                }
            }

            &.m--flipped {
                transform: rotateY(180deg);
            }

            &:hover {
                cursor: pointer;
                margin-top: -5px;
                background-color: var(--color-background-highlighted);
                color: var(--color-text-bright);
                border-color: var(--color-border-active);
                box-shadow: 0 5px 10px 0 var(--color-border-dark);
            }

            .product-service-card-state {
                position: absolute;
                top: 10px;
                left: 12px;
                font-size: 11px;
                color: var(--color-text-disabled);

                &.m--active {
                    color: var(--color-success);
                    text-shadow: 0 0 12px var(--color-success);
                }
            }

            .product-service-card-flip {
                position: absolute;
                top: -2px;
                right: -2px;
                width: 35px;
                height: 30px;
                font-size: 13px;
                border-top-right-radius: 15px;
                border-bottom-left-radius: 10px;
                background-color: var(--color-background-dark);
                color: var(--color-text-bright);
                text-align: center;
                padding: 8px 3px 0 0;
                border-left: 2px solid transparent;
                border-bottom: 2px solid transparent;

                &.m--flipped {
                    transform: rotateY(180deg);
                    right: unset;
                    left: -2px;
                }

                &:hover {
                    cursor: pointer;
                    background-color: var(--color-info);
                    border-color: var(--color-border-almost-white);
                }
            }

            .product-service-card-title {
                font-family: "Source Sans Pro Bold", sans-serif;
            }

            .product-service-card-info {
                position: absolute;
                width: 100%;
                padding: 0 15px;
                font-size: 13px;
                bottom: 10px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;

            }

            .product-service-card-description {
                font-size: 14px;
                transform: scale(-1, 1);

                &.m--process {
                    div:first-of-type {
                        -webkit-line-clamp: 5;
                    }
                }

                div:first-of-type {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 6;
                    -webkit-box-orient: vertical;
                }
            }
        }
    }
}

.product-services-pdf-conflicts-container {
    .c_accordion-header {
        border: 1px solid var(--color-warning);
        border-left: 6px solid var(--color-warning);
        background-color: var(--color-background-default);

        &:hover {
            background-color: unset;
        }
    }

    .c_data-row-label {
        font-family: "Source Sans Pro Bold", sans-serif;
    }
}

.product-services-pdf-list-container {
    height: 100%;

    &.m--with-conflicts {
        height: calc(100% - var(--accordion-head-height));
    }
}
</style>
