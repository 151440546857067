<script>
/**
 * Custom Task view for Satzprozess - 'Finales Gesamt-PDF erstellen'
 *
 * Simple read-only form
 *
 * @property {String} formJson
 **/
import EssentialSmartForm from '@/components/EssentialSmartForm.vue'
import { smartFormHelper } from '@/mixins/smartFormHelper'
import { productLinkHandler } from '@/mixins/productLinkHandler'
import EssentialSmartFormService from '@/services/EssentialSmartFormService'
import TextInput from '@/components/TextInput.vue'
import FilesAndLinks from '@/services/FilesAndLinks'
import EntryList from '@/components/EntryList.vue'

export default {
    name: 'CustomTaskFinalPdf',
    mixins: [
        smartFormHelper,
        productLinkHandler
    ],
    components: {
        EntryList,
        TextInput,
        EssentialSmartForm
    },
    props: {
        taskData: Object
    },
    // @task-completable-changed: Event emitted when the task-completable state changed
    emits: ['task-completable-changed'],
    data () {
        return {
            task: this.taskData,
            customForm: null,
            formType: this.taskData.formKey,
            formExists: false,
            formIsValid: false,
            formWasSavedSuccessfully: true,
            essentialSmartFormService: null,
            listUpdateCounter: 0,
            config: {
                customFormKey: 'FINAL_PDF',
                getErrorMessage: 'task.taskViews.genericCustomTask.getFormError',
                updateErrorMessage: 'task.taskViews.genericCustomTask.updateFormError'
            },
            states: {
                finalPdf: {
                    showSpinner: false,
                    isUploading: false
                }
            }
        }
    },
    methods: {
        async getCustomForm () {
            // Is called every time data comes from the back end
            function setupForm (appContext, form, formData) {
                appContext.applyValuesToFormConfigurationAndReturnChanges(null, formData.values, true, form, null, this)
                appContext.formExists = true
                appContext.customForm = form

                appContext.essentialSmartFormService = new EssentialSmartFormService(appContext.customForm, appContext.$global.localization.locale)
                appContext.addProductNumberNameToHeadline()
            }

            const form = this.loadFormConfiguration(this.config.customFormKey)

            try {
                const formData = await this.axiosGet(`forms/search/${this.formType}/${this.task.productNumber}`)
                // search found something
                setupForm(this, form, formData)
            } catch (errorResponse) {
                if (errorResponse.status === 422) {
                    // search didn't find anything. Create a new form.
                    try {
                        const formData = await this.saveForm({
                            formConfig: form,
                            changes: {}
                        })
                        setupForm(this, form, formData)
                    } catch (error) {
                        console.error(error)
                    }
                } else {
                    this.addNotification({
                        type: 'error',
                        duration: 10000,
                        message: this.$tc(this.config.getErrorMessage)
                    })
                }
            }
        },

        setFormValid (isValid) {
            this.formIsValid = isValid
        },

        saveForm (formSubmitEventData) {
            return this.saveFormChanges(this.customForm, formSubmitEventData.changes, () => {}, false)
        },

        saveFormChanges (formConfig, changes, successCallback, doForceRender = true) {
            return this.saveSmartForm(
                this.formType,
                this.task.productNumber,
                formConfig,
                changes,
                this.formExists,
                this.$tc(this.config.updateErrorMessage))
                .then(formData => {
                    successCallback()
                    this.formWasSavedSuccessfully = true
                    if (doForceRender) this.forceListRender()
                    return Promise.resolve(formData)
                })
                .catch(() => {
                    this.formWasSavedSuccessfully = false
                    return Promise.reject(new Error())
                })
        },

        addProductNumberNameToHeadline () {
            const headline = this.essentialSmartFormService?.getTranslatedObjectProperty(this.customForm, 'headline')
            this.setFormHeadline(this.customForm, `${headline}: ${this.task.productNumber}`)
        },

        async loadLinks () {
            function setDynamicLists (appContext, data, fieldId) {
                appContext.setFormFieldValue(appContext.customForm, fieldId, data)
                appContext.updateFormIsValid()
            }

            async function setUploadData (appContext, productId, fieldId) {
                const links = await appContext.getProductLinks(productId, null, fieldId, null)
                setDynamicLists(appContext, FilesAndLinks.from(links, []).formFieldValue, fieldId)
            }

            await setUploadData(this, this.task.productId, 'finalPdf')
        },

        getLinksFromDynamicInputData (fieldId) {
            return this.getFormFieldValue(this.customForm, fieldId)?.links || []
        },

        saveLink (urlString, fieldId) {
            function updateDynamicLists (data, fieldId, appContext) {
                appContext.setFormFieldValue(appContext.customForm, fieldId, data)
                appContext.states[fieldId].isUploading = false
            }

            const filesAndLinks = new FilesAndLinks(this.getFormFieldValue(this.customForm, fieldId))

            // ensure there is only ever one link by deleting links before saving a new one
            filesAndLinks.links.forEach((link, index) => this.deleteLinkInternal(link, index, fieldId))

            this.states[fieldId].isUploading = true
            this.saveProductLink({
                productId: this.task.productId,
                productContentId: this.task.productContentId,
                formField: fieldId,
                url: urlString
            })
                .then(
                    uploadedLink => {
                        if (filesAndLinks.addLink(uploadedLink)) {
                            const dynamicInputData = filesAndLinks.formFieldValue

                            updateDynamicLists(dynamicInputData, fieldId, this)
                            this.saveFormChanges(this.customForm, { [fieldId]: dynamicInputData }, () => {
                                this.formExists = true
                            })
                            this.updateFormIsValid()
                            this.forceListRender()
                        }
                    })
                .catch(error => {
                    console.error(error)
                    this.states[fieldId].isUploading = false
                })
        },

        deleteLink (entryListSubmitEventData, fieldId) {
            const link = entryListSubmitEventData.entry
            const linkIndex = entryListSubmitEventData.index
            this.deleteLinkInternal(link, linkIndex, fieldId)
        },

        deleteLinkInternal (link, linkIndex, fieldId) {
            function updateDynamicLists (data, fieldId, appContext) {
                appContext.setFormFieldValue(appContext.customForm, fieldId, data)
            }
            this.deleteProductLink(link.id)
                .then(() => {
                    const filesAndLinks = new FilesAndLinks(this.getFormFieldValue(this.customForm, fieldId))
                    if (filesAndLinks.removeLink(linkIndex)) {
                        const dynamicData = filesAndLinks.formFieldValue
                        updateDynamicLists(dynamicData, fieldId, this)
                        this.saveFormChanges(this.customForm, { [fieldId]: dynamicData }, () => {
                            this.updateFormIsValid()
                        })
                    }
                })
                .catch(error => console.error(error))
        },

        updateFormIsValid () {
            this.formIsValid = !this.essentialSmartFormService?.hasIncompleteSections()
        },

        forceListRender () {
            this.listUpdateCounter++
        }
    },
    computed: {
        isTaskCompletable () {
            return this.formIsValid && this.formWasSavedSuccessfully
        }
    },
    watch: {
        isTaskCompletable (isCompletable) {
            this.$emit('task-completable-changed', {
                isCompletable: isCompletable
            })
        }
    },
    mounted () {
        this.getCustomForm().then(this.loadLinks)
    }
}
</script>

<template>
    <div class="custom-task-content-container">
        <EssentialSmartForm v-if="customForm"
                            id="customForm"
                            v-bind:hide-submit-button="true"
                            v-bind:form-config="customForm"
                            @form-change="saveForm($event)"
                            @form-submittable-change="setFormValid($event)"
        >
            <template #field(finalPdf)="data">
                <TextInput v-if="getLinksFromDynamicInputData(data.field.id).length === 0"
                           v-bind:id="data.field.id"
                           v-bind:label="this.essentialSmartFormService.getFieldLabel(data.field)"
                           icon-class="fas fa-check"
                           v-bind:show-unsaved-changes="true"
                           v-bind:patternKey="data.field.patternKey"
                           v-bind:initially-valid="data.field.initiallyValid"
                           @input-submit="saveLink($event.value, data.field.id)">
                </TextInput>
                <EntryList v-if="getLinksFromDynamicInputData(data.field.id).length > 0"
                           v-bind:label="this.essentialSmartFormService.getFieldLabel(data.field)"
                           class="custom-task-form-files"
                           entry-label-key="url"
                           icon-class="fas fa-trash"
                           v-bind:entries="getLinksFromDynamicInputData(data.field.id)"
                           v-bind:enable-click="false"
                           v-bind:enable-submit="data.field.upload"
                           @entry-submit="deleteLink($event, data.field.id)"
                >
                </EntryList>
                <div v-if="states[data.field.id].showSpinner">
                    <span class="fas fa-circle-notch fa-spin"></span>
                    <span>{{ $tc('task.taskViews.files.spinnerText') }}</span>
                    <span class="generals-walking-dots"></span>
                </div>
            </template>
        </EssentialSmartForm>

    </div>
</template>

<style scoped lang="less">
.custom-task-content-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
</style>
