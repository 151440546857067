<script>
/**
 * PageRangeInput is a pre-styled input component for defining a range of pages. It consists of two text fields for "from" and "to" pages,
 * with optional validation, labels, and a submit button. The component emits events based on user interactions.
 * - id: Optional identifier for the input fields.
 * - label: Text displayed above the input fields.
 * - tinyLabel: Smaller text displayed within the input container.
 * - placeholder: Array of two strings for "from" and "to" field placeholders.
 * - defaultValue: Object with initial `page_from` and `page_till` values.
 * - submitButton (default: true): If true, displays a submit button.
 * - iconClass: CSS class for the submit button icon.
 * - isDisabled (default: false): If true, disables the input fields and submit button.
 * - maxLength: Maximum character length for the input fields.
 * - pattern: Regex pattern for validating input values.
 * - patternKey: Key to a predefined pattern in regexHelper mixin.
 * - initiallyValid (default: true): If true, assumes the input is initially valid.
 *
 * Emits:
 * - input-submit: When the submit button is clicked or Enter is pressed, with the current values.
 * - input-blurred: When the input fields lose focus, with the current values.
 * - input-change: When the input values change.
 * - input-close: When Escape is pressed.
 */
import { regexHelper } from '@/mixins/regexHelper'
import { $t } from '@/i18n/i18n'

export default {
    name: 'PageRangeInput',
    mixins: [regexHelper],
    props: {
        id: [String, Number],
        label: String,
        tinyLabel: String,
        placeholder: Array,
        defaultValue: Object,
        submitButton: {
            type: Boolean,
            default: true
        },
        iconClass: String,
        isDisabled: {
            type: Boolean,
            default: false
        },
        maxLength: Number,
        pattern: String,
        patternKey: String,
        initiallyValid: {
            type: Boolean,
            default: true
        }
    },
    data () {
        return {
            pageFrom: this.defaultValue ? this.defaultValue.page_from : '',
            pageTill: this.defaultValue ? this.defaultValue.page_till : '',
            lastSubmittedValues: this.defaultValue ? { ...this.defaultValue } : { page_from: '', page_till: '' },
            patternMatched: this.initiallyValid
        }
    },
    methods: {
        $t,
        emitSubmit (event) {
            if (this.validateInput()) {
                this.$emit('input-submit', {
                    event,
                    value: {
                        page_from: this.pageFrom,
                        page_till: this.pageTill
                    }
                })
                this.lastSubmittedValues = {
                    page_from: this.pageFrom,
                    page_till: this.pageTill
                }
            }
        },
        emitBlur () {
            if (this.validateInput()) {
                this.$emit('input-blurred', {
                    value: {
                        page_from: this.pageFrom,
                        page_till: this.pageTill
                    }
                })
            }
        },
        keyPressedHandler (event) {
            if (event.key === 'Enter') {
                this.emitSubmit(event)
            } else if (event.key === 'Escape') {
                this.$emit('input-close')
            }
        },
        validateInput () {
            this.checkPattern()
            if (!this.patternMatched) {
                this.errorMessage = $t('pageRangeInput.patternDoesNotMatch')
                return false
            }

            this.errorMessage = ''
            return true
        },
        checkPattern () {
            if (this.compiledPattern) {
                const fromMatches = this.pageFrom ? this.compiledPattern.test(this.pageFrom) : false
                const tillMatches = this.pageTill ? this.compiledPattern.test(this.pageTill) : false
                this.patternMatched = fromMatches && tillMatches
            } else if (this.patternKey) {
                this.patternMatched = this.checkForPatternKey(this.patternKey, `${this.pageFrom}${this.pageTill}`)
            } else {
                this.patternMatched = true
            }
        }
    },
    computed: {
        getMaxLengthCount () {
            if (!this.maxLength) return ''

            const lengthFirstInput = this.pageFrom?.length || 0
            const lengthSecondInput = this.pageTill?.length || 0

            return this.$t('textInput.maxLengthCounter', [lengthFirstInput + lengthSecondInput, this.maxLength])
        },
        compiledPattern () {
            return this.pattern ? new RegExp(this.pattern) : null
        }
    },
    watch: {
        pageFrom () {
            this.checkPattern()
            this.$emit('input-change', {
                page_from: this.pageFrom,
                page_till: this.pageTill
            })
        },
        pageTill () {
            this.checkPattern()
            this.$emit('input-change', {
                page_from: this.pageFrom,
                page_till: this.pageTill
            })
        },
        defaultValue (newVal) {
            this.pageFrom = newVal ? newVal.page_from : ''
            this.pageTill = newVal ? newVal.page_till : ''
            this.lastSubmittedValues = newVal ? { ...newVal } : { page_from: '', page_till: '' }
        }
    }
}
</script>

<template>
    <div class="c_page-range-wrapper generals-input-wrapper">
        <label v-if="label" class="c_page-range-label generals-input-label">
            <span>{{ label }}</span>
        </label>
        <div class="c_page-range-container generals-input-container">
            <div v-if="tinyLabel" class="c_page-range-tiny-label generals-input-tiny-label">
                <span>{{ tinyLabel }}</span>
            </div>
            <div class="input-wrapper">
                <input
                    :id="id ? `c_page-range_from_${id}` : null"
                    type="text"
                    class="c_page-range m--start generals-input"
                    :disabled="isDisabled"
                    :placeholder="placeholder[0] || $t('pageRangeInput.defaultPlaceholderFrom')"
                    :maxlength="maxLength"
                    @blur="emitBlur"
                    @keyup="keyPressedHandler"
                    v-model="pageFrom"
                />
            </div>
            <div class="input-wrapper">
                <input
                    :id="id ? `c_page-range_to_${id}` : null"
                    type="text"
                    class="c_page-range m--end generals-input"
                    :disabled="isDisabled"
                    :placeholder="placeholder[1] || $t('pageRangeInput.defaultPlaceholderTo')"
                    :maxlength="maxLength"
                    @blur="emitBlur"
                    @keyup="keyPressedHandler"
                    v-model="pageTill"
                />
                <div v-if="maxLength" class="c_page-range-max-length-indicator generals-input-max-length-indicator">
                    <span>{{ getMaxLengthCount }}</span>
                </div>
            </div>
            <button
                v-if="!isDisabled && (submitButton || iconClass)"
                tabindex="-1"
                class="c_page-range-submit"
                :class="{ 'm--disabled': !submitButton }"
                @mousedown="$event.preventDefault()"
                @click="emitSubmit($event)"
            >
                <span class="c_page-range-icon" :class="iconClass"></span>
            </button>
            <div v-if="!patternMatched" class="c_page-range-error-message generals-input-error-message">
                <span>{{ $t('pageRangeInput.patternDoesNotMatch') }}</span>
            </div>
        </div>
    </div>
</template>

<style lang="less" scoped>
.c_page-range-wrapper {
    .c_page-range-label {
    }

    .c_page-range-container {
        display: flex;
        align-items: flex-start;
        gap: 8px;

        .c_page-range-tiny-label {
        }

        .input-wrapper {
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }

        .c_page-range {
            padding: 8px;
            border: 1px solid #ccc;
            border-radius: 4px;

            &.m--start {
                width: 150px;
            }

            &.m--end {
                width: 150px;
            }

            &::placeholder {
                color: #999;
                font-size: 12px;
            }
        }

        .c_page-range-max-length-indicator {
            align-self: flex-end;
            margin-top: 4px;
            font-size: 10px;
            color: #666;
        }

        .c_page-range-submit {
            background: none;
            border: none;
            cursor: pointer;

            .c_page-range-icon {
                font-size: 16px;
            }
        }

        .c_page-range-error-message {
            color: red;
            font-size: 12px;
        }
    }
}
</style>
