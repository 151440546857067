<script>
/**
 * LoadingScreen is a modal overlay which works as a loading indicator.
 * It throws a @loading-cancel event on pressing the cancel text.
 * Both buttons are optional.
 * - displayText (optional): Text displayed below the loading indicator.
 * - isCancelable (default: false): If true, there will be a clickable text to close the loading screen.
 **/
export default {
    name: 'LoadingScreen',
    // @loading-cancel: Event emitted on pressing cancel text | returns the event
    emits: ['loading-cancel'],
    props: {
        displayText: String,
        isCancelable: {
            default: false,
            type: Boolean
        }
    },
    data () {
        return {}
    },
    methods: {
        emitCancel () {
            this.$emit('loading-cancel')
        }
    }
}
</script>

<template>
    <div class="c_loading-screen-wrapper">
        <div class="c_loading-screen">
            <div class="c_loading-screen-book">
                <div class="c_loading-screen-page first"></div>
                <div class="c_loading-screen-page second"></div>
                <div class="c_loading-screen-page third"></div>
            </div>
            <div class="c_loading-screen-text"
                 v-dompurify-html="displayText || $tc('loadingScreen.defaultText')"></div>
            <div v-if="isCancelable"
                 class="c_loading-screen-cancel-text"
                 v-on:click="emitCancel()">
                <span>{{$tc('loadingScreen.cancelText')}}</span>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_loading-screen-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background-color: rgba(51,51,51, 0.75);
    height: 100%;
    z-index: var(--z-index-modal-background);

    .c_loading-screen {
        position: relative;
        top: 42.5%;
        margin: auto;
        width: 500px;

        .c_loading-screen-book{
            transform: translateY(-50%);
            position: relative;
            margin: 0 auto;
            border: 4px solid var(--color-border-active);
            border-radius: 2px;
            background-color: var(--color-background-light);
            width: 160px;
            height: 100px;
        }

        .c_loading-screen-page{
            position: absolute;
            left: 50%;
            margin: 0 auto;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            border-left: 1px solid var(--color-border-light);
            background-color: var(--color-background-light);
            width: 72px;
            height: 92px;
            transform-origin: 0 50%;
            animation: flip 1.2s infinite linear;
            animation-fill-mode: forwards;

            &.first {
                z-index: -1;
                animation-delay: 1.5s*1;
            }

            &.second {
                z-index: -2;
                animation-delay: 1.5s*2;
            }

            &.third {
                z-index: -3;
                animation-delay: 1.5s*3;
            }
        }

        @keyframes flip {
            0%{
                transform: perspective(600px)
                rotateY(-0deg);
            }

            20%{
                background:darken(#F5F5F5, 20%);
            }

            29.9%{
                background:darken(#F5F5F5, 20%);
            }

            30%{
                transform: perspective(200px)
                rotateY(-90deg);
                background-color: var(--color-background-light);
            }

            54.999%{
                opacity: 1;
            }

            55%{
                opacity: 0;
            }

            60%{
                transform: perspective(200px)
                rotateY(-180deg);
                background-color: var(--color-background-light);
            }

            100%{
                transform: perspective(200px)
                rotateY(-180deg);
                background-color: var(--color-background-light);
            }
        }

        .c_loading-screen-text,
        .c_loading-screen-cancel-text {
            position: relative;
            width: 100%;
            text-align: center;
            color: var(--color-text-bright);
        }

        .c_loading-screen-text {
            margin: -10px 0 60px 0;
        }

        .c_loading-screen-cancel-text {
            &:hover {
                cursor: pointer;
                color: var(--color-text-highlighted);
            }
        }
    }
}
</style>
