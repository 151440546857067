<script>
/**
 * Task view
 *
 * @property {String} formJson
 **/
import EssentialSmartForm from '@/components/EssentialSmartForm.vue'
import { axiosService } from '@/mixins/axiosService'
import { taskExecutionHandler } from '@/mixins/taskExecutionHandler'
import { smartFormHelper } from '@/mixins/smartFormHelper'
import EssentialSmartFormService from '@/services/EssentialSmartFormService'

export default {
    name: 'CustomTaskSpecifyPublicationData',
    mixins: [
        axiosService,
        taskExecutionHandler,
        smartFormHelper
    ],
    components: {
        EssentialSmartForm
    },
    props: {
        taskData: Object
    },
    data () {
        return {
            task: this.taskData,
            customForm: null,
            formType: this.taskData.formKey,
            formIsValid: false,
            formExists: false,
            formWasSavedSuccessfully: true,
            essentialSmartFormService: null,
            listUpdateCounter: 0,
            config: {
                customFormKey: 'SPECIFY_PUBLICATION_DATA', // used in a dictionary lookup in smartFormHelper mixin to identify this component as the right custom task
                getErrorMessage: 'task.taskViews.specifyPublicationData.getFormError',
                updateErrorMessage: 'task.taskViews.specifyPublicationData.updateFormError'
            }
        }
    },
    methods: {
        async getCustomForm () {
            // Is called every time data comes from the back end
            function setupForm (appContext, form, formData) {
                appContext.applyValuesToFormConfigurationAndReturnChanges(null, formData.values, true, form, null, this)
                appContext.formExists = true
                appContext.customForm = form

                appContext.essentialSmartFormService = new EssentialSmartFormService(appContext.customForm, appContext.$global.localization.locale)
            }

            const form = this.loadFormConfiguration(this.config.customFormKey)

            try {
                const formData = await this.axiosGet(`forms/search/${this.formType}/${this.task.productNumber}?productContentId=${this.task.productContentId}`)
                // search found something
                setupForm(this, form, formData)
            } catch (errorResponse) {
                if (errorResponse.status === 422) {
                    // search didn't find anything. Create a new form.
                    try {
                        const formData = await this.saveForm({
                            formConfig: form,
                            changes: {}
                        })
                        setupForm(this, form, formData)
                    } catch (error) {
                        console.error(error)
                    }
                } else {
                    this.addNotification({
                        type: 'error',
                        duration: 10000,
                        message: this.$tc(this.config.getErrorMessage)
                    })
                }
            }
        },

        setFormValid (isValid) {
            this.formIsValid = isValid
        },

        saveForm (formSubmitEventData) {
            return this.saveFormChanges(this.customForm, formSubmitEventData.changes, () => {}, false)
        },

        saveFormChanges (formConfig, changes, successCallback, doForceRender = true) {
            return this.saveSmartForm(
                this.formType,
                this.task.productNumber,
                formConfig,
                changes,
                this.formExists,
                this.$tc(this.config.updateErrorMessage))
                .then(formData => {
                    successCallback()
                    this.formWasSavedSuccessfully = true
                    if (doForceRender) this.forceListRender()
                    return Promise.resolve(formData)
                })
                .catch(() => {
                    this.formWasSavedSuccessfully = false
                    return Promise.reject(new Error())
                })
        },
        forceListRender () {
            this.listUpdateCounter++
        }
    },
    computed: {
        isTaskCompletable () {
            return this.formIsValid && this.formWasSavedSuccessfully
        }
    },
    watch: {
        isTaskCompletable (isCompletable) {
            this.$emit('task-completable-changed', {
                isCompletable: isCompletable
            })
        }
    },
    mounted () {
        this.getCustomForm()
    }
}
</script>

<template>
    <div class="custom-task-content-container">
        <EssentialSmartForm v-if="customForm"
                            id="customForm"
                            v-bind:hide-submit-button="true"
                            v-bind:form-config="customForm"
                            @form-change="saveForm($event)"
                            @form-submittable-change="setFormValid($event)">
        </EssentialSmartForm>
    </div>
</template>

<style scoped lang="less">
.custom-task-content-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
</style>
