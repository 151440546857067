<script>
/**
 * Task view
 *
 * @property {String} defaultSelection
 * @property {Boolean} collection
 * @property {Array} documentExtensions
 * @property {Date} selectionDate
 **/
import ContentHeader from '@/components/ContentHeader.vue'
import Table from '@/components/Table.vue'
import Icon from '@/components/Icon.vue'
import IconButton from '@/components/IconButton.vue'
import SmartForm from '@/components/SmartForm.vue'
import TextInput from '@/components/TextInput.vue'
import AreaInput from '@/components/AreaInput.vue'
import DropInput from '@/components/DropInput.vue'
import InteractionLink from '@/components/InteractionLink.vue'
import InlineConfirmButton from '@/components/InlineConfirmButton.vue'
import SmartSelect from '@/components/SmartSelect.vue'
import VerticalLine from '@/components/VerticalLine.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { downloadHandler } from '@/mixins/downloadHandler'
import { taskExecutionHandler } from '@/mixins/taskExecutionHandler'
import { regexHelper } from '@/mixins/regexHelper'
import { debounceHandler } from '@/mixins/debounceHandler'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'DefaultTask',
    mixins: [
        axiosService,
        userHandler,
        downloadHandler,
        taskExecutionHandler,
        regexHelper,
        debounceHandler,
        dateTimeHelper
    ],
    components: {
        ContentHeader,
        Table,
        Icon,
        IconButton,
        SmartForm,
        TextInput,
        AreaInput,
        DropInput,
        InteractionLink,
        InlineConfirmButton,
        SmartSelect,
        VerticalLine
    },
    // @task-completable-changed: Event emitted when the task-completable state changed
    emits: ['task-completable-changed'],
    props: {
        taskData: Object
    },
    data () {
        return {
            task: {
                inputs: [],
                outputs: [],
                choice: {}
            },
            dataContentTypes: {
                document: 'DOCUMENT',
                text: 'TEXT',
                form: 'FORM',
                link: 'HYPERLINK',
                multi: 'MULTIPLE'
            },
            widths: {
                icon: 5,
                normalizedName: 15,
                description: 20,
                uploadInfo: 25,
                data: 25,
                action: 10
            },
            formIsValid: false
        }
    },
    methods: {
        setTaskMetadata () {
            const taskData = this.taskData
            taskData.inputs = taskData.inputs.filter(input => !input.dataContentTypes.includes(this.dataContentTypes.form))
            taskData.inputs.forEach(input => {
                input.id = input.id || input.name
            })
            taskData.outputs.forEach(output => {
                output.id = output.id || output.name
            })
            if (taskData.choice &&
                taskData.choice.defaultSelection &&
                !taskData.choice.currentSelection) {
                taskData.choice.currentSelection = taskData.choice.defaultSelection
            }
            this.task = taskData
        },

        closeActiveInputFields () {
            this.task.outputs.forEach(output => {
                delete output.editing
                output.dataValues.forEach(dataValue => delete dataValue.editing)
            })
            if (this.task.choice) {
                delete this.task.choice.editing
            }
            this.$forceUpdate()
        },

        getInputUploadInfo (input) {
            return input.dataValues.length > 0 && input.dataValues.at(-1).userName
                ? input.dataValues.at(-1).userName
                : this.$tc('generals.systemInformation')
        },

        getDataContentType (taskDataObject, taskDataObjectValue) {
            return this.getDataObjectContentType(taskDataObject, taskDataObjectValue)
        },

        getContentTypesSelectOptions (output) {
            const options = []
            output.dataContentTypes.forEach(contentType => {
                options.push({
                    name: this.$tc(`task.taskViews.defaultView.outputs.table.contentTypesSelectLabels.${contentType}`),
                    value: contentType
                })
            })
            return options
        },

        setTemporaryType (selectSubmitEventData, output) {
            output.temporaryType = selectSubmitEventData ? selectSubmitEventData.option.value : null
            this.$forceUpdate()
        },

        getIconClass (taskDataObject) {
            const contentType = this.getDataContentType(taskDataObject)
            switch (contentType) {
            case this.dataContentTypes.document:
                return 'fas fa-file-contract'
            case this.dataContentTypes.text:
                return 'fas fa-pen'
            case this.dataContentTypes.link:
                return 'fas fa-external-link-alt'
            case this.dataContentTypes.multi:
                return 'fas fa-user-cog'
            }
        },

        getIconType (output) {
            return output.dataValues.length === 0 && (!output.defaultValue || this.getDataContentType(output) === this.dataContentTypes.multi)
                ? 'warning'
                : 'success'
        },

        getIconTooltip (output) {
            return output.dataValues.length === 0 && (!output.defaultValue || this.getDataContentType(output) === this.dataContentTypes.multi)
                ? this.$tc('task.taskViews.defaultView.outputs.table.noDataProvided')
                : this.$tc('task.taskViews.defaultView.outputs.table.dataProvided')
        },

        getInputTextValue (input) {
            return input.dataValues.length > 0
                ? input.dataValues.at(-1).textContent
                : input.defaultValue
        },

        openUrl (dataValue) {
            const value = dataValue.textContent || dataValue.defaultValue
            const url = this.isValidUrl(value)
                ? value
                : `https://${value}`

            window.open(url, '_blank')
        },

        downloadDocumentFile (dataValue) {
            this.downloadFileFromUrl(
                `dataexchange/document/download/${dataValue.id}`,
                false,
                dataValue.fileName
            )
        },

        async downloadDocumentFilesDebounced () {
            await this.debounceAsync(this.downloadDocumentFiles)
        },

        async downloadDocumentFiles () {
            const taskName = this.task.name.replaceAll(' ', '_')
            const zipFilename = `${this.task.productNumber}-${taskName}-Material.zip`
            let documentIds = []
            this.task.inputs.forEach(input => {
                if (this.getDataContentType(input) === this.dataContentTypes.document) {
                    documentIds = documentIds.concat(input.dataValues.map(
                        document => document.id
                    ))
                }
            })
            await this.downloadFileFromUrl(
                `dataexchange/document/downloadMultipleFiles/${documentIds}?filename=${zipFilename}`,
                false,
                zipFilename
            )
        },

        isDataValueEmptyOrEditing (output) {
            return (!output.dataValues[0] && !output.defaultValue) || output.editing
        },

        setDataEditable (output, editing) {
            output.editing = editing
            this.$forceUpdate()
        },

        setDataUploading (output, uploading) {
            if (this.getDataContentType(output) === this.dataContentTypes.document) {
                output.uploading = uploading
                output.editing = false
                this.$forceUpdate()
            }
        },

        prepareSaveText (inputSubmitEventData, output, dataValue, isUpdate) {
            const data = new FormData()
            data.append('textContent', inputSubmitEventData.value)

            if (isUpdate) {
                this.updateData(data, output, dataValue)
            } else {
                const type = (this.getDataContentType(output) === this.dataContentTypes.text)
                    ? 'text'
                    : 'link'
                data.append('dataObjectName', output.name)
                this.saveData(type, data, output, true)
            }
        },

        prepareSaveDocument (inputSubmitEventData, output) {
            this.setDataUploading(output, true)
            const data = inputSubmitEventData.value
            if (output.collection) {
                const files = data.getAll('file')
                const existingFiles = []
                data.delete('file')
                files.forEach(file => {
                    const fileExists = output.dataValues.some(dataValue => dataValue.fileName === file.name)
                    fileExists
                        ? existingFiles.push(file.name)
                        : data.append('file', file)
                })
                if (existingFiles.length > 0) {
                    this.addNotification({
                        type: 'warning',
                        duration: 10000,
                        message: this.$t('task.taskViews.defaultView.fileAlreadyExists', [existingFiles.join(',<br />')])
                    })
                }
                if (files.length === existingFiles.length) {
                    this.setDataUploading(output, false)
                    return
                }
            }
            data.append('dataObjectName', output.name)

            output.collection || output.dataValues.length === 0
                ? this.saveData('upload', data, output)
                : this.updateData(data, output)
        },

        saveForm (formSubmitEventData) {
            this.saveOrUpdateForm(this.task, formSubmitEventData.formConfig)
        },

        saveData (type, requestBody, output, append) {
            this.axiosPost(
                `task/${this.task.id}/${type}`,
                requestBody,
                this.$t('task.taskViews.defaultView.outputs.updateTaskEditError', [output.name]),
                {
                    standard: this.$t('task.taskViews.defaultView.outputs.updateTaskEditSuccess.standard', [output.name]),
                    short: this.$tc('task.taskViews.defaultView.outputs.updateTaskEditSuccess.short')
                })
                .then(addedData => {
                    if (append) {
                        output.dataValues.push(addedData)
                        delete output.editing
                    } else {
                        output.dataValues = Array.isArray(addedData)
                            ? addedData
                            : [addedData]
                    }
                    this.setDataUploading(output, false)
                })
                .catch(() => {
                    this.setDataUploading(output, false)
                })
        },

        updateData (requestBody, output, dataValue) {
            const value = dataValue || output.dataValues[0]
            this.axiosPut(
                `task/${this.task.id}/data/${value.id}`,
                requestBody,
                this.$t('task.taskViews.defaultView.outputs.updateTaskEditError', [output.name]),
                {
                    standard: this.$t('task.taskViews.defaultView.outputs.updateTaskEditSuccess.standard', [output.name]),
                    short: this.$tc('task.taskViews.defaultView.outputs.updateTaskEditSuccess.short')
                })
                .then(updatedData => {
                    if (dataValue) {
                        dataValue.textContent = updatedData.textContent
                        delete dataValue.editing
                    } else {
                        output.dataValues = [updatedData]
                    }
                    this.setDataUploading(output, false)
                    this.setDataEditable(output, false)
                })
                .catch(() => {
                    this.setDataUploading(output, false)
                })
        },

        removeData (output) {
            this.axiosDelete(
                `task/${this.task.id}/data/${encodeURIComponent(output.name)}`,
                null,
                this.$t('task.taskViews.defaultView.outputs.deleteTaskEditError', [output.name]),
                {
                    standard: this.$t('task.taskViews.defaultView.outputs.deleteTaskEditSuccess.standard', [output.name]),
                    short: this.$tc('task.taskViews.defaultView.outputs.deleteTaskEditSuccess.short')
                })
                .then(() => {
                    output.dataValues = []
                    delete output.temporaryType
                })
                .catch(() => {})
        },

        removeDataValue (output) {
            const value = output.dataValues.at(-1)
            this.axiosDelete(
                `task/${this.task.id}/dataValue/${value.id}`,
                null,
                this.$t('task.taskViews.defaultView.outputs.updateTaskEditError', [output.name]),
                this.getDataContentType(output) === this.dataContentTypes.form
                    ? null
                    : {
                        standard: this.$t('task.taskViews.defaultView.outputs.updateTaskEditSuccess.standard', [output.name]),
                        short: this.$tc('task.taskViews.defaultView.outputs.updateTaskEditSuccess.short')
                    })
                .then(() => {
                    output.dataValues.pop()
                })
                .catch(() => {})
        },

        saveOrUpdateChoice (selectSubmitEventData) {
            const decisionId = selectSubmitEventData ? selectSubmitEventData.option.id : null
            const requestBody = new FormData()
            requestBody.append('option', decisionId)

            this.axiosPut(
                `task/${this.task.id}/decision`,
                requestBody,
                decisionId
                    ? this.$tc('task.taskViews.defaultView.outputs.updateTaskDecisionError')
                    : this.$tc('task.taskViews.defaultView.outputs.deleteTaskDecisionError'),
                {
                    standard: this.$tc(`task.taskViews.defaultView.outputs.${decisionId ? 'updateTaskDecisionSuccess' : 'deleteTaskDecisionSuccess'}.standard`),
                    short: this.$tc(`task.taskViews.defaultView.outputs.${decisionId ? 'updateTaskDecisionSuccess' : 'deleteTaskDecisionSuccess'}.short`)
                })
                .then(choice => {
                    this.task.choice = choice
                    this.setDataEditable(this.task.choice, false)
                })
                .catch(() => {})
        },

        setFormValid (isValid) {
            this.formIsValid = isValid
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getTaskInputsTableConfig () {
            return [{
                key: 'icon',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: this.widths.icon
            }, {
                key: 'normalizedName',
                label: this.$tc('task.taskViews.defaultView.inputs.table.name'),
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: this.widths.normalizedName
            }, {
                key: 'description',
                label: this.$tc('task.taskViews.defaultView.inputs.table.description'),
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: this.widths.description
            }, {
                key: 'uploadInfo',
                label: this.$tc('task.taskViews.defaultView.inputs.table.uploadInfo'),
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: this.widths.uploadInfo
            }, {
                key: 'data',
                label: this.$tc('task.taskViews.defaultView.inputs.table.data'),
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: this.widths.data
            }, {
                key: 'action',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'right',
                width: this.widths.action
            }]
        },

        isDownloadAllButtonDisabled () {
            return !this.task.inputs.some(input => this.getDataContentType(input) === this.dataContentTypes.document)
        },

        getTaskOutputsTableConfig () {
            return [{
                key: 'icon',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: this.widths.icon
            }, {
                key: 'normalizedName',
                label: this.$tc('task.taskViews.defaultView.outputs.table.name'),
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: this.widths.normalizedName
            }, {
                key: 'description',
                label: this.$tc('task.taskViews.defaultView.outputs.table.description'),
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: this.widths.description
            }, {
                key: 'uploadInfo',
                label: this.$tc('task.taskViews.defaultView.outputs.table.uploadInfo'),
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: this.widths.uploadInfo
            }, {
                key: 'data',
                label: this.$tc('task.taskViews.defaultView.outputs.table.data'),
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: this.widths.data
            }, {
                key: 'action',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'right',
                width: this.widths.action
            }]
        },

        getChoice () {
            if (!this.task.choice || !this.task.choice.currentSelection) {
                return null
            }
            return this.task.choice.options.find(option => option.id === this.task.choice.currentSelection)
        },

        hasDataOutputOfTypeForm () {
            return this.task.outputs.some(output => this.getDataContentType(output) === this.dataContentTypes.form)
        },

        getDataOutputOfTypeForm () {
            return this.task.outputs.find(output => this.getDataContentType(output) === this.dataContentTypes.form)
        },

        isFormTableOfContentVisible () {
            const formConfig = this.getFormConfiguration
            return formConfig
                ? formConfig.sections.length > 1
                : true
        },

        getFormConfiguration () {
            const formContent = this.getDataOutputOfTypeForm
            if (!formContent) {
                return null
            }
            return JSON.parse(formContent.dataValues[0]
                ? formContent.dataValues[0].textContent
                : `${formContent.defaultValue}`)
        },

        isTaskCompletable () {
            function outputsIncomplete (context) {
                return context.task.outputs && context.task.outputs.some(output => {
                    return !output.optional && output.dataValues.length === 0 && !output.defaultValue
                })
            }
            function choiceIncomplete (context) {
                return context.task.choice && !context.task.choice.currentSelection
            }
            function formInvalid (context) {
                return context.hasDataOutputOfTypeForm && !context.formIsValid
            }
            return !outputsIncomplete(this) && !choiceIncomplete(this) && !formInvalid(this)
        }
    },
    watch: {
        isTaskCompletable (isCompletable) {
            this.$emit('task-completable-changed', {
                isCompletable: isCompletable
            })
        }
    },
    mounted () {
        this.setTaskMetadata()
    }
}
</script>

<template>
    <div class="default-task-content-container"
         v-on:click="closeActiveInputFields()">
        <div v-if="task.inputs.length > 0"
             class="default-task-inputs-container">
            <ContentHeader v-bind:header-title="$tc('task.taskViews.defaultView.inputs.headerTitle')"
                           v-bind:use-slot="true">
                <template v-slot>
                    <IconButton v-bind:is-disabled="isDownloadAllButtonDisabled"
                                icon-class="fas fa-download"
                                v-bind:tooltip="$tc('task.taskViews.defaultView.inputs.downloadFiles')"
                                @button-submit="downloadDocumentFilesDebounced">
                    </IconButton>
                </template>
            </ContentHeader>
            <Table table-id="taskInputs"
                   v-bind:table-config="getTaskInputsTableConfig"
                   v-bind:table-data="task.inputs"
                   v-bind:read-only=true
                   v-bind:is-scrollable=false
                   v-bind:table-empty-message="$tc('task.taskViews.defaultView.inputs.table.tableEmpty')">
                <template #cell(icon)="data">
                    <Icon v-bind:icon-class="getIconClass(data.row)"
                          v-bind:icon-large=true>
                    </Icon>
                </template>
                <!-- normalizedName: default cell content -->
                <!-- description: default cell content -->
                <template #cell(uploadInfo)="data">
                    <span>{{getInputUploadInfo(data.row)}}</span>
                    <div v-if="data.row.dataValues.length > 0 && data.row.dataValues.at(-1).uploadDate">
                        <span>{{ getFormattedLongDate(data.row.dataValues.at(-1).uploadDate, this.locale)
                            }}</span>
                    </div>
                </template>
                <template #cell(data)="data">
                    <InteractionLink v-if="getDataContentType(data.row) === dataContentTypes.text ||
                                           getDataContentType(data.row) === dataContentTypes.link && data.row.dataValues.length === 0"
                                     class="generals-table-input"
                                     v-bind:is-disabled="getDataContentType(data.row) === dataContentTypes.text"
                                     v-bind:is-highlighted="getDataContentType(data.row) === dataContentTypes.link"
                                     v-bind:icon-class="getDataContentType(data.row) === dataContentTypes.link ? 'fas fa-external-link-alt' : null"
                                     v-bind:value="getInputTextValue(data.row)"
                                     @input-click="openUrl(data.row)">
                    </InteractionLink>
                    <template v-else>
                        <InteractionLink v-for="value in data.row.dataValues"
                                         class="default-task-inputs-table-value"
                                         v-bind:key="value.id"
                                         v-bind:is-highlighted="true"
                                         v-bind:icon-class="getDataContentType(data.row, value) === dataContentTypes.document ? 'fas fa-download' : 'fas fa-external-link-alt'"
                                         v-bind:value="getDataContentType(data.row, value) === dataContentTypes.document ? value.fileName : value.textContent"
                                         @input-click="getDataContentType(data.row, value) === dataContentTypes.document ? downloadDocumentFile(value) : openUrl(value)">
                        </InteractionLink>
                    </template>
                </template>
            </Table>
        </div>
        <div v-if="task.inputs.length > 0 && (task.outputs.length > 0 || task.choice)"
             class="default-task-inputs-outputs-divider">
        </div>
        <div v-if="task.outputs.length > 0 || task.choice || task.inputs.length === 0"
             class="default-task-outputs-container" v-bind:class="{'m--full-height': hasDataOutputOfTypeForm}">
            <ContentHeader v-if="!hasDataOutputOfTypeForm"
                           v-bind:header-title="$tc('task.taskViews.defaultView.outputs.headerTitle')">
            </ContentHeader>
            <div v-if="hasDataOutputOfTypeForm"
                 class="default-task-outputs-form-container">
                <SmartForm v-bind:form-config="getFormConfiguration"
                           v-bind:hide-table-of-contents="!isFormTableOfContentVisible"
                           v-bind:hide-submit-button=true
                           @form-change="saveForm($event)"
                           @form-submittable-change="setFormValid($event)">
                </SmartForm>
            </div>
            <Table v-else
                   table-id="taskOutputs"
                   v-bind:table-config="getTaskOutputsTableConfig"
                   v-bind:table-data="task.outputs"
                   v-bind:read-only="true"
                   v-bind:use-table-footer="!!task.choice"
                   v-bind:is-scrollable="false"
                   v-bind:table-empty-message="$tc('task.taskViews.defaultView.outputs.table.tableEmpty')">
                <template #cell(icon)="data">
                    <Icon v-bind:icon-class="getIconClass(data.row)"
                          v-bind:icon-type="getIconType(data.row)"
                          v-bind:tooltip="getIconTooltip(data.row)"
                          v-bind:icon-large=true>
                    </Icon>
                </template>
                <!-- normalizedName: default cell content -->
                <!-- description: default cell content -->
                <template #cell(uploadInfo)="data">
                    <div v-if="data.row.dataValues.length > 0">
                        <span>{{data.row.dataValues.at(-1).userName}}</span>
                        <br />
                        <span>{{ getFormattedLongDate(data.row.dataValues.at(-1).uploadDate, this.locale)
                            }}</span>
                    </div>
                </template>
                <template #cell(data)="data">
                    <div v-if="data.row.temporaryType && data.row.dataValues.length === 0"
                         class="default-task-outputs-table-back-arrow"
                         v-on:click="setTemporaryType(null, data.row)">
                        <span class="fas fa-arrow-left"></span>
                        <span> {{$tc('task.taskViews.defaultView.outputs.table.contentTypesSelectLabels.backToSelection')}}</span>
                    </div>
                    <div v-if="getDataContentType(data.row) === dataContentTypes.text">
                        <AreaInput v-if="isDataValueEmptyOrEditing(data.row)"
                                   v-bind:id="data.row.id"
                                   v-bind:submit-button=false
                                   v-bind:default-value="data.row.dataValues[0] ? data.row.dataValues[0].textContent : null"
                                   v-bind:is-auto-focused="!!data.row.dataValues[0]"
                                   v-bind:show-unsaved-changes=true
                                   v-bind:is-expandable="true"
                                   @input-blurred="prepareSaveText($event, data.row, null,!!data.row.dataValues[0])"
                                   @input-submit="prepareSaveText($event, data.row, null, !!data.row.dataValues[0])"
                                   @input-close="data.row.dataValues[0] ? setDataEditable(data.row, false): null"
                                   icon-class="far fa-sticky-note">
                        </AreaInput>
                        <InteractionLink v-else
                                         class="generals-table-input"
                                         v-bind:value="data.row.dataValues[0] ? data.row.dataValues[0].textContent : null"
                                         v-bind:placeholder="data.row.defaultValue"
                                         icon-class="fas fa-edit"
                                         v-bind:is-underlined="true"
                                         @input-click="setDataEditable(data.row, true)">
                        </InteractionLink>
                    </div>
                    <div v-else-if="getDataContentType(data.row) === dataContentTypes.link">
                        <template v-for="dataValue in data.row.dataValues">
                            <TextInput v-if="dataValue.editing"
                                       v-bind:key="dataValue.id"
                                       v-bind:id="dataValue.id"
                                       class="generals-table-input"
                                       v-bind:placeholder="$tc('task.taskViews.defaultView.outputs.table.linkPlaceholder')"
                                       v-bind:submit-button=false
                                       v-bind:default-value="dataValue.textContent"
                                       v-bind:is-auto-focused="true"
                                       v-bind:show-unsaved-changes=true
                                       @input-blurred="prepareSaveText($event, data.row, dataValue,true)"
                                       @input-submit="prepareSaveText($event, data.row, dataValue, true)"
                                       @input-close="setDataEditable(dataValue, false)"
                                       icon-class="fas fa-pen">
                            </TextInput>
                            <InteractionLink v-else
                                             v-bind:key="dataValue.id"
                                             class="generals-table-input"
                                             v-bind:value="dataValue.textContent"
                                             icon-class="fas fa-edit"
                                             ctrl-icon-class="fas fa-external-link-alt"
                                             v-bind:is-highlighted="true"
                                             @input-click="setDataEditable(dataValue, true)"
                                             @ctrl-input-click="openUrl(dataValue)">
                            </InteractionLink>
                        </template>
                        <TextInput v-if="isDataValueEmptyOrEditing(data.row)"
                                   v-bind:id="data.row.id"
                                   v-bind:placeholder="$tc('task.taskViews.defaultView.outputs.table.linkPlaceholder')"
                                   v-bind:submit-button=false
                                   v-bind:is-auto-focused="!!data.row.dataValues[0]"
                                   v-bind:show-unsaved-changes=true
                                   @input-blurred="prepareSaveText($event, data.row, null, false)"
                                   @input-submit="prepareSaveText($event, data.row, null, false)"
                                   @input-close="data.row.dataValues[0] ? setDataEditable(data.row, false): null"
                                   icon-class="fas fa-pen">
                        </TextInput>
                        <InteractionLink v-else-if="data.row.defaultValue && data.row.dataValues.length === 0"
                                         class="generals-table-input"
                                         v-bind:placeholder="data.row.defaultValue"
                                         icon-class="fas fa-edit"
                                         v-bind:is-underlined="true"
                                         @input-click="setDataEditable(data.row, true)">
                        </InteractionLink>
                        <div v-if="data.row.collection"
                             class="default-task-outputs-table-button-pair">
                            <IconButton v-bind:is-disabled="data.row.dataValues.length <= 1"
                                        icon-class="fas fa-minus"
                                        @button-submit="removeDataValue(data.row)">
                            </IconButton>
                            <VerticalLine></VerticalLine>
                            <IconButton v-bind:is-disabled="data.row.dataValues.length === 0 || data.row.editing"
                                        icon-class="fas fa-plus"
                                        @button-submit="setDataEditable(data.row, true)">
                            </IconButton>
                        </div>
                    </div>
                    <div v-else-if="getDataContentType(data.row) === dataContentTypes.document">
                        <DropInput v-if="!data.row.dataValues[0] || data.row.editing"
                                   v-bind:allow-multiple="data.row.collection"
                                   v-bind:is-uploading="data.row.uploading"
                                   v-bind:file-types="data.row.documentExtensions"
                                   @input-submit="prepareSaveDocument($event, data.row)">
                        </DropInput>
                        <template v-else>
                            <InteractionLink v-for="file in data.row.dataValues"
                                             class="generals-table-input default-task-outputs-table-document"
                                             v-bind:key="file.id"
                                             v-bind:value="file.fileName"
                                             icon-class="fas fa-download"
                                             v-bind:is-highlighted="true"
                                             @input-click="downloadDocumentFile(file)">
                            </InteractionLink>
                            <div class="default-task-outputs-table-document-upload">
                                <IconButton v-bind:icon-class="data.row.collection ? 'fas fa-plus' : 'fas fa-sync-alt'"
                                            v-bind:tooltip="data.row.collection
                                                ? $tc('task.taskViews.defaultView.outputs.table.addFiles')
                                                : $tc('task.taskViews.defaultView.outputs.table.changeFile')"
                                            @button-submit="setDataEditable(data.row, true)">
                                </IconButton>
                            </div>
                        </template>
                    </div>
                    <div v-else-if="getDataContentType(data.row) === dataContentTypes.multi">
                        <SmartSelect v-bind:id="data.row.id + '_select'"
                                     v-bind:submit-button=false
                                     v-bind:placeholder="$tc('task.taskViews.defaultView.outputs.table.contentTypesSelectLabels.placeholder')"
                                     v-bind:options="getContentTypesSelectOptions(data.row)"
                                     v-bind:sort-options=false
                                     v-bind:option-label-specifiers="['name']"
                                     v-bind:filter-label-specifiers="['name']"
                                     v-bind:allow-input=true
                                     @select-change="setTemporaryType($event, data.row)">
                        </SmartSelect>
                    </div>
                </template>
                <template #cell(action)="data">
                    <InlineConfirmButton v-bind:key="data.row.id"
                                         icon-class="fas fa-trash"
                                         v-bind:tooltip="$tc('task.taskViews.defaultView.outputs.table.removeData')"
                                         v-bind:is-disabled="!data.row.dataValues[0]"
                                         v-bind:confirm-message="$tc('generals.confirmDelete')"
                                         @button-submit="removeData(data.row)">
                    </InlineConfirmButton>
                </template>
                <template v-if="task.choice"
                          v-slot:taskOutputs_footer>
                    <table class="default-task-outputs-decision"><tbody>
                        <tr class="default-task-outputs-decision-tr">
                            <td class="default-task-outputs-decision-td"
                                v-bind:style="{width: widths.icon  + '%'}">
                                <Icon class="default-task-outputs-decision-icon"
                                      icon-class="far fa-plus-square"
                                      v-bind:icon-type="task.choice.currentSelection ? 'success' : 'warning'"
                                      v-bind:tooltip="task.choice.currentSelection
                                        ? $tc('task.taskViews.defaultView.outputs.table.decisionSelected')
                                        : $tc('task.taskViews.defaultView.outputs.table.noDecisionSelected')"
                                      v-bind:icon-large=true>
                                </Icon>
                            </td>
                            <td class="default-task-outputs-decision-td"
                                v-bind:style="{width: widths.normalizedName  + '%'}">
                                <span>{{task.choice.name || $tc('task.taskViews.defaultView.outputs.table.defaultChoiceName')}}</span>
                            </td>
                            <td class="default-task-outputs-decision-td"
                                v-bind:style="{width: widths.description  + '%'}">
                                <span>{{task.choice.description || $tc('task.taskViews.defaultView.outputs.table.defaultChoiceDescription')}}</span>
                            </td>
                            <td class="default-task-outputs-decision-td"
                                v-bind:style="{width: widths.uploadInfo  + '%'}">
                                <div v-if="task.choice.currentSelection">
                                    <span>{{task.choice.userName || $tc('generals.systemInformation')}}</span>
                                    <br />
                                    <span>{{ getFormattedLongDate(task.choice.selectionDate, this.locale)
                                        }}</span>
                                </div>
                            </td>
                            <td class="default-task-outputs-decision-td"
                                v-bind:style="{width: widths.data  + '%'}">
                                <SmartSelect v-if="!task.choice.currentSelection || task.choice.editing"
                                             v-bind:id="task.id + '_select'"
                                             class="generals-table-input"
                                             v-bind:submit-button=false
                                             v-bind:placeholder="$tc('task.taskViews.defaultView.outputs.table.decisionPlaceholder')"
                                             v-bind:options="task.choice.options"
                                             v-bind:default-option="getChoice"
                                             v-bind:sort-options=false
                                             v-bind:is-auto-focused="!!task.choice.currentSelection"
                                             v-bind:option-label-specifiers="['name']"
                                             v-bind:filter-label-specifiers="['name']"
                                             v-bind:allow-input=true
                                             v-bind:show-unsaved-changes=true
                                             @select-blurred="saveOrUpdateChoice($event)"
                                             @select-change="saveOrUpdateChoice($event)"
                                             @select-submit="saveOrUpdateChoice($event)"
                                             @select-close="setDataEditable(task.choice, false)">
                                </SmartSelect>
                                <InteractionLink v-else
                                                 class="generals-table-input"
                                                 v-bind:value="getChoice.name"
                                                 v-bind:is-underlined="true"
                                                 icon-class="fas fa-edit"
                                                 @input-click="setDataEditable(task.choice, true)">
                                </InteractionLink>
                            </td>
                            <td class="default-task-outputs-decision-td"
                                v-bind:style="{width: widths.actions + '%'}">
                                <div class="default-task-outputs-decision-actions">
                                    <InlineConfirmButton icon-class="fas fa-trash"
                                                         v-bind:tooltip="$tc('task.taskViews.defaultView.outputs.table.resetDecision')"
                                                         v-bind:is-disabled="!task.choice.currentSelection"
                                                         v-bind:confirm-message="$tc('generals.confirmDelete')"
                                                         @button-submit="saveOrUpdateChoice(null)">
                                    </InlineConfirmButton>
                                </div>
                            </td>
                        </tr>
                    </tbody></table>
                </template>
            </Table>
        </div>
    </div>
</template>

<style scoped lang="less">
.default-task-content-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;

    .default-task-inputs-container {
        margin-bottom: var(--headline-height);

        .default-task-inputs-table-value {
            margin-top: -4px;
            display: block;
        }
    }

    .default-task-inputs-outputs-divider {
        margin: 40px 0 var(--container-spacing) 0;
        width: 100%;
        border-bottom: 3px solid var(--color-border-light);
    }

    .default-task-outputs-container {

        &.m--full-height {
            height: 100%;
        }

        .default-task-outputs-form-container {
            height: 100%;
        }

        .default-task-outputs-table-back-arrow {
            font-size: 13px;
            margin-bottom: 6px;
            cursor: pointer;

            &:hover {
                color: var(--color-text-highlighted);
            }
        }

        .default-task-outputs-table-button-pair {
            margin-left: -8px;
        }

        .default-task-outputs-table-document {
            display: block;

            &:not(:first-child) {
                margin-top: -8px;
            }
        }

        .default-task-outputs-table-document-upload {
            margin: -4px 0 -6px -8px;
        }

        .default-task-outputs-decision {
            width: 100%;
            border-collapse: collapse;

            .default-task-outputs-decision-tr {
                width: 100%;
                border-bottom: 1px solid var(--color-border-light);

                .default-task-outputs-decision-td {
                    padding: 14px 10px;

                    .default-task-outputs-decision-icon {
                        -ms-transform: rotate(45deg);
                        transform: rotate(45deg);
                    }
                }

                .default-task-outputs-decision-actions {
                    float: right;
                }
            }
        }
    }
}
</style>
