<script>
/**
 * Product asset orders sub view | Sub view of a Product
 **/
import Table from '@/components/Table.vue'
import Icon from '@/components/Icon.vue'
import SmartSelect from '@/components/SmartSelect.vue'
import BadgeInput from '@/components/BadgeInput.vue'
import IconButton from '@/components/IconButton.vue'
import VerticalLine from '@/components/VerticalLine.vue'
import InlineConfirmButton from '@/components/InlineConfirmButton.vue'
import ModalContainer from '@/components/ModalContainer.vue'
import SmartFormSubheading from '@/components/SmartFormSubheading.vue'
import AreaInput from '@/components/AreaInput.vue'
import EssentialSmartForm from '@/components/EssentialSmartForm.vue'
import DropInput from '@/components/DropInput.vue'
import EntryList from '@/components/EntryList.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { notificationHandler } from '@/mixins/notificationHandler'
import { smartFormHelper } from '@/mixins/smartFormHelper'
import { downloadHandler } from '@/mixins/downloadHandler'
import _ from 'lodash'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'ProductAssetOrders',
    mixins: [
        axiosService,
        userHandler,
        notificationHandler,
        smartFormHelper,
        downloadHandler,
        dateTimeHelper
    ],
    components: {
        Table,
        Icon,
        SmartSelect,
        BadgeInput,
        IconButton,
        VerticalLine,
        InlineConfirmButton,
        ModalContainer,
        SmartFormSubheading,
        AreaInput,
        EssentialSmartForm,
        DropInput,
        EntryList
    },
    props: {
        productState: Object
    },
    data () {
        return {
            productInfo: this.$attrs.productInfo || this.productState,
            isCloseDisabled: false,
            fieldsToNormalize: {
                product_number_of_original: {
                    regex: '^[0-9]{4,13}$',
                    errorMessage: 'product.assetOrders.productNumberOfOriginalError'
                }
            },
            assetOrders: [],
            assetTypes: [{
                id: 'AUDIO',
                key: 'ASSET_ORDER_AUDIO',
                label: this.getAssetTypeLabel('AUDIO'),
                icon: 'fas fa-volume-up',
                repeatFieldOnAllPages: 'track_title',
                /*
                The smartFormHelper runs through the form once completely section by section and field by field in order to enter existing values.
                A function can be passed to it that is called for the individual fields, for example to modify properties based on certain conditions (e.g. only required under certain conditions).
                Otherwise, the structure would have to be run through again in the controller. These functions are defined here and used when placing a new order.
                */
                fieldConfigFunction: function (field, appContext) {
                    appContext.setCurrentYearAsMaximumForField('release_year', field)
                }
            }, {
                id: 'PICTURE',
                key: 'ASSET_ORDER_PICTURE',
                label: this.getAssetTypeLabel('PICTURE'),
                icon: 'fas fa-image',
                repeatFieldOnAllPages: 'motif_description',
                fieldConfigFunction: function (field, appContext) {
                    appContext.setCurrentYearAsMaximumForField('release_year', field)
                    appContext.setCurrentYearAsMaximumForField('year_of_creation', field)
                }
            }, {
                id: 'TEXT',
                key: 'ASSET_ORDER_TEXT',
                label: this.getAssetTypeLabel('TEXT'),
                icon: 'fas fa-pen',
                repeatFieldOnAllPages: 'original_title'
            }, {
                id: 'VIDEO',
                key: 'ASSET_ORDER_VIDEO',
                label: this.getAssetTypeLabel('VIDEO'),
                icon: 'fas fa-video',
                repeatFieldOnAllPages: 'work_title',
                fieldConfigFunction: function (field, appContext) {
                    appContext.setCurrentYearAsMaximumForField('release_year', field)
                    appContext.evaluateValueForLocalFieldMusicInformationAvailable('music_information_available', field, appContext)
                }
            }],
            unknownAssetType: {
                id: 'UNKNOWN',
                label: this.getAssetTypeLabel('UNKNOWN'),
                icon: 'fas fa-exchange-alt'
            },
            formConfiguration: null,
            isFormSubmittable: false,
            currentOrder: null,
            selectedOrder: null,
            orderCommentsExpanded: false,
            showSpinner: true,
            fileList: [],
            uploadingFiles: false,
            orderStates: {
                ASSET_DEMAND_AVAILABLE: 'Assetbedarf vorhanden',
                PROVIDER_REQUESTED: 'Rechtegeber wurde angefragt',
                LICENSING_SUCCESSFUL: 'Lizenzierung erfolgreich',
                LICENSING_IMPOSSIBLE: 'Lizenzierung nicht möglich',
                DATA_IMPORTED_TO_WWA: 'Feindaten sind in WWA importiert',
                ASSET_DEMAND_CHANGED: 'Assetbedarf geändert',
                ORDER_CANCELED: 'Bestellung gestrichen'
            }
        }
    },
    methods: {
        getOrders () {
            this.axiosGet(
                `asset-order/${this.productInfo.id}`,
                this.$tc('product.assetOrders.getOrdersError'))
                .then(assetOrders => {
                    const ongoingOrders = assetOrders.filter(order => order.state !== this.orderStates.ORDER_CANCELED || order.submitted === true)
                    ongoingOrders.forEach(order => this.setOrderInformation(order))
                    this.assetOrders = ongoingOrders
                    this.showSpinner = false
                })
                .catch(error => {
                    console.error(error)
                    this.showSpinner = false
                })
        },

        setOrderInformation (order) {
            order.id = `${order.contentRequestId}_${order.positionId}_${order.assetId}`
            const assetTypeDefinition = this.getAssetTypeDefinition(order.type)
            order.assetTypeDisplayText = assetTypeDefinition.label

            order.createdAtDisplayText = this.getFormattedShortDate(order.createdAt, this.locale)
            order.updatedAtDisplayText = order.updatedAt ? this.getFormattedShortDate(order.updatedAt, this.locale) : '-'

            order.isDisabled = !this.isAllowedToPlaceAndEditOrders || order.state === this.orderStates.ORDER_CANCELED
            order.latestCommentCreationDateTime = this.getLatestCommentForOrder(order)?.createdAt
            this.setFlaggedText(order)
        },

        isUnknownOrderType (order) {
            return order.type === this.unknownAssetType.id
        },

        isRemovedOrder (order) {
            return order.state === this.orderStates.ORDER_CANCELED
        },

        getAssetTypeDefinition (type) {
            return this.assetTypes.find(assetType => assetType.id === type) || this.unknownAssetType
        },

        getAssetTypeLabel (type) {
            return this.$tc(`product.assetOrders.assetTypes.${type}`)
        },

        getOrderStateTooltip (order) {
            const param = order.lastStatusChange
                ? this.getFormattedLongDate(order.lastStatusChange, this.locale)
                : '-'
            return this.$t('product.assetOrders.table.lastStatusChange', [param])
        },

        getOrderStateColor (order) {
            switch (order.state) {
            case this.orderStates.PROVIDER_REQUESTED:
                return 'generals-text-warning'
            case this.orderStates.LICENSING_IMPOSSIBLE:
                return 'generals-text-error'
            case this.orderStates.LICENSING_SUCCESSFUL:
            case this.orderStates.DATA_IMPORTED_TO_WWA:
                return 'generals-text-success'
            default:
                return 'generals-text-dark'
            }
        },

        isOrderEditable (order) {
            return !this.isRemovedOrder(order) && this.isAllowedToPlaceAndEditOrders
        },

        setOrderSelected (order) {
            this.selectedOrder = order
            setTimeout(() => {
                this.setOrderCommentsExpanded(true)
                const commentsContainer = document.getElementById('product-asset-orders-modal-comments')
                commentsContainer.scrollTop = commentsContainer.scrollHeight
            }, 50)
        },

        setOrderCommentsExpanded (expanded) {
            this.orderCommentsExpanded = expanded
            if (!expanded) {
                setTimeout(() => {
                    this.selectedOrder = null
                }, 200)
            }
        },

        setFlaggedText (order) {
            order.flaggedFilterText = order.flagged
                ? this.$tc('product.assetOrders.table.clarificationNecessary')
                : this.$tc('product.assetOrders.table.noClarificationNecessary')
        },

        toggleOrderFlagged (event, order) {
            event.stopPropagation()
            this.axiosPut(
                `asset-order/${this.productInfo.id}/contentRequest/${order.contentRequestId}/position/${order.positionId}/${order.flagged ? 'no-need-to-talk' : 'need-to-talk'}`,
                null,
                this.$t('product.assetOrders.switchNeedToTalkOrderError', [order.title]),
                {
                    standard: this.$t('product.assetOrders.switchNeedToTalkOrderSuccess.standard', [order.title]),
                    short: this.$tc('product.assetOrders.switchNeedToTalkOrderSuccess.short')
                })
                .then(() => {
                    order.flagged = !order.flagged
                    this.setFlaggedText(order)
                })
                .catch(() => {})
        },

        setCurrentYearAsMaximumForField (matchingFieldId, field) {
            if (field.id === matchingFieldId) {
                // may be off from the Berlin time zone by a few hours, but I can't see this ever playing a role
                field.maximum = this.getNowDate().getFullYear()
            }
        },

        evaluateValueForLocalFieldMusicInformationAvailable (matchingFieldId, field, appContext) {
            if (field.id === matchingFieldId) {
                const checkForExistingValuesForFieldIds = [
                    'work_title_music',
                    'composer_full_name',
                    'lyricist_full_name',
                    'music_publisher',
                    'artist_full_name',
                    'production_company_music',
                    'isrc',
                    'label_code',
                    'playing_time'
                ]
                const hasExistingValue = checkForExistingValuesForFieldIds.some(fieldId => {
                    const fieldValue = appContext.getFormFieldValue(appContext.formConfiguration, fieldId)
                    return fieldValue !== undefined && fieldValue !== null && fieldValue !== ''
                })
                appContext.setFormFieldValue(appContext.formConfiguration, matchingFieldId, hasExistingValue)
            }
        },

        prepareAndCreateNewOrder (selectSubmitEventData) {
            const type = selectSubmitEventData.option.id
            const assetTypeDefinition = this.getAssetTypeDefinition(type)
            this.formConfiguration = this.loadFormConfiguration(assetTypeDefinition.key)

            this.applyValuesToFormConfigurationAndReturnChanges(
                null,
                {},
                true,
                this.formConfiguration,
                assetTypeDefinition.fieldConfigFunction,
                this)

            const formValues = this.getExtractedFormValues()
            formValues.submitted = false
            this.createNewOrder(type, formValues)
        },

        createNewOrder (orderType, formValues) {
            this.axiosPut(
                `asset-order/${this.productInfo.id}/type/${orderType}`,
                formValues,
                this.$tc('product.assetOrders.saveOrderError'))
                .then(newOrder => {
                    this.setOrderInformation(newOrder)
                    this.setFormFieldValue(this.formConfiguration, 'custom_provider_id', newOrder.customProviderId)
                    this.currentOrder = newOrder
                    this.assetOrders.push(newOrder)
                })
        },

        tableRowDoubleClickHandler (order) {
            if (this.isOrderEditable(order)) {
                this.editOrder(order)
            } else {
                this.addCursorNotification({
                    type: 'info',
                    duration: 5000,
                    message: this.$tc('product.assetOrders.orderNotEditable')
                })
            }
        },

        editOrder (order, isCopy) {
            const assetTypeDefinition = this.getAssetTypeDefinition(order.type)
            this.formConfiguration = this.loadFormConfiguration(assetTypeDefinition.key)
            this.axiosGet(
                `asset-order/${this.productInfo.id}/contentRequest/${order.contentRequestId}/position/${order.positionId}/asset/${order.assetId}`,
                this.$t('product.assetOrders.getOrderInfoError', [order.title]))
                .then(orderInfo => {
                    const tempOrder = _.cloneDeep(order)
                    if (isCopy) {
                        tempOrder.submitted = false
                        delete orderInfo.custom_provider_id
                        delete tempOrder.contentRequestId
                        delete tempOrder.positionId
                        delete tempOrder.assetId
                    } else {
                        tempOrder.submitted = orderInfo.submitted
                    }

                    // Pre-fill the form values that already exist in the order
                    this.applyValuesToFormConfigurationAndReturnChanges(
                        null,
                        orderInfo,
                        false,
                        this.formConfiguration,
                        assetTypeDefinition.fieldConfigFunction,
                        this)
                    this.fileList = this.getFormFieldValue(this.formConfiguration, 'screenshots')
                    this.currentOrder = tempOrder
                })
                .catch(() => {})
        },

        copyOrder (order) {
            this.editOrder(order, true)
        },

        getExtractedFormValues () {
            const formValues = {}
            if (!this.formConfiguration) {
                return formValues
            }

            // Return values of each section as a concatenation of key-value-pairs
            this.formConfiguration.sections.forEach(section => {
                Object.assign(formValues, section.pages[0])
            })
            return formValues
        },

        formChangeHandler (formChangeEventData) {
            const changes = formChangeEventData.changes
            const assetTypeDefinition = this.getAssetTypeDefinition(this.currentOrder?.type)

            function transferValueToAllPages (fieldKey, fieldValue, appContext) {
                appContext.formConfiguration.sections.forEach(section => {
                    section.pages.forEach(page => {
                        page[fieldKey] = fieldValue
                    })
                })
            }

            function normalizeValueAndTestRegex (fieldId, fieldValue, appContext) {
                fieldValue = fieldValue.replaceAll(' ', '')
                fieldValue = fieldValue.replaceAll('-', '')
                const valueFulFillsRegexConditions = new RegExp(appContext.fieldsToNormalize[fieldId].regex, 'g').test(fieldValue)
                if (valueFulFillsRegexConditions) {
                    appContext.setFormFieldValue(
                        appContext.formConfiguration,
                        fieldId,
                        fieldValue)
                } else {
                    appContext.addNotification({
                        type: 'warning',
                        duration: 10000,
                        message: appContext.$tc(appContext.fieldsToNormalize[fieldId].errorMessage)
                    })
                }
            }

            const fieldsWithRegexForNormalization = Object.keys(this.fieldsToNormalize)
            Object.keys(changes).forEach(key => {
                if (assetTypeDefinition.repeatFieldOnAllPages === key) {
                    transferValueToAllPages(key, changes[key], this)
                } else if (fieldsWithRegexForNormalization.includes(key)) {
                    normalizeValueAndTestRegex(key, changes[key], this)
                }
            })
        },

        formSubmittableStateChanged (submittableChange) {
            this.isFormSubmittable = submittableChange
        },

        uploadFile (dropInputSubmitEventData) {
            function updateFileList (files, appContext) {
                appContext.setFormFieldValue(appContext.formConfiguration, 'screenshots', files)
                appContext.fileList = files
                appContext.uploadingFiles = false
            }

            this.uploadingFiles = true
            this.axiosPost(
                `asset-order/${this.productInfo.id}/contentRequest/${this.currentOrder.contentRequestId}/position/${this.currentOrder.positionId}/file`,
                dropInputSubmitEventData.value,
                this.$tc('product.assetOrders.addFileToOrderError'),
                {
                    standard: this.$tc('product.assetOrders.addFileToOrderSuccess.standard'),
                    short: this.$tc('product.assetOrders.addFileToOrderSuccess.short')
                })
                .then(uploadedFiles => updateFileList(uploadedFiles, this))
                .catch(() => {
                    this.axiosGet(
                        `asset-order/${this.productInfo.id}/contentRequest/${this.currentOrder.contentRequestId}/position/${this.currentOrder.positionId}/files`,
                        this.$tc('product.assetOrders.getFilesOfOrderError'))
                        .then(currentFiles => updateFileList(currentFiles, this))
                        .catch(() => {
                            // The fallback is to show the state before the upload.
                            const currentFiles = this.getFormFieldValue(this.formConfiguration, 'screenshots')
                            updateFileList(currentFiles, this)
                        })
                })
        },

        downloadFile (entryListClickEventData) {
            const file = entryListClickEventData.entry
            this.downloadFileFromUrl(`asset-order/${this.productInfo.id}/file-download/${file.composite_id}`)
        },

        deleteFile (entryListSubmitEventData) {
            const file = entryListSubmitEventData.entry
            const fileIndex = entryListSubmitEventData.index
            this.axiosDelete(
                `asset-order/${this.productInfo.id}/file-delete/${file.composite_id}`,
                null,
                this.$t('product.assetOrders.removeFileFromOrderError'),
                {
                    standard: this.$t('product.assetOrders.removeFileFromOrderSuccess.standard'),
                    short: this.$tc('product.assetOrders.removeFileFromOrderSuccess.short')
                })
                .then(() => this.fileList.splice(fileIndex, 1))
                .catch(error => console.error(error))
        },

        closeOrder () {
            const assetTypeDefinition = this.getAssetTypeDefinition(this.currentOrder.type)
            assetTypeDefinition.label = this.getAssetTypeLabel(this.currentOrder.type)
            this.formConfiguration = null
            this.fileList = []
            this.isCloseDisabled = false
            this.currentOrder = null
        },

        adjustCustomProviderInfoIfNecessary (currentOrder, formValues) {
            const isPictureOrder = currentOrder.type === 'PICTURE'
            const isProviderSearchHidden = !!formValues.asset_is_artwork
            const customProviderSearchHidden = isPictureOrder && isProviderSearchHidden
            if (customProviderSearchHidden) {
                // Special case that comes with PAU-2850:
                // A specific checkbox makes it so that the provider search is hidden.
                // We need to reset the customProviderId in that case, so that the now-invisible provider gets deleted
                // in the back end on save.
                delete currentOrder.customProviderId
                delete formValues.custom_provider_id // must be same name as used in asset-order-picture-form.json
            }
        },

        saveOrder (isOrderComplete, formSubmitEventData) {
            const isUpdate = this.currentOrder.assetId !== null && this.currentOrder.assetId !== undefined
            const formValues = this.getExtractedFormValues()
            formValues.assetId = this.currentOrder.assetId
            formValues.positionId = this.currentOrder.positionId
            formValues.contentRequestId = this.currentOrder.contentRequestId
            if (!this.currentOrder.submitted) {
                formValues.submitted = isOrderComplete
            }
            this.adjustCustomProviderInfoIfNecessary(this.currentOrder, formValues)
            this.axiosPut(
                `asset-order/${this.productInfo.id}/type/${this.currentOrder.type}`,
                formValues,
                this.$tc('product.assetOrders.saveOrderError'),
                {
                    standard: this.$tc('product.assetOrders.saveOrderSuccess.standard'),
                    short: this.$tc('product.assetOrders.saveOrderSuccess.short')
                })
                .then(assetOrder => {
                    this.setOrderInformation(assetOrder)
                    if (isUpdate) {
                        const orderAssetIds = this.assetOrders.map(order => order.assetId)
                        const orderIndex = orderAssetIds.indexOf(assetOrder.assetId)
                        // Set attachments manually, as they are not part of the rms response -> Hacky: better solution is that rms returns files in the response
                        assetOrder.attachments = formValues.screenshots?.map(file => file.file_name)
                        this.assetOrders.splice(orderIndex, 1, assetOrder)
                    } else {
                        this.assetOrders.push(assetOrder)
                    }
                    if (formSubmitEventData?.repeat) {
                        delete formValues.assetId
                        delete formValues.positionId
                        delete formValues.contentRequestId
                        delete formValues.custom_provider_id // must be same name as used in asset-order-picture-form.json
                        formValues.submitted = false
                        this.createNewOrder(this.currentOrder.type, formValues)
                    } else {
                        this.closeOrder()
                    }
                })
                .catch(() => {
                    this.isCloseDisabled = false
                })
        },

        showConfirmationDialogForClose () {
            if (this.isFormSubmittable) {
                this.$swal({
                    title: this.$tc('product.assetOrders.abortUnsavedChangesPopup.title'),
                    html: this.$tc('product.assetOrders.abortUnsavedChangesPopup.saveText'),
                    icon: 'warning',
                    input: 'checkbox',
                    inputPlaceholder: this.$tc('product.assetOrders.abortUnsavedChangesPopup.checkboxLabel'),
                    showConfirmButton: true,
                    confirmButtonText: this.$tc('product.assetOrders.abortUnsavedChangesPopup.confirmText'),
                    showCancelButton: true,
                    cancelButtonText: this.$tc('generals.cancel'),
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    if (result.isConfirmed) {
                        result.value === 1 // checkbox value === true
                            ? this.saveOrder(true)
                            : this.closeOrder()
                    } else {
                        this.isCloseDisabled = false
                    }
                })
            } else { // not submittable => we can just close and abort
                this.$swal({
                    title: this.$tc('product.assetOrders.abortUnsavedChangesPopup.title'),
                    html: this.$tc('product.assetOrders.abortUnsavedChangesPopup.abortText'),
                    icon: 'warning',
                    showConfirmButton: true,
                    confirmButtonText: this.$tc('product.assetOrders.abortUnsavedChangesPopup.confirmText'),
                    showCancelButton: true,
                    cancelButtonText: this.$tc('generals.cancel'),
                    allowOutsideClick: false,
                    allowEscapeKey: false
                }).then(result => {
                    if (result.isConfirmed) {
                        this.closeOrder()
                    } else {
                        this.isCloseDisabled = false
                    }
                })
            }
        },

        closeFormView () {
            this.isCloseDisabled = true
            if (this.currentOrder?.submitted) {
                this.showConfirmationDialogForClose()
            } else {
                this.saveOrder(false)
            }
        },

        removeOrder (order) {
            this.axiosDelete(
                `asset-order/${this.productInfo.id}/contentRequest/${order.contentRequestId}/position/${order.positionId}`,
                null,
                this.$t('product.assetOrders.removeOrderError', [order.title]),
                {
                    standard: this.$t('product.assetOrders.removeOrderSuccess.standard', [order.title]),
                    short: this.$tc('product.assetOrders.removeOrderSuccess.short')
                })
                .then(() => {
                    // Grey out submitted orders and remove those which are not submitted
                    if (order.submitted) {
                        order.flagged = false
                        order.state = this.orderStates.ORDER_CANCELED
                        order.isDisabled = true
                    } else {
                        const orderIds = this.assetOrders.map(order => order.id)
                        const index = orderIds.indexOf(order.id)
                        this.assetOrders.splice(index, 1)
                    }
                })
                .catch(() => {})
        },

        getCommentCreationTime (comment) {
            return this.getFormattedTime(comment.createdAt, this.locale)
        },

        getCommenterRole (comment) {
            return comment.textSource === 'EDITOR' ? 'Red' : 'MM'
        },

        getCommentUserName (comment) {
            return `${comment.firstName || ''} ${comment.lastName || ''}`.trim()
        },

        isMyComment (comment) {
            return this.getCommentUserName(comment) === this.getUserFirstLastName()
        },

        hasSameDayAsPreviousComment (order, currentCommentIndex) {
            const currentComment = order.comments[currentCommentIndex]
            const previousComment = order.comments[currentCommentIndex - 1]

            // the previous existing code only compared the day of month (getDate()), which may have been a bug
            return previousComment
                ? this.toBerlinDateAsISOString(currentComment.createdAt) === this.toBerlinDateAsISOString(previousComment.createdAt)
                : false
        },

        hasSameAuthorAsPreviousComment (order, currentCommentIndex) {
            const currentComment = order.comments[currentCommentIndex]
            const previousComment = order.comments[currentCommentIndex - 1]

            return previousComment
                ? this.getCommentUserName(currentComment) === this.getCommentUserName(previousComment)
                : false
        },

        saveCommentOnOrder (inputSubmitEventData, order) {
            const comment = inputSubmitEventData.value
            if (comment && comment !== '') {
                const newComment = {
                    text: comment
                }
                this.axiosPost(
                    `asset-order/${this.productInfo.id}/contentRequest/${order.contentRequestId}/position/${order.positionId}/comment`,
                    newComment,
                    this.$t('product.assetOrders.addCommentOnOrderError', [order.title]),
                    {
                        standard: this.$t('product.assetOrders.addCommentOnOrderSuccess.standard', [order.title]),
                        short: this.$tc('product.assetOrders.addCommentOnOrderSuccess.short')
                    })
                    .then(allComments => {
                        order.comments = allComments
                        order.latestCommentCreationDateTime = this.getLatestCommentForOrder(order)?.createdAt
                        setTimeout(() => {
                            const commentsContainer = document.getElementById('product-asset-orders-modal-comments')
                            commentsContainer.scrollTop = commentsContainer.scrollHeight
                        }, 50)
                    })
                    .catch(() => {})
            }
        },

        getLatestCommentForOrder (order) {
            return order.comments?.at(-1)
        },

        hasAttachments (order) {
            return order.attachments?.length > 0
        },

        getAttachmentsCount (order) {
            return order.attachments?.length || 0
        },

        getAttachmentsTooltip (order) {
            return order.attachments?.join(',\n')
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getTableConfig () {
            return [{
                key: 'assetTypeDisplayText',
                label: this.$tc('product.assetOrders.table.assetType'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 5
            }, {
                key: 'title',
                label: this.$tc('product.assetOrders.table.motif'),
                filterable: true,
                sortable: true,
                breakAnywhere: true,
                alignment: 'left',
                exportable: true,
                width: 12.5
            }, {
                key: 'usage',
                label: this.$tc('product.assetOrders.table.usage'),
                filterable: true,
                sortable: true,
                breakAnywhere: true,
                alignment: 'left',
                exportable: true,
                width: 10
            }, {
                key: 'source',
                label: this.$tc('product.assetOrders.table.source'),
                filterable: true,
                sortable: true,
                breakAnywhere: true,
                alignment: 'left',
                exportable: true,
                width: 0
            }, {
                key: 'sourceReference',
                label: this.$tc('product.assetOrders.table.reference'),
                filterable: true,
                sortable: true,
                breakAnywhere: true,
                alignment: 'left',
                exportable: true,
                width: 12.5
            }, {
                key: 'licensing',
                label: this.$tc('product.assetOrders.table.licensing'),
                tooltip: this.$tc('product.assetOrders.table.licensingToolTip'),
                filterable: true,
                sortable: true,
                breakAnywhere: true,
                alignment: 'left',
                exportable: true,
                width: 7.5
            }, {
                key: 'createdAtDisplayText',
                sortKey: 'createdAt',
                label: this.$tc('product.assetOrders.table.createdAt'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 7.5
            }, {
                key: 'updatedAtDisplayText',
                sortKey: 'updatedAt',
                label: this.$tc('product.assetOrders.table.updatedAt'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 7.5
            }, {
                key: 'comments',
                sortKey: 'latestCommentCreationDateTime',
                label: this.$tc('product.assetOrders.table.comments'),
                filterable: false,
                sortable: true,
                breakAnywhere: true,
                alignment: 'left',
                width: 10
            }, {
                key: 'state',
                label: this.$tc('product.assetOrders.table.state'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 7.5
            }, {
                key: 'attachments',
                label: this.$tc('product.assetOrders.table.attachments'),
                alignment: 'left',
                exportable: true,
                width: 5
            }, {
                key: 'flaggedFilterText',
                filterKey: 'flaggedFilterText',
                label: this.$tc('product.assetOrders.table.flagged'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 5
            }, {
                key: 'action',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'right',
                width: 10
            }]
        },

        isAllowedToPlaceAndEditOrders () {
            return this.productInfo?.userRights?.isAllowedToEditAssetOrders
        },

        isFormTableOfContentsHidden () {
            return !this.formConfiguration ||
                this.formConfiguration.sections.length === 1
        },

        getAvailableAssetOrderTypes () {
            return this.assetTypes.filter(type => !type.hidden)
        }
    },
    mounted () {
        this.getOrders()
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="product-asset-orders-container">
            <transition-group name="fade">
                <div v-show="!currentOrder"
                     key="table"
                     class="product-asset-orders-table-container">
                    <SmartSelect id="order-forms-select"
                                 class="product-asset-orders-table-select"
                                 v-bind:placeholder="$tc('product.assetOrders.placeNewOrder')"
                                 v-bind:is-disabled="!isAllowedToPlaceAndEditOrders"
                                 v-bind:allow-input="false"
                                 v-bind:submit-button="false"
                                 v-bind:clear-input-on-submit="true"
                                 v-bind:sort-options="false"
                                 v-bind:options="getAvailableAssetOrderTypes"
                                 v-bind:option-label-specifiers="['label']"
                                 @select-change="prepareAndCreateNewOrder($event)">
                    </SmartSelect>
                    <Table table-id="productAssetOrders"
                           class="product-asset-orders-table"
                           v-bind:table-config="getTableConfig"
                           v-bind:table-data="assetOrders"
                           v-bind:filter-placeholder="$tc('product.assetOrders.table.filterPlaceholder')"
                           v-bind:table-empty-message="$tc('product.assetOrders.table.tableEmpty')"
                           v-bind:filter-no-results-message="$tc('product.assetOrders.table.filterNoResults')"
                           v-bind:show-spinner="showSpinner"
                           v-bind:read-only=false
                           v-bind:allow-fullscreen="true"
                           v-bind:custom-user-filter="true"
                           v-bind:highlight-selected="true"
                           v-bind:selection-id="selectedOrder ? selectedOrder.id : null"
                           default-sorting-key="updatedAt"
                           default-sorting-direction="desc"
                           @row-click="setOrderSelected($event.row)"
                           @row-double-click="tableRowDoubleClickHandler($event.row)">
                        <template #cell(assetTypeDisplayText)="data">
                            <Icon v-bind:icon-class="getAssetTypeDefinition(data.row.type).icon"
                                  v-bind:tooltip="data.row.assetTypeDisplayText"
                                  v-bind:icon-large="true">
                            </Icon>
                        </template>
                        <!-- title: default cell content -->
                        <!-- usage: default cell content -->
                        <!-- source: default cell content -->
                        <!-- sourceReference: default cell content -->
                        <template #cell(createdAtDisplayText)="data">
                            <div v-bind:title="getFormattedLongDate(data.row.createdAt, this.locale)">
                                <span>{{data.row.createdAtDisplayText}}</span>
                            </div>
                        </template>
                        <template #cell(updatedAtDisplayText)="data">
                            <div v-bind:title="getFormattedLongDate(data.row.updatedAt,this.locale)">
                                <span>{{data.row.updatedAtDisplayText}}</span>
                            </div>
                        </template>
                        <template #cell(comments)="data">
                            <div v-if="data.row.comments?.length > 0"
                                 class="product-asset-orders-table-comments">
                                <div>{{ getFormattedShortDate(getLatestCommentForOrder(data.row).createdAt, this.locale)
                                    }}</div>
                                <div>{{getCommentUserName(getLatestCommentForOrder(data.row))}} ({{getCommenterRole(getLatestCommentForOrder(data.row))}})</div>
                                <div class="product-asset-orders-table-comment">{{getLatestCommentForOrder(data.row).text}}</div>
                            </div>
                            <span v-else>-</span>
                        </template>
                        <template #cell(state)="data">
                            <div v-bind:title="getOrderStateTooltip(data.row)">
                                <span v-bind:class="getOrderStateColor(data.row)">{{data.row.state}}</span>
                            </div>
                        </template>
                        <template #cell(attachments)="data">
                            <BadgeInput v-if="hasAttachments(data.row)"
                                        class="product-asset-orders-table-attachments-tag"
                                        v-bind:is-disabled="true"
                                        v-bind:value="getAttachmentsCount(data.row)"
                                        v-bind:tooltip="getAttachmentsTooltip(data.row)">
                            </BadgeInput>
                            <span v-else>–</span>
                        </template>
                        <template #cell(flaggedFilterText)="data">
                            <div v-if="!isUnknownOrderType(data.row)"
                                 class="product-asset-orders-table-flagged-container"
                                 v-bind:class="{'m--disabled': isRemovedOrder(data.row)}"
                                 v-on:click="!toggleOrderFlagged($event, data.row)">
                                <Icon class="product-asset-orders-table-flagged"
                                      v-bind:icon-class="data.row.flagged ? 'fas fa-flag' : 'far fa-flag'"
                                      v-bind:icon-type="data.row.flagged ? 'error' : 'inactive'"
                                      v-bind:tooltip="data.row.flagged
                                          ? $tc('product.assetOrders.table.unsetClarification')
                                          : $tc('product.assetOrders.table.setClarification')"
                                      v-bind:icon-large=true>
                                </Icon>
                            </div>
                            <span v-else>-</span>
                        </template>
                        <template #cell(action)="data">
                            <IconButton icon-class="far fa-copy"
                                        v-bind:tooltip="$tc('product.assetOrders.table.copyOrder')"
                                        v-bind:is-disabled="!isOrderEditable(data.row)"
                                        @button-submit="copyOrder(data.row)">
                            </IconButton>
                            <VerticalLine></VerticalLine>
                            <IconButton icon-class="fas fa-edit"
                                        v-bind:tooltip="$tc('product.assetOrders.table.editOrder')"
                                        v-bind:is-disabled="!isOrderEditable(data.row)"
                                        @button-submit="editOrder(data.row)">
                            </IconButton>
                            <VerticalLine></VerticalLine>
                            <InlineConfirmButton icon-class="fas fa-trash"
                                                 v-bind:tooltip="$tc('product.assetOrders.table.removeOrder')"
                                                 v-bind:confirm-message="$tc('generals.confirmDelete')"
                                                 v-bind:is-disabled="!isOrderEditable(data.row)"
                                                 @button-submit="removeOrder(data.row)">
                            </InlineConfirmButton>
                        </template>
                    </Table>
                    <ModalContainer v-if="selectedOrder"
                                    class="product-asset-orders-modal-container"
                                    v-bind:is-expanded="orderCommentsExpanded"
                                    v-bind:modal-title="$tc('product.assetOrders.modal.commentHistory')"
                                    v-bind:modal-subtitle="getFormattedLongDate(selectedOrder.createdAt, this.locale)"
                                    v-bind:modal-width="45"
                                    @modal-close="setOrderCommentsExpanded(false)">
                        <template v-slot>
                            <div id="product-asset-orders-modal-comments"
                                 class="product-asset-orders-modal-comments">
                                <div v-if="!selectedOrder.comments || selectedOrder.comments.length === 0"
                                     class="product-asset-orders-modal-comments-empty"
                                     v-dompurify-html="$tc('product.assetOrders.modal.noCommentHistory')">
                                </div>
                                <template v-else v-for="(comment, index) in selectedOrder.comments" :key="`wrapper_${index}`" >
                                    <SmartFormSubheading v-if="!hasSameDayAsPreviousComment(selectedOrder, index)"
                                                         v-bind:key="`heading_${index}`"
                                                         v-bind:label="getFormattedShortDate(comment.createdAt, this.locale)"
                                                         class="product-asset-orders-modal-comment-subheading"
                                                         v-bind:class="{'m--small-margin': index === 0}">
                                    </SmartFormSubheading>
                                    <div class="product-asset-orders-modal-comment-wrapper"
                                         v-bind:class="{'m--small-margin': index === 0 ||
                                         (hasSameAuthorAsPreviousComment(selectedOrder, index) && hasSameDayAsPreviousComment(selectedOrder, index))}">
                                        <span v-if="!hasSameAuthorAsPreviousComment(selectedOrder, index) || !hasSameDayAsPreviousComment(selectedOrder, index)"
                                              class="product-asset-orders-modal-comment-caret fas fa-caret-down"
                                              v-bind:class="{
                                                  'm--highlighted': isMyComment(comment),
                                                  'm--align-right': getCommenterRole(comment) === 'MM'
                                              }">
                                        </span>
                                        <div class="product-asset-orders-modal-comment"
                                             v-bind:class="{
                                                 'm--highlighted': isMyComment(comment),
                                                 'm--align-right': getCommenterRole(comment) === 'MM',
                                                 'm--no-caret': hasSameAuthorAsPreviousComment(selectedOrder, index) && hasSameDayAsPreviousComment(selectedOrder, index)
                                             }">
                                            <div v-if="index === 0 ||
                                                 !hasSameAuthorAsPreviousComment(selectedOrder, index) ||
                                                 !hasSameDayAsPreviousComment(selectedOrder, index)"
                                                 class="product-asset-orders-modal-comment-username">
                                                <span>{{getCommentUserName(comment)}} ({{getCommenterRole(comment)}})</span>
                                            </div>
                                            <div class="product-asset-orders-modal-comment-text"
                                                 v-dompurify-html="comment.text">
                                            </div>
                                            <div class="product-asset-orders-modal-comment-time">
                                                <span>{{getCommentCreationTime(comment)}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                            <div class="product-asset-orders-modal-textarea">
                                <AreaInput v-bind:fill-available-space="true"
                                           v-bind:submit-button="true"
                                           v-bind:placeholder="$tc('product.assetOrders.modal.enterCommentHere')"
                                           v-bind:reset-on-submit="true"
                                           v-bind:is-disabled="!isOrderEditable(selectedOrder)"
                                           v-bind:is-expandable="false"
                                           v-bind:max-length=500
                                           icon-class="far fa-paper-plane"
                                           @input-submit="saveCommentOnOrder($event, selectedOrder)">
                                </AreaInput>
                            </div>
                        </template>
                    </ModalContainer>
                </div>
                <div v-if="currentOrder"
                     key="form"
                     id="product-asset-orders-form-container"
                     class="product-asset-orders-form-container">
                    <div class="product-asset-orders-form-header">
                        <div class="product-asset-orders-form-close"
                             data-testid="close-form-link"
                             v-bind:class="{'m--disabled': isCloseDisabled}"
                             v-on:click="closeFormView()">
                            <span class="fas fa-arrow-left"></span>
                            <span class="product-asset-orders-form-close-title">{{$tc('product.assetOrders.backToOrderList')}}</span>
                        </div>
                    </div>
                    <EssentialSmartForm v-bind:form-config="formConfiguration"
                                        v-bind:allow-repetition="true"
                                        v-bind:repeat-form-button-label="$tc('product.assetOrders.repeatOrderButtonLabel')"
                                        v-bind:hide-submit-button="false"
                                        v-bind:hide-table-of-contents="isFormTableOfContentsHidden"
                                        @form-submit="saveOrder(true, $event)"
                                        @form-change="formChangeHandler($event)"
                                        @form-submittable-change="formSubmittableStateChanged($event)">
                        <template #field(screenshots)="data">
                            <DropInput v-bind:label="data.field.label"
                                       v-bind:placeholder="data.field.placeholder"
                                       v-bind:file-types="['.png', '.jpg', '.jpeg', '.tif', '.tiff', '.gif', '.pdf', '.doc', '.docx']"
                                       v-bind:allow-multiple="true"
                                       v-bind:use-default-label="true"
                                       v-bind:upload-limit="5"
                                       v-bind:is-uploading="uploadingFiles"
                                       @input-submit="uploadFile($event)">
                            </DropInput>
                            <EntryList v-if="fileList.length > 0"
                                       class="product-asset-orders-form-files"
                                       entry-label-key="file_name"
                                       entry-disabled-key="is_deleted"
                                       icon-class="fas fa-trash"
                                       v-bind:entries="fileList"
                                       v-bind:enable-click="true"
                                       v-bind:enable-submit="true"
                                       @entry-click="downloadFile($event)"
                                       @entry-submit="deleteFile($event)">
                            </EntryList>
                        </template>
                    </EssentialSmartForm>
                </div>
            </transition-group>
        </div>
    </div>
</template>

<style lang="less">
.product-asset-orders-container {
    position: relative;
    width: 100%;
    height: 100%;

    .product-asset-orders-table-container {
        height: 100%;

        .product-asset-orders-table-select {
            position: absolute;
            z-index: var(--z-index-header-button);

            ::placeholder {
                color: var(--color-text-mid);
            }
        }

        .product-asset-orders-table {
            .c_table-excel-generate-button {
                left: calc(var(--input-width) + 8px);
            }
        }

        .product-asset-orders-table-comments {
            line-break: anywhere;

            .product-asset-orders-table-comment {
                margin-top: 15px;
                line-break: unset;
            }
        }

        .product-asset-orders-table-attachments-tag {
            margin-top: -4px;
        }

        .product-asset-orders-table-flagged-container {

            &.m--disabled {
                pointer-events: none;
            }

            .product-asset-orders-table-flagged {
                &:hover {
                    .c_icon {
                        cursor: pointer;
                        color: var(--color-icon-active);
                    }
                }
            }
        }

        .product-asset-orders-modal-container {

            .product-asset-orders-modal-comments {
                position: relative;
                width: 100%;
                height: calc(100% - var(--product-asset-orders-textarea-height));
                overflow-y: auto;

                .product-asset-orders-modal-comments-empty {
                    margin-top: 10px;
                    text-align: center;
                }

                .product-asset-orders-modal-comment-subheading {
                    margin: 40px 0 25px;

                    &.m--small-margin {
                        margin-top: 20px;
                    }
                }

                .product-asset-orders-modal-comment-wrapper {
                    position: relative;
                    display: table;
                    width: 100%;
                    margin-top: var(--container-spacing);

                    &.m--small-margin {
                        margin-top: 4px;
                    }

                    .product-asset-orders-modal-comment-caret {
                        position: relative;
                        float: left;
                        top: -9.5px;
                        font-size: 26px;
                        color: var(--color-background-light);

                        &.m--highlighted {
                            color: var(--color-background-highlighted);
                        }

                        &.m--align-right {
                            float: right;
                        }
                    }

                    .product-asset-orders-modal-comment {
                        float: left;
                        max-width: 85%;
                        background-color: var(--color-background-light);
                        border-radius: 4px 12px 12px 12px;
                        margin: 0 0 0 -9px;
                        padding: 12px;

                        &.m--highlighted {
                            background-color: var(--color-background-highlighted);
                            color: var(--color-text-bright);
                        }

                        &.m--align-right {
                            border-radius: 12px 4px 12px 12px;
                            margin: 0 -9px 0 0;
                            float: right;

                            &.m--no-caret {
                                margin: 0 7px 0 0;
                                border-radius: 12px;
                            }
                        }

                        &.m--no-caret {
                            margin: 0 0 0 7px;
                            border-radius: 12px;
                        }

                        .product-asset-orders-modal-comment-username,
                        .product-asset-orders-modal-comment-text {
                            white-space: pre-wrap;
                            position: relative;
                            width: auto;
                        }

                        .product-asset-orders-modal-comment-username {
                            font-family: "Source Sans Pro Bold", sans-serif;
                            margin-bottom: 8px;
                        }

                        .product-asset-orders-modal-comment-time {
                            float: right;
                            font-size: 12px;
                            margin: 8px 0 -6px 0;
                        }
                    }
                }
            }

            .product-asset-orders-modal-textarea {
                position: relative;
                width: 100%;
                height: var(--product-asset-orders-textarea-height);
                padding-top: var(--container-spacing);
            }
        }
    }

    .product-asset-orders-form-container {
        height: calc(100% - var(--table-head-height));

        .product-asset-orders-form-header {
            display: flex;
            width: 100%;
            height: var(--table-head-height);
            align-items: center;
            justify-content: space-between;
            margin: -8px 0 8px 0;

            .product-asset-orders-form-close {
                &.m--disabled {
                    pointer-events: none;
                }

                &:hover {
                    color: var(--color-text-highlighted);
                    cursor: pointer;
                }

                .product-asset-orders-form-close-title {
                    font-family: "Source Sans Pro", sans-serif;
                    margin-left: 5px;
                }
            }
        }

        .product-asset-orders-form-files {
            float: right;
            margin-bottom: var(--container-spacing);
            z-index: 1;
        }
    }
}
</style>
