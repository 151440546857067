/**
 * A reusable service for handling copy functions.
 **/
import { notificationHandler } from '@/mixins/notificationHandler'

export const copyHandler = {
    mixins: [
        notificationHandler
    ],
    methods: {
        copyValueToClipboard (value, showSuccessMessage) {
            navigator.clipboard.writeText(value)
                .then(() => {
                    if (showSuccessMessage) {
                        this.addCursorNotification({
                            type: 'success',
                            duration: 1500,
                            message: this.$tc('generals.copiedToClipboard')
                        })
                    }
                })
        }
    }
}
