import { axiosService } from '@/mixins/axiosService'

export const contentHelper = {
    mixins: [
        axiosService
    ],
    data () {
        return {
            basePathContent: 'contents/v2'
        }
    },
    methods: {
        getContentRound (productId, contentId) {
            return this.axiosGet(
                `${this.basePathContent}/${productId}/${contentId}/round`,
                this.$t('product.contents.fetchContentRoundError'))
        },
        getRoundOfField (productId, contentId, fieldName) {
            return this.axiosGet(
                `${this.basePathContent}/${productId}/${contentId}/${fieldName}/round`,
                this.$t('product.contents.fetchContentRoundError'))
        }
    }
}
