<script>
/**
 * Migrations overview sub view | Sub view of admin controlling
 *
 * @property {String} resultMessage
 * @property {String} processKey
 * @property {String} targetProcessDefinitionId
 **/
import Table from '@/components/Table.vue'
import Icon from '@/components/Icon.vue'
import ModalContainer from '@/components/ModalContainer.vue'
import { axiosService } from '@/mixins/axiosService'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'MigrationsOverview',
    mixins: [
        axiosService,
        dateTimeHelper
    ],
    components: {
        Table,
        Icon,
        ModalContainer
    },
    data () {
        return {
            processMigrationsList: [],
            selectedMigration: {},
            migrationDetailsExpanded: false,
            showSpinner: true,
            maxPartsCharSize: 0,
            migrationStatus: {
                COMPLETED: 'COMPLETED',
                IN_PROGRESS: 'IN_PROGRESS',
                CONFLICT: 'CONFLICT'
            },
            migrationPartStatus: {
                COMPLETED: 'COMPLETED',
                CONFLICT: 'CONFLICT'
            },
            migrationPartResults: {
                SUCCESS: 'SUCCESS',
                FAIL: 'FAIL'
            }
        }
    },
    methods: {
        getMigrationsList () {
            const aWeekAgoToday = this.getNowDate()
            aWeekAgoToday.setDate(aWeekAgoToday.getDate() - 7)
            this.axiosGet(
                `migration/batches?minCreatedAt=${aWeekAgoToday.toISOString()}`,
                this.$tc('administration.controlling.migrationsOverview.getMigrationsListError'))
                .then(processMigrationsList => {
                    processMigrationsList.forEach(migration => {
                        migration.id = migration.batchId
                        migration.instancesCount = migration.parts.length
                        migration.successCount = migration.parts.filter(part => part.result === this.migrationPartResults.SUCCESS).length
                        migration.failedCount = migration.parts.filter(part => part.result === this.migrationPartResults.FAIL).length

                        // Calculate necessary space to make the number of instances look consistent
                        const charSize = migration.parts.length.toString().length
                        if (this.maxPartsCharSize < charSize * 2 + 2) {
                            this.maxPartsCharSize = charSize * 2 + 2
                        }
                        this.getMigrationInformation(migration)
                    })
                    this.processMigrationsList = processMigrationsList
                    this.showSpinner = false
                })
                .catch(() => {})
        },

        setSelected (rowClickEventData) {
            this.selectedMigration = rowClickEventData.row
            setTimeout(() => {
                this.setMigrationDetailsExpanded(true)
            }, 50)
        },

        setMigrationDetailsExpanded (isExpanded) {
            this.migrationDetailsExpanded = isExpanded
            if (!isExpanded) {
                this.selectedMigration = {}
            }
        },

        isMigrationCompletedWithErrors (migration) {
            return migration.status === this.migrationStatus.COMPLETED &&
                   migration.parts.some(part => part.result === this.migrationPartResults.FAIL)
        },

        getMigrationInformation (migration) {
            migration.statusText = this.getMigrationStatus(migration)
            migration.createdAtDisplayText = this.getFormattedLongDate(migration.createdAt, this.locale)
            if (migration.completedAt) {
                migration.completedAtDisplayText = this.getFormattedLongDate(migration.completedAt, this.locale)
            }
            migration.parts.forEach(part => {
                if (part.status === this.migrationPartStatus.CONFLICT) {
                    part.resultMessage = this.$tc('administration.controlling.migrationsOverview.instancesTable.conflictMessage')
                }
            })
        },

        getMigrationStatus (migration) {
            if (this.isMigrationCompletedWithErrors(migration)) {
                return this.$tc('administration.controlling.migrationsOverview.migrationsTable.migrationStatus.COMPLETED_WITH_ERRORS')
            }
            return this.$tc(`administration.controlling.migrationsOverview.migrationsTable.migrationStatus.${migration.status}`)
        },

        getMigrationIconClass (migration) {
            switch (migration.status) {
            case this.migrationStatus.COMPLETED:
                return 'fas fa-flag-checkered'
            case this.migrationStatus.IN_PROGRESS:
                return 'fas fa-tasks'
            case this.migrationStatus.CONFLICT:
                return 'fas fa-bolt'
            default:
                return 'far fa-pause-circle'
            }
        },

        getMigrationIconType (migration) {
            if (this.isMigrationCompletedWithErrors(migration)) {
                return 'error'
            }
            switch (migration.status) {
            case this.migrationStatus.COMPLETED:
                return 'dark'
            case this.migrationStatus.IN_PROGRESS:
            case this.migrationStatus.CONFLICT:
                return 'warning'
            default:
                return 'default'
            }
        },

        getMigrationStatusTextType (migration) {
            if (this.isMigrationCompletedWithErrors(migration)) {
                return 'm--error'
            }
            switch (migration.status) {
            case this.migrationStatus.COMPLETED:
                return 'm--success'
            case this.migrationStatus.IN_PROGRESS:
            case this.migrationStatus.CONFLICT:
                return 'm--warning'
            default:
                return 'default'
            }
        },

        getVersionNumber (versionId) {
            if (!versionId) {
                return 'X'
            }
            const versionPart = versionId.substring(versionId.indexOf(':') + 1)
            return versionPart.substring(0, versionPart.indexOf(':'))
        },

        getInstanceIconClass (instance) {
            if (instance.status !== this.migrationPartStatus.COMPLETED) {
                return instance.status === this.migrationPartStatus.CONFLICT
                    ? 'fas fa-bolt'
                    : 'far fa-pause-circle'
            }
            return instance.result === this.migrationPartResults.SUCCESS
                ? 'far fa-check-circle'
                : 'fas fa-minus-circle'
        },

        getInstanceIconType (instance) {
            if (instance.status !== this.migrationPartStatus.COMPLETED) {
                return 'warning'
            } else {
                return instance.result === this.migrationPartResults.SUCCESS
                    ? 'success'
                    : 'error'
            }
        },

        getInstanceIconTooltip (instance) {
            if (instance.status !== this.migrationPartStatus.COMPLETED) {
                return instance.status === this.migrationPartStatus.CONFLICT
                    ? this.$tc('administration.controlling.migrationsOverview.instancesTable.instanceStatus.CONFLICT')
                    : this.$tc('administration.controlling.migrationsOverview.instancesTable.instanceStatus.PENDING')
            }
            return this.$tc(`administration.controlling.migrationsOverview.instancesTable.instanceStatus.${instance.result}`)
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getMigrationsTableConfig () {
            return [{
                key: 'statusIcon',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: 5
            }, {
                key: 'name',
                label: this.$tc('administration.controlling.migrationsOverview.migrationsTable.processName'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 20
            }, {
                key: 'createdAtDisplayText',
                sortKey: 'createdAt',
                label: this.$tc('administration.controlling.migrationsOverview.migrationsTable.createdAt'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 15
            }, {
                key: 'completedAtDisplayText',
                sortKey: 'completedAt',
                label: this.$tc('administration.controlling.migrationsOverview.migrationsTable.completedAt'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 15
            }, {
                key: 'version',
                label: this.$tc('administration.controlling.migrationsOverview.migrationsTable.version'),
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: 10
            }, {
                key: 'instancesCount',
                label: this.$t('administration.controlling.migrationsOverview.migrationsTable.instancesCount'),
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: 20
            }, {
                key: 'statusText',
                label: this.$tc('administration.controlling.migrationsOverview.migrationsTable.status'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 15
            }]
        },
        getInstancesTableConfig () {
            return [{
                key: 'status',
                label: this.$tc('administration.controlling.migrationsOverview.instancesTable.status'),
                filterable: false,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 15
            }, {
                key: 'id',
                label: this.$tc('administration.controlling.migrationsOverview.instancesTable.processInstanceId'),
                filterable: false,
                sortable: true,
                alignment: 'left',
                copyable: true,
                width: 35
            }, {
                key: 'resultMessage',
                label: this.$tc('administration.controlling.migrationsOverview.instancesTable.errorMessage'),
                filterable: false,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 50
            }]
        }
    },
    watch: {
        '$global.localization.locale': {
            handler: function () {
                this.processMigrationsList.forEach(migration => {
                    this.getMigrationInformation(migration)
                })
            },
            deep: true
        }
    },
    mounted () {
        this.getMigrationsList()
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="process-migrations-table-container">
            <Table table-id="processMigrationOverview"
                   v-bind:table-config="getMigrationsTableConfig"
                   v-bind:table-data="processMigrationsList"
                   v-bind:filter-placeholder="$tc('administration.controlling.migrationsOverview.migrationsTable.filterPlaceholder')"
                   v-bind:table-empty-message="$tc('administration.controlling.migrationsOverview.migrationsTable.tableEmpty')"
                   v-bind:filter-no-results-message="$tc('administration.controlling.migrationsOverview.migrationsTable.filterNoResults')"
                   v-bind:read-only="false"
                   v-bind:show-spinner="showSpinner"
                   v-bind:selection-id="selectedMigration.id"
                   v-bind:highlight-selected="true"
                   default-sorting-key="createdAt"
                   default-sorting-direction="desc"
                   @row-click="setSelected($event)">
                <template #cell(statusIcon)="data">
                    <Icon v-bind:key="data.row.id"
                          v-bind:tooltip="data.row.statusText"
                          v-bind:icon-class="getMigrationIconClass(data.row)"
                          v-bind:icon-type="getMigrationIconType(data.row)"
                          v-bind:icon-large="true">
                    </Icon>
                </template>
                <!-- name: default cell content -->
                <!-- createdAtDisplayText: default cell content -->
                <!-- completedAtDisplayText: default cell content -->
                <template #cell(version)="data">
                    <div v-bind:key="data.row.id">
                        <span class="process-migration-version-modification-from">{{getVersionNumber(data.row.sourceProcessDefinitionId)}}</span>
                        <span class="process-migration-version-modification-arrow fas fa-arrow-right"></span>
                        <span class="process-migration-version-modification-to">{{getVersionNumber(data.row.targetProcessDefinitionId)}}</span>
                    </div>
                </template>
                <template #cell(instancesCount)="data">
                    <div class="process-migration-instances-count">
                        <span v-bind:style="{'min-width': `${maxPartsCharSize}ch`}">{{data.row.successCount + data.row.failedCount}}/{{data.row.instancesCount}}</span>(
                        <Icon class="process-migration-instances-count-icon"
                              icon-class="fas fa-check-circle"
                              icon-type="success">
                        </Icon><span>{{data.row.successCount + ' |'}}</span>
                        <Icon class="process-migration-instances-count-icon"
                              icon-class="fas fa-times-circle"
                              icon-type="error">
                        </Icon><span>{{data.row.failedCount + ' )'}}</span>
                    </div>
                </template>
                <template #cell(statusText)="data">
                    <div class="process-migration-status-text"
                         v-bind:key="data.row.id"
                         v-bind:class="getMigrationStatusTextType(data.row)">
                        <span>{{data.row.statusText}}</span>
                    </div>
                </template>
            </Table>
        </div>
        <ModalContainer v-if="selectedMigration.id"
                        v-bind:is-expanded="migrationDetailsExpanded"
                        v-bind:modal-title="$t('administration.controlling.migrationsOverview.modalTitle', [selectedMigration.createdAtDisplayText]).toString()"
                        v-bind:modal-subtitle="selectedMigration.statusText"
                        v-bind:modal-width-percent="75"
                        @modal-close="setMigrationDetailsExpanded(false)">
            <template v-slot>
                <Table table-id="processMigrationInstances"
                       v-bind:table-config="getInstancesTableConfig"
                       v-bind:table-data="selectedMigration.parts"
                       v-bind:filter-placeholder="$tc('administration.controlling.migrationsOverview.instancesTable.filterPlaceholder')"
                       v-bind:table-empty-message="$tc('administration.controlling.migrationsOverview.instancesTable.tableEmpty')"
                       v-bind:filter-no-results-message="$tc('administration.controlling.migrationsOverview.instancesTable.filterNoResults')"
                       v-bind:read-only=true>
                    <template #cell(status)="data">
                        <Icon v-bind:tooltip="getInstanceIconTooltip(data.row)"
                              v-bind:icon-class="getInstanceIconClass(data.row)"
                              v-bind:icon-type="getInstanceIconType(data.row)"
                              v-bind:icon-large="true">
                        </Icon>
                    </template>
                    <!-- processInstanceId: default cell content -->
                    <!-- resultMessage: default cell content -->
                </Table>
            </template>
        </ModalContainer>
    </div>
</template>

<style scoped lang="less">
.process-migrations-table-container {
    width: 100%;
    height: 100%;
    padding: 0 var(--container-spacing) 1px;

    .process-migration-version-modification-from {
        text-decoration: line-through;
        color: var(--color-error);
    }

    .process-migration-version-modification-to {
        color: var(--color-success);
    }

    .process-migration-version-modification-arrow {
        margin: 0 12px;
    }

    .process-migration-instances-count {
        display: flex;
        letter-spacing: 1px;

        .process-migration-instances-count-icon {
            margin-top: -3px;
        }
    }

    .process-migration-status-text {
        &.m--success {
            color: var(--color-success);
        }
        &.m--warning {
            color: var(--color-warning);
        }
        &.m--error {
            color: var(--color-error);
        }
    }

    .process-migration_table-expanded-content {
        width: 100%;
    }
}
</style>
