<script>
import { regexHelper } from '@/mixins/regexHelper'

/**
 * AreaInput is a pre styled text area element.
 * It will throw an @input-submit event on pressing enter or clicking the submit icon, an @input-change event when input value changes, an @input-blurred event when losing focus and an @input-close event on pressing esc.
 * - id: Should be provided in order to get full functionality. Without an id you won't get the ability to close on esc and instant focus the input field when opening.
 * - label (optional): Text displayed as the label of the input field.
 * - defaultValue (optional): The default value of the input. For editing purposes for example.
 * - placeholder (optional): Text shown if the input is empty.
 * - submitButton (default: true): Determines whether there is a submit button.
 * - resetOnSubmit (default: false): If true, the value will be reset to default or null after submit.
 * - iconClass (required if submitButton): A font awesome icon class.
 * - isDisabled (default: false): If true, the user can't change the input value.
 * - isAutoFocused (default: false): If true, the input field will get focused automatically when it is rendered.
 * - showUnsavedChanges (default: false): If true, the input will highlight if it's value differs from the last submitted one.
 * - fillAvailableSpace (default: false): If true, the width and height of the input are set to 100% in order to use the provided space of its parent element.
 * - isExpandable (default: false): If true, the input can be expanded on the y-axis.
 * - maxLength: Set the maximum char length of the input.
 * - pattern: (Regular Expression pattern as string): Only allow a specific input format.
 * - patternKey: Like pattern, but you do not specify a pattern directly, but specify one that is stored under a key with RegexHelper. If both pattern and patternKey are defined, patternKey is ignored.
 **/
export default {
    name: 'AreaInput',
    // @input-submit: Event emitted on submit | returns the event and current input value
    // @input-change: Event emitted on input | returns current input value
    // @input-blurred: Event emitted on blur | returns current input value
    // @input-close: Event emitted on pressing esc key | no return
    // Note: We do not declare the emits here, because we want to have the registrations as part of $attrs
    //       emits: ['input-submit', 'input-change', 'input-blurred', 'input-close'],
    props: {
        id: String,
        label: String,
        defaultValue: String,
        placeholder: String,
        submitButton: {
            default: true,
            type: Boolean
        },
        resetOnSubmit: {
            default: false,
            type: Boolean
        },
        iconClass: String,
        isDisabled: {
            default: false,
            type: Boolean
        },
        isAutoFocused: {
            default: false,
            type: Boolean
        },
        showUnsavedChanges: {
            default: false,
            type: Boolean
        },
        fillAvailableSpace: {
            default: false,
            type: Boolean
        },
        isExpandable: {
            default: false,
            type: Boolean
        },
        maxLength: Number,
        pattern: String,
        patternKey: String
    },
    mixins: [
        regexHelper
    ],
    data () {
        return {
            inputValue: this.defaultValue || '',
            lastSubmittedValue: this.defaultValue || '',
            patternMatched: true
        }
    },
    methods: {
        focusInputElement () {
            document.getElementById(`c_area-input_${this.id}`).focus()
        },

        keyPressedHandler (event) {
            const keycode = (event.keyCode ? event.keyCode : event.which)
            if (keycode === 13 && !event.shiftKey) {
                event.preventDefault()
                this.emitSubmit(event)
            }
        },

        keyUpHandler (event) {
            if (event.key === 'Escape') {
                this.emit(null, 'input-close')
                this.updateLastSubmittedValueAndReset(false)
            }
        },

        emitSubmit (event) {
            // We actually need to check for listeners as the lastSubmittedValue must not be
            // updated if there is no listener which performs the actual submit
            //
            // see https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/593#note_316549
            //     for further information
            if (this.$attrs.onInputSubmit) {
                this.emit(event, 'input-submit')
                this.updateLastSubmittedValueAndReset(true)
            }
        },

        emitBlur () {
            // We actually need to check for listeners as the lastSubmittedValue must not be
            // updated if there is no listener which performs the actual submit
            //
            // see https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/593#note_316549
            //     for further information
            if (this.$attrs.onInputBlurred) {
                this.emit(null, 'input-blurred')
                this.updateLastSubmittedValueAndReset(false)
            }
        },

        emit (event, name) {
            if (event) {
                event.stopPropagation()
            }
            if (this.hasUnsavedChanges && this.patternMatched) {
                this.$emit(name, {
                    event: event,
                    value: this.inputValue
                })
            }
        },

        updateLastSubmittedValueAndReset (doResetInput) {
            this.lastSubmittedValue = this.inputValue
            if (doResetInput && this.resetOnSubmit) {
                this.inputValue = this.defaultValue || ''
            }
        }
    },
    computed: {
        hasUnsavedChanges () {
            return this.inputValue !== this.lastSubmittedValue
        },

        getMaxLengthCount () {
            return this.$t('areaInput.maxLengthCounter', [this.inputValue.length, this.maxLength])
        }
    },
    watch: {
        inputValue () {
            if (this.pattern) {
                this.patternMatched = new RegExp(this.pattern.toString(), 'g').test(this.inputValue)
            } else if (this.patternKey) {
                this.patternMatched = this.checkForPatternKey(this.patternKey, this.inputValue)
            }
            this.emit(null, 'input-change')
        },
        defaultValue () {
            this.inputValue = this.defaultValue || ''
            this.lastSubmittedValue = this.defaultValue || ''
        }
    },
    mounted () {
        if (this.isAutoFocused) {
            this.focusInputElement()
        }
    }
}
</script>

<template>
    <div class="c_area-input-wrapper generals-input-wrapper"
         v-bind:class="{'m--full-width-and-height': fillAvailableSpace}">
        <label v-if="label"
               class="c_area-input-label generals-input-label">
            <span>{{label}}</span>
        </label>
        <div class="c_area-input-container generals-input-container">
            <textarea v-bind:id="id ? `c_area-input_${id}`: null"
                      class="c_area-input generals-input"
                      v-bind:disabled="isDisabled"
                      v-bind:class="{
                          'm--no-icon': !submitButton && !iconClass,
                          'm--unsaved': showUnsavedChanges && hasUnsavedChanges,
                          'm--expandable': isExpandable
                      }"
                      v-bind:placeholder="placeholder || $tc('areaInput.defaultPlaceholder')"
                      v-bind:maxlength="maxLength"
                      v-on:blur="emitBlur()"
                      v-on:click="$event.stopPropagation()"
                      v-on:keypress="keyPressedHandler($event)"
                      v-on:keyup="keyUpHandler($event)"
                      v-model="inputValue">
            </textarea>
            <button v-if="!isDisabled && (submitButton || iconClass)"
                    class="c_area-input-submit"
                    v-bind:class="{'m--disabled': !submitButton}"
                    v-on:mousedown="$event.preventDefault()"
                    v-on:click="emitSubmit($event)">
                <span class="c_area-input-icon"
                      v-bind:class="iconClass">
                </span>
            </button>
            <div v-if="maxLength"
                 class="c-area-input-max-length-indicator generals-input-max-length-indicator"
                 v-bind:class="{
                     'm--close-to-max': inputValue.length >= maxLength * 0.9,
                     'm--max-reached': inputValue.length === maxLength
                 }">
                <span>{{getMaxLengthCount}}</span>
            </div>
            <div v-if="!patternMatched"
                 class="c_area-input-error-message generals-input-error-message">
                <span>{{$tc('areaInput.patternDoesNotMatch')}}</span>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_area-input-wrapper {

    &.m--full-width-and-height {
        position: relative;
        width: 100%;
        height: 100%;

        .generals-input-container.c_area-input-container {
            position: relative;
            width: 100%;
            height: 100%;

            .generals-input.c_area-input {
                width: 100%;
                height: 100%;
            }

            .generals-input-max-length-indicator.c-area-input-max-length-indicator {
                bottom: 1px;
            }
        }
    }

    .generals-input-label.c_area-input-label {
        padding-top: 3px;
    }

    .generals-input-container.c_area-input-container {
        height: auto;

        .generals-input.c_area-input {
            border: 1px solid var(--color-border-almost-white);
            border-bottom: 1px solid var(--color-border-dark);
            outline: none;
            overflow-x: hidden;
            overflow-y: auto;
            resize: none;
            height: unset;
            min-height: var(--area-height);
            padding: 2px 30px 0 5px;

            &.m--unsaved {
                border-bottom-color: var(--color-warning);
                &:focus {
                    border-bottom-color: var(--color-warning);
                }
            }

            &.m--expandable {
                resize: vertical;
            }

            &:focus {
                border-bottom-color: var(--color-border-active);
            }
        }

        .c_area-input-submit {
            position: absolute;
            height: 25px;
            top: unset;
            bottom: 6px;

            .c_area-input-icon {
            }
        }

        .generals-input-max-length-indicator.c-area-input-max-length-indicator {
            position: absolute;
            height: 0;
            right: 0;
            bottom: 4px;
        }

        .c_area-input-error-message {
            position: absolute;
            margin-top: -4px;
        }
    }
}
</style>
