/**
 * A service that can be used by components that want to interact with the SmartForm.
 * Typical use cases are: obtaining values from the form, setting values in the form, loading form configurations etc.
 **/
import assetOrderAudioForm from '@/assets/forms/asset-order-audio-form.json'
import assetOrderPictureForm from '@/assets/forms/asset-order-picture-form.json'
import assetOrderTextForm from '@/assets/forms/asset-order-text-form.json'
import assetOrderVideoForm from '@/assets/forms/asset-order-video-form.json'
import briefingForm from '@/assets/forms/briefing-form.json'
import arrangeEinreichungForm from '@/assets/forms/arrange-einreichung-form.json'
import arrangeResubmissionForm from '@/assets/forms/arrange-resubmission-form.json'
import communicateResultEinreichungForm from '@/assets/forms/communicate-result-einreichung-form.json'
import communicateResultResubmissionForm from '@/assets/forms/communicate-result-resubmission-form.json'
import titleDocForm from '@/assets/forms/titledoc-form.json'
import illustratorForm from '@/assets/forms/illustrators-form.json'
import photographersForm from '@/assets/forms/photographers-form.json'
import prepareEinreichungForm from '@/assets/forms/prepare-einreichung-form.json'
import performEinreichungForm from '@/assets/forms/perform-einreichung-form.json'
import performResubmissionForm from '@/assets/forms/perform-resubmission-form.json'
import provideManuskriptSatzprozessForm from '@/assets/forms/provide-manuskript-satzprozess-form.json'
import typesettingAndCorrectionsForm from '@/assets/forms/typesetting-corrections-satzprozess-form.json'
import checkTypesettingSatzprozessForm from '@/assets/forms/check-typesetting-satzprozess-form.json'
import finalPdfForm from '@/assets/forms/final-pdf-form.json'
import conclusionSurveyForm from '@/assets/forms/conclusion-survey-form.json'
import specifyPublicationDataForm from '@/assets/forms/specify-publication-data-form.json'
import processDataWithoutCorrectionForm from '@/assets/forms/process-data-without-correction-satzprozess-form.json'
import publicationDataCreationForm from '@/assets/forms/publication-data-creation-form.json'

import _ from 'lodash'
import { axiosService } from '@/mixins/axiosService'
import { notificationHandler } from '@/mixins/notificationHandler'
import { urlHelper } from './urlHelper'

export const smartFormHelper = {
    mixins: [
        axiosService,
        notificationHandler,
        urlHelper
    ],
    data () {
        return {
            forms: {
                ASSET_ORDER_AUDIO: assetOrderAudioForm,
                ASSET_ORDER_PICTURE: assetOrderPictureForm,
                ASSET_ORDER_TEXT: assetOrderTextForm,
                ASSET_ORDER_VIDEO: assetOrderVideoForm,
                BRIEFING: briefingForm,
                TITLEDOC: titleDocForm,
                ILLUSTRATOR: illustratorForm,
                PHOTOGRAPHER: photographersForm,
                PREPARE_EINREICHUNG: prepareEinreichungForm,
                ARRANGE_EINREICHUNG: arrangeEinreichungForm,
                COMMUNICATE_RESULT_EINREICHUNG: communicateResultEinreichungForm,
                COMMUNICATE_RESULT_RESUBMISSION: communicateResultResubmissionForm,
                PERFORM_EINREICHUNG: performEinreichungForm,
                ARRANGE_RESUBMISSION: arrangeResubmissionForm,
                PERFORM_RESUBMISSION: performResubmissionForm,
                MANUSCRIPT_PROVISIONING: provideManuskriptSatzprozessForm,
                TYPESETTING_CORRECTIONS: typesettingAndCorrectionsForm,
                TYPESETTING_CHECK: checkTypesettingSatzprozessForm,
                FINAL_PDF: finalPdfForm,
                CONCLUSION_SURVEY: conclusionSurveyForm,
                SPECIFY_PUBLICATION_DATA: specifyPublicationDataForm,
                PROCESS_DATA_WITHOUT_CORRECTION: processDataWithoutCorrectionForm,
                PUBLICATION_DATA_CREATION: publicationDataCreationForm
            }
        }
    },
    methods: {
        loadFormConfiguration (formKey) {
            if (this.forms[formKey]) {
                return _.cloneDeep(this.forms[formKey])
            } else {
                const durations = {
                    short: 5000,
                    standard: 10000
                }
                const messages = {
                    short: this.$tc('smartFormHelper.formNotExisting.short'),
                    standard: this.$t('smartFormHelper.formNotExisting.standard', [formKey])
                }
                this.configureNotification('error', durations, messages)
                return null
            }
        },

        // If keysToApply is not provided, all values in keyValuePairs will be applied to the formConfig
        // fieldAction enables you to do some further operations with the fields within the loop.
        // Hand over the desired function and pass 'this' as actionContext, which must contain all the necessary functions that should be executed.
        applyValuesToFormConfigurationAndReturnChanges (keysToApply, keyValuePairs, applyDefaultValues, formConfig, fieldAction, actionContext) {
            const changes = {}
            formConfig.sections.forEach(section => {
                function setDefaultValues () {
                    Object.entries(section.defaultValues).forEach(([defaultValueKey, defaultValue]) => {
                        if (section.allowMultiple) {
                            section.pages.forEach(page => {
                                page[defaultValueKey] = defaultValue
                            })
                            changes[section.id] = section.pages
                        } else {
                            section.pages[0][defaultValueKey] = defaultValue
                            changes[defaultValueKey] = defaultValue
                        }
                    })
                }

                function applyValuesOfTemporarilyGeneratedFields (field, source, target) {
                    if (field.quantity && field.quantity > 1) {
                        for (let index = 2; index <= field.quantity; index += 1) {
                            const generatedFieldId = `${field.id}_${index}`
                            if (source[generatedFieldId]) {
                                target[generatedFieldId] = source[generatedFieldId]
                            }
                        }
                    }
                }

                function setMultiPageValues () {
                    const fieldsToApply = keysToApply && keysToApply.length > 0
                        ? section.fields.filter(field => keysToApply.includes(field.id))
                        : section.fields
                    if (fieldsToApply.length > 0 && section.id in keyValuePairs) {
                        const sectionPages = keyValuePairs[section.id]
                        sectionPages.forEach((sectionPage, index) => {
                            const page = {}
                            fieldsToApply.forEach(fieldToApply => {
                                const pageValue = sectionPage[fieldToApply.id]
                                if (pageValue !== undefined && pageValue !== null) {
                                    page[fieldToApply.id] = pageValue
                                }
                                applyValuesOfTemporarilyGeneratedFields(fieldToApply, sectionPage, page)
                            })
                            section.pages[index] = page
                        })
                    }
                    section.fields.forEach(field => {
                        if (fieldAction && actionContext) {
                            fieldAction(field, actionContext)
                        }
                    })
                }

                function setSinglePageValues () {
                    section.fields.forEach(field => {
                        if ((!keysToApply || keysToApply.includes(field.id)) && field.id in keyValuePairs) {
                            section.pages[0][field.id] = keyValuePairs[field.id]
                            changes[field.id] = keyValuePairs[field.id]
                            applyValuesOfTemporarilyGeneratedFields(field, keyValuePairs, section.pages[0])
                        }
                        if (fieldAction && actionContext) {
                            fieldAction(field, actionContext)
                        }
                    })
                }

                if (applyDefaultValues && section.defaultValues) {
                    setDefaultValues()
                }
                section.allowMultiple
                    ? setMultiPageValues()
                    : setSinglePageValues()
            })
            return changes
        },

        saveSmartForm (formType, productNumber, formConfig, changes, isUpdate, errorMessage) {
            return this.saveSmartFormWithParameters(formType, {}, productNumber, formConfig, changes, isUpdate, errorMessage)
        },

        saveSmartFormWithParameters (formType, formParameters, productNumber, formConfig, changes, isUpdate, errorMessage) {
            const convertedChanges = {}
            if (changes) {
                Object.entries(changes).forEach(([changeKey, changeValue]) => {
                    const relatedSection = formConfig.sections.find(section => {
                        return section.fields.some(field => field.id === changeKey)
                    })
                    // If the section allows multiple pages, save all page related values as list with section id as key. Otherwise, keep the change as is.
                    relatedSection?.allowMultiple
                        ? convertedChanges[relatedSection.id] = relatedSection.pages
                        : convertedChanges[changeKey] = changeValue
                })
            }
            const createOrUpdate = isUpdate ? 'axiosPut' : 'axiosPost'
            const body = isUpdate ? convertedChanges : {}
            const urlParameters = formParameters ? `?${this.toQueryParameters(formParameters)}` : ''
            return this[createOrUpdate](
                `forms/${formType}/${productNumber}${urlParameters}`,
                body,
                errorMessage)
                .then(successResponse => {
                    return Promise.resolve(successResponse)
                })
                .catch(() => {
                    return Promise.reject(new Error())
                })
        },

        setFormFieldValue (formConfig, fieldId, value) {
            const formSection = formConfig.sections.find(section => {
                return section.fields.some(field => field.id === fieldId)
            })
            formSection.pages[0][fieldId] = value
        },

        getFormFieldValue (formConfig, fieldId) {
            const formSection = formConfig.sections.find(section => {
                return section.fields.some(field => field.id === fieldId)
            })
            return formSection.pages[0][fieldId]
        },

        setFormHeadline (formConfig, headline) {
            formConfig.headline = headline
        },

        getSectionIndexByFieldId (formConfig, fieldId) {
            return formConfig.sections.findIndex(section => {
                return section.fields.some(field => field.id === fieldId)
            })
        }
    }
}
