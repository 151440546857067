<script>
/**
 * InteractionLink is an url that can be edited on click, or opened while holding ctrl. If the input is disabled then it will work like a typical link.
 * Returns an @input-click event when clicking the value or a @ctrl-input-click on click while holding ctrl button.
 * - label: Text displayed as the label of the input field.
 * - value: The field value.
 * - placeholder: Text shown if the value is empty.
 * - iconClass: Icon shown next to the value on hover.
 * - textColorClass: must be one of ['info','success','warning','error']
 * - ctrlIconClass: Icon shown next to the value on hover while holding ctrl.
 * - tooltip: Text on hover. Ctrl has no effect on the tooltip.
 * - isDisabled (default: false): Determines whether click and keyboard functionality is supported.
 * - isUnderlined (default: false): Determines whether the value is underline. Better visualization, if value is editable.
 * - isHighlighted (default: false): Determines whether the element looks more like an actual link.
 **/

export default {
    name: 'InteractionLink',
    // @input-click: Event emitted on click
    // @ctrl-input-click: Event emitted on click while pressing ctrl
    // Note: We do not declare the emits here, because we want to have the registrations as part of $attrs
    //       emits: ['input-click', 'ctrl-input-click'],
    props: {
        label: String,
        value: [String, Number, Date],
        placeholder: [String, Number, Date],
        iconClass: String,
        ctrlIconClass: String,
        tooltip: String,
        isDisabled: {
            default: false,
            type: Boolean
        },
        isUnderlined: {
            default: false,
            type: Boolean
        },
        isHighlighted: {
            default: false,
            type: Boolean
        },
        textColorClass: String
    },
    data () {
        return {
            inputValue: this.value,
            ctrlIsPressed: false
        }
    },
    methods: {
        emitClick (event) {
            event.stopPropagation()

            this.hasCtrlClickHandlerBinding && this.ctrlIsPressed
                ? this.$emit('ctrl-input-click')
                : this.$emit('input-click')
        },

        focusContainer (event) {
            event.target.focus()
        },

        checkCtrlKey (event) {
            this.ctrlIsPressed = !this.isDisabled && (event.ctrlKey || event.metaKey)
        },

        resetCtrlPress (event) {
            this.ctrlIsPressed = false
            event.target.blur()
        },
        hasColorClass (colorClass) {
            return this.textColorClass === colorClass
        }
    },
    computed: {
        hasValue () {
            return this.value !== undefined &&
                this.value !== null &&
                this.value !== ''
        },

        hasCtrlClickHandlerBinding () {
            return !!(this.$attrs.onCtrlInputClick)
        }
    },
    watch: {
        value () {
            this.inputValue = this.value || ''
        }
    }
}
</script>

<template>
    <div class="c_interaction-link-input-wrapper generals-input-wrapper">
        <label v-if="label"
               class="c_interaction-link-input-label generals-input-label">
            <span>{{label}}</span>
        </label>
        <div class="c_interaction-link-input-container generals-input-container"
             tabindex="-1"
             v-bind:class="{
                'm--label': label,
                'm--disabled': isDisabled,
             }"
             v-bind:title="tooltip"
             v-on:mouseover="hasCtrlClickHandlerBinding ? focusContainer($event) : null"
             v-on:mouseleave="hasCtrlClickHandlerBinding ? resetCtrlPress($event) : null"
             v-on:keydown="hasCtrlClickHandlerBinding ? checkCtrlKey($event) : null"
             v-on:keyup="hasCtrlClickHandlerBinding ? checkCtrlKey($event): null">
            <div class="c_interaction-link-text-container"
                 v-bind:class="{
                     'm--no-value': !hasValue,
                     'm--underlined': !isDisabled && isUnderlined,
                     'm--highlighted': !isDisabled && isHighlighted,
                     'm--error': this.hasColorClass('error'),
                     'm--warn': this.hasColorClass('warn'),
                     'm--info': this.hasColorClass('info'),
                     'm--success': this.hasColorClass('success'),
                 }"
                 v-on:click="emitClick($event)">
                <span
                    class="c_interaction-link-text"
                >
                    {{hasValue ? inputValue : placeholder}}
                </span>
                <span class="c_interaction-link-icon"
                      v-bind:class="ctrlIsPressed && ctrlIconClass ? ctrlIconClass : iconClass">
                </span>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_interaction-link-input-wrapper {

    .c_interaction-link-input-label {
    }

    .generals-input-container.c_interaction-link-input-container {
        padding: 8px 0 4px 0;
        height: auto;
        width: unset;
        outline: none;

        &.m--label {
            padding-top: 12px
        }

        &.m--disabled {
            text-decoration: none;

            .c_interaction-link-text-container {
                pointer-events: none;
            }
        }

        .c_interaction-link-text-container {
            cursor: pointer;
            color: var(--color-text-mid);

            &.m--no-value {
                color: var(--color-text-disabled);
            }

            &.m--underlined {
                .c_interaction-link-text {
                    text-decoration: underline;
                }
            }

            &.m--highlighted {
                color: var(--color-link);

                &:hover {
                    color: var(--color-link-active);

                    .c_interaction-link-icon {
                        display: inline;
                        color: var(--color-link-active);
                    }
                }
            }

            &:hover {
                color: var(--color-text-highlighted);

                .c_interaction-link-icon {
                    display: inline;
                    color: var(--color-text-highlighted);
                }
            }

            .c_interaction-link-icon {
                display: none;
                margin-left: 8px;
            }

            &.m--info {
                .c_interaction-link-text {
                    color: var(--color-info);
                }
            }

            &.m--success {
                .c_interaction-link-text {
                    color: var(--color-success);
                }
            }

            &.m--warn {
                .c_interaction-link-text {
                    color: var(--color-warning);
                }
            }

            &.m--error {
                .c_interaction-link-text {
                    color: var(--color-error);
                }
            }
        }
    }
}
</style>
