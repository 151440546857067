/**
 * A reusable service for handling the functionality of the pdf preview module.
 **/
import { axiosService } from '@/mixins/axiosService'
import { notificationHandler } from '@/mixins/notificationHandler'
import { downloadHandler } from '@/mixins/downloadHandler'

export const generatePdfPreviewHandler = {
    mixins: [
        axiosService,
        notificationHandler,
        downloadHandler
    ],
    methods: {
        async collectContentPdfs (productId) {
            const productContentsResponse = await this.axiosGet(
                `contents/v2/${productId}`,
                this.$tc('generatePdfPreviewHandler.getContentListError')
            )
            // Use localCompare to sort product contents by name, but include numeric sorting -> See https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/localeCompare
            const sortedContents = productContentsResponse.contents.sort((a, b) => a.name.localeCompare(
                b.name,
                this.$global.localization.locale,
                { numeric: true, sensitivity: 'base' }
            ))
            const contentIds = sortedContents.map(content => content.id)
            return this.axiosPost(
                `pdfPreview/prepare/${productId}`,
                contentIds,
                this.$tc('generatePdfPreviewHandler.getPdfListError'))
                .then(pdfPreviewResponse => {
                    if (pdfPreviewResponse.previewParts.length > 0) {
                        return Promise.resolve(pdfPreviewResponse)
                    } else {
                        const durations = {
                            standard: 5000,
                            short: 3000
                        }
                        const messages = {
                            standard: this.$tc('generatePdfPreviewHandler.noPdfsFound.standard'),
                            short: this.$tc('generatePdfPreviewHandler.noPdfsFound.short')
                        }
                        this.configureNotification('info', durations, messages)
                        return Promise.reject(new Error())
                    }
                })
                .catch(() => {
                    return Promise.reject(new Error())
                })
        },

        generatePreviewPdf (sortedPdfList, productId, productNumber) {
            return this.axiosPost(
                `pdfPreview/generatePreview/${productId}`,
                sortedPdfList,
                this.$tc('generatePdfPreviewHandler.generatePdfError'),
                null,
                false,
                { responseType: 'blob' })
                .then(previewPdf => {
                    const objectUrl = URL.createObjectURL(new Blob([previewPdf], { type: 'application/pdf' }))
                    const filename = `${productNumber}-preview.pdf`
                    this.saveToFileSystem(objectUrl, filename)
                    return Promise.resolve(true)
                })
                .catch(() => {
                    return Promise.reject(new Error())
                })
        }
    }
}
