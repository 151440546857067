<script>
/**
 * Task view
 **/
import TextInput from '@/components/TextInput.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'

export default {
    name: 'CustomTaskExample',
    mixins: [
        axiosService,
        userHandler
    ],
    components: {
        TextInput
    },
    // @task-completable-changed: Event emitted when the task-completable state changed
    emits: ['task-completable-changed'],
    props: {
        taskData: Object
    },
    data () {
        return {
            task: this.taskData,
            inputValue: null
        }
    },
    methods: {
        updateInputValue (inputChangeEventData) {
            this.inputValue = inputChangeEventData.value
        }
    },
    computed: {
        // Use this computed function to evaluate if the task is complete
        isTaskCompletable () {
            return this.inputValue === 'Fertig'
        }
    },
    watch: {
        // Use this watcher to notice, when the computed function changes its return value and emit it.
        isTaskCompletable (isCompletable) {
            this.$emit('task-completable-changed', {
                isCompletable: isCompletable
            })
        }
    },
    mounted () {
    }
}
</script>

<template>
    <div class="custom-task-example-content-container">
        <TextInput label="Trage den richtigen Wert ein (Fertig)"
                   @input-change="updateInputValue($event)">
        </TextInput>
    </div>
</template>

<style scoped lang="less">
.custom-task-example-content-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
</style>
