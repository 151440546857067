/**
 * A service used for handling combined sets of files and links
 *
 * Note: Will be replaced with a vue component that can handle both file uploads and links (JAMP-2150)
 **/
import type { Link } from '@/types/Link'
import type { File } from '@/types/File'

type FilesAndLinksFieldValue = {
    files?: File[]
    links?: Link[]
}

export default class FilesAndLinks {
    links: Link[]
    files: File[]
    constructor (formFieldValue?: FilesAndLinksFieldValue) {
        this.links = formFieldValue?.links || []
        this.files = formFieldValue?.files || []
    }

    static from (links: Link[], files:File[]) {
        const filesAndLinks = new FilesAndLinks()
        filesAndLinks.addFiles(files)
        filesAndLinks.addLinks(links)

        return filesAndLinks
    }

    copy () {
        return FilesAndLinks.from(this.links, this.files)
    }

    /**
     * Returns the value of the form field and whether it is valid (= not empty).
     *
     * Please note that the attribute 'value' is only set if there are files or links.
     * It indicates to the EssentialSmartFormService that the form field has a valid value.
     *
     * @returns {Object} - the value of the form field
     */
    get formFieldValue () {
        if (this.files.length + this.links.length === 0) {
            return { files: [...this.files], links: [...this.links] }
        } else {
            return { files: [...this.files], links: [...this.links], value: true }
        }
    }

    addLink (link: Link): boolean {
        if (this.containsLink(link.url)) {
            return false
        }

        this.links.push(link)
        return true
    }

    addLinks (links: Link[]) {
        let anyLinkAdded = false
        links.forEach((link) => {
            anyLinkAdded = this.addLink(link) || anyLinkAdded
        })
        return anyLinkAdded
    }

    containsLink (url: string) {
        return this.links.find((l) => l.url === url)
    }

    removeLink (index: number) {
        return this.links.splice(index, 1)
    }

    addFile (file: File): boolean {
        if (this.containsFile(file)) {
            return false
        }

        this.files.push(file)
        return true
    }

    addFiles (files: File[]) {
        let anyFileAdded = false
        files.forEach((file) => {
            anyFileAdded = this.addFile(file) || anyFileAdded
        })
        return anyFileAdded
    }

    containsFile (file: File) {
        return this.files.find((f) => f.fileName === file.fileName)
    }

    removeFile (index: number) {
        return this.files.splice(index, 1)
    }
}
