import { dateTimeHelper } from './dateTimeHelper'

export const einreichungHelper = {
    methods: {
        parseFederalStatesAndSchoolTypesKeyValuesToSmartFormFields (formData) {
            const formConditionToFederalStateMap = {
                isBw: 'Baden-Württemberg',
                isBy: 'Bayern',
                isBra: 'Brandenburg',
                isHb: 'Bremen',
                isHe: 'Hessen',
                isMeb: 'Mecklenburg-Vorpommern',
                isNds: 'Niedersachsen',
                isNrw: 'Nordrhein-Westfalen',
                isRhp: 'Rheinland-Pfalz',
                isSas: 'Sachsen',
                isSan: 'Sachsen-Anhalt',
                isThg: 'Thüringen',
                isBe: 'Berlin',
                isHh: 'Hamburg',
                isSh: 'Schleswig-Holstein',
                isSl: 'Saarland'
            }
            const formConditionToSchoolTypeMap = {
                isBg: 'Berufliches Gymnasium',
                isBfs: 'Berufsfachschule',
                isBkvoa: 'Berufskolleg VOA',
                isBos: 'Berufsoberschule',
                isDualbs: 'Duale Berufsschule',
                isFak: 'Fachakademie',
                isFos: 'Fachoberschule',
                isFsvoa: 'Fachschule VOA',
                isFoes: 'Förderschule',
                isGs14: 'Grundschule 1-4',
                isGs56: 'Grundschule 5-6',
                isGymsi: 'Gymnasium SEK I',
                isHs: 'Hauptschule',
                isIgs: 'Integrierte Gesamtschule',
                isRs: 'Realschule',
                isSek: 'Sekundarschule',
                isSekii: 'SekundarstufeII'
            }

            function parseEinreichungConditions (valueToFieldIdMap, valueListName) {
                const valueList = formData.values[valueListName]
                if (valueList?.length > 0) {
                    Object.entries(valueToFieldIdMap).forEach(([key, value]) => {
                        // We are basically doing a lookup of a key using a value.
                        // We do this because JavaScript won't like our values (with umlauts and more) as keys.
                        if (valueList.includes(value)) formData.values[key] = true
                    })
                }
            }

            // quickfix for typo / size restriction in SAP
            if (formData.values.federalStatesList) {
                const wrongValueIndex = formData.values.federalStatesList.indexOf('Mecklenburg-Vorpomme')
                if (wrongValueIndex !== -1) {
                    formData.values.federalStatesList[wrongValueIndex] = 'Mecklenburg-Vorpommern'
                }
            }
            parseEinreichungConditions(formConditionToFederalStateMap, 'federalStatesList')
            parseEinreichungConditions(formConditionToSchoolTypeMap, 'schoolTypeList')
            formData.values.federalStates = formData.values.federalStatesList?.join(', ')
            formData.values.schoolTypes = formData.values.schoolTypeList?.join(', ')
            if (formData.values.level) {
                formData.values.level = formData.values.level.join(', ')
            }
        },
        parseDateString (dateString) {
            if (!dateString) {
                return null
            }
            const parsedDate = dateTimeHelper.methods.tryToParseToDate(dateString)
            return parsedDate ? dateTimeHelper.methods.getFormattedShortDate(parsedDate, 'de') : null
        }
    }
}
