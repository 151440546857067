<script>
/**
 * Process migration sub view | Sub view of admin controlling
 *
 * @property {String} batchId
 **/
import LoadingScreen from '@/components/LoadingScreen.vue'
import ModalContainer from '@/components/ModalContainer.vue'
import ContentHeader from '@/components/ContentHeader.vue'
import Button from '@/components/Button.vue'
import Table from '@/components/Table.vue'
import SmartSelect from '@/components/SmartSelect.vue'
import TextInput from '@/components/TextInput.vue'
import PageOverlay from '@/components/PageOverlay.vue'
import IconButton from '@/components/IconButton.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { notificationHandler } from '@/mixins/notificationHandler'
import { copyHandler } from '@/mixins/copyHandler'

export default {
    name: 'ProcessMigration',
    mixins: [
        axiosService,
        userHandler,
        notificationHandler,
        copyHandler
    ],
    components: {
        LoadingScreen,
        ContentHeader,
        Button,
        Table,
        SmartSelect,
        TextInput,
        PageOverlay,
        IconButton,
        ModalContainer
    },
    data () {
        return {
            updateCounter: 0,
            showSpinner: true,
            loadingScreen: {
                text: this.$tc('administration.controlling.processMigration.loadingScreenText'),
                visible: false,
                timeout: null
            },
            processDefinition: {
                name: '',
                latestVersion: '',
                processVersions: [],
                instances: []
            },
            selectedInstanceId: null,
            processActivities: [],
            migrationTypes: {
                SINGLE: 'single',
                VERSION: 'version',
                SELECTED: 'selected'
            },
            migrationTypeOptions: [{
                id: 'single',
                label: this.$tc('administration.controlling.processMigration.singleInstanceMigration')
            }, {
                id: 'version',
                label: this.$tc('administration.controlling.processMigration.versionMigration')
            }, {
                id: 'selected',
                label: this.$tc('administration.controlling.processMigration.visibleInstancesMigration')
            }],
            selectedMigrationType: null,
            selectedMigrationOption: {},
            activityFrom: null,
            migrationScript: {},
            migrationScriptEditable: false,
            migrationConfigurationName: 'configuration-summary',
            diagramImage: {
                source: null,
                modalExpanded: false
            }
        }
    },
    methods: {
        getProcessInstances () {
            this.axiosGet(
                `migration/instances/processKey/${this.$route.params.processId}`,
                this.$tc('administration.controlling.processMigration.getProcessInstancesError'))
                .then(processDefinition => {
                    const activeVersions = []
                    this.processDefinition = processDefinition
                    this.processDefinition.instances.forEach(instance => {
                        if (!activeVersions.includes(instance.processDefinitionVersion)) {
                            activeVersions.push(instance.processDefinitionVersion.toString())
                        }
                        instance.activities = instance.activeFlowNodes.map(flowNode => flowNode.id)
                    })
                    this.processDefinition.processVersions = []
                    Object.keys(this.processDefinition.versions).forEach(key => {
                        this.processDefinition.processVersions.push({
                            id: this.processDefinition.versions[key],
                            label: parseInt(key)
                        })
                        if (activeVersions.includes(key)) {
                            this.getProcessActivities(this.processDefinition.versions[key])
                        }
                    })
                    this.setupMigrationScript(true)
                    this.showSpinner = false
                })
                .catch(() => {})
        },

        getProcessActivities (versionId) {
            this.axiosGet(
                `admin/processDefinition/${versionId}/flowNodes`,
                this.$tc('administration.controlling.processMigration.getProcessActivitiesError'))
                .then(activities => {
                    activities = activities.filter(activity => {
                        return !this.processActivities.some(processActivity => processActivity.id === activity.id)
                    })
                    this.processActivities.push(...activities)
                })
                .catch(() => {})
        },

        clearLoadingScreen (resetMigrationConfiguration) {
            clearTimeout(this.loadingScreen.timeout)
            if (resetMigrationConfiguration) {
                this.setupMigrationScript(true)
            }
            this.loadingScreen.visible = false
        },

        setupMigrationScript (reset) {
            this.migrationScript = {
                fromProcessInstanceOrVersionId: '',
                selectedProcessInstancesIds: [],
                toProcessDefinitionId: '',
                activityMappings: []
            }
            if (reset) {
                this.selectedMigrationType = null
                this.updateCounter++
            }
        },

        copyToClipboard (event, value) {
            event.stopPropagation()
            this.copyValueToClipboard(value, true)
        },

        updateMigrationType (selectSubmitEventData) {
            this.setupMigrationScript()
            this.selectedMigrationOption = {}
            this.activityFrom = null
            this.selectedMigrationType = selectSubmitEventData.option.id
        },

        updateMigrationOption (selectSubmitEventData) {
            this.selectedMigrationOption = selectSubmitEventData.option
            this.migrationScript.fromProcessInstanceOrVersionId = selectSubmitEventData.option.id
        },

        updateMigrationVersion (selectSubmitEventData) {
            this.migrationScript.toProcessDefinitionId = selectSubmitEventData.option.id
            if (this.selectedMigrationType === this.migrationTypes.SELECTED) {
                this.migrationScript.selectedProcessInstancesIds = this.$refs.processInstancesTable
                    .getTableRows
                    .map(row => row.id)
            }
        },

        updateMigrationVariables (inputSubmitEventData) {
            const postScriptKey = this.$refs.postScriptKeySelect.inputValue
            if (!this.migrationScript.postUpgradeScript) {
                this.migrationScript.postUpgradeScript = {}
                this.migrationScript.postUpgradeScript.script = ''
                this.migrationScript.postUpgradeScript.language = 'javascript'
            }
            if (this.migrationScript.postUpgradeScript.script.indexOf(postScriptKey) !== -1) {
                const scriptCommands = this.migrationScript.postUpgradeScript.script.split(';')
                for (let i = 0; i < scriptCommands.length; i++) {
                    if (scriptCommands[i].indexOf(postScriptKey)) {
                        scriptCommands.splice(i, 1, `execution.setVariable('${postScriptKey}', '${inputSubmitEventData.value}')`)
                        this.migrationScript.postUpgradeScript.script = scriptCommands.join(';')
                        i = scriptCommands.length
                    }
                }
            } else {
                this.migrationScript.postUpgradeScript.script += `execution.setVariable('${postScriptKey}', '${inputSubmitEventData.value}');`
            }
            this.$forceUpdate()
        },

        showDiagram (rowClickEventData) {
            const rowData = rowClickEventData.row
            this.selectedInstanceId = rowData.id
            this.axiosGet(
                `admin/processinstance/${rowData.id}/diagram`,
                this.$tc('administration.controlling.processMigration.showDiagramFailed'),
                null,
                false,
                { responseType: 'blob' })
                .then(blob => {
                    console.debug(`Result size: ${blob.size}; result type: ${blob.type}`)
                    return blob
                })
                .then(this.blobToBase64)
                .then(base64 => {
                    this.diagramImage.source = base64
                    this.setDiagramImageModalOpened()
                })
                .catch((error) => {
                    console.error(`Error: ${error}`)
                    // otherwise, default error handling is enough
                })
        },

        blobToBase64 (blob) {
            return new Promise((resolve) => {
                const reader = new FileReader()
                reader.onloadend = () => {
                    return resolve(reader.result)
                }
                reader.readAsDataURL(blob)
            })
        },

        setDiagramImageModalOpened () {
            this.diagramImage.modalExpanded = true
        },

        setDiagramImageModalClosed () {
            this.diagramImage.modalExpanded = false
            this.selectedInstanceId = null
        },

        getActivityDisplayText (activity) {
            return activity.name
                ? `${activity.id} (${activity.name})`
                : activity.id
        },

        setActivityFrom (inputSubmitEventData) {
            this.activityFrom = inputSubmitEventData.option.value
        },

        updateActivityMappings (inputSubmitEventData, activityFrom) {
            const newActivityToId = inputSubmitEventData.option.value
            if (!activityFrom) {
                this.addNotification({
                    type: 'error',
                    duration: 10000,
                    message: this.$tc('administration.controlling.processMigration.activityIsMissing')
                })
            } else {
                const existingMappingForActivity = this.migrationScript.activityMappings.find(activity => activity.fromActivityId === activityFrom)
                if (existingMappingForActivity) {
                    if (existingMappingForActivity.toActivityId && existingMappingForActivity.toActivityId !== newActivityToId) {
                        existingMappingForActivity.toActivityIds = [existingMappingForActivity.toActivityId, newActivityToId]
                        delete existingMappingForActivity.toActivityId
                        this.$forceUpdate()
                    } else if (existingMappingForActivity.toActivityIds && !existingMappingForActivity.toActivityIds.includes(newActivityToId)) {
                        existingMappingForActivity.toActivityIds.push(newActivityToId)
                        this.$forceUpdate()
                    }
                } else {
                    this.migrationScript.activityMappings.push({
                        fromActivityId: activityFrom,
                        toActivityId: newActivityToId
                    })
                }
            }
        },

        toggleMigrationScriptEditable () {
            if (this.migrationScriptEditable) {
                document.getElementById('process-migration-summary-code').removeAttribute('contenteditable')
                this.migrationScriptEditable = false
            } else {
                document.getElementById('process-migration-summary-code').setAttribute('contenteditable', 'true')
                this.migrationScriptEditable = true
            }
        },

        updateMigrationScript (event) {
            this.migrationScript = JSON.parse(event.target.innerHTML)
        },

        executeProcessInstanceMigration (apiData) {
            this.axiosPost(
                `migration/instances/${this.selectedMigrationOption.id}/migrate`,
                apiData,
                this.$t('administration.controlling.processMigration.processInstanceMigrationError', [this.selectedMigrationOption.id]),
                {
                    standard: this.$tc('administration.controlling.processMigration.processMigrationSuccess.standard'),
                    short: this.$tc('administration.controlling.processMigration.processMigrationSuccess.short')
                })
                .then(updatedInstance => {
                    const processInstance = this.processDefinition.instances
                        .find(instance => instance.id === this.selectedMigrationOption.id)
                    const newVersion = this.processDefinition.processVersions
                        .find(version => version.id === this.migrationScript.toProcessDefinitionId)
                    processInstance.processDefinitionVersion = newVersion.label
                    processInstance.activeFlowNodes = updatedInstance.activeFlowNodes
                    processInstance.activities = processInstance.activeFlowNodes.map(flowNode => flowNode.id)
                    this.getProcessActivities(newVersion.id)
                    this.setupMigrationScript(true)
                })
                .catch(() => {
                })
        },

        executeProcessVersionMigration (apiData) {
            this.axiosPost(
                'migration/batches',
                apiData,
                this.$tc('administration.controlling.processMigration.processMigrationError'))
                .then(migrationInfo => {
                    function checkMigrationStatus () {
                        vm.loadingScreen.timeout = setTimeout(() => {
                            vm.axiosGet(
                                `migration/batches?minCreatedAt=${migrationInfo.createdAt}`,
                                vm.$tc('administration.controlling.processMigration.processMigrationError'))
                                .then(migrationList => {
                                    // Consider reloading the instances instead of recalculating versions etc., because activities might have changed during migration!
                                    const currentMigration = migrationList.find(migration => migration.batchId === migrationInfo.batchId)
                                    if (currentMigration.completedAt) {
                                        const newVersion = vm.processDefinition.processVersions.find(version => version.id === vm.migrationScript.toProcessDefinitionId)
                                        let containsErrors = 0
                                        vm.processDefinition.instances.forEach(instance => {
                                            const index = currentMigration.parts.findIndex(part => part.processInstanceId === instance.id)
                                            if (index !== -1) {
                                                if (currentMigration.parts[index].result === 'SUCCESS') {
                                                    instance.processDefinitionVersion = newVersion.label
                                                } else {
                                                    containsErrors++
                                                }
                                                currentMigration.parts.splice(index, 1)
                                            }
                                        })
                                        vm.getProcessActivities(newVersion.id)
                                        vm.clearLoadingScreen(true)
                                        const userPreferences = vm.getUserSettingsParameter('userPreferences')
                                        if (userPreferences.reducedNotifications) {
                                            vm.addCursorNotification({
                                                type: containsErrors === 0 ? 'success' : 'warning',
                                                duration: containsErrors === 0 ? 3000 : 5000,
                                                message: containsErrors === 0
                                                    ? vm.$tc('administration.controlling.processMigration.processMigrationSuccess.short')
                                                    : vm.$t('administration.controlling.processMigration.processMigrationFailed.short', [containsErrors, currentMigration.parts.length])
                                            })
                                        } else {
                                            vm.addNotification({
                                                type: containsErrors === 0 ? 'success' : 'warning',
                                                duration: containsErrors === 0 ? 5000 : 10000,
                                                message: containsErrors === 0
                                                    ? vm.$tc('administration.controlling.processMigration.processMigrationSuccess.standard')
                                                    : vm.$t('administration.controlling.processMigration.processMigrationFailed.standard', [containsErrors, currentMigration.parts.length])
                                            })
                                        }
                                    } else {
                                        checkMigrationStatus()
                                    }
                                })
                                .catch(() => {
                                    vm.clearLoadingScreen()
                                })
                        }, 5000)
                    }
                    const vm = this
                    vm.loadingScreen.visible = true
                    checkMigrationStatus()
                })
                .catch(() => {
                    this.loadingScreen.visible = false
                })
        },

        confirmMigrationExecution () {
            this.$swal({
                title: this.$tc('administration.controlling.processMigration.startMigrationPopup.title'),
                text: this.$tc('administration.controlling.processMigration.startMigrationPopup.text'),
                icon: 'info',
                showConfirmButton: true,
                confirmButtonText: this.$tc('administration.controlling.processMigration.startMigrationPopup.confirmText'),
                showCancelButton: true,
                cancelButtonText: this.$tc('administration.controlling.processMigration.startMigrationPopup.cancelText'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.isConfirmed) {
                    const data = {
                        migrationDocument: this.migrationScript,
                        sourceProcessDefinitionId: this.selectedMigrationType === this.migrationTypes.VERSION
                            ? this.selectedMigrationOption.id
                            : null,
                        processInstanceIds: this.selectedMigrationType === this.migrationTypes.SELECTED
                            ? this.migrationScript.selectedProcessInstancesIds
                            : null
                    }
                    this.selectedMigrationType === this.migrationTypes.SINGLE
                        ? this.executeProcessInstanceMigration(data)
                        : this.executeProcessVersionMigration(data)
                }
            })
        }
    },
    computed: {
        getHeaderCountInfo () {
            return `${this.$tc('administration.controlling.processMigration.version')} ${this.processDefinition.latestVersion}`
        },

        getInstancesHeaderCount () {
            if (!this.$refs.processInstancesTable) {
                return this.processDefinition.instances.length
            }
            const visibleRowsCount = this.$refs.processInstancesTable.getTableRows.length
            return visibleRowsCount !== this.processDefinition.instances.length
                ? `${visibleRowsCount}/${this.processDefinition.instances.length}`
                : this.processDefinition.instances.length
        },

        isInstancesTableFilterDisabled () {
            return this.selectedMigrationType === this.migrationTypes.SELECTED &&
                this.migrationScript.toProcessDefinitionId !== ''
        },

        getInstancesTableConfig () {
            return [{
                key: 'id',
                label: this.$tc('administration.controlling.processMigration.instanceTable.instanceId'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                copyable: true,
                exportable: true,
                width: 25
            }, {
                key: 'processDefinitionVersion',
                label: this.$tc('administration.controlling.processMigration.instanceTable.version'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                copyable: true,
                exportable: true,
                width: 10
            }, {
                key: 'product.productNumber',
                label: this.$tc('administration.controlling.processMigration.instanceTable.productNumber'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                copyable: true,
                exportable: true,
                width: 15
            }, {
                key: 'taskContext',
                label: this.$tc('administration.controlling.processMigration.instanceTable.taskContext'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                copyable: true,
                exportable: true,
                width: 15
            }, {
                key: 'activities',
                label: this.$tc('administration.controlling.processMigration.instanceTable.activities'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 35
            }]
        },

        isValidMigrationVersionSelected () {
            return this.selectedMigrationType && this.processDefinition.processVersions
                .some(version => version.id === this.migrationScript.toProcessDefinitionId)
        },

        getSelectedMigrationTypeDescription () {
            if (this.selectedMigrationType === this.migrationTypes.SINGLE) {
                return this.$tc('administration.controlling.processMigration.migrateSingleInstanceVersionInfo')
            }
            if (this.selectedMigrationType === this.migrationTypes.VERSION) {
                return this.$tc('administration.controlling.processMigration.migrateVersionInstancesVersionInfo')
            }
            return this.$tc('administration.controlling.processMigration.migrateVisibleInstancesVersionInfo')
        },

        getAvailableMigrationOptions () {
            if (this.selectedMigrationType === this.migrationTypes.VERSION) {
                return this.processDefinition.processVersions
            } else {
                const options = []
                this.processDefinition.instances.forEach(instance => {
                    options.push({
                        id: instance.id,
                        label: instance.id,
                        version: instance.processDefinitionVersion
                    })
                })
                return options
            }
        },

        getPossibleMigrationVersions () {
            const versions = []
            if (this.selectedMigrationType === this.migrationTypes.SELECTED) {
                const instanceVersions = this.$refs.processInstancesTable.getTableRows.map(instance => instance.processDefinitionVersion)
                const maxVersion = Math.max(...instanceVersions)
                this.processDefinition.processVersions.forEach(version => {
                    versions.push({
                        id: version.id,
                        label: version.label,
                        disabled: maxVersion > version.label
                    })
                })
            } else {
                if (!this.selectedMigrationOption.id) {
                    return versions
                }
                this.processDefinition.processVersions.forEach(version => {
                    versions.push({
                        id: version.id,
                        label: version.label,
                        disabled: this.selectedMigrationType === this.migrationTypes.SINGLE
                            ? version.label < this.selectedMigrationOption.version
                            : version.label < this.selectedMigrationOption.label
                    })
                })
            }
            return versions
        },

        // Vue implies a possible infinite loop with two selects accessing the same function to get options.
        getAvailableActivitiesFrom () {
            return this.processActivities.map(activity => {
                return {
                    value: activity.id,
                    label: this.getActivityDisplayText(activity)
                }
            })
        },

        getAvailableActivitiesTo () {
            return this.processActivities.map(activity => {
                return {
                    value: activity.id,
                    label: this.getActivityDisplayText(activity)
                }
            })
        },

        getSuggestions () {
            return [{
                value: 'contentsAdHocProcessDefinitionId',
                label: 'contentsAdHocProcessDefinitionId'
            }]
        }
    },
    watch: {
        '$refs.processInstancesTable.getTableRows' () {
            if (this.selectedMigrationType === this.migrationTypes.SELECTED) {
                this.migrationScript.toProcessDefinitionId = null
            }
        }
    },
    mounted () {
        this.getProcessInstances()
    }
}
</script>

<template>
    <div class="generals-container">
        <LoadingScreen v-if="loadingScreen.visible"
                       v-bind:display-text="loadingScreen.text"
                       v-bind:is-cancelable=true
                       @loading-cancel="clearLoadingScreen(true)">
        </LoadingScreen>
        <ContentHeader v-bind:header-title="processDefinition.name"
                       v-bind:header-count="getHeaderCountInfo"
                       v-bind:is-primary-header=true
                       v-bind:go-back-target="{name: 'Controlling', title: $tc('administration.controlling.headline')}">
        </ContentHeader>
        <div class="process-migration-wrapper">
            <div class="process-migration-instances-container">
                <ContentHeader v-bind:header-title="$tc('administration.controlling.processMigration.processInstances')"
                               v-bind:header-count="getInstancesHeaderCount">
                </ContentHeader>
                <div class="process-migration-instances-table-container">
                    <Table table-id="processMigration"
                           v-bind:table-config="getInstancesTableConfig"
                           v-bind:table-data="processDefinition.instances"
                           v-bind:filter-placeholder="$tc('administration.controlling.processMigration.instanceTable.filterPlaceholder')"
                           v-bind:table-empty-message="$tc('administration.controlling.processMigration.instanceTable.tableEmpty')"
                           v-bind:filter-no-results-message="$tc('administration.controlling.processMigration.instanceTable.filterNoResults')"
                           v-bind:filter-disabled="isInstancesTableFilterDisabled"
                           v-bind:show-spinner="showSpinner"
                           default-sorting-key="processDefinitionVersion"
                           v-bind:custom-user-filter="true"
                           v-bind:read-only=false
                           v-bind:highlight-selected=true
                           v-bind:selection-id="selectedInstanceId"
                           @row-click=showDiagram($event)
                           ref="processInstancesTable">
                        <!-- id: default cell content -->
                        <!-- processDefinitionVersion: default cell content -->
                        <!-- product.productNumber: default cell content -->
                        <!-- product.shortTitle: default cell content -->
                        <template #cell(activities)="data">
                            <div v-for="flowNode in data.row.activeFlowNodes"
                                 v-bind:key="flowNode.id"
                                 v-bind:title="$tc('generals.copyToClipboard')"
                                 class="process-migration-instance-activity"
                                 v-on:click="copyToClipboard($event, flowNode.id)">
                                <span class="process-migration-instance-activity-id">{{getActivityDisplayText(flowNode)}}</span>
                            </div>
                        </template>
                    </Table>

                    <ModalContainer v-if="this.diagramImage.source"
                                    v-bind:is-expanded="this.diagramImage.modalExpanded"
                                    v-bind:modal-title="this.processDefinition.name"
                                    v-bind:modal-width-percent="75"
                                    @modal-close="setDiagramImageModalClosed()">
                        <div class="process-migration-instances-table-diagram-preview">
                            <img alt="BPMN-Process-Model"
                                 v-bind:src="this.diagramImage.source"
                                 class="process-migration-instances-table-diagram-preview-img"/>
                        </div>
                    </ModalContainer>
                </div>
            </div>
        </div>
        <PageOverlay v-bind:slot-specifier="migrationConfigurationName"
                     v-bind:use-header="true"
                     header-size="medium"
                     v-bind:collapsed-text="$tc('administration.controlling.processMigration.expandConfiguration')"
                     v-bind:expanded-text="$tc('administration.controlling.processMigration.collapseConfiguration')">
            <template v-slot:[`${migrationConfigurationName}_overlay-header`]>
                <div class="process-migration-configuration-header">
                    <ContentHeader v-bind:header-title="$tc('administration.controlling.processMigration.migrationConfiguration')"
                                   v-bind:is-primary-header=true
                                   v-bind:use-slot="true">
                        <template v-slot>
                            <Button v-bind:is-disabled=!isValidMigrationVersionSelected
                                    button-type="submit"
                                    @button-submit="confirmMigrationExecution()">
                                <template v-slot>
                                    <span>{{$tc('administration.controlling.processMigration.migrateProcess')}}</span>
                                </template>
                            </Button>
                            <Button button-type="cancel"
                                    @button-submit="setupMigrationScript(true)">
                                <template v-slot>
                                    <span>{{$tc('administration.controlling.processMigration.migrateReset')}}</span>
                                </template>
                            </Button>
                        </template>
                    </ContentHeader>
                </div>
            </template>
            <template v-slot:[`${migrationConfigurationName}_overlay`]>
                <div class="process-migration-configuration-wrapper">
                    <div class="process-migration-setup-container">
                        <div class="process-migration-setup-step">
                            <div class="process-migration-setup-step-description">{{$tc('administration.controlling.processMigration.selectMigrationTypeInfo')}}</div>
                            <SmartSelect v-bind:id="`${migrationConfigurationName}_migration-type`"
                                         v-bind:key="updateCounter"
                                         v-bind:submit-button=false
                                         v-bind:placeholder="$tc('administration.controlling.processMigration.selectMigrationType')"
                                         v-bind:tiny-label="$tc('administration.controlling.processMigration.migrationType')"
                                         v-bind:allow-input=false
                                         v-bind:sort-options=false
                                         v-bind:option-label-specifiers="['label']"
                                         v-bind:options="migrationTypeOptions"
                                         @select-blurred="updateMigrationType($event)"
                                         @select-submit="updateMigrationType($event)"
                                         @select-change="updateMigrationType($event)">
                            </SmartSelect>
                        </div>
                        <transition name="fade">
                            <div v-if="selectedMigrationType" class="process-migration-setup-step">
                                <div class="process-migration-setup-step-description">{{getSelectedMigrationTypeDescription}}</div>
                                <SmartSelect v-if="selectedMigrationType !== migrationTypes.SELECTED"
                                             v-bind:id="`${migrationConfigurationName}_migration-from`"
                                             v-bind:key="selectedMigrationType"
                                             v-bind:submit-button=false
                                             v-bind:placeholder="selectedMigrationType === migrationTypes.SINGLE
                                                ? $tc('administration.controlling.processMigration.selectProcessInstance')
                                                : $tc('administration.controlling.processMigration.selectProcessVersion')"
                                             v-bind:tiny-label="selectedMigrationType === migrationTypes.SINGLE
                                                ? $tc('administration.controlling.processMigration.migrateInstance')
                                                : $tc('administration.controlling.processMigration.migrateVersion')"
                                             v-bind:allow-input=true
                                             v-bind:sort-options=false
                                             v-bind:filter-label-specifiers="['label']"
                                             v-bind:option-label-specifiers="['label']"
                                             v-bind:options="getAvailableMigrationOptions"
                                             @select-blurred="updateMigrationOption($event)"
                                             @select-submit="updateMigrationOption($event)"
                                             @select-change="updateMigrationOption($event)">
                                </SmartSelect>
                                <span v-if="selectedMigrationType !== migrationTypes.SELECTED"
                                      class="fas fa-arrow-right process-migration-setup-step-arrow">
                                </span>
                                <SmartSelect v-bind:id="`${migrationConfigurationName}_migration-to`"
                                             v-bind:key="`${selectedMigrationType}_${selectedMigrationOption.id}`"
                                             v-bind:submit-button=false
                                             v-bind:placeholder="$tc('administration.controlling.processMigration.selectProcessVersion')"
                                             v-bind:tiny-label="selectedMigrationType === migrationTypes.SELECTED
                                                ? $tc('administration.controlling.processMigration.targetVersion')
                                                : $tc('administration.controlling.processMigration.processVersion')"
                                             v-bind:allow-input=false
                                             v-bind:sort-options=false
                                             v-bind:filter-label-specifiers="['label']"
                                             v-bind:option-label-specifiers="['label']"
                                             v-bind:is-disabled="getPossibleMigrationVersions.length === 0"
                                             v-bind:options="getPossibleMigrationVersions"
                                             @select-blurred="updateMigrationVersion($event)"
                                             @select-submit="updateMigrationVersion($event)"
                                             @select-change="updateMigrationVersion($event)">
                                </SmartSelect>
                            </div>
                        </transition>
                        <transition name="fade">
                            <div v-if="isValidMigrationVersionSelected"
                                 class="process-migration-setup-step">
                                <div class="process-migration-setup-step-description">{{$tc('administration.controlling.processMigration.defineAdditionalActions')}}</div>
                                <div class="process-migration-setup-step-description">{{$tc('administration.controlling.processMigration.defineActivityMappings')}}</div>
                                <SmartSelect v-bind:id="`${migrationConfigurationName}_activity-from`"
                                             v-bind:key="`${selectedMigrationType}_${selectedMigrationOption.id}`"
                                             v-bind:submit-button=false
                                             v-bind:placeholder="$tc('administration.controlling.processMigration.enterActivity')"
                                             v-bind:tiny-label="$tc('administration.controlling.processMigration.fromThisActivity')"
                                             v-bind:allow-input=true
                                             v-bind:sort-options=true
                                             v-bind:filter-label-specifiers="['label']"
                                             v-bind:option-label-specifiers="['label']"
                                             v-bind:options="getAvailableActivitiesFrom"
                                             v-bind:is-disabled="getAvailableActivitiesFrom.length === 0"
                                             @select-blurred="setActivityFrom($event)"
                                             @select-submit="setActivityFrom($event)"
                                             @select-change="setActivityFrom($event)">
                                </SmartSelect>
                                <span class="fas fa-arrow-right process-migration-setup-step-arrow"></span>
                                <SmartSelect v-bind:id="`${migrationConfigurationName}_activity-to`"
                                             v-bind:key="`${selectedMigrationOption.id}_${selectedMigrationType}`"
                                             v-bind:submit-button=true
                                             v-bind:show-unsaved-changes=true
                                             v-bind:placeholder="$tc('administration.controlling.processMigration.enterActivity')"
                                             v-bind:tiny-label="$tc('administration.controlling.processMigration.toThisActivity')"
                                             v-bind:allow-input=true
                                             v-bind:sort-options=true
                                             v-bind:filter-label-specifiers="['label']"
                                             v-bind:option-label-specifiers="['label']"
                                             v-bind:options="getAvailableActivitiesTo"
                                             v-bind:is-disabled="getAvailableActivitiesTo.length === 0"
                                             @select-submit="updateActivityMappings($event, activityFrom)">
                                </SmartSelect>
                            </div>
                        </transition>
                        <transition name="fade">
                            <div v-if="isValidMigrationVersionSelected"
                                 class="process-migration-setup-step">
                                <div class="process-migration-setup-step-description">{{$tc('administration.controlling.processMigration.definePostScript')}}</div>
                                <SmartSelect v-bind:id="`${migrationConfigurationName}_suggestions`"
                                             v-bind:key="`${selectedMigrationOption.id}_${selectedMigrationType}`"
                                             v-bind:tiny-label="$tc('administration.controlling.processMigration.processVariable')"
                                             v-bind:placeholder="$tc('administration.controlling.processMigration.enterProcessVariable')"
                                             v-bind:submit-button=false
                                             v-bind:allow-input="true"
                                             v-bind:is-type-ahead="true"
                                             v-bind:filter-label-specifiers="['label']"
                                             v-bind:option-label-specifiers="['label']"
                                             v-bind:options="getSuggestions"
                                             ref="postScriptKeySelect">
                                </SmartSelect>
                                <span class="fas fa-arrow-right process-migration-setup-step-arrow"></span>
                                <TextInput v-bind:tiny-label="$tc('administration.controlling.processMigration.newValue')"
                                           v-bind:placeholder="$tc('administration.controlling.processMigration.enterNewValue')"
                                           v-bind:submit-button=true
                                           v-bind:show-unsaved-changes=true
                                           v-bind:default-value="migrationScript.toProcessDefinitionId"
                                           icon-class="fas fa-check"
                                           @input-submit="updateMigrationVariables($event)">
                                </TextInput>
                            </div>
                        </transition>
                    </div>
                    <div class="process-migration-summary-container">
                        <div>{{$tc('administration.controlling.processMigration.doubleCheckAndFinish')}}</div>
                        <div>{{$tc('administration.controlling.processMigration.migrationScriptEditableHint')}}</div>
                        <IconButton class="process-migration-summary-write-protection-button"
                                    v-bind:class="{'m--editable': migrationScriptEditable}"
                                    v-bind:icon-class="migrationScriptEditable ? 'fas fa-unlock' : 'fas fa-lock'"
                                    v-bind:tooltip="migrationScriptEditable
                                        ? $tc('administration.controlling.processMigration.lockMigrationScript')
                                        : $tc('administration.controlling.processMigration.unlockMigrationScript')"
                                    @button-submit="toggleMigrationScriptEditable()">
                        </IconButton>
                        <pre class="process-migration-summary-code-wrapper"><code
                            id="process-migration-summary-code"
                            class="process-migration-summary-code"
                            v-bind:class="{'m--editable': migrationScriptEditable}"
                            v-on:blur="updateMigrationScript($event)">{{migrationScript}}</code>
                        </pre>
                    </div>
                </div>
            </template>
        </PageOverlay>
    </div>
</template>

<style scoped lang="less">
.process-migration-wrapper {
    padding: var(--container-spacing);
    width: 100%;
    height: calc(100% - var(--primary-headline-height) - var(--page-overlay-collapsed-height));

    .process-migration-instances-container {
        position: relative;
        float: left;
        height: 100%;
        width: 100%;

        .process-migration-instances-table-container {
            width: 100%;
            height: calc(100% - var(--headline-height));

            .process-migration-instance-activity .process-migration-instance-activity-id {
                &:hover {
                    color: var(--color-text-highlighted);
                    cursor: pointer;
                    &:after {
                        position: absolute;
                        margin: 4px 0 0 10px;
                        content: "\f0c5";
                        font-family: "Font Awesome 5 Free", serif;
                        font-size: 15px;
                        font-weight: 500;
                    }
                }
            }

            .process-migration-instance-activity {
                &:not(:first-child) {
                    margin-top: 4px;
                }
            }

            .process-migration-instances-table-diagram-preview {
                height: 100%;
                width: 100%;
                overflow: auto;
                position: relative;

                .process-migration-instances-table-diagram-preview-img {
                    height: 99%;
                    width: auto;
                }
            }
        }
    }
}

.process-migration-configuration-header {
    position: relative;
    height: 100%;
    width: 100%;
    background-color: var(--color-background-light);
}

.process-migration-configuration-wrapper {
    position: relative;
    height: 100%;

    .process-migration-setup-container,
    .process-migration-summary-container {
        position: relative;
        float: left;
        height: 100%;
        width: 50%;
        overflow-y: auto;
    }

    .process-migration-setup-container {
        padding-right: var(--container-spacing);
        border-right: 1px solid var(--color-border-light);

        .process-migration-setup-step {
            &:not(:last-child) {
                margin-bottom: 25px;
            }

            .process-migration-setup-step-arrow {
                margin: 0 var(--container-spacing)
            }

            .process-migration-setup-step-description {
                margin-bottom: 25px;
            }

            .process-migration-setup-step-info {
                font-size: 13px;
                margin-top: 5px;
            }
        }
    }

    .process-migration-summary-container {
        padding-left: var(--container-spacing);

        .process-migration-summary-write-protection-button {
            margin-left: -3px;
            padding: 0;
            color: var(--color-success);

            &.m--editable {
                color: var(--color-warning);
            }
        }

        .process-migration-summary-code-wrapper {
            margin: 0;

            .process-migration-summary-code {
                cursor: not-allowed;

                &.m--editable {
                    cursor: text;
                    color: var(--color-info);

                    &:focus {
                        outline: none;
                    }
                }
            }
        }

    }
}
</style>
