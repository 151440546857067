<script>
/**
 * @deprecated - This component is deprecated. Use the new EssentialSmartForm component instead.
 * SmartForm is a styled customizable and extendable form with fields that include lots of logic.
 * It will throw a @form-submit event on pressing submit, a @form-change event when input value changes and a @form-submittable-change when the submittable state of the form changes to true or false.
 * - id: Should be provided in order to get full functionality. Without an id you won't get the ability to close on esc and instant focus the input field when opening.
 * - allowRepetition (default: false): If true, the user will be redirected to the first page of the pre-filled form, after the form has been sent successfully, in order to modify it and send it again.
 * - repeatFormButtonLabel (optional): If set, the button to repeat the form will show the given text. Otherwise, the button will show a default.
 * - hideTableOfContents (default: false): If true, there will be no table of contents on the left side of the form, which also acts as a form navigation.
 * - hideSubmitButton (default: false): If true, there will be no submit button on the last page of the form.
 * - startSection (default: 0): Set the desired section to be active once the form was rendered.
 * - formConfig (required): Object with form specific information. Determines the functionality of the form. The following information should be passed:
 *      - id: The form id.
 *      - headline: Text displayed in the form header.
 *      - sections: List of form sections. The following information should be passed:
 *          - id: The section id.
 *          - title: The page title. Will be displayed in the table of content.
 *          - allowMultiple: If true, the form allows adding new pages with the same section fields. At least one page must exist. Additional pages can be deleted.
 *          - pageTitle: Necessary if allowMultiple is true. Defines the default name of the pages in the section
 *          - pageTitleKey: Enter the ID of a field in the section here to specify its value as the page title when it is changed.
 *          - pages: List of pages within the section. At least on page must exist. Pages only contain key values pairs of each field of the section.
 *          - fields: List of form fields within the section. The following information should be provided for each field:
 *              - id: The field is. Must be provided in order to make the form work as intended.
 *              - label: The text displayed on the left side of the input field.
 *              - required: If true, the field must not be empty in order to continue to the next form page.
 *              - description: Text displayed in order to give the user some extra information about the field. Displayed in a clickable light bulb.
 *              - placeholder: The placeholder text in the input field. If not provided, a default text will be displayed.
 *              - disabled: If true, the field will always be disabled. Notice: A disabled field can be required. Make sure that you only use required property for disabled fields, if the disabled flag can be removed by filling other form fields or if you edit the field automatically.
 *              - invisible: If true, the field will always be invisible. Notice: A field cannot be required as long as it is not visible! Required will be overwritten when checking the correctness of the form.
 *              - validValues: The valid values of the field. If unset validValues has no effect. If set, the validation of the field is successful only, if the actual value is in the valid values.
 *              - quantity: A number which results in the possibility to add the same field until the quantity is reached. The user can add and remove fields by pressing the generated plus and minus buttons. The id of additional fields will have the current field count as suffix. For example: Original-ID 'myFieldId' results in 'myFieldId_2', 'myFieldId_3' and so on.
 *              - minimum: The minimum value. Can be used for Date or Number values.
 *              - maximum: The maximum value. Can be used for Date or Number values.
 *              - maxLength: The maximum character length: Can be used for Text and Textarea.
 *              - uploadLimit: The maximum number of files that can be uploaded. Can be used for Upload and UploadInput.
 *              - options: Only for type Select and Checklist. List of objects with label and value. Label will be displayed, value will be saved.
 *              - typeAhead: Only for Checklist. If true, there will be an 'Other' option, allowing the user to enter a value different from the provided ones.
 *              - pattern: Currently only available for Textarea. Regex pattern as string to prevent undesired user inputs.
 *              - singleChoice: Only for Checklist. If true, only a single option can be selected.
 *              - multiUpload: Only for Upload(Input). True enables multiselect in file explorer.
 *              - isEntryDisabledKey: Only for Upload(Input) and EntryList. If provided, the checklist entry will be disabled if the given key is set to true.
 *              - isDownload: Only for Link. If true, a download from the back end will be triggered with proper authorization. The field value will be the url, placeholder will be used as filename.
 *              - keyLabel: Only for EntryList (with or without associated UploadInput) and LinkList. The specified key should be part of the objects within the list, as it will be used as label for each list entry.
 *              - keyUrl: Only for LinkList. The specified key should be part of the objects within the list, as it will be used as URL for each list entry.
 *              - showUploads: Only for UploadInput: If true, a list with uploaded files will appear underneath the upload input. Click and submit functions of this list can be configured by parent component.
 *              - actions: Only for EntryList. Define an api for click and/or submit functionality. Enable them by providing the following properties:
 *                  - click: Api for the click functionality
 *                  - delete: Api for submit functionality
 *                  - param: A key of the objects within the list. Will be added to your api as url parameter.
 *              - visibleIf: Ruleset which determines whether the field will be displayed or not. VisibleIf is an array of arrays. Rules within an array are AND conditions. Different arrays are OR conditions. Single condition contains the following information:
 *                  - fieldId: The id of any field in the form. Can be a field in a different section.
 *                  - rule: The rule. Currently, valid rules are "EQUALS" (field with fieldId has a specific value) and "EXISTS" (field with fieldId has any value).
 *                  - value: The expected value if rule is "EQUALS"
 *                  - not: Reverts the rule if set to true. For example "EXISTS" will be like null or empty.
 *              - enableIf: Ruleset which determines whether the field will be displayed or not. See "visibleIf" for further information.
 *              - submitButton: Show a submit button alongside the field (e.g. for text input).
 *              - request: Configure a request that will be executed once the input value changed. The request has the following properties:
 *                  - api: Rest-Api that will be requested on submit with the entered value(s). The response can be automatically applied to other form fields,
 *                  - apiType: Rest API type. GET or POST,
 *                  - apiParameter: This value will be used as api parameter key with the inputs value as api parameter value. If not provided, the value will be added as body,
 *                  - isExternalApi: If true, a complete api url must be provided in 'api' (https://someUrl). If false, 'api' must be the name of the desired api. The path to the CorFlow rest service will be added automatically.
 *                  - lockScreen: If true, the screen will be locked until the backend responded,
 *                  - lockScreenCancel: If true, the user can cancel the lockScreen and come back later.
 *                  - polling: An object with a polling configuration. If provided the form will do a request to the specified api every two seconds and check if a specific property has a specific value. 'polling' has the following properties:
 *                      - readyStateProperty: Name of the property of the backend response to be used for evaluating the progress.
 *                      - readyStateValues: Values indicating that the process is complete.
 *                  - formFieldMappings: Maps values of the JSON-object backend response to the correct form field of the section. Key value pairs. The key specifies a property of the backend response. The value is an id of a field within the section.
 *              - type: Determines which kind of input field will be used. Valid types are:
 *                  - Text (String)
 *                  - Textarea (String)
 *                  - Email (String): Same as Text, but with a '@' icon and with validation.
 *                  - Number (Integer)
 *                  - Date (Date String)
 *                  - Checkbox (Boolean)
 *                  - Checklist (String Array)
 *                  - Select (String)
 *                  - ReadOnly (No save functionality): Works like a disabled text input, but it looks like a static text without greyed out background.x
 *                  - Paragraph (No save functionality): Renders HTML snippets. Can be used in order to display error messages etc. Displays the content within 'label' property.
 *                    When setting property 'custom' to true, the paragraphs content can be defined via backend or bpmn. The content will then be taken from its value.
 *                    Paragraph can have the special property 'subType', which determines the look of the rendered paragraph. Possible subTypes are:
 *                      - info,
 *                      - success,
 *                      - warning,
 *                      - error
 *                  - Link (String)
 *                  - EntryList ('List'): Like a list of 'Links' (rendered from an array of JSON objects), but with the possibility to bind an api to click, plus an optional submit button.
 *                  - LinkList: Like EntryList, but the click event will just open the listed link's URL.
 *                  - SimpleList: A simple list used in combination with key-value mechanism. Deletion of list entries, is done not by a separate api, but by synching the actual field-value, a list of entries, with the backend.
 *                  - TimeSpan: Two time picker to specify a time span.
 *                  - TimeCode: A single time picker to specifiy a duration or time code.
 *                  - DualText: Like text, but with two separated text input fields.
 *                  - Currency (Integer): Same as Number, but with a '€' icon to make clear it's a currency.
 *                  - Estimate (Object with Integer and Boolean): renders an input type number with a checkbox next to it, which allows to declare a value as estimation. Value will be an object e.g. { value: 5, isEstimate: true }
 *                  - Subheading (No save functionality): Used to cluster field in a single section
 *                  - Upload (Multipart): Meant to be used in combination with 'request'
 *                  - UploadInput (Multipart): Meant to be used in combination with 'request'. Unlike Upload, it is constructed like a typical input.
 *                  - DynamicInput: Field can differentiate between field types e.g. Text, Number, Date, Upload, based on the provided fieldTypes.
 *                  - Form (No save functionality): Used to display a sub form. Provides search functionality and an expandable results list in which the user can choose the correct set of data from the backend response. Automatically fills out specified input fields.
 *                    Important: The backend response items must have an id!
 *                    Form has the following special attributes:
 *                      - fields: The fields of the sub form. Attributes are id, label, description, placeholder. See fields of section for further information. The values of the sub form fields will be sent as request parameters with the field's id as parameter name. If property apiParameter is provided, it will be used instead of the id.
 *                      - enableReset: There will be an additional reset button which will reset all values of 'formFieldMappings'.
 *                      // New style
 *                      - Use 'request' configuration like described above.
 *                      // Old style
 *                      - apiCall: Rest API that will be requested on submit.
 *                      - apiType: Rest API type. GET or POST,
 *                      - isExternalApi: If true, a complete api url must be provided in apiCall (https://someUrl). If false, apiCall must be the name of the desired api. The path to the CorFlow rest service will be added automatically.
 *                      - desiredResponseFields: List of property strings of the backend response. This specifies the information that will be displayed in the results table.
 *                      - formFieldMappings: Maps values of the backend response to the correct form field of the section. Key value pairs. The key specifies a property of the backend response. The value is an id of a field within the section.
 *
 * @property {String} formConfiguration.id
 * @property {String} formConfiguration.headline
 * @property {Array} formConfiguration.sections
 * @property {Array} pages
 * @property {Array} sections
 * @property {Array} visibleIf
 * @property {Array} enableIf
 * @property {Array} desiredResponseFields
 * @property {Array} validValues
 * @property {String} pageTitle
 * @property {String} pageTitleKey
 * @property {String} fieldId
 * @property {String} apiCall
 * @property {String} apiType
 * @property {String} apiParameter
 * @property {Boolean} invisible
 * @property {Boolean} submitButton
 * @property {Boolean} isExternalApi
 * @property {Boolean} typeAhead
 * @property {Boolean} reducedNotifications
 * @property {Integer} quantity
 * @property {Object} formFieldMappings
 * @property {Boolean} lockScreen
 * @property {Boolean} lockScreenCancel
 * @property {Boolean} polling
 * @property {String} readyStateProperty
 * @property {Array} readyStateValues
 * @property {Array} fileTypes
 * @property {String} subType
 * @property {Boolean} isDownload
 * @property {String} keyLabel
 * @property {String} keyUrl
 * @property {Boolean} multiUpload
 * @property {Boolean} showUploads
 * @property {Number} uploadLimit
 * @property {String} isEntryDisabledKey
 **/
import HelpText from '@/components/HelpText.vue'
import Icon from '@/components/Icon.vue'
import IconButton from '@/components/IconButton.vue'
import Button from '@/components/Button.vue'
import ContentHeader from '@/components/ContentHeader.vue'
import SmartFormSubheading from '@/components/SmartFormSubheading.vue'
import InlineForm from '@/components/InlineForm.vue'
import TextInput from '@/components/TextInput.vue'
import EmailInput from '@/components/EmailInput.vue'
import AreaInput from '@/components/AreaInput.vue'
import NumberInput from '@/components/NumberInput.vue'
import DateInput from '@/components/DateInput.vue'
import SmartSelect from '@/components/SmartSelect.vue'
import Checkbox from '@/components/Checkbox.vue'
import DropInput from '@/components/DropInput.vue'
import Checklist from '@/components/Checklist.vue'
import InteractionLink from '@/components/InteractionLink.vue'
import TimeSpanInput from '@/components/TimeSpanInput.vue'
import TimeCodeInput from '@/components/TimeCodeInput.vue'
import DualTextInput from '@/components/DualTextInput.vue'
import EntryList from '@/components/EntryList.vue'
import DynamicInput from '@/components/DynamicInput.vue'
import VerticalLine from '@/components/VerticalLine.vue'
import LoadingScreen from '@/components/LoadingScreen.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { notificationHandler } from '@/mixins/notificationHandler'
import { downloadHandler } from '@/mixins/downloadHandler'
import _ from 'lodash'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'SmartForm',
    mixins: [
        axiosService,
        userHandler,
        notificationHandler,
        downloadHandler,
        dateTimeHelper
    ],
    components: {
        HelpText,
        Icon,
        IconButton,
        Button,
        ContentHeader,
        SmartFormSubheading,
        InlineForm,
        TextInput,
        EmailInput,
        AreaInput,
        NumberInput,
        DateInput,
        SmartSelect,
        Checkbox,
        DropInput,
        Checklist,
        InteractionLink,
        TimeSpanInput,
        TimeCodeInput,
        DualTextInput,
        EntryList,
        DynamicInput,
        VerticalLine,
        LoadingScreen
    },
    // @form-submit: Event emitted on submit | returns the event and the formConfig
    // @form-change: Event emitted on input | returns the formConfig
    // @form-submittable-change: Event emitted when form submittable state changes / returns the submittable state
    emits: ['form-submit', 'form-change', 'form-submittable-change'],
    props: {
        id: String,
        allowRepetition: {
            default: false,
            type: Boolean
        },
        repeatFormButtonLabel: String,
        hideTableOfContents: {
            default: false,
            type: Boolean
        },
        hideSubmitButton: {
            default: false,
            type: Boolean
        },
        startSection: {
            default: 0,
            type: Number
        },
        formConfig: Object
    },
    data () {
        return {
            // Make sure to make a copy in order to not mutate the original form. We only let the parent element know about changes, but delegate how to deal with it.
            formConfiguration: _.cloneDeep(this.formConfig),
            repeatOnSubmit: false,
            submitLocked: false,
            sectionSelectionIndex: 0,
            pageSelectionIndex: 0,
            tableOfContentExpanded: true,
            showMissingValues: false,
            savingFields: {},
            isFormSubmittable: null,
            updateCounter: 0,
            rules: {
                exists: 'EXISTS',
                equals: 'EQUALS'
            },
            loadingScreen: {
                visible: false,
                cancelable: false,
                text: ''
            }
        }
    },
    methods: {
        toggleTableOfContent () {
            this.tableOfContentExpanded = !this.tableOfContentExpanded
        },

        toggleSection (section) {
            section.isOpen = !section.isOpen
            this.$forceUpdate()
        },

        isSectionDisabled (index) {
            let disabled = false
            while (index - 1 >= 0 && !disabled) {
                disabled = this.isSectionIncomplete(index - 1)
                index--
            }
            return disabled
        },

        isSectionSelected (index) {
            return index === this.sectionSelectionIndex
        },

        setSectionSelected (section, index) {
            if (section.allowMultiple) {
                this.toggleSection(section)
            } else if (!this.isSectionSelected(index)) {
                this.setPageSelected(0, index)
            }
        },

        isPageSelected (pageIndex, sectionIndex) {
            return pageIndex === this.pageSelectionIndex &&
                this.isSectionSelected(sectionIndex)
        },

        // Generate temporary fields within the form structure, based on the field's quantity
        generateTemporaryFields () {
            // originalId = ID of original field without index - indicator that it is a field with temporary fields
            this.getSelectedSection.fields = this.getSelectedSection.fields.filter(field => !field.originalId)
            for (const field of this.getSelectedSection.fields) {
                // when a new temp field is added, the actual current index changes
                // => We have to retrieve it for each loop again
                const index = this.getSelectedSection.fields.indexOf(field)
                // quantity = maximum number of fields including temporary fields
                if (field.quantity && field.quantity > 1) {
                    // hasChild = if falsy, indicates that it is the last field
                    delete field.hasChild
                    const childFieldCount = this.getChildFieldCount(field)
                    const tempFieldId = `${field.originalId || field.id}_${childFieldCount + 1}`
                    const valueExists = this.getSelectedPage[tempFieldId]
                    if (valueExists) {
                        this.addChildFieldToSelectedSelection(field, index)
                    }
                }
            }
        },

        setPageSelected (pageIndex, sectionIndex) {
            if (!this.isPageSelected(pageIndex, sectionIndex)) {
                this.pageSelectionIndex = pageIndex
                this.showMissingValues = false
                if (!this.isSectionSelected(sectionIndex)) {
                    this.sectionSelectionIndex = sectionIndex
                }
                this.generateTemporaryFields()
                document.getElementById(`c_smart-form-body_${this.formConfiguration.id}`).scrollTop = 0
            }
        },

        setFormSubmitStatus () {
            this.isFormSubmittable = !this.hasIncompleteSections()
        },

        getSectionTitle (section) {
            return this.getTranslatedObjectProperty(section, 'title')
        },

        getHeaderTitle () {
            const selectedSection = this.getSelectedSection
            if (!selectedSection.allowMultiple) {
                return this.getSectionTitle(selectedSection)
            }
            return this.getPageTitle(this.getSelectedPage, selectedSection, this.pageSelectionIndex)
        },

        hasIncompleteSections () {
            return this.formConfiguration.sections.some((section, index) => this.isSectionIncomplete(index))
        },

        getPageTitle (page, section, index) {
            if (page.title) {
                return page.title
            }
            if (section.pageTitleKey && page[section.pageTitleKey]) {
                return page[section.pageTitleKey]
            }
            const pageTitle = this.getTranslatedObjectProperty(section, 'pageTitle')
            return `${index + 1}. ${pageTitle}`
        },

        setPageTitle (event, field, page) {
            delete page.isInEditMode
            this.setFieldValue(event, field)
        },

        setPageInEditMode (page, inEditMode) {
            inEditMode
                ? page.isInEditMode = true
                : delete page.isInEditMode
            this.$forceUpdate()
        },

        setRepeatOnSubmit (doRepeat) {
            this.repeatOnSubmit = doRepeat
        },

        addFormPage (section, index) {
            section.pages.push({})
            if (index) {
                this.setPageSelected(section.pages.length - 1, index)
            }
            this.setFormSubmitStatus()
        },

        isDeletePageDisabled (section) {
            return section.pages.length <= 1
        },

        deleteFormPageFromSection (section, sectionIndex, page, pageIndex) {
            const changes = {}
            Object.keys(page).forEach(key => {
                changes[key] = null
            })
            section.pages.splice(pageIndex, 1)

            const pageIsPartOfTheSelectedSection = sectionIndex === this.sectionSelectionIndex
            const isPageOnAPositionBeforeTheSelectedOne = pageIndex < this.pageSelectionIndex
            const isTheSelectedPageOnLastPosition = pageIndex === this.pageSelectionIndex && pageIndex === section.pages.length
            if (pageIsPartOfTheSelectedSection &&
                (isPageOnAPositionBeforeTheSelectedOne || isTheSelectedPageOnLastPosition)) {
                this.setPageSelected(this.pageSelectionIndex - 1, this.sectionSelectionIndex)
            }
            this.emitChange(changes)
        },

        navigateForwards () {
            const selectedSection = this.getSelectedSection
            if (this.pageSelectionIndex + 1 < selectedSection.pages.length) {
                const nextPageDisabled = selectedSection.fields.some(field => this.isRequiredButEmptyField(field))
                this.showMissingValues = nextPageDisabled
                nextPageDisabled
                    ? this.showFormIncompleteMessage()
                    : this.setPageSelected(this.pageSelectionIndex + 1, this.sectionSelectionIndex)
            } else {
                const nextSectionDisabled = this.isSectionDisabled(this.sectionSelectionIndex + 1)
                this.showMissingValues = nextSectionDisabled
                if (nextSectionDisabled) {
                    this.showFormIncompleteMessage()
                } else {
                    this.setPageSelected(0, this.sectionSelectionIndex + 1)
                    this.getSelectedSection.isOpen = this.getSelectedSection.allowMultiple
                }
            }
        },

        navigateBackwards () {
            if (this.pageSelectionIndex - 1 >= 0) {
                this.setPageSelected(this.pageSelectionIndex - 1, this.sectionSelectionIndex)
            } else {
                const newSectionIndex = this.sectionSelectionIndex - 1
                const pages = this.formConfiguration.sections[newSectionIndex].pages
                this.setPageSelected(pages.length - 1, newSectionIndex)
            }
        },

        hasFilledDependentFields (page, section, dynamicField) {
            function hasRequestMappingDependency (potentialDependentField) {
                return dynamicField?.request?.formFieldMappings &&
                    Object.values(dynamicField?.request?.formFieldMappings).includes(potentialDependentField.id)
            }

            const dependentFields = section.fields
                .filter(potentialDependentField => potentialDependentField.id !== dynamicField.id &&
                    hasRequestMappingDependency(potentialDependentField))
            return dependentFields.some(dependentField => {
                return !this.isFalsyValue(dependentField, page[dependentField.id])
            })
        },

        isFalsyValue (field, value) {
            if (Array.isArray(value)) {
                return value.length === 0
            }
            const conditions = []
            const fieldValue = value instanceof Object
                ? value.value
                : value

            conditions.push(fieldValue === undefined)
            conditions.push(fieldValue === null)
            conditions.push(fieldValue === '')
            if (Array.isArray(field.validValues) && field.validValues.length > 0) {
                conditions.push(!field.validValues.includes(fieldValue))
            }
            return conditions.includes(true)
        },

        isSectionIncomplete (index) {
            const section = this.formConfiguration.sections[index]
            return section.fields.some(field => {
                return section.pages.some((page, pageIndex) => {
                    const nonDynamicFalsy = field.type !== 'Dynamic' &&
                        this.isFalsyValue(field, page[field.id])
                    const dynamicNoFilledDependents = field.type === 'Dynamic' &&
                        !this.hasFilledDependentFields(page, section, field)
                    return this.isFieldRequired(field, section.id, pageIndex) &&
                        (nonDynamicFalsy || dynamicNoFilledDependents)
                })
            })
        },

        isRequiredButEmptyField (field) {
            const currentPage = this.getSelectedPage
            return this.isFalsyValue(field, currentPage[field.id]) && this.isFieldRequired(field)
        },

        // sectionId and pageIndex are set automatically, if necessary (only for isSectionComplete in order to know the section that is currently checked in areFieldConditionsFulfilled).
        isFieldRequired (field, sectionId, pageIndex) {
            return field.required && this.isFieldVisible(field, sectionId, pageIndex)
        },

        areFieldConditionsFulfilled (orConditionsList, sectionId, pageIndex) {
            /* Start: Closures to simplify code structure */
            function getFormValues (appContext, condition) {
                const values = []

                // Due to conditions based on values on different pages, it is necessary to check for the fields value on all pages in all sections
                appContext.formConfiguration.sections.forEach(section => {
                    if (section.allowMultiple && (section.id === sectionId || section.id === currentSection.id)) {
                        const sectionPage = pageIndex !== undefined ? section.pages[pageIndex] : appContext.getSelectedPage
                        const value = sectionPage[condition.fieldId]
                        values.push(value)
                    } else {
                        section.pages.forEach(page => {
                            const value = page[condition.fieldId]
                            values.push(value)
                        })
                    }
                })
                return values
            }
            function isExistConditionFulfilled (fieldValue) {
                if (fieldValue === null || fieldValue === undefined) {
                    return false
                }
                if (Array.isArray(fieldValue)) {
                    return fieldValue.length > 0
                }
                if (fieldValue instanceof Object) {
                    return fieldValue.value !== null && fieldValue.value !== undefined && fieldValue.value !== ''
                }
                return !!fieldValue
            }
            function isEqualConditionFulfilled (fieldValue, condition) {
                if (Array.isArray(fieldValue)) {
                    return fieldValue.includes(condition.value)
                }
                if (fieldValue instanceof Object) {
                    return fieldValue.value === condition.value
                }
                if (condition.value === false) {
                    // we accept "undefined" (e.g. unset checkbox) as value for "false" (e.g. checkbox set to "false")
                    return !!fieldValue === condition.value
                }
                return fieldValue === condition.value
            }
            /* End: Closures to simplify code structure */

            // Actual conditions check
            const currentSection = this.getSelectedSection

            // Stop execution if one orCondition is fulfilled
            return orConditionsList.some(orCondition => {
                // All conditions within a conditionsList are AND conditions (DNF)  - stop execution if one condition is not fulfilled
                return orCondition.every(andCondition => {
                    const fieldValues = getFormValues(this, andCondition)
                    const logicallyFulfilled = fieldValues.some(fieldValue => {
                        switch (andCondition.rule) {
                        case this.rules.exists:
                            return isExistConditionFulfilled(fieldValue)
                        case this.rules.equals:
                            return isEqualConditionFulfilled(fieldValue, andCondition)
                        default:
                            return false
                        }
                    })
                    return andCondition.not
                        ? !logicallyFulfilled
                        : logicallyFulfilled
                })
            })
        },

        getAndResetDependentFields (fieldId) {
            function hasVisibleDependency (field) {
                return field.visibleIf && field.visibleIf.some(conditionList => {
                    return conditionList.some(condition => condition.fieldId === fieldId)
                })
            }
            function hasEnableDependency (field) {
                return field.enableIf && field.enableIf.some(conditionList => {
                    return conditionList.some(condition => condition.fieldId === fieldId)
                })
            }
            function deleteFieldDataOnPage (appContext, page, dependentField) {
                if ((!appContext.isFieldVisible(dependentField) || appContext.isFieldDisabled(dependentField)) && page[dependentField.id]) {
                    const changes = {}
                    changes[dependentField.id] = null
                    resetFields.push(dependentField.id)
                    delete page[dependentField.id]
                    if (dependentField.onHideDelete) {
                        appContext.axiosDelete(
                            dependentField.onHideDelete,
                            appContext.$tc('smartForm.apiError'),
                            null,
                            false
                        )
                    }
                }
            }
            const currentSection = this.getSelectedSection
            const resetFields = []
            this.formConfiguration.sections.forEach(section => {
                const dependentFields = section.fields.filter(field => field.id !== fieldId && (hasVisibleDependency(field) || hasEnableDependency(field)))
                dependentFields.forEach(dependentField => {
                    section.id === currentSection.id
                        ? deleteFieldDataOnPage(this, this.getSelectedPage, dependentField)
                        : section.pages.forEach(page => deleteFieldDataOnPage(this, page, dependentField))
                })
            })
            return resetFields
        },

        // sectionId and pageIndex are set automatically, if necessary (only for isSectionComplete in order to know the section that is currently checked in areFieldConditionsFulfilled
        isFieldDisabled (field, sectionId, pageIndex) {
            if (field.disabled || !field.enableIf || field.enableIf.length === 0) {
                return field.disabled
            }
            return !this.areFieldConditionsFulfilled(field.enableIf, sectionId, pageIndex)
        },

        // sectionId and pageIndex are set automatically, if necessary (only for isSectionComplete in order to know the section that is currently checked in areFieldConditionsFulfilled
        isFieldVisible (field, sectionId, pageIndex) {
            if (field.invisible || !field.visibleIf || field.visibleIf.length === 0) {
                return !field.invisible
            }
            return this.areFieldConditionsFulfilled(field.visibleIf, sectionId, pageIndex)
        },

        isChildFieldButtonPairVisible (field) {
            return field.quantity && field.quantity > 1 && !field.hasChild
        },

        getChildFieldCount (field) {
            const comparisonId = field.originalId || field.id
            return this.getSelectedSection.fields
                .filter(sectionField => sectionField.id === comparisonId || sectionField.originalId === comparisonId)
                .length
        },

        addChildFieldToSelectedSelection (field, index) {
            const newField = _.cloneDeep(field)
            newField.required = false
            newField.description = null
            newField.hasChild = null
            newField.label = ' '
            newField.originalId = field.originalId || field.id
            newField.id = `${newField.originalId}_${this.getChildFieldCount(field) + 1}`
            field.hasChild = newField.id
            this.getSelectedSection.fields.splice(index + 1, 0, newField)
        },

        removeChildField (field, index) {
            delete this.getSelectedSection.fields[index - 1].hasChild
            this.getSelectedSection.fields.splice(index, 1)
            this.setFieldValue({ value: null }, field)
        },

        isFieldSaving (field) {
            return this.savingFields[field.id] && this.savingFields[field.id].showSaveIndicator
        },

        setFieldValue (submitEventData, field) {
            const value = field.type === 'Select'
                ? submitEventData.option.value
                : submitEventData.value
            if (field.request) {
                const config = submitEventData.inputType === 'link' ? { headers: { 'Content-Type': 'text/plain' } } : null
                this.executeApiRequest(field, value, false, config)
            } else {
                this.getSelectedPage[field.id] = field.type === 'Estimate'
                    ? { value: value, isEstimate: submitEventData.isEstimate }
                    : value

                const changes = {}
                changes[field.id] = value

                this.emitChange(changes, [field.id])
            }
        },

        getTranslatedObjectProperty (object, property) {
            // Check for language and default to german
            const language = this.$global.localization.locale
            return object[`${property}_${language}`] || object[property]
        },

        getSelectLabelSpecifier (options) {
            const noTranslatedLabels = options.some(option => !option[`label_${this.$global.localization.locale}`])
            return noTranslatedLabels
                ? ['label']
                : [`label_${this.$global.localization.locale}`]
        },

        getChecklistOptions (field) {
            const options = []
            field.options.forEach(option => {
                options.push({
                    label: this.getTranslatedObjectProperty(option, 'label'),
                    value: option.value
                })
            })
            return options
        },

        getFieldLabel (field) {
            const label = this.getTranslatedObjectProperty(field, 'label')
            return this.isFieldRequired(field)
                ? `${label} *`
                : label
        },

        getFieldDescription (field) {
            return this.getTranslatedObjectProperty(field, 'description')
        },

        getFieldPlaceholder (field) {
            return this.getTranslatedObjectProperty(field, 'placeholder')
        },

        getFieldValue (field) {
            return this.getSelectedPage[field.id]
        },

        getEstimateValue (field) {
            const fieldValue = this.getSelectedPage[field.id]
            return fieldValue
                ? fieldValue.value
                : null
        },

        getEstimateBool (field) {
            const fieldValue = this.getSelectedPage[field.id]
            return fieldValue
                ? fieldValue.isEstimate
                : false
        },

        getDateByField (field) {
            const value = this.getFieldValue(field)
            return value || null
        },

        getValueAsDate (value) {
            // returns null if it cannot be parsed as a date
            return this.tryToParseToDate(value)
        },

        getOptionByFieldValue (field) {
            const value = this.getFieldValue(field)
            return value === undefined || value === null
                ? null
                : field.options.find(option => option.value === value)
        },

        getParagraphIconClass (subType) {
            switch (subType) {
            case 'info':
                return 'fas fa-info-circle'
            case 'success':
                return 'fas fa-check-circle'
            case 'warning':
                return 'fas fa-exclamation-circle'
            case 'error':
                return 'fas fa-minus-circle'
            default:
                return 'fas fa-question-circle'
            }
        },

        executeApiRequest (apiField, value, continueRunningRequest, config) {
            /* Start: Closures to simplify code structure */
            function getValueOfNestedObjectProperty (propertyString, object) {
                if (propertyString === '.') {
                    // selects the whole object
                    return object
                }
                const propertyChain = propertyString.split('.')
                const propertyValue = propertyChain.reduce((previous, current) => previous && previous[current], object)
                return propertyValue || null
            }
            function prepareRequest (appContext, field, value) {
                if (field.request.apiType.toLowerCase() === 'get') {
                    let url = field.request.api
                    url += field.request.apiParameter
                        ? `?${field.request.apiParameter}=${value}`
                        : `/${value}`

                    return appContext.axiosGet(
                        url,
                        appContext.$tc('smartForm.apiError'),
                        null,
                        field.request.isExternalApi
                    )
                } else {
                    let data = {}
                    field.request.apiParameter
                        ? data[field.request.apiParameter] = value
                        : data = value

                    let url
                    try {
                        // eslint-disable-next-line no-eval
                        url = eval(field.request.api)
                    } catch (exception) {
                        url = field.request.api
                    }
                    return appContext.axiosPost(
                        url,
                        data,
                        appContext.$tc('smartForm.apiError'),
                        null,
                        field.request.isExternalApi,
                        config
                    )
                }
            }
            function mapResponseFields (appContext, apiField, apiFieldValue, response) {
                const changedFieldIds = []
                const changes = {}
                const selectedPage = appContext.getSelectedPage
                const defaultValues = appContext.getSelectedSection.defaultValues

                if (apiField.type === 'Upload' || apiField.type === 'UploadInput') {
                    apiFieldValue = apiFieldValue
                        ? 'FileUpload'
                        : null
                }
                selectedPage[apiField.id] = apiFieldValue
                changes[apiField.id] = apiFieldValue
                changedFieldIds.push(apiField.id)

                Object.keys(apiField.request.formFieldMappings).forEach(mappingKey => {
                    const fieldId = apiField.request.formFieldMappings[mappingKey]
                    let fieldValue = getValueOfNestedObjectProperty(mappingKey, response)
                    if ((fieldValue === null || fieldValue === undefined) && defaultValues) {
                        fieldValue = defaultValues[fieldId]
                    }
                    selectedPage[fieldId] = fieldValue
                    changes[fieldId] = fieldValue
                    changedFieldIds.push(fieldId)
                })
                appContext.emitChange(changes, changedFieldIds)
                appContext.loadingScreen.visible = false
                appContext.loadingScreen.text = null
            }
            function executeLongRunningRequest (appContext, requestConfig) {
                appContext.activeLongRunningRequestInterval = setInterval(() => {
                    appContext.axiosGet(
                        requestConfig.pollStatusApi,
                        appContext.$tc('smartForm.apiError'),
                        null)
                        .then(pollingResponse => {
                            const readyStateFormValue = getValueOfNestedObjectProperty(requestConfig.readyStateProperty, pollingResponse)
                            if (requestConfig.readyStateValues.includes(readyStateFormValue)) {
                                clearInterval(appContext.activeLongRunningRequestInterval)
                                delete appContext.formConfiguration.activeLongRunningRequest
                                mapResponseFields(appContext, requestConfig.apiField, requestConfig.value, pollingResponse)
                            }
                        })
                        .catch(() => {})
                }, 2000)
            }
            /* End: Closures to simplify code structure */

            /* Start: Actual request preparation and execution */
            // Continue execution of a pending long-running request.
            if (continueRunningRequest) {
                this.loadingScreen.visible = this.formConfiguration.activeLongRunningRequest.apiField.request.lockScreen
                this.loadingScreen.cancelable = this.formConfiguration.activeLongRunningRequest.apiField.request.lockScreenCancel
                if (this.loadingScreen.cancelable) {
                    this.loadingScreen.text = this.$tc('smartForm.continueRequestInfo')
                }
                return executeLongRunningRequest(this, this.formConfiguration.activeLongRunningRequest)
            }

            // Reset dependent field values, if empty value was entered in api field.
            if (!value || value === '') {
                return mapResponseFields(this, apiField, null, {})
            }

            this.loadingScreen.visible = apiField.request.lockScreen
            this.loadingScreen.cancelable = apiField.request.lockScreenCancel
            if (this.loadingScreen.cancelable) {
                this.loadingScreen.text = this.$tc('smartForm.longRequestInfo')
            }

            const request = prepareRequest(this, apiField, value)
            request
                .then(response => {
                    if (apiField.request.polling) {
                        this.formConfiguration.activeLongRunningRequest = {
                            pollStatusApi: response.pollStatusApi,
                            readyStateProperty: apiField.request.polling.readyStateProperty,
                            readyStateValues: apiField.request.polling.readyStateValues,
                            value: value,
                            apiField: {
                                id: apiField.id,
                                type: apiField.type,
                                request: {
                                    lockScreen: apiField.request.lockScreen,
                                    lockScreenCancel: apiField.request.lockScreenCancel,
                                    formFieldMappings: apiField.request.formFieldMappings
                                }
                            }
                        }
                        this.emitChange({})
                        executeLongRunningRequest(this, this.formConfiguration.activeLongRunningRequest)
                    } else {
                        mapResponseFields(this, apiField, value, response)
                    }
                })
                .catch(() => mapResponseFields(this, apiField, null, {}))
        },

        getInlineFormFieldProperties (inlineFormField) {
            const props = {}
            props[inlineFormField.id] = {
                visible: this.isFieldVisible(inlineFormField),
                disabled: this.isFieldDisabled(inlineFormField)
            }
            inlineFormField.fields.forEach(input => {
                props[input.id] = {
                    visible: this.isFieldVisible(input),
                    disabled: this.isFieldDisabled(input)
                }
            })
            return props
        },

        getListItems (field) {
            const items = this.getFieldValue(field)

            return items && Array.isArray(items)
                ? items
                : []
        },

        fieldHasClickFunction (field) {
            return !!(field.actions && field.actions.click)
        },

        fieldHasDeleteFunction (field) {
            return !!(field.actions && field.actions.delete)
        },

        openListItem (entryListSubmitData, field) {
            const values = this.getFieldValue(field)
            const itemToOpen = values[entryListSubmitData.index]
            let urlValue = field.keyUrl
                ? itemToOpen[field.keyUrl]
                : this.getFieldValue(field)
            urlValue = this.addParamToUrl(field, itemToOpen, urlValue)
            this.openUrlForValue(urlValue)
        },

        downloadListItem (entryListSubmitData, field) {
            const values = this.getFieldValue(field)
            const itemToDownload = values[entryListSubmitData.index]
            const filename = itemToDownload[field.keyLabel]
            const urlString = this.addParamToUrl(field, itemToDownload, field.actions.click)
            this.downloadFileFromUrl(urlString, false, filename)
        },

        removeListItem (entryListSubmitData, field) {
            const values = this.getFieldValue(field)
            const itemToDelete = values[entryListSubmitData.index]
            const urlString = this.addParamToUrl(field, itemToDelete, field.actions.delete)
            this.axiosDelete(
                urlString,
                null,
                this.$tc('smartForm.deleteEntryError'))
                .then(() => {
                    values.splice(entryListSubmitData.index, 1)
                    this.setFieldValue({ value: values }, field)

                    this.emitChange({}, [field.id])
                    this.forceRender()
                }).catch(() => {})
        },

        removeFromSimpleList (entryListSubmitData, field) {
            const values = this.getFieldValue(field)
            values.splice(entryListSubmitData.index, 1)
            const changes = {}
            changes[field.id] = values
            this.emitChange(changes, [field.id])
        },

        addParamToUrl: function (field, listItem, rawUrlString) {
            let urlString = rawUrlString
            const urlParam = field.actions?.param
            if (urlParam) {
                const urlParamValue = listItem[urlParam]
                if (rawUrlString.includes(`{${urlParam}}`)) {
                    // e.g. for param "id" URL includes "{id}"
                    urlString = urlString.replace(`{${urlParam}}`, urlParamValue)
                } else {
                    urlString += urlParamValue
                }
            }
            return urlString
        },

        applySearchData (dataAppliedEventData) {
            this.emitChange(dataAppliedEventData.changes, Object.keys(dataAppliedEventData.changes), false)
        },

        resetAppliedSearchData (dataResetEventData) {
            this.emitChange(dataResetEventData.changes, Object.keys(dataResetEventData.changes), false)
        },

        downloadFile (field) {
            const value = this.getFieldValue(field)
            this.downloadFileFromUrl(value)
        },

        openUrl (field) {
            const value = this.getFieldValue(field)
            this.openUrlForValue(value)
        },

        openUrlForValue (value) {
            let urlString
            try {
                urlString = new URL(value)
            } catch (_) {
                return window.open(`https://${value}`, '_blank')
            }
            const url = urlString.protocol === 'http:' || urlString.protocol === 'https:'
                ? value
                : `https://${value}`

            window.open(url, '_blank')
        },

        emitChange (changes, savedFieldsIds, resetDependentFields = true) {
            if (savedFieldsIds) {
                const processedFieldIds = []
                let processFieldIds = Array.from(savedFieldsIds)
                while (processFieldIds.length > 0) {
                    const processFieldId = processFieldIds.pop()
                    if (processedFieldIds.includes(processFieldId)) {
                        continue
                    }
                    processedFieldIds.push(processFieldId)
                    if (savedFieldsIds.includes(processFieldId)) {
                        if (this.savingFields[processFieldId]) {
                            clearTimeout(this.savingFields[processFieldId].runningTimeout)
                        }
                        this.savingFields[processFieldId] = {
                            showSaveIndicator: true,
                            runningTimeout: setTimeout(() => {
                                delete this.savingFields[processFieldId]
                                this.$forceUpdate()
                            }, 3000)
                        }
                    } else {
                        changes[processFieldId] = null
                    }
                    if (resetDependentFields) {
                        processFieldIds = processFieldIds.concat(this.getAndResetDependentFields(processFieldId))
                    }
                }
            }
            this.$emit('form-change', {
                formConfig: this.formConfiguration,
                changes: changes
            })
            this.setFormSubmitStatus()
            this.$forceUpdate()
        },

        emitScroll () {
            window.dispatchEvent(new Event('scroll'))
        },

        showFormIncompleteMessage () {
            const userPreferences = this.getUserSettingsParameter('userPreferences')
            const reducedNotifications = userPreferences.reducedNotifications
            const notification = {
                type: 'warning',
                duration: reducedNotifications
                    ? 3000
                    : 5000,
                message: reducedNotifications
                    ? this.$t('smartForm.incompleteMessage.short')
                    : this.$t('smartForm.incompleteMessage.standard')
            }
            reducedNotifications
                ? this.addCursorNotification(notification)
                : this.addNotification(notification)
        },

        submitForm (event) {
            event.stopPropagation()
            const sendDisabled = this.isSectionIncomplete(this.sectionSelectionIndex)
            this.showMissingValues = sendDisabled
            if (!sendDisabled) {
                this.$emit('form-submit', {
                    event: event,
                    formConfig: this.formConfiguration,
                    repeat: this.repeatOnSubmit
                })
                if (this.repeatOnSubmit) {
                    this.setPageSelected(0, 0)
                    this.setRepeatOnSubmit(false)
                } else {
                    this.submitLocked = true
                    setTimeout(() => {
                        this.submitLocked = false
                    }, 7500)
                }
            } else {
                this.showFormIncompleteMessage()
            }
        },

        forceRender () {
            this.updateCounter++
        }
    },
    computed: {
        getFormHeadline () {
            return this.getTranslatedObjectProperty(this.formConfiguration, 'headline')
        },

        isAddAnotherPageVisible () {
            const selectedSection = this.getSelectedSection
            return selectedSection.allowMultiple &&
                selectedSection.pages.length - 1 === this.pageSelectionIndex
        },

        isFirstPageSelected () {
            return this.sectionSelectionIndex === 0 &&
                this.pageSelectionIndex === 0
        },

        isLastPageSelected () {
            return this.formConfiguration.sections.length - 1 === this.sectionSelectionIndex &&
                this.getSelectedSection.pages.length - 1 === this.pageSelectionIndex
        },

        getSelectedSection () {
            return this.formConfiguration.sections[this.sectionSelectionIndex]
        },

        getSelectedPage () {
            const selectedSection = this.getSelectedSection
            const selectedPageFieldCount = Object.keys(selectedSection.pages[this.pageSelectionIndex]).length
            if (selectedPageFieldCount === 0 && selectedSection.defaultValues) {
                const changes = _.cloneDeep(selectedSection.defaultValues)
                selectedSection.pages[this.pageSelectionIndex] = changes
                this.emitChange(changes)
            }
            return selectedSection.pages[this.pageSelectionIndex]
        }
    },
    watch: {
        isFormSubmittable () {
            this.$emit('form-submittable-change', this.isFormSubmittable)
        }
    },
    mounted () {
        this.setFormSubmitStatus()
        // Check if the form configuration contains an active long-running request and finish it.
        if (this.formConfiguration.activeLongRunningRequest) {
            this.executeApiRequest(null, null, true)
        }
        // Open selected "page" of the form and generate its temporary fields.
        // If we start at the beginning, we will still generate the temporary fields.
        this.startSection > 0 && this.startSection < this.formConfiguration.sections.length
            ? this.setPageSelected(0, this.startSection)
            : this.generateTemporaryFields()
    },
    beforeUnmount () {
        // Make sure that possibly active long-running requests stop executing status requests when leaving the form page.
        clearInterval(this.activeLongRunningRequestInterval)
    }
}
</script>

<template>
    <div class="c_smart-form-wrapper">
        <transition name="fade">
            <LoadingScreen v-if="loadingScreen.visible"
                           v-bind:is-cancelable="loadingScreen.cancelable"
                           v-bind:display-text="loadingScreen.text"
                           @loading-cancel="loadingScreen.visible=false">
            </LoadingScreen>
        </transition>
        <div v-if="!this.hideTableOfContents"
             class="c_smart-form-content-container generals-animate"
             v-bind:class="{'m--collapsed': !tableOfContentExpanded}">
            <div class="c_smart-form-content-header">
                <span>{{$tc('smartForm.tableOfContents')}}</span>
            </div>
            <div class="c_smart-form-content">
                <div v-for="(section, sectionIndex) in formConfiguration.sections"
                     v-bind:key="sectionIndex"
                     class="c_smart-form-content-section"
                     v-bind:class="{
                         'm--selected': isSectionSelected(sectionIndex),
                         'm--multiple': section.allowMultiple,
                         'm--complete': !isSectionIncomplete(sectionIndex),
                         'm--disabled': isSectionDisabled(sectionIndex)}">
                    <div class="c_smart-form-content-section-title"
                          v-on:click="setSectionSelected(section, sectionIndex)">
                        <span>{{getSectionTitle(section)}}</span>
                    </div>
                    <IconButton v-if="section.allowMultiple"
                                class="c_smart-form-content-section-button"
                                v-bind:icon-class="section.isOpen ? 'fas fa-caret-down' : 'fas fa-caret-left'"
                                @button-submit="toggleSection(section)">
                    </IconButton>
                    <transition name="show" tag="div">
                        <div v-if="section.isOpen"
                             v-bind:key="sectionIndex"
                             class="c_smart-form-content-section-pages">
                            <div v-for="(page, pageIndex) in section.pages"
                                 v-bind:key="pageIndex"
                                 class="c_smart-form-content-section-page"
                                 v-bind:class="{'m--selected': isPageSelected(pageIndex, sectionIndex)}"
                                 v-on:click="setPageSelected(pageIndex, sectionIndex)">
                                <span v-if="!page.isInEditMode"
                                      tabindex="1"
                                      class="c_smart-form-content-section-page-title"
                                      v-on:click="setPageInEditMode(page,true)">{{getPageTitle(page, section, pageIndex)}}
                                </span>
                                <TextInput v-else
                                           v-bind:id="`page-title-input_${sectionIndex}_${pageIndex}`"
                                           v-bind:default-value="getPageTitle(page, section, pageIndex)"
                                           v-bind:submit-button="true"
                                           icon-class="fas fa-check"
                                           v-bind:is-auto-focused="true"
                                           @input-lost-focus="setPageInEditMode(page,false)"
                                           @input-close="setPageInEditMode(page,false)"
                                           @input-submit="setPageTitle($event, { id: section.pageTitleKey || 'title', type: 'Text'}, page)">
                                </TextInput>
                                <IconButton class="c_smart-form-content-section-page-delete-button"
                                            icon-class="fas fa-trash"
                                            v-bind:font-size="13"
                                            v-bind:tooltip="$t('smartForm.deletePage', [page.title ? `'${page.title}'` : section.pageTitle]).toString()"
                                            v-bind:is-disabled="isDeletePageDisabled(section)"
                                            @button-submit="deleteFormPageFromSection(section, sectionIndex, page, pageIndex)">
                                </IconButton>
                            </div>
                            <div class="c_smart-form-content-section-pages-button"
                                 v-on:click="addFormPage(section, sectionIndex)">
                                <span class="fas fa-plus"> </span>
                                <span>{{$t('smartForm.addPage', [section.pageTitle])}}</span>
                            </div>
                        </div>
                    </transition>
                </div>
            </div>
        </div>

        <div class="c_smart-form-container generals-animate"
             v-bind:class="{'m--full-width': hideTableOfContents || !tableOfContentExpanded}">
            <div class="c_smart-form-headline">
                <div v-if="!this.hideTableOfContents"
                     class="c_smart-form-header-toggle"
                     v-on:click="toggleTableOfContent()">
                    <span v-bind:class="[tableOfContentExpanded ? 'fas fa-caret-left' : 'fas fa-caret-right']"></span>
                </div>
                <span>{{getFormHeadline || $tc('smartForm.defaultHeadline')}}</span>
            </div>
            <div class="c_smart-form">
                <ContentHeader v-bind:header-title="getHeaderTitle()"
                               v-bind:use-slot="true">
                    <template v-slot>
                        <IconButton icon-class="fas fa-trash"
                                    v-bind:tooltip="$t('smartForm.deletePage', [getSelectedPage.title ? `'${getSelectedPage.title}'` : getSelectedSection.pageTitle]).toString()"
                                    v-bind:is-disabled="isDeletePageDisabled(getSelectedSection)"
                                    @button-submit="deleteFormPageFromSection(getSelectedSection, sectionSelectionIndex, getSelectedPage, pageSelectionIndex)">
                        </IconButton>
                    </template>
                </ContentHeader>
                <div v-bind:id="`c_smart-form-body_${formConfiguration.id}`"
                     class="c_smart-form-body"
                     v-on:scroll="emitScroll()">
                    <div class="c_smart-form-fields">
                        <transition-group name="show" tag="div">
                            <template v-for="(field, fieldIndex) in getSelectedSection.fields">
                                <div v-if="isFieldVisible(field)"
                                     v-bind:key="`${field.id}_${pageSelectionIndex}`"
                                     class="c_smart-form-field"
                                     v-bind:class="{
                                         'm--missing': isRequiredButEmptyField(field) && showMissingValues,
                                         'm--no-margin': field.originalId
                                     }">
                                    <div v-if="field.type === 'Subheading'">
                                        <SmartFormSubheading v-bind:label="getFieldLabel(field)" />
                                    </div>
                                    <InlineForm v-if="field.type === 'Form'"
                                                v-bind:field="field"
                                                v-bind:field-properties="getInlineFormFieldProperties(field)"
                                                v-bind:page="getSelectedPage"
                                                v-bind:page-index="pageSelectionIndex"
                                                @data-applied="applySearchData($event)"
                                                @data-reset="resetAppliedSearchData($event)">
                                    </InlineForm>
                                    <TextInput v-if="field.type === 'Text'"
                                               v-bind:label="getFieldLabel(field)"
                                               v-bind:submit-button="field.submitButton || false"
                                               v-bind:show-unsaved-changes=true
                                               v-bind:is-disabled=isFieldDisabled(field)
                                               v-bind:placeholder="getFieldPlaceholder(field)"
                                               v-bind:default-value="getFieldValue(field)"
                                               v-bind:max-length="field.maxLength"
                                               icon-class="fas fa-pen"
                                               @input-blurred="setFieldValue($event, field)"
                                               @input-submit="setFieldValue($event, field)">
                                    </TextInput>
                                    <EmailInput v-else-if="field.type === 'Email'"
                                                v-bind:label="getFieldLabel(field)"
                                                v-bind:submit-button="field.submitButton || false"
                                                v-bind:show-unsaved-changes=true
                                                v-bind:is-disabled=isFieldDisabled(field)
                                                v-bind:placeholder="getFieldPlaceholder(field)"
                                                v-bind:default-value="getFieldValue(field)"
                                                icon-class="fas fa-at"
                                                @input-blurred="setFieldValue($event, field)"
                                                @input-submit="setFieldValue($event, field)">
                                    </EmailInput>
                                    <AreaInput v-else-if="field.type === 'Textarea'"
                                               v-bind:label="getFieldLabel(field)"
                                               v-bind:submit-button="field.submitButton || false"
                                               v-bind:show-unsaved-changes=true
                                               v-bind:is-disabled=isFieldDisabled(field)
                                               v-bind:placeholder="getFieldPlaceholder(field)"
                                               v-bind:default-value="getFieldValue(field)"
                                               v-bind:is-expandable="true"
                                               v-bind:max-length="field.maxLength"
                                               v-bind:pattern="field.pattern"
                                               v-bind:patternKey="field.patternKey"
                                               icon-class="far fa-sticky-note"
                                               @input-blurred="setFieldValue($event, field)"
                                               @input-submit="setFieldValue($event, field)">
                                    </AreaInput>
                                    <div v-else-if="field.type === 'Paragraph'"
                                         class="c_smart-form-field-paragraph"
                                         v-bind:class="`m--${field.subType}`">
                                        <span v-if="field.subType"
                                              class="c_smart-form-field-paragraph-icon"
                                              v-bind:class="getParagraphIconClass(field.subType)">
                                        </span>
                                        <div v-dompurify-html="field.custom
                                            ? getFieldValue(field) || getFieldPlaceholder(field)
                                            : getFieldLabel(field)"></div>
                                    </div>
                                    <NumberInput v-else-if="field.type === 'Number'"
                                                 v-bind:label="getFieldLabel(field)"
                                                 v-bind:submit-button="field.submitButton || false"
                                                 v-bind:show-unsaved-changes=true
                                                 v-bind:is-disabled=isFieldDisabled(field)
                                                 v-bind:placeholder="getFieldPlaceholder(field)"
                                                 v-bind:default-value="getFieldValue(field)"
                                                 v-bind:minimum-value="field.minimum || 0"
                                                 v-bind:maximum-value="field.maximum"
                                                 @input-blurred="setFieldValue($event, field)"
                                                 @input-submit="setFieldValue($event, field)">
                                    </NumberInput>
                                    <NumberInput v-else-if="field.type === 'Estimate'"
                                                 v-bind:label="getFieldLabel(field)"
                                                 v-bind:submit-button="field.submitButton || false"
                                                 v-bind:show-unsaved-changes=true
                                                 v-bind:allow-estimate=true
                                                 v-bind:default-estimate="getEstimateBool(field)"
                                                 v-bind:is-disabled=isFieldDisabled(field)
                                                 v-bind:placeholder="getFieldPlaceholder(field)"
                                                 v-bind:default-value="getEstimateValue(field)"
                                                 v-bind:minimum-value="field.minimum || 0"
                                                 v-bind:maximum-value="field.maximum"
                                                 @input-blurred="setFieldValue($event, field)"
                                                 @input-submit="setFieldValue($event, field)">
                                    </NumberInput>
                                    <NumberInput v-else-if="field.type === 'Currency'"
                                                 v-bind:label="getFieldLabel(field)"
                                                 v-bind:submit-button="field.submitButton || false"
                                                 v-bind:show-unsaved-changes=true
                                                 icon-class="fas fa-euro-sign"
                                                 v-bind:is-disabled=isFieldDisabled(field)
                                                 v-bind:placeholder="getFieldPlaceholder(field)"
                                                 v-bind:default-value="getFieldValue(field)"
                                                 v-bind:minimum-value="field.minimum || 0"
                                                 v-bind:maximum-value="field.maximum"
                                                 @input-blurred="setFieldValue($event, field)"
                                                 @input-submit="setFieldValue($event, field)">
                                    </NumberInput>
                                    <DateInput v-else-if="field.type === 'Date'"
                                               v-bind:label="getFieldLabel(field)"
                                               v-bind:submit-button="field.submitButton || false"
                                               v-bind:show-unsaved-changes=true
                                               v-bind:is-disabled=isFieldDisabled(field)
                                               v-bind:placeholder="getFieldPlaceholder(field)"
                                               v-bind:default-value="getDateByField(field)"
                                               v-bind:minimum-date="getValueAsDate(field.minimum)"
                                               v-bind:maximum-date="getValueAsDate(field.maximum)"
                                               @input-blurred="setFieldValue($event, field)"
                                               @input-submit="setFieldValue($event, field)">
                                    </DateInput>
                                    <Checkbox v-else-if="field.type === 'Checkbox'"
                                              v-bind:label="getFieldLabel(field)"
                                              v-bind:is-disabled=isFieldDisabled(field)
                                              v-bind:default-value="getFieldValue(field)"
                                              @input-change="setFieldValue($event, field)">
                                    </Checkbox>
                                    <div v-else-if="field.type === 'Upload' || field.type === 'UploadInput'">
                                        <DropInput v-bind:id="field.id"
                                                   v-bind:label="getFieldLabel(field)"
                                                   v-bind:is-disabled="isFieldDisabled(field)"
                                                   v-bind:is-uploading="field.isUploading"
                                                   v-bind:is-drop-zone="field.type === 'Upload'"
                                                   v-bind:upload-limit="field.uploadLimit"
                                                   v-bind:use-default-label="field.type === 'UploadInput'"
                                                   v-bind:file-types="field.fileTypes || []"
                                                   v-bind:allow-multiple="field.multiUpload"
                                                   @input-submit="setFieldValue($event, field)">
                                        </DropInput>
                                        <EntryList v-if="field.showUploads && getListItems(field).length > 0"
                                                   class="c_smart-form-field-uploaded-files"
                                                   v-bind:entries="getListItems(field)"
                                                   v-bind:enable-click="true"
                                                   v-bind:enable-submit="true"
                                                   v-bind:entry-disabled-key="field.isEntryDisabledKey"
                                                   v-bind:entry-label-key="field.keyLabel"
                                                   icon-class="fas fa-times"
                                                   @entry-click="field.actions.click($event.index)"
                                                   @entry-submit="field.actions.submit($event.index)"
                                                   :key="`${field.id}_${pageSelectionIndex}_uploadlist_${updateCounter}`">
                                        </EntryList>
                                    </div>
                                    <Checklist v-else-if="field.type === 'Checklist'"
                                               v-bind:label="getFieldLabel(field)"
                                               v-bind:is-disabled=isFieldDisabled(field)
                                               v-bind:single-choice="field.singleChoice === true"
                                               v-bind:allow-custom-option="field.typeAhead"
                                               v-bind:default-values="getFieldValue(field) || []"
                                               v-bind:options="getChecklistOptions(field)"
                                               @checklist-change="setFieldValue($event, field)">
                                    </Checklist>
                                    <SmartSelect v-else-if="field.type === 'Select'"
                                                 v-bind:key="`${field.id}_${pageSelectionIndex}_${getFieldValue(field)}`"
                                                 v-bind:id="`${field.id}_${pageSelectionIndex}`"
                                                 v-bind:label="getFieldLabel(field)"
                                                 v-bind:submit-button=false
                                                 v-bind:show-unsaved-changes=true
                                                 v-bind:is-disabled=isFieldDisabled(field)
                                                 v-bind:placeholder="getFieldPlaceholder(field)"
                                                 v-bind:sort-options=false
                                                 v-bind:is-auto-focused=false
                                                 v-bind:allow-input=false
                                                 v-bind:options="field.options || []"
                                                 v-bind:filter-label-specifiers="getSelectLabelSpecifier(field.options)"
                                                 v-bind:option-label-specifiers="getSelectLabelSpecifier(field.options)"
                                                 v-bind:default-option="getOptionByFieldValue(field)"
                                                 @select-submit="setFieldValue($event, field)"
                                                 @select-change="setFieldValue($event, field)">
                                    </SmartSelect>
                                    <InteractionLink v-else-if="field.type === 'Link'"
                                                     v-bind:id="field.id"
                                                     v-bind:label="getFieldLabel(field)"
                                                     v-bind:is-highlighted="true"
                                                     v-bind:value="getFieldPlaceholder(field)"
                                                     @input-click="field.isDownload ? downloadFile(field) : openUrl(field)">
                                    </InteractionLink>
                                    <InteractionLink v-else-if="field.type === 'ReadOnly'"
                                                     v-bind:id="field.id"
                                                     v-bind:label="getFieldLabel(field)"
                                                     v-bind:value="getFieldValue(field)"
                                                     v-bind:placeholder="getFieldPlaceholder(field)"
                                                     v-bind:is-disabled="true">
                                    </InteractionLink>
                                    <TimeSpanInput v-else-if="field.type === 'TimeSpan'"
                                                   v-bind:label="getFieldLabel(field)"
                                                   v-bind:submit-button=false
                                                   v-bind:show-unsaved-changes=true
                                                   v-bind:is-disabled=isFieldDisabled(field)
                                                   v-bind:default-value="getFieldValue(field) || []"
                                                   @input-change="setFieldValue($event, field)">
                                    </TimeSpanInput>
                                    <TimeCodeInput v-else-if="field.type === 'TimeCode'"
                                                   v-bind:id="field.id"
                                                   v-bind:label="getFieldLabel(field)"
                                                   v-bind:submit-button=false
                                                   v-bind:show-unsaved-changes=true
                                                   v-bind:is-disabled=isFieldDisabled(field)
                                                   v-bind:default-value="getFieldValue(field)"
                                                   @input-blurred="setFieldValue($event, field)"
                                                   @input-submit="setFieldValue($event, field)">
                                    </TimeCodeInput>
                                    <DualTextInput v-else-if="field.type === 'DualText'"
                                                   v-bind:label="getFieldLabel(field)"
                                                   v-bind:submit-button=false
                                                   v-bind:show-unsaved-changes=true
                                                   v-bind:placeholder="field.placeholder || []"
                                                   v-bind:is-disabled=isFieldDisabled(field)
                                                   v-bind:max-length="field.maxLength"
                                                   v-bind:default-value="getFieldValue(field) || []"
                                                   icon-class="fas fa-pen"
                                                   @input-blurred="setFieldValue($event, field)"
                                                   @input-submit="setFieldValue($event, field)">
                                    </DualTextInput>
                                    <EntryList v-else-if="field.type === 'List'"
                                               v-bind:label="getFieldLabel(field)"
                                               v-bind:entries="getListItems(field)"
                                               v-bind:entry-disabled-key="field.isEntryDisabledKey"
                                               v-bind:entry-label-key="field.keyLabel"
                                               v-bind:enable-click="fieldHasClickFunction(field)"
                                               v-bind:enable-submit="fieldHasDeleteFunction(field)"
                                               icon-class="fas fa-times"
                                               @entry-click="downloadListItem($event, field)"
                                               @entry-submit="removeListItem($event, field)"
                                               :key="`${field.id}_${pageSelectionIndex}_list_${updateCounter}`">
                                    </EntryList>
                                    <EntryList v-else-if="field.type === 'LinkList'"
                                               v-bind:label="getFieldLabel(field)"
                                               v-bind:entries="getListItems(field)"
                                               v-bind:entry-disabled-key="field.isEntryDisabledKey"
                                               v-bind:entry-label-key="field.keyLabel"
                                               v-bind:enable-click="true"
                                               v-bind:enable-submit="fieldHasDeleteFunction(field)"
                                               icon-class="fas fa-times"
                                               @entry-click="openListItem($event, field)"
                                               @entry-submit="removeListItem($event, field)"
                                               :key="`${field.id}_${pageSelectionIndex}_linklist_${updateCounter}`">
                                    </EntryList>
                                    <EntryList v-else-if="field.type === 'SimpleList'"
                                               v-bind:label="getFieldLabel(field)"
                                               v-bind:entries="getListItems(field)"
                                               v-bind:entry-disabled-key="field.isEntryDisabledKey"
                                               v-bind:entry-label-key="field.keyLabel"
                                               v-bind:enable-click="false"
                                               v-bind:enable-submit="true"
                                               icon-class="fas fa-times"
                                               @entry-submit="removeFromSimpleList($event, field)"
                                               :key="`${field.id}_${pageSelectionIndex}_simplelist_${updateCounter}`">
                                    </EntryList>
                                    <DynamicInput v-else-if="field.type === 'Dynamic'"
                                                  v-bind:id="field.id"
                                                  v-bind:is-disabled="isFieldDisabled(field)"
                                                  v-bind:label="getFieldLabel(field)"
                                                  v-bind:input-types="field.dynamicTypes || []"
                                                  v-bind:allow-multiple="field.multiUpload"
                                                  @dynamic-submit="setFieldValue($event, field)">
                                    </DynamicInput>
                                    <transition name="fade">
                                        <Icon v-if="isFieldSaving(field)"
                                              class="c_smart-form-field-changes-saved"
                                              icon-type="success"
                                              icon-class="far fa-check-circle"
                                              v-bind:icon-large=true>
                                        </Icon>
                                    </transition>
                                    <div v-if="isChildFieldButtonPairVisible(field)"
                                         class="c_smart-form-field-button-pair">
                                        <IconButton v-bind:is-disabled="isFieldDisabled(field) || getChildFieldCount(field) === 1"
                                                    icon-class="fas fa-minus"
                                                    @button-submit="removeChildField(field, fieldIndex)">
                                        </IconButton>
                                        <VerticalLine></VerticalLine>
                                        <IconButton v-bind:is-disabled="isFieldDisabled(field) || getChildFieldCount(field) >= field.quantity"
                                                    icon-class="fas fa-plus"
                                                    @button-submit="addChildFieldToSelectedSelection(field, fieldIndex)">
                                        </IconButton>
                                    </div>
                                    <HelpText v-if="field.description && field.type !== 'Form'"
                                              class="c_smart-form-field-help-container"
                                              v-bind:width-px="340"
                                              v-bind:text="getFieldDescription(field)"
                                              position="left">
                                    </HelpText>
                                </div>
                            </template>
                        </transition-group>
                    </div>
                </div>
                <div class="c_smart-form-footer">
                    <span class="c_smart-form-footer-info">{{$tc('smartForm.requiredFieldsInfo')}}</span>
                    <Button v-if="!isLastPageSelected || !hideSubmitButton"
                            class="c_smart-form-body-button-margin"
                            button-type="submit"
                            v-bind:is-disabled="submitLocked"
                            @button-submit="isLastPageSelected ? submitForm($event) : navigateForwards()">
                        <template v-slot>
                            <span>
                                <span v-if="submitLocked"
                                      class="fas fa-circle-notch fa-spin"></span>
                                {{isLastPageSelected ? $tc('smartForm.submit') : $tc('smartForm.next')}}
                            </span>
                        </template>
                    </Button>
                    <Button button-type="cancel"
                            class="c_smart-form-body-button-margin"
                            v-bind:is-disabled="isFirstPageSelected"
                            @button-submit="navigateBackwards()">
                        <template v-slot>
                            <span>{{$tc('smartForm.back')}}</span>
                        </template>
                    </Button>
                    <Button v-if="isAddAnotherPageVisible"
                            button-type="inline"
                            @button-submit="addFormPage(getSelectedSection, sectionSelectionIndex)">
                        <template v-slot>
                            <span>{{$t('smartForm.addPage', [getSelectedSection.pageTitle])}}</span>
                        </template>
                    </Button>
                    <Checkbox v-if="allowRepetition && isLastPageSelected"
                              v-bind:id="`smartForm-repetition-checkbox${id}`"
                              v-bind:label="repeatFormButtonLabel || $tc('smartForm.repeatFormDefault')"
                              v-bind:default-value="repeatOnSubmit"
                              @input-change="setRepeatOnSubmit($event.value)">
                    </Checkbox>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_smart-form-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    border: 1px solid var(--color-border-light);

    .c_smart-form-content-container {
        position: relative;
        float: left;
        height: 100%;
        width: var(--smart-form-table-of-content-width);

        &.m--collapsed {
            width: 0;
            opacity: 0;
        }

        .c_smart-form-content-header {
            position: relative;
            height: var(--smart-form-line-height);
            width: 100%;
            background-color: var(--color-background-mid);
            text-align: center;
            padding-top: 6px;
            overflow: hidden;
        }

        .c_smart-form-content {
            position: relative;
            height: calc(100% - var(--smart-form-line-height));
            width: 100%;
            border-right: 1px solid var(--color-border-light);
            padding-bottom: var(--container-spacing);
            overflow-y: auto;
            overflow-x: hidden;

            .c_smart-form-content-section {
                width: 100%;
                min-height: var(--smart-form-line-height);
                border-bottom: 1px solid var(--color-border-light);
                border-left: 4px solid var(--color-warning);
                padding: var(--container-spacing);

                &.m--complete {
                    border-left-color: var(--color-success);
                }

                &.m--disabled {
                    border-left-color: transparent;

                    > * {
                        color: var(--color-text-disabled);
                        pointer-events: none;
                    }

                    .c_smart-form-content-section-pages {
                        .c_smart-form-content-section-pages-button {
                            background-color: var(--color-button-disabled);
                        }
                    }

                }

                &.m--selected:not(.m--multiple) {
                    font-family: "Source Sans Pro Bold", sans-serif;
                    .c_smart-form-content-section-title {
                        &:hover {
                            cursor: default;
                            color: var(--color-text-mid);
                        }
                    }
                }

                .c_smart-form-content-section-title {
                    display: inline-block;

                    &:hover {
                        cursor: pointer;
                        color: var(--color-text-highlighted);
                    }
                }

                .c_smart-form-content-section-button {
                    position: absolute;
                    right: 8px;
                    margin-top: -10px;
                }

                .c_smart-form-content-section-pages {
                    margin-top: 12px;
                    width: 100%;

                    .c_smart-form-content-section-page {
                        position: relative;
                        width: 100%;
                        min-height: var(--smart-form-line-height);
                        padding: var(--container-spacing);
                        border-top: 1px solid var(--color-border-light);
                        cursor: default;

                        &.m--selected {
                            font-family: "Source Sans Pro Bold", sans-serif;
                        }

                        &:hover:not(.m--selected) {
                            cursor: pointer;
                            color: var(--color-text-highlighted);
                        }

                        .c_smart-form-content-section-page-title {
                            &:hover {
                                cursor: pointer;
                                color: var(--color-text-highlighted);

                                &:after {
                                    font-weight: 500;
                                    content: "\f044";
                                    color: var(--color-info);
                                    margin-left: 2px;
                                    font-family: "Font Awesome 5 Free", serif;
                                }
                            }
                        }

                        .c_smart-form-content-section-page-delete-button {
                            position: absolute;
                            top: 6px;
                            right: -8px;
                        }

                    }

                    .c_smart-form-content-section-pages-button {
                        width: 100%;
                        height: 16px;
                        font-size: 12px;
                        background-color: var(--color-button-with-border);
                        color: var(--color-text-bright);
                        text-align: center;

                        &:hover {
                            cursor: pointer;
                            background-color: var(--color-button-with-background);
                        }

                        span {
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
    }

    .c_smart-form-container {
        position: relative;
        float: left;
        height: 100%;
        width: calc(100% - var(--smart-form-table-of-content-width));

        &.m--full-width {
            width: 100%;
        }

        .generals-input-container .generals-input {
            border-top: 0;
            border-left: 0;
            border-right: 0;
        }

        .c_smart-form-headline {
            position: relative;
            height: var(--smart-form-line-height);
            width: 100%;
            border-bottom: 1px solid var(--color-border-light);
            text-align: center;
            padding-top: 6px;

            .c_smart-form-header-toggle {
                position: absolute;
                height: 100%;
                width: var(--smart-form-line-height);
                border-right: 1px solid var(--color-border-light);
                padding: 7px 1px 0 0;
                top: 0;
                left: 0;

                &:hover {
                    color: var(--color-text-highlighted);
                }
            }
        }

        .c_smart-form {
            position: relative;
            height: calc(100% - var(--smart-form-line-height));
            width: 100%;
            padding: var(--container-spacing);
            padding-top: 6px;

            .c_smart-form-body {
                position: relative;
                height: calc(100% - var(--headline-height) - var(--button-height));
                width: 100%;
                overflow-y: auto;
                overflow-x: hidden;

                .c_smart-form-fields {
                    position: absolute;
                    left: 50%;
                    width: 666px;
                    -ms-transform: translate(-50%, 0);
                    transform: translate(-50%, 0);
                    padding-bottom: var(--container-spacing);

                    .c_smart-form-field {
                        position: relative;
                        margin-top: 20px;

                        &.m--no-margin {
                            margin-top: 0;
                        }

                        .c_smart-form-field-uploaded-files {
                            float: right;
                            margin-bottom: var(--container-spacing);
                            z-index: var(--z-index-entry-list);
                        }

                        .c_smart-form-field-changes-saved {
                            position: absolute;
                            top: 10px;
                            right: -35px;
                            background-color: var(--color-background-default);
                            z-index: var(--z-index-page-overlay);
                        }

                        .c_smart-form-field-button-pair {
                            width: 100%;
                            text-align: end;
                        }

                        .c_smart-form-field-subheading {
                            border-top: 1px solid var(--color-border-light);
                            margin: 50px 0 20px 0;

                            .c_smart-form-field-subheading-label {
                                text-align: center;
                                margin-top: -12px;

                                span {
                                    background-color: var(--color-background-default);
                                    text-transform: uppercase;
                                    padding: 0 10px;
                                }
                            }
                        }

                        .c_smart-form-field-paragraph {
                            position: relative;

                            :is(h1, h2, h3, h4, h5, h6) {
                                font-family: "Source Sans Pro Bold", sans-serif;
                                margin: -4px 0 8px 0;
                            }

                            &.m--info,
                            &.m--success,
                            &.m--warning,
                            &.m--error {
                                border: 1px solid;
                                border-left: 25px solid;
                                padding: var(--container-spacing);
                            }

                            &.m--info {
                                border-color: var(--color-info);

                                :is(h1, h2, h3, h4, h5, h6) {
                                    color: var(--color-info);
                                }
                            }

                            &.m--success {
                                border-color: var(--color-success);

                                :is(h1, h2, h3, h4, h5, h6) {
                                    color: var(--color-success);
                                }
                            }

                            &.m--warning {
                                border-color: var(--color-warning);

                                :is(h1, h2, h3, h4, h5, h6) {
                                    color: var(--color-warning);
                                }
                            }

                            &.m--error {
                                border-color: var(--color-error);

                                :is(h1, h2, h3, h4, h5, h6) {
                                    color: var(--color-error);
                                }
                            }

                            .c_smart-form-field-paragraph-icon {
                                position: absolute;
                                font-size: 17px;
                                left: -21px;
                                top: 50%;
                                color: var(--color-text-bright);
                                transform: translateY(-50%);
                                z-index: 1;
                            }
                        }

                        .generals-input-wrapper {

                            &:not(.m--dropzone) {

                                .generals-input-label {
                                    &:not(.c_drop-input-label) {
                                        width: var(--smart-form-input-label-width);
                                    }
                                }

                                .generals-input-container {
                                    width: var(--smart-form-input-width);
                                }

                                .c_smart-select-options-container {
                                    min-width: var(--smart-form-input-width);
                                }
                            }
                        }

                        .c_checklist-options-container {
                            width: var(--smart-form-input-width);
                        }

                        &.m--missing {
                            .generals-input-label {
                                color: var(--color-error);
                            }
                        }
                    }

                    .c_smart-form-field-help-container {
                        position: absolute;
                        right: calc(-1 * var(--container-spacing));
                        top: 10px;
                    }
                }
            }

            .c_smart-form-footer {
                position: relative;
                width: 100%;
                height: var(--button-height);

                .c_smart-form-footer-info {
                    position: absolute;
                    bottom: 0;
                }

                button,
                .c_checkbox-wrapper {
                    float: right;
                }

                .c_checkbox-label,
                .c_checkbox-container {
                    width: auto;
                }

                .c_smart-form-body-button-margin {
                    margin-left: var(--container-spacing);
                }
            }
        }
    }
}
</style>
