<script>
/**
 * Generates a light bulb which can be clicked to show up the given help text.
 * Will close on click or automatically, if you leave the light bulb or help text container.
 * - text: The actual text that will appear. Can be html, too.
 * - widthPx: Width of the hint container in px.
 * - position: The position of the help text container. Can be 'left', 'center' or 'right'.
 **/
export default {
    name: 'HelpText',
    props: {
        text: String,
        widthPx: Number,
        position: {
            default: 'center',
            type: String
        }
    },
    data () {
        return {
            helpTextVisible: false,
            mouseOverHelpText: false
        }
    },
    methods: {
        lightBulbClick () {
            this.helpTextVisible = !this.helpTextVisible
        },

        lightBulbMouseLeave () {
            this.delayedHelpTextFadeOut()
        },

        helpTextMouseEnter () {
            this.mouseOverHelpText = true
        },

        helpTextClick () {
            this.helpTextVisible = false
            this.mouseOverHelpText = false
        },

        helpTextMouseLeave () {
            this.mouseOverHelpText = false
            this.delayedHelpTextFadeOut()
        },

        delayedHelpTextFadeOut () {
            setTimeout(() => {
                this.helpTextVisible = this.mouseOverHelpText
            }, 750)
        }
    }
}
</script>

<template>
    <div class="c_help-text-wrapper">
        <div class="c_help-text-component-container">
            <div class="c_help-text-icon"
                 v-on:click.stop="lightBulbClick()"
                 v-on:mouseleave.stop="lightBulbMouseLeave()">
            </div>
            <div v-if="helpTextVisible"
                 class="c_help-text-container"
                 v-bind:style="{
                     width: `${widthPx}px`,
                     'max-width': `${widthPx || 500}px`
                 }">
                <div class="c_help-text-arrow"></div>
                <div class="c_help-text"
                     v-bind:class="`m--${position}`"
                     v-on:mouseenter.stop="helpTextMouseEnter()"
                     v-on:click.stop="helpTextClick()"
                     v-on:mouseleave.stop="helpTextMouseLeave()"
                     v-dompurify-html="text">
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_help-text-wrapper {
    // Wrapper should be positioned from outside the component.

    .c_help-text-component-container {
        position: absolute;

        .c_help-text-icon {
            position: relative;
            font-weight: 500;
            cursor: pointer;
            width: 20px;

            &:hover {
                font-weight: 900;
            }

            &:after {
                content: "\f0eb";
                color: var(--color-warning);
                font-family: "Font Awesome 5 Free", serif;
                font-size: 21px;
            }
        }

        .c_help-text-container {
            position: relative;
            user-select: none;
            width: max-content;

            .c_help-text-arrow {
                position: absolute;
                border-color: transparent transparent var(--color-warning);
                border-style: solid;
                margin-top: -7px;
                z-index: var(--z-index-input-hint-arrow);
                border-width: 0 7px 7px;
            }

            .c_help-text {
                position: relative;
                min-height: var(--input-height);
                max-height: var(--hint-max-height);
                padding: 8px 12px;
                margin-top: 12px;
                border: 1px solid var(--color-warning);
                cursor: pointer;
                background-color: var(--color-background-default);
                overflow-y: auto;
                z-index: var(--z-index-input-hint);

                &.m--left {
                    right: calc(100% - 24px);
                }

                &.m--center {
                    right: calc(50%);
                }

                &.m--right {
                    left: -12px;
                }

                li ul li {
                    list-style-type: "– ";
                }
            }
        }
    }
}
</style>
