/**
 * A service used by the component EssentialSmartForm, in order to outsource calculating logic.
 **/
import _ from 'lodash'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default class EssentialSmartFormService {
    constructor (form, systemLanguage) {
        this.form = form
        this.systemLanguage = systemLanguage
    }

    /**
     * Returns the disabled state of the trash icon to delete additional pages. Based on the amount of pages in the section.
     * @param section The section object to check, if the trash icon should be disabled.
     * @returns {boolean}
     */
    isDeletePageDisabled (section) {
        return section.pages.length <= 1
    }

    /**
     * Returns, whether the provided indexes are equal.
     * @param sectionIndex Index of the section to check equality.
     * @param selectedSectionIndex Index of the selected section.
     * @returns {boolean}
     */
    isSectionSelected (sectionIndex, selectedSectionIndex) {
        return sectionIndex === selectedSectionIndex
    }

    /**
     * Returns, whether the page with the provided index is currently selected.
     * @param pageIndex Index of the page to check, if it is selected.
     * @param sectionIndex Index of the section the page belongs to.
     * @param selectedPageIndex Index of the currently selected page.
     * @param selectedSectionIndex Index of the currently selected section.
     * @returns {boolean}
     */
    isPageSelected (pageIndex, sectionIndex, selectedPageIndex, selectedSectionIndex) {
        return pageIndex === selectedPageIndex && this.isSectionSelected(sectionIndex, selectedSectionIndex)
    }

    /**
     * Returns true iff the page of the section with the given index is logically incomplete or not valid.
     * Also respects 'Checkboxes' with 'required' attribute.
     *
     * To be used for display purposes only.
     *
     * Will most likely be removed with JAMP-2402.
     *
     * @param pageIndex Index of the page to check, if it is incomplete
     * @param sectionIndex Index of the section the page belongs to.
     * @returns {boolean}
     */
    isPageIncompleteInclusiveCheckboxes (pageIndex, sectionIndex) {
        const section = this.form.sections[sectionIndex]
        const page = section.pages[pageIndex]
        return section.fields.some(field => this.isRequiredButFalsyIncludingCheckboxes(page, field, section.id, pageIndex))
    }

    /**
     * Returns, whether the section with the given index is logically incomplete or not valid.
     * Does   n o t   respect 'Checkboxes' with 'required' attribute.
     *
     * Used for the actual evaluation whether a form is submittable or not.
     *
     * @param sectionIndex Index of the section to check, if it is incomplete.
     * @returns {boolean}
     */
    isSectionIncomplete (sectionIndex) {
        const section = this.form.sections[sectionIndex]
        return section.fields.some(field => {
            return section.pages.some((page, pageIndex) => this.isRequiredButFalsy(page, field, section.id, pageIndex))
        })
    }

    /**
     * Returns, whether the section with the given index is logically incomplete or not valid.
     * Also respects 'Checkboxes' with 'required' attribute.
     *
     * To be used for display purposes only.
     *
     * Will most likely be removed with JAMP-2402.
     *
     * @param sectionIndex Index of the section to check, if it is incomplete.
     * @returns {boolean}
     */
    isSectionIncompleteInclCheckboxes (sectionIndex) {
        const section = this.form.sections[sectionIndex]
        return section.fields.some(field => {
            return section.pages.some((page, pageIndex) => this.isRequiredButFalsyIncludingCheckboxes(page, field, section.id, pageIndex))
        })
    }

    /**
     * Returns true iff the field is required, but has a missing or falsy value.
     * Does   n o t   respect 'Checkboxes' with 'required' attribute.
     *
     * Used for the actual evaluation whether a form is submittable or not.
     *
     * @param page The page, the field belongs to.
     * @param field The field to check its validity.
     * @param sectionId (optional) The id of the section the field belongs to.
     * @param pageIndex (optional) The index of the page the field belongs to.
     * @returns {boolean}
     */
    isRequiredButFalsy (page, field, sectionId, pageIndex) {
        return isFalsyValue(field, page[field.id]) && this.isFieldRequired(field, sectionId, pageIndex)
    }

    /**
     * Returns true iff the field is required, but has a missing or falsy value.
     * Respects 'Checkboxes' with 'required' attribute.
     *
     * To be used for display purposes only.
     *
     * Will most likely be removed with JAMP-2402.
     *
     * @param page The page, the field belongs to.
     * @param field The field to check its validity.
     * @param sectionId (optional) The id of the section the field belongs to.
     * @param pageIndex (optional) The index of the page the field belongs to.
     * @returns {boolean}
     */
    isRequiredButFalsyIncludingCheckboxes (page, field, sectionId, pageIndex) {
        if (field.type === 'Checkbox') {
            const isFalsy = page[field.id] !== true
            const isRequired = !!field.required &&
                !this.isFieldDisabled(field, sectionId, pageIndex) &&
                this.isFieldVisible(field, sectionId, pageIndex)
            return isRequired && isFalsy
        }
        return this.isRequiredButFalsy(page, field, sectionId, pageIndex)
    }

    /**
     * Returns true iff each of the fields for all given field IDs is either invisible or checked 'true'.
     *
     * NB: Considers only the first field that is found!
     *
     * @param fieldIds The field IDs of the fields that are to be checked
     * @returns {boolean}
     */
    areAllCheckboxesSetTrue (fieldIds) {
        if (!fieldIds?.length) {
            // no checkboxes => all mandatory ones are checked (i.e., it's trivially true)
            return true
        }

        // for all relevant fields:
        // * check that the field shows up in at least one section and page, ...
        // * ...and that is either set to 'true', or invisible (= does not count as a form completion requirement)
        return fieldIds
            .every(fieldId => this.form.sections
                .some(section => section.pages
                    .some((page, pageIndex) => {
                        const field = this.findFirstFieldById(fieldId)
                        const fieldIsInvisible = !this.isFieldVisible(field, section.id, pageIndex)
                        const fieldValue = page ? page[fieldId] : null
                        return fieldIsInvisible || fieldValue === true
                    })))
    }

    /**
     * Returns the field definition, given the field's ID. Assumption: The field exists only once in one section.
     * @param fieldId The field's ID.
     * @returns {object}
     */
    findFirstFieldById (fieldId) {
        return this.form.sections.flatMap(section => section.fields).find(field => field.id === fieldId)
    }

    /**
     * Returns, whether the field is required to proceed the form.
     * @param field The field to check, if it is required.
     * @param sectionId The id of the section the field belongs to.
     * @param pageIndex The index of the page the field belongs to.
     * @returns {boolean}
     */
    isFieldRequired (field, sectionId, pageIndex) {
        if (field.type === 'Checkbox') {
            return false
        }
        return !!field.required &&
            !this.isFieldDisabled(field, sectionId, pageIndex) &&
            this.isFieldVisible(field, sectionId, pageIndex)
    }

    /**
     * Returns, whether the field is visible or not.
     * @param field The field to check, if it is visible.
     * @param sectionId The id of the section the field belongs to.
     * @param pageIndex The index of the page the field belongs to.
     * @returns {boolean}
     */
    isFieldVisible (field, sectionId, pageIndex) {
        if (field.invisible) {
            return false
        }
        return field.visibleIf
            ? this.evaluateExpression(field.visibleIf, sectionId, pageIndex)
            : true
    }

    /**
     * Returns, whether the field is disabled or not.
     * @param field The field to check, if it is disabled.
     * @param sectionId The id of the section the field belongs to.
     * @param pageIndex The index of the page the field belongs to.
     * @returns {boolean}
     */
    isFieldDisabled (field, sectionId, pageIndex) {
        if (field.disabled) {
            return true
        }
        return field.enableIf
            ? !this.evaluateExpression(field.enableIf, sectionId, pageIndex)
            : false
    }

    /**
     * Returns, whether the field is currently saving or not. Necessary to show a save indicator (green checkmark).
     * @param field The field to check, if it is saving.
     * @param currentlySavingFields The fields, that are currently saving.
     * @returns {boolean}
     */
    isFieldSaving (field, currentlySavingFields) {
        return !!currentlySavingFields[field.id] &&
            currentlySavingFields[field.id].showSaveIndicator === true
    }

    /**
     * Returns, whether the given form has sections with missing or falsy values.
     * @returns {boolean}
     */
    hasIncompleteSections () {
        return this.form.sections.some((section, index) => this.isSectionIncomplete(index))
    }

    /**
     * Returns, whether the given field has a dependency to a different field regarding its visibility or enablement.
     * @param field The field to check, if it has a dependency.
     * @param dependentFieldId The id of the field, the dependency is based on.
     * @returns {boolean}
     */
    fieldHasVisibleOrEnableDependency (field, dependentFieldId) {
        return field.id !== dependentFieldId &&
            ((field.visibleIf && field.visibleIf.includes(dependentFieldId)) ||
                (field.enableIf && field.enableIf.includes(dependentFieldId)))
    }

    /**
     * Indicates whether a field fulfills one of the special conditions so that it does not have to be reset.
     * Fields may be reset, for example, if changes in another field make them invisible.
     * @param field The field to check, if it needs to be reset.
     * @returns {boolean}
     */
    fieldFulfillsSpecialConditionNotToBeReset (field) {
        /* Currently there are only two conditions for a field not to be reset: It is a field of type 'WebLink' or 'DownloadLink'.
         * Those are always read-only and receive their values from the defaultValues only.
         * Thus, they must not be reset, as they would lose their functionality.
         *
         * Add further conditions if necessary.
         */
        return field.type === 'WebLink' || field.type === 'DownloadLink'
    }

    /**
     * Returns, whether the field has a button pair to create identical child fields based on the fields property 'quantity'.
     * @param field The field to check, if it has the button pair to create child fields.
     * @returns {boolean}
     */
    isChildFieldButtonPairVisible (field) {
        return !!field.quantity &&
            field.quantity > 1 &&
            !field.hasChild
    }

    /**
     * Returns, the amount of children the given field has.
     * @param section The section the field belongs to.
     * @param field The field to check, how many children it has.
     * @returns {number}
     */
    getChildFieldCount (section, field) {
        const comparisonId = field.originalId || field.id
        return section.fields
            .filter(sectionField => sectionField.id === comparisonId || sectionField.originalId === comparisonId)
            .length
    }

    /**
     * Returns a form field, which is a clone of the given field, but with a calculated id. The child field stores the field id of its parent in the property 'originalId'.
     * @param section The section the field belongs to.
     * @param field The field to generate a child field from.
     * @returns {object}
     */
    generateChildField (section, field) {
        const generatedChildField = this.getClone(field)
        generatedChildField.required = false
        generatedChildField.description = null
        generatedChildField.hasChild = null
        generatedChildField.label = ' '
        generatedChildField.originalId = field.originalId || field.id
        generatedChildField.id = `${generatedChildField.originalId}_${this.getChildFieldCount(section, field) + 1}`
        return generatedChildField
    }

    /**
     * Returns the translated property of an object based on the system language. Defaults to german.
     * @param object The object to get the property from.
     * @param property The property to get the translated value from.
     * @returns {string}
     */
    getTranslatedObjectProperty (object, property) {
        return object[`${property}_${this.systemLanguage}`] || object[property]
    }

    /**
     * Returns the title of a section based on the system language. Defaults to german.
     * @param section The section to get the translated title from.
     * @returns {string}
     */
    getSectionTitle (section) {
        return this.getTranslatedObjectProperty(section, 'title')
    }

    /**
     * Returns the title of a page based on the system language. Defaults to german. Only used for sections with allowMultiple set to true.
     * If the page has a title, it is returned.
     * If the section has a pageTitleKey and the page has a property with this key, it is returned.
     * As default the section title is returned with the index of the page as prefix.
     * @param page The page to get the title from.
     * @param section The section the page belongs to.
     * @param pageIndex The index of the page in the section.
     * @returns {string|null}
     */
    getPageTitle (page, section, pageIndex) {
        if (!section.allowMultiple) {
            return null
        }
        if (page.title) {
            return page.title
        }
        if (section.pageTitleKey && page[section.pageTitleKey]) {
            return page[section.pageTitleKey]
        }
        const pageTitle = this.getTranslatedObjectProperty(section, 'pageTitle')
        return `${pageIndex + 1}. ${pageTitle}`
    }

    /**
     * Returns the title of the header based on the system language. Defaults to german.
     * If the section allows multiple pages, the title of the page is returned. Otherwise, the title of the section.
     * @param selectedSection The currently selected section.
     * @param selectedPage The currently selected page.
     * @param pageIndex The index of the selected page.
     * @returns {string}
     */
    getHeaderTitle (selectedSection, selectedPage, pageIndex) {
        return selectedSection.allowMultiple
            ? this.getPageTitle(selectedPage, selectedSection, pageIndex)
            : this.getSectionTitle(selectedSection)
    }

    /**
     * Returns the property which contains the label for the select options based on the system language. Defaults to german.
     * @param options The options to get the label specifier from.
     * @returns {string[]}
     */
    getSelectOptionsLabelSpecifier (options) {
        const noTranslatedLabels = options.some(option => !option[`label_${this.systemLanguage}`])
        return noTranslatedLabels
            ? ['label']
            : [`label_${this.systemLanguage}`]
    }

    /**
     * Returns the options of a checklist field with the translated labels based on the system language. Defaults to german.
     * @param field The field to get the options from.
     * @returns {object[]}
     */
    getChecklistOptions (field) {
        const options = []
        field.options.forEach(option => {
            options.push({
                label: this.getTranslatedObjectProperty(option, 'label'),
                value: option.value
            })
        })
        return options
    }

    /**
     * Returns the label of a field based on the system language. Defaults to german.
     * @param field The field to get the label from.
     * @returns {string}
     */
    getFieldLabel (field) {
        const label = this.getTranslatedObjectProperty(field, 'label')
        return this.isFieldRequired(field, null, null)
            ? `${label} *`
            : label
    }

    /**
     * Returns the value of a field.
     * @param field The field to get the value from.
     * @param selectedPage The currently selected page the field belongs to.
     * @returns {*}
     */
    getFieldValue (field, selectedPage) {
        return selectedPage[field.id]
    }

    /**
     * Returns the value of a field with type Estimate.
     * @param field The field to get the value from.
     * @param selectedPage The currently selected page the field belongs to.
     * @returns {number}
     */
    getEstimateValue (field, selectedPage) {
        const fieldValue = this.getFieldValue(field, selectedPage)
        return fieldValue
            ? fieldValue.value
            : null
    }

    /**
     * Returns, whether value of a field with type Estimate is an estimation or not.
     * @param field The field to check, if the value is an estimation.
     * @param selectedPage The currently selected page the field belongs to.
     * @returns {boolean}
     */
    getEstimateBool (field, selectedPage) {
        const fieldValue = this.getFieldValue(field, selectedPage)
        return fieldValue
            ? !!fieldValue.isEstimate
            : false
    }

    /**
     * Returns the given value as Date object.
     * @param value
     * @returns {Date}
     */
    getValueAsDate (value) {
        return dateTimeHelper.methods.tryToParseToDate(value)
    }

    /**
     * Returns a select option that fits the current value of the given field
     * @param field The field to get the option for.
     * @param selectedPage The currently selected page the field belongs to.
     * @returns {object}
     */
    getOptionByFieldValue (field, selectedPage) {
        const value = this.getFieldValue(field, selectedPage)
        return value === undefined || value === null
            ? null
            : field.options.find(option => option.value === value)
    }

    /**
     * Returns the font awesome icon class based on the subtype of a field with type Paragraph.
     * @param paragraphSubType The subtype of the paragraph field.
     * @returns {string}
     */
    getParagraphIconClass (paragraphSubType) {
        switch (paragraphSubType) {
        case 'info':
            return 'fas fa-info-circle'
        case 'success':
            return 'fas fa-check-circle'
        case 'warning':
            return 'fas fa-exclamation-circle'
        case 'error':
            return 'fas fa-minus-circle'
        default:
            return ''
        }
    }

    /**
     * Returns an object with the calculated properties 'visible' and 'disabled' for the inline form field and its children.
     * Necessary for fields with type Form.
     * @param inlineFormField The inline form field to get the calculated properties for.
     * @returns {object}
     */
    getInlineFormFieldProperties (inlineFormField) {
        const props = {}
        props[inlineFormField.id] = {
            visible: this.isFieldVisible(inlineFormField, null, null),
            disabled: this.isFieldDisabled(inlineFormField, null, null)
        }
        inlineFormField.fields.forEach(input => {
            props[input.id] = {
                visible: this.isFieldVisible(input, null, null),
                disabled: this.isFieldDisabled(input, null, null)
            }
        })
        return props
    }

    /**
     * Returns a clone of the provided item without any reference to it.
     * @param item The item to clone.
     * @returns {object}
     */
    getClone (item) {
        return _.cloneDeep(item)
    }

    /**
     * Returns the visibility or enablement of a field based on the provided expression.
     * There are different operations available:
     ** exists: Check, if the field has a value.
     ** equals: Check, if the field value is equal to the comparison value.
     ** isTrue: Check, if the field value is true.
     *
     * All operations can be negated with '!'.
     * Operations can be chained with '&&' and '||'.
     * The expression will be split into conditions based on '||'.
     * @param rawExpression The expression of a field. Used to calculate the visibility or enablement of the field.
     * @param sectionId The id of the section the field expression belongs to.
     * @param pageIndex The index of the page the field expression belongs to.
     * @returns {boolean}
     */
    evaluateExpression (rawExpression, sectionId, pageIndex) {
        if (rawExpression.match(/!\(/)) {
            throw new Error('Negation of terms in parentheses is not supported, currently. As a quick fix, apply De Morgan\'s laws to work around this.')
        }
        // paranoia: avoid parentheses being confused with operations
        const expression = rawExpression.replace('(', ' ( ').replace(')', ' ) ').trim()
        return evaluateExpressionRecursively(expression, this.form, sectionId, pageIndex)
    }
}

const scope = {
    exists: exists,
    equals: equals,
    isTrue: isTrue
}

/**
 * The algorithm is now recursive because we need to support parentheses as precedence modifiers.
 * <ul>
 *     <li>We go through the string expressionOrSubExpression from front to back, counting opening / closing parentheses</li>
 *     <ul>
 *     <li>We increase a counter for every opening parenthesis, decrease it for a closing one.</li>
 *     <li>If we encounter a "||" or "&&", we escape it if the counter is non-zero.</li>
 *     </ul>
 *     <li>We split the string into sub-expressions like before, with the usual rules (first by "||", then by "&&", so that "&&" takes precedence)</li>
 *     <li>We unescape the previously escaped "||" and "&&" for each of the sub-expressions.</li>
 *     <li>We do a recursive call for the unescaped sub-expressions.</li>
 *     <li>If there is only one sub-expression that has the same length as the input expressionOrSubExpression, we evaluate the expression as a leaf</li>
 * </ul>
 */
function evaluateExpressionRecursively (rawExpressionOrSubExpression, form, sectionId, pageIndex) {
    const expressionOrSubExpression = escapeInnerOperators(rawExpressionOrSubExpression)
    const orConditions = expressionOrSubExpression.split(' || ').map(s => s.trim())
    return orConditions.some(orExpression => {
        const andConditions = orExpression.split(' && ').map(s => s.trim())
        return andConditions.every(rawAndCondition => {
            const andCondition = unescapeOperators(rawAndCondition)
            if (andCondition.length === rawExpressionOrSubExpression.length) {
                // Same length as the input => It seems we didn't split anything, so this must be a leaf!
                return parseAndEvaluateLeafCondition(andCondition, form, sectionId, pageIndex)
            } else {
                return evaluateExpressionRecursively(andCondition, form, sectionId, pageIndex)
            }
        })
    })
}

// must not contain '&&' nor '||' and should not conflict with other possible terms in the expression
const escapedAndOperator = 'escaped(&)'
const escapedOrOperator = 'escaped(|)'

/**
 * @see #evaluateExpressionRecursively
 */
function escapeInnerOperators (rawSubExpression) {
    const subExpression = rawSubExpression.trim()
    const loopState = {
        // basically a "string builder" for the escaped expression (= our result)
        escapedExpression: '',
        // +1 if a parenthesis opens, -1 if one closes while we go iterate over the input expression string
        openParenthesesCounter: 0,

        // if true, we saw an '&' or '|' as last character (and the next '&' or '|' completes an 'AND' or 'OR')
        operatorStarted: {
            '&': false,
            '|': false
        },
        escapedOperator: {
            '&': escapedAndOperator,
            '|': escapedOrOperator
        },

        // watching if the whole thing is just one big term in parentheses (that case needs special handling)
        isOneTermInParentheses: subExpression.startsWith('(') && subExpression.endsWith(')')
    }
    for (let position = 0; position < subExpression.length; position++) {
        processCurrentCharacter(loopState, subExpression[position], position === subExpression.length - 1, position)
    }

    if (loopState.openParenthesesCounter !== 0) {
        // opening and closing parentheses do not match
        throw new Error('invalid expression, mismatching parentheses')
    }

    if (loopState.isOneTermInParentheses) {
        const expressionWithoutSurroundingParentheses = subExpression.substring(1, subExpression.length - 1)
        loopState.escapedExpression = expressionWithoutSurroundingParentheses
    }
    return loopState.escapedExpression
}

function processCurrentCharacter (loopState, currentCharacter, isLastCharacter, position) {
    const shouldBreak =
        updateOperatorRecognitionState(loopState, currentCharacter, '&') ||
        updateOperatorRecognitionState(loopState, currentCharacter, '|')
    if (shouldBreak) {
        return
    }

    updateOpenParenthesesCounter(loopState, currentCharacter, isLastCharacter, position)

    loopState.escapedExpression = loopState.escapedExpression + currentCharacter
}

function updateOperatorRecognitionState (loopState, currentCharacter, operator) {
    if (currentCharacter === operator && loopState.openParenthesesCounter > 0) {
        if (loopState.operatorStarted[operator]) {
            loopState.escapedExpression = loopState.escapedExpression + loopState.escapedOperator[operator]
        } else {
            // remember that we saw one '&' or '|' already - if the next is also one like it, let's write an escaped one
            loopState.operatorStarted[operator] = true
        }
        return true
    }
    // so, it was just a single "&" or "|" then, not the start of a "&&" or "||"
    loopState.operatorStarted[operator] = false
    return false
}

function updateOpenParenthesesCounter (loopState, currentCharacter, isLastCharacter, position) {
    if (currentCharacter === '(') {
        loopState.openParenthesesCounter++
    } else if (currentCharacter === ')') {
        loopState.openParenthesesCounter--
        if (loopState.openParenthesesCounter < 0) {
            // more closing than opening parentheses
            throw new Error(`Invalid expression: Unexpected closing parenthesis at position ${position}`)
        }
        if (loopState.openParenthesesCounter === 0) {
            if (!isLastCharacter) {
                // it's not just one big term in parentheses, this is at least 2 terms connected with an "&&" or "||"
                loopState.isOneTermInParentheses = false
            }
        }
    }
}

function unescapeOperators (subExpression) {
    const unescaped = subExpression.replace(escapedOrOperator, '||').replace(escapedAndOperator, '&&')
    return unescaped
}

/**
 * Parses and evaluates the given low-level condition (= condition without "OR" or "AND") in order to check whether a
 * field is visible or enabled.
 * @param rawCondition The condition string to parse and evaluate.
 * @param formConfiguration The form configuration to get the values from.
 * @param sectionId The id of the section the field condition belongs to.
 * @param pageIndex The index of the page the field condition belongs to.
 * @returns {boolean}
 */
function parseAndEvaluateLeafCondition (rawCondition, formConfiguration, sectionId, pageIndex) {
    const condition = rawCondition.replace('( ', '(').replace(' (', '(').replace(') ', ')').replace(' )', ')').trim()
    if (condition.startsWith('!')) {
        return !parseAndEvaluateLeafCondition(condition.substring(1), formConfiguration, sectionId, pageIndex)
    }
    const leafFunctionName = condition.substring(0, condition.indexOf('('))
    const openingParenthesisIndex = condition.indexOf('(') + 1
    const closingParenthesisIndex = condition.indexOf(')')
    const methodParameters = condition.substring(openingParenthesisIndex, closingParenthesisIndex).split(', ')
    if (scope[leafFunctionName]) {
        const fieldId = methodParameters[0]
        const comparisonValue = methodParameters[1]
        const existingFieldValues = getFormValues(fieldId, formConfiguration, sectionId, pageIndex)
        if (closingParenthesisIndex < condition.length - 1) {
            throw new Error(`Condition continues beyond recognized leaf function. Recognized call: ${leafFunctionName}(${fieldId}, ${comparisonValue}). Whole condition: ${condition}`)
        }
        return existingFieldValues.some(fieldValue => scope[leafFunctionName](fieldValue, comparisonValue))
    } else {
        throw new Error(`unknown leaf function: ${leafFunctionName}`)
    }
}

/**
 * Returns all values of a field from all pages in all sections.
 * @param fieldId The id of the field to get the values from.
 * @param formConfiguration The form configuration to get the values from.
 * @param sectionId The id of the section the field belongs to.
 * @param pageIndex The index of the page the field belongs to.
 * @returns {*[]}
 */
function getFormValues (fieldId, formConfiguration, sectionId, pageIndex) {
    const values = []

    // Due to conditions based on values on different pages, it is necessary to check for the fields value on all pages in all sections
    formConfiguration.sections.forEach(section => {
        if (section.allowMultiple && section.id === sectionId) {
            const sectionPage = section.pages[pageIndex]
            const value = sectionPage[fieldId]
            if (value) {
                values.push(value)
            }
        } else {
            section.pages.forEach(page => {
                const value = page[fieldId]
                if (value) {
                    values.push(value)
                }
            })
        }
    })
    return values
}

/**
 * Returns, whether the field value exists or not, based on the value's type.
 * @param fieldValue The value of the field to check, if it exists.
 * @returns {boolean}
 */
function exists (fieldValue) {
    if (fieldValue === null || fieldValue === undefined) {
        return false
    }
    if (Array.isArray(fieldValue)) {
        return fieldValue.length > 0
    }
    if (fieldValue instanceof Object) {
        return fieldValue.value !== null && fieldValue.value !== undefined && fieldValue.value !== ''
    }
    return fieldValue !== ''
}

/**
 * Returns, whether the field value equals the comparison value, based on the value's type.
 * @param fieldValue The value of the field to compare.
 * @param comparisonValue The value to compare the field value to.
 * @returns {boolean}
 */
function equals (fieldValue, comparisonValue) {
    if (Array.isArray(fieldValue)) {
        return fieldValue.includes(comparisonValue)
    }
    if (fieldValue instanceof Object) {
        return fieldValue.value === comparisonValue
    }
    return fieldValue === comparisonValue
}

/**
 * Returns, whether the given field value equals true.
 * @param fieldValue The value of the field to check, if it is true.
 * @returns {boolean}
 */
function isTrue (fieldValue) {
    return fieldValue === true
}

/**
 * Returns, whether the value of the field is falsy. Falsy can have different meanings, depending on the field type.
 ** Value is list: Must not be empty.
 ** Value is object: Must contain property 'value'.
 ** Default: Must not be null, undefined or empty.
 * @param field The field to check, if its value is falsy.
 * @param value The value to check, if it is falsy.
 * @returns {boolean}
 */
function isFalsyValue (field, value) {
    if (Array.isArray(value)) {
        return value.length === 0
    }
    if (field.type === 'ToggleButton') {
        return value !== true
    }
    const fieldValue = value instanceof Object
        ? value.value
        : value
    return fieldValue === undefined || fieldValue === null || fieldValue === ''
}
