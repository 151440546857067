<script>
/**
 * SortableList is simple list with draggable items to change their order. It will throw a @list-submit event, when the submit button in the header was pressed.
 * As default, the dragged element can be placed between two elements which will create a gap to drop the dragged element.
 * Alternatively, when pressing shift while starting to drag an element, the dragged element can be placed on another element, with both of them swapping their positions.
 * - items: List of objects that can be dragged and dropped to change their order. An item must at least contain an id:
 * - titleKey: A property of the items to be used as a title
 * - subtitleKey: A property of the items to be used as a subtitle
 * - submitIcon (default: fas fa-check): The icon class for the submit button.
 * - submitTooltip (default: $tc('sortableList.submitTooltip')): The tooltip for the submit button.
 * - enableRemove (default: false): If true, list elements can be removed while changing their order.
 **/

import IconButton from '@/components/IconButton.vue'
import VerticalLine from '@/components/VerticalLine.vue'

export default {
    name: 'SortableList',
    components: {
        IconButton,
        VerticalLine
    },
    // @list-submit: Event emitted when pressing check button | returns the event and the ordered list
    emits: ['list-submit'],
    props: {
        items: Array,
        titleKey: {
            default: 'title',
            type: String
        },
        subtitleKey: {
            default: 'subtitle',
            type: String
        },
        submitIcon: {
            default: 'fas fa-check',
            type: String
        },
        submitTooltip: String,
        enableRemove: {
            default: false,
            type: Boolean
        }
    },
    data () {
        return {
            listItems: [],
            swapMode: false,
            dragItem: null,
            dragItemIndex: null,
            dragEnterY: null,
            dropItem: null,
            threshold: 20
        }
    },
    methods: {
        setListItems () {
            this.listItems = [...this.items]
        },

        isDraggedItem (item) {
            return item.id === this.dragItem?.id
        },

        isDropItem (item) {
            return item.id === this.dropItem?.id
        },

        addHoverClass (event) {
            event.target.classList.add('hover')
        },

        removeHoverClass (event) {
            event.target.classList.remove('hover')
        },

        startDrag (event, item, index) {
            event.dataTransfer.effectAllowed = 'move'
            this.dragItem = item
            this.dragItemIndex = index
            this.swapMode = event.shiftKey
        },

        dragEnterElement (event, item) {
            this.swapMode
                ? this.dropItem = item
                : this.dragEnterY = event.y
        },

        dragOverElement (event, dragOverIndex) {
            if (!this.swapMode) {
                const dragOverY = event.y
                if (Math.abs(dragOverY - this.dragEnterY) >= this.threshold) {
                    this.listItems.splice(this.dragItemIndex, 1)
                    this.listItems.splice(dragOverIndex, 0, this.dragItem)
                    this.dragItemIndex = dragOverIndex
                }
            }
        },

        dragLeaveElement () {
            if (this.swapMode) {
                this.dropItem = null
            }
        },

        dropped (dropIndex) {
            if (this.swapMode) {
                this.listItems.splice(dropIndex, 1, this.dragItem)
                this.listItems.splice(this.dragItemIndex, 1, this.dropItem)
            }
        },

        endDrag () {
            this.dragItem = null
            this.dragItemIndex = null
            this.dropItem = null
            this.swapMode = false
        },

        removeListItem (index) {
            this.listItems.splice(index, 1)
        },

        revertChanges () {
            this.setListItems()
        },

        emitSubmit () {
            this.$emit('list-submit', {
                list: this.listItems
            })
        }
    },
    watch: {
        items () {
            this.setListItems()
        }
    },
    mounted () {
        this.setListItems()
    }
}
</script>

<template>
    <div class="c_sortable-list">
        <div class="c_sortable-list-header">
            <div class="c_sortable-list-header-title">
                <span>{{$tc('sortableList.headline')}}</span>
            </div>
            <IconButton class="c_sortable-list-header-button"
                        icon-class="fas fa-sync-alt"
                        v-bind:tooltip="$tc('sortableList.resetList')"
                        @button-submit="revertChanges()">
            </IconButton>
            <VerticalLine></VerticalLine>
            <IconButton class="c_sortable-list-header-button"
                        v-bind:icon-class="submitIcon"
                        v-bind:tooltip="submitTooltip || $tc('sortableList.submitTooltip')"
                        @button-submit="emitSubmit()">
            </IconButton>
        </div>
        <div class="c_sortable-list-items">
            <div v-for="(listItem, index) in listItems"
                 v-bind:key="`drag-element_${listItem.id}`"
                 class="c_sortable-list-item-wrapper">
                <div class="c_sortable-list-item"
                     v-bind:class="{
                         'm--inactive': dragItem && !isDraggedItem(listItem),
                         'm--dragging': isDraggedItem(listItem),
                         'm--target': isDropItem(listItem)
                     }"
                     v-bind:draggable="true"
                     v-on:mouseenter="addHoverClass($event)"
                     v-on:mouseleave="removeHoverClass($event)"
                     v-on:dragenter.prevent="dragEnterElement($event, listItem)"
                     v-on:dragover.prevent="dragOverElement($event, index)"
                     v-on:dragleave.prevent="dragLeaveElement()"
                     v-on:dragstart="startDrag($event, listItem, index)"
                     v-on:drop.prevent="dropped(index)"
                     v-on:dragend.prevent="endDrag()">
                    <div class="c_sortable-list-item-title-wrapper">
                        <span class="c_sortable-list-item-title">{{listItem[titleKey]}}</span>
                        <span v-if="listItem[subtitleKey]" class="c_sortable-list-item-subtitle">{{listItem[subtitleKey]}}</span>
                    </div>
                    <IconButton v-if="enableRemove"
                                icon-class="fas fa-trash"
                                v-bind:font-size="13"
                                v-bind:tooltip="$tc('sortableList.removeEntry')"
                                class="c_sortable-list-item-remove"
                                @button-submit="removeListItem(index)">
                    </IconButton>
                </div>
                <div v-if="isDraggedItem(listItem)"
                     class="c_sortable-list-item-hollow">
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="less">
.c_sortable-list {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .c_sortable-list-header {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0 11px 0 8px;
        height: var(--sortable-list-header-height);

        .c_sortable-list-header-title {
            font-family: "Source Sans Pro Bold", sans-serif;
            flex-grow: 1;
        }
    }

    .c_sortable-list-items {
        position: relative;
        width: 100%;
        height: calc(100% - var(--sortable-list-header-height));
        padding: 5px;
        overflow-y: auto;

        .c_sortable-list-item-wrapper {
            position: relative;
            width: 100%;
            height: var(--sortable-list-item-height);

            &:not(:last-of-type) {
                margin-bottom: 10px;
            }

            .c_sortable-list-item {
                position: absolute;
                width: 100%;
                height: 100%;
                padding: 0 16px;
                display: flex;
                align-items: center;
                cursor: grab;
                border: 1px solid var(--color-border-light);
                background-color: var(--color-background-default);
                transition: opacity 0.7s ease, box-shadow 0.7s ease, margin 0.7s ease;

                &:not(.m--inactive).hover {
                    margin-top: -3px;
                    box-shadow: 0 3px 6px 0 var(--color-border-dark);
                    background-color: var(--color-background-hover-feedback);
                }

                &.m--dragging {
                    opacity: 0;
                }

                &.m--target {
                    background-color: rgba(0, 148, 170, 0.3);
                }

                .c_sortable-list-item-title-wrapper {
                    flex-grow: 1;
                    display: flex;
                    flex-direction: column;
                    pointer-events: none;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    .c_sortable-list-item-title {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-family: "Source Sans Pro Bold", sans-serif;
                    }

                    .c_sortable-list-item-subtitle {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 14px;
                        margin-top: 2px;
                    }
                }

                .c_sortable-list-item-remove {
                }
            }

            .c_sortable-list-item-hollow {
                width: 100%;
                height: 100%;
                border: 4px dashed var(--color-border-active);
                opacity: 0.3;
                pointer-events: none;
            }
        }
    }
}
</style>
