/**
 * A reusable service for the exchange of product resp. product content related links with the backend.
 **/
import { axiosService } from '@/mixins/axiosService'
import { notificationHandler } from '@/mixins/notificationHandler'

export const productLinkHandler = {
    mixins: [
        axiosService,
        notificationHandler
    ],
    data () {
        return {
            basePathLink: 'product/link'
        }
    },
    methods: {
        getProductLinks (productId, contentId, fieldName, round) {
            const roundParam = (typeof round === 'number') ? `&round=${round}` : ''
            const urlParameterString = contentId
                ? `productId=${productId}&productContentId=${contentId}&formField=${fieldName}${roundParam}`
                : `productId=${productId}&formField=${fieldName}${roundParam}`
            return this.axiosGet(
                `${this.basePathLink}?${urlParameterString}`,
                this.$t('task.taskViews.links.fetchLinksError'))
        },
        /**
         * @param linkData - contains the link information incl. product ID, product content ID,...
         */
        saveProductLink (linkData) {
            const url = linkData.id ? `${this.basePathLink}/${linkData.id}` : this.basePathLink
            const createOrUpdate = linkData.id ? 'axiosPut' : 'axiosPost'
            return this[createOrUpdate](
                url,
                linkData,
                this.$tc('task.taskViews.links.uploadLinkError'),
                {
                    standard: this.$tc('task.taskViews.links.uploadLinkSuccess.standard'),
                    short: this.$tc('task.taskViews.links.uploadLinkSuccess.short')
                })
        },
        deleteProductLink (linkId) {
            return this.axiosDelete(
                `${this.basePathLink}/${linkId}`,
                null,
                this.$t('task.taskViews.links.removeLinkError'),
                {
                    standard: this.$t('task.taskViews.links.removeLinkSuccess.standard'),
                    short: this.$tc('task.taskViews.links.removeLinkSuccess.short')
                })
        }
    }
}
