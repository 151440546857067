<script>
/**
 * TimeCodeInput is a pre styled input time element.
 * It will throw an @input-submit event on pressing enter or clicking the submit icon, an @input-blurred event when losing focus and an @input-close event on pressing esc.
 * - id: Should be provided in order to get full functionality. Without an id you won't get the ability to close on esc and instant focus the input field when opening.
 * - label (optional): Text displayed as the label of the input field.
 * - tinyLabel (optional): Small label displayed along the top border of the input. If set, there will be a border around the whole input field.
 * - defaultValue (optional): The default value of the input.
 * - resetOnSubmit (default: false): If true, the value will be reset to default or null after submit.
 * - isDisabled (default: false): If true, the user can't change the input value.
 * - isAutoFocused (default: false): If true, the input field will get focused automatically when it is rendered.
 * - showUnsavedChanges (default: false): If true, the input will highlight if its value differs from the last submitted one.
 * - useClockTime (default: true): If true, it is only possible to select an hour value between 0 and 23. Otherwise, it will be 0 and 99.
 * - showErrorMessage (default: true): If true, the error message for invalid time formats will be displayed.
 **/

export default {
    name: 'TimeCodeInput',
    // @input-submit: Event emitted on submit | returns the event and current input value
    // @input-blurred: Event emitted on blur | returns current input value
    // @input-close: Event emitted on pressing esc key | no return
    // Note: We do not declare the emits here, because we want to have the registrations as part of $attrs
    //       emits: ['input-submit', 'input-blurred', 'input-close'],
    props: {
        id: String,
        label: String,
        tinyLabel: String,
        defaultValue: String,
        resetOnSubmit: {
            default: false,
            type: Boolean
        },
        isDisabled: {
            default: false,
            type: Boolean
        },
        isAutoFocused: {
            default: false,
            type: Boolean
        },
        showUnsavedChanges: {
            default: false,
            type: Boolean
        },
        useClockTime: {
            default: true,
            type: Boolean
        },
        showErrorMessage: {
            default: true,
            type: Boolean
        }
    },
    data () {
        return {
            inputValue: this.defaultValue,
            lastSubmittedValue: this.defaultValue,
            hourValues: [],
            minuteAndSecondValues: [],
            selectedHour: null,
            selectedMinute: null,
            selectedSecond: null,
            activeElementId: null, // used to determine whether an element is in focus, which enables/shows the time picker rollers (Walzen)
            inputValid: true
        }
    },
    methods: {
        focusInputElement () {
            document.getElementById(`c_time-code-input_start_${this.id}`).focus()
        },

        keyPressedHandler (event) {
            if (event.key === 'Enter') {
                this.emitSubmit(event)
            } else if (event.key === 'Escape') {
                this.$emit('input-close')
            }
        },

        setActiveElement (event) {
            this.activeElementId = event.target.id
        },

        resetActiveElement (event) {
            setTimeout(() => {
                if (this.activeElementId === event.target.id) {
                    this.activeElementId = null
                    if (this.hasUnsavedChanges) {
                        this.emitSubmit()
                    }
                }
            }, 100)
        },

        toggleTimePicker () {
            this.activeElementId = this.activeElementId ? null : 'c_time-code-input-submit'
        },

        normalizeAndSetTimeValues (setValidationProperty) {
            // Check for a correct time format consisting of hours:minutes:seconds. Reformat value to fit hh:mm:ss.
            const regex = /^[0-9]?[0-9](:[0-5]?[0-9]){2}$/
            const result = regex.test(this.inputValue)
            if (result) {
                const timeValues = this.inputValue.split(':')
                const hoursValue = timeValues[0].length === 1 ? `0${timeValues[0]}` : timeValues[0]
                const minutesValue = timeValues[1].length === 1 ? `0${timeValues[1]}` : timeValues[1]
                const secondsValue = timeValues[2].length === 1 ? `0${timeValues[2]}` : timeValues[2]
                this.setTime(hoursValue, minutesValue, secondsValue)
            }
            if (setValidationProperty) {
                this.inputValid = result
            }
        },

        setTime (hours, minutes, seconds) {
            if (hours) this.selectedHour = hours
            if (minutes) this.selectedMinute = minutes
            if (seconds) this.selectedSecond = seconds
            this.inputValue = `${this.selectedHour || '00'}:${this.selectedMinute || '00'}:${this.selectedSecond || '00'}`
        },

        emitSubmit (event) {
            this.normalizeAndSetTimeValues(true)

            // We actually need to check for listeners as the lastSubmittedValue must not be
            // updated if there is no listener which performs the actual submit
            //
            // see https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/593#note_316549
            //     for further information
            if (this.$attrs.onInputSubmit) {
                this.emit(event, 'input-submit')
                this.updateLastSubmittedValueAndReset(true)
            }
        },

        emitBlur (event) {
            this.resetActiveElement(event)
            this.normalizeAndSetTimeValues(true)

            // We actually need to check for listeners as the lastSubmittedValue must not be
            // updated if there is no listener which performs the actual submit
            //
            // see https://git.cornelsen.de/pub-ops/corflow/frontend/-/merge_requests/593#note_316549
            //     for further information
            if (this.$attrs.onInputBlurred) {
                this.emit(null, 'input-blurred')
                this.updateLastSubmittedValueAndReset(false)
            }
        },

        emit (event, name) {
            this.normalizeAndSetTimeValues(true)
            if (this.hasUnsavedChanges && this.inputValid) {
                this.$emit(name, {
                    event: event,
                    value: this.inputValue
                })
            }
        },

        updateLastSubmittedValueAndReset (resetInput) {
            if (resetInput && this.resetOnSubmit) {
                this.inputValue = this.defaultValue
            }
            this.lastSubmittedValue = this.inputValue
            this.activeElementId = null
        }
    },
    computed: {
        isTimePickerVisible () {
            return this.activeElementId !== null
        },

        hasUnsavedChanges () {
            return this.inputValue !== this.lastSubmittedValue
        }
    },
    watch: {
        defaultValue () {
            this.inputValue = this.defaultValue
            this.normalizeAndSetTimeValues()
            this.lastSubmittedValue = this.inputValue
        }
    },
    mounted () {
        if (this.isAutoFocused) {
            this.focusInputElement()
        }
        /*
        Initialize the range of values used for the time picker rollers.
        Either 0 to 23 or 0 to 99 for hours, depending on whether clock time is enabled or not.
        0 to 59 for both, minutes and seconds.
        */
        this.hourValues = Array
            .apply(null, { length: this.useClockTime ? 24 : 100 })
            .map(function (value, index) {
                return index < 10 ? `0${index}` : index.toString()
            })
        this.minuteAndSecondValues = Array
            .apply(null, { length: 60 })
            .map(function (value, index) {
                return index < 10 ? `0${index}` : index.toString()
            })
        this.normalizeAndSetTimeValues()
        this.lastSubmittedValue = this.inputValue
    }
}
</script>

<template>
    <div class="c_time-code-input-wrapper generals-input-wrapper">
        <label v-if="label"
               class="c_time-code-input-label generals-input-label">
            <span>{{label}}</span>
        </label>
        <div class="c_time-code-input-container generals-input-container">
            <div v-if="tinyLabel"
                 class="c_time-code-input-tiny-label generals-input-tiny-label">
                <span>{{tinyLabel}}</span>
            </div>
            <input v-bind:id="id ? `c_time-code-input_start_${id}`: null"
                   type="text"
                   class="c_time-code-input generals-input"
                   v-bind:disabled="isDisabled"
                   v-bind:class="{'m--unsaved': showUnsavedChanges && hasUnsavedChanges}"
                   placeholder="- - : - - : - -"
                   v-on:focus="setActiveElement($event)"
                   v-on:blur="emitBlur($event)"
                   v-on:click.stop
                   v-on:keyup="keyPressedHandler($event)"
                   v-model="inputValue" />
            <button v-if="!isDisabled"
                    v-on:click="toggleTimePicker()"
                    class="c_time-code-input-submit">
                <span class="c_time-code-input-icon far fa-clock"></span>
            </button>
            <div v-if="!inputValid && showErrorMessage"
                 class="c_time-code-input-error-message generals-input-error-message">
                <span>{{$tc('timeCodeInput.noValidTimeCode')}}</span>
            </div>
            <!-- construct the hh:mm:ss time picker rollers -->
            <div class="c_time-code-input-time-picker-rollers generals-animate"
                 v-bind:class="{'m--visible': isTimePickerVisible}">
                <div class="c_time-code-input-time-picker-roller generals-animate">
                    <div v-for="number in hourValues"
                         v-bind:key="number"
                         tabindex="-1"
                         v-bind:id="`c_time-code-input-hour-value-${number}`"
                         class="c_time-code-input-time-picker-roller-item"
                         v-bind:class="{'m--selected': selectedHour === number}"
                         v-on:click.stop="setTime(number)"
                         v-on:focus="setActiveElement($event)"
                         v-on:blur="resetActiveElement($event)">
                        <span>{{number}}</span>
                    </div>
                </div>
                <div class="c_time-code-input-time-picker-roller generals-animate">
                    <div v-for="number in minuteAndSecondValues"
                         v-bind:key="number"
                         tabindex="-1"
                         v-bind:id="`c_time-code-input-minute-value-${number}`"
                         class="c_time-code-input-time-picker-roller-item"
                         v-bind:class="{'m--selected': selectedMinute === number}"
                         v-on:click.stop="setTime(null, number)"
                         v-on:focus="setActiveElement($event)"
                         v-on:blur="resetActiveElement($event)">
                        <span>{{number}}</span>
                    </div>
                </div>
                <div class="c_time-code-input-time-picker-roller generals-animate">
                    <div v-for="number in minuteAndSecondValues"
                         v-bind:key="number"
                         tabindex="-1"
                         v-bind:id="`c_time-code-input-second-value-${number}`"
                         class="c_time-code-input-time-picker-roller-item"
                         v-bind:class="{'m--selected': selectedSecond === number}"
                         v-on:click.stop="setTime(null, null, number)"
                         v-on:focus="setActiveElement($event)"
                         v-on:blur="resetActiveElement($event)">
                        <span>{{number}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_time-code-input-wrapper {

    .c_time-code-input-label {
    }

    .c_time-code-input-container {

        .c_time-code-input-tiny-label {
        }

        .c_time-code-input {
            padding-right: 0;
        }

        .c_time-code-input-submit {

            .c_time-code-input-icon {
            }
        }

        .c_time-code-input-time-picker-rollers {
            display: inline-block;
            height: 0;

            &.m--visible {
                height: 160px;
                border-bottom: 1px solid var(--color-border-dark);

                .c_time-code-input-time-picker-roller {
                    opacity: 1;
                }
            }

            .c_time-code-input-time-picker-roller {
                position: relative;
                width: 50px;
                height: 100%;
                overflow: auto;
                float: left;
                background-color: var(--color-background-default);
                opacity: 0;
                z-index: 1;

                .c_time-code-input-time-picker-roller-item {
                    position: relative;
                    width: 100%;
                    height: 40px;
                    text-align: center;
                    border-radius: 4px;
                    padding-top: 9px;
                    cursor: pointer;

                    &:not(:first-of-type) {
                        border-top: 1px solid var(--color-border-light);
                    }

                    &.m--selected {
                        background-color: var(--color-background-mid);
                        pointer-events: none;
                    }

                    &:hover {
                        background-color: var(--color-background-highlighted);
                        color: var(--color-text-bright);
                    }
                }
            }
        }

        .c_time-code-input-error-message {
            position: absolute;
        }
    }
}
</style>
