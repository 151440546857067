<script>
/**
 * Product completion sub view | Sub view of admin bpmn processes
 *
 * @property {String} cancellationReason
 * @property {String} cancellationTicket
 * @property {String} cancellationInstant
 **/
import Table from '@/components/Table.vue'
import SmartSelect from '@/components/SmartSelect.vue'
import IconButton from '@/components/IconButton.vue'
import Icon from '@/components/Icon.vue'
import RouteLink from '@/components/RouteLink.vue'
import { axiosService } from '@/mixins/axiosService'
import { notificationHandler } from '@/mixins/notificationHandler'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'ProductCompletion',
    mixins: [
        axiosService,
        notificationHandler,
        dateTimeHelper
    ],
    components: {
        Table,
        SmartSelect,
        IconButton,
        Icon,
        RouteLink
    },
    data () {
        return {
            productList: [],
            selectedProductIds: [],
            showSpinner: true,
            isSelectedColumnVisible: true
        }
    },
    methods: {
        async getProductList () {
            const manualCompletedProducts = await this.axiosGet(
                'migration/product',
                this.$tc('administration.controlling.productCompletion.getCompletedProductsError'))
            this.axiosGet(
                'products',
                this.$tc('administration.controlling.productCompletion.getProductListError'))
                .then(productList => {
                    manualCompletedProducts.forEach(manualCompletedProduct => {
                        const product = productList.find(product => product.productNumber === manualCompletedProduct.productNumber)
                        if (product) this.setCompletionInfo(product, manualCompletedProduct)
                    })
                    productList.forEach(product => this.setProductInfo(product))
                    this.productList = productList
                    this.showSpinner = false
                })
                .catch(() => {
                    this.showSpinner = false
                })
        },

        setCompletionInfo (product, completionInfo) {
            product.manualCompleteReason = completionInfo.cancellationReason
            product.manualCompleteTicket = completionInfo.cancellationTicket
            product.manualCompleteTime = completionInfo.cancellationInstant
        },

        setProductInfo (product) {
            const productState = product.manualCompleteReason || product.productState
            const productReleased = !!product.releaseDate
            const plannedOrActual = productReleased
                ? this.$tc('generals.actual')
                : this.$tc('generals.planned')

            product.releaseDatePlannedOrActual = productReleased
                ? product.releaseDate
                : product.plannedReleaseDate

            product.releaseDateDisplayText = product.releaseDatePlannedOrActual
                ? `${this.getFormattedShortDate(product.releaseDatePlannedOrActual, this.locale)} (${plannedOrActual})`
                : null

            product.manualCompleteTimeDisplayText = product.manualCompleteTime
                ? this.getFormattedLongDate(product.manualCompleteTime, this.locale)
                : null

            product.productStateForTable = productState
                ? this.$tc(`generals.productStateValues.${productState}`)
                : this.$tc('generals.infoNotAvailable')
        },

        isCompleted (product) {
            return !!product.manualCompleteTime
        },

        getCompletableProducts () {
            return this.$refs.adminProducts.getTableRows.filter(product => !this.isCompleted(product))
        },

        isProductSelected (product) {
            return this.selectedProductIds.includes(product.id)
        },

        areAllSelected () {
            return this.$refs.adminProducts // check if reference to admin products table is already set
                ? this.getCompletableProducts().every(row => this.selectedProductIds.includes(row.id))
                : false
        },

        toggleSelected (product) {
            if (this.isCompleted(product)) {
                return
            }
            if (this.isProductSelected(product)) {
                const index = this.selectedProductIds.indexOf(product.id)
                this.selectedProductIds.splice(index, 1)
            } else {
                this.selectedProductIds.push(product.id)
            }
        },

        toggleAllSelected () {
            this.areAllSelected()
                ? this.resetSelectedProducts()
                : this.selectedProductIds = this.getCompletableProducts().map(row => row.id)
            this.$forceUpdate()
        },

        resetSelectedProducts () {
            this.selectedProductIds = []
        },

        completeProducts (productIds, reason, ticket) {
            const body = {
                ids: productIds,
                jiraIssueNumber: ticket,
                reason: reason
            }
            const errorMessage = this.$tc('administration.controlling.productCompletion.productCompleteError')
            const successMessage = {
                standard: this.$tc('administration.controlling.productCompletion.productCompleteSuccess.standard'),
                short: this.$tc('administration.controlling.productCompletion.productCompleteSuccess.short')
            }
            this.axiosPost('migration/product', body, errorMessage, successMessage)
                .then(completedProducts => {
                    completedProducts.forEach(completedProduct => {
                        const product = this.productList.find(product => product.productNumber === completedProduct.productNumber)
                        this.setCompletionInfo(product, completedProduct)
                        this.setProductInfo(product)
                    })
                    this.resetSelectedProducts()
                }).catch(() => {})
        },

        showConfirmationDialogForProductCompletion (selectSubmitEventData) {
            const reasonKey = selectSubmitEventData.option.reason
            const reason = this.$tc(`generals.productStateValues.${reasonKey}`)
            this.$swal({
                title: this.$tc('administration.controlling.productCompletion.completeProductsPopup.title'),
                html: this.$t('administration.controlling.productCompletion.completeProductsPopup.text', [this.selectedProductIds.length, reason]),
                icon: 'info',
                input: 'text',
                inputPlaceholder: this.$tc('administration.controlling.productCompletion.completeProductsPopup.inputPlaceholder'),
                showConfirmButton: true,
                confirmButtonText: this.$tc('administration.controlling.productCompletion.completeProductsPopup.confirmText'),
                showCancelButton: true,
                cancelButtonText: this.$tc('generals.cancel'),
                allowOutsideClick: false,
                allowEscapeKey: false
            }).then(result => {
                if (result.isConfirmed) {
                    if (result.value !== '') {
                        const ticket = result.value
                        this.completeProducts(this.selectedProductIds, reasonKey, ticket)
                    } else {
                        const durations = {
                            standard: 7500,
                            short: 5000
                        }
                        const messages = {
                            standard: this.$tc('administration.controlling.productCompletion.completeProductsPopup.cancelMessage.standard'),
                            short: this.$tc('administration.controlling.productCompletion.completeProductsPopup.cancelMessage.short')
                        }
                        this.configureNotification('warning', durations, messages)
                    }
                }
            })
        },

        handleColumnVisibilityChange (settings) {
            this.isSelectedColumnVisible = settings.find(c => c.key === 'selected').visible
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getTableConfig () {
            return [{
                key: 'selected',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'left',
                width: 5
            }, {
                key: 'shortTitle',
                label: this.$tc('administration.controlling.productCompletion.table.shortTitle'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 15
            }, {
                key: 'productNumber',
                label: this.$tc('administration.controlling.productCompletion.table.productNumber'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 15
            }, {
                key: 'releaseDateDisplayText',
                sortKey: 'releaseDatePlannedOrActual',
                label: this.$tc('administration.controlling.productCompletion.table.releaseDate'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 15
            }, {
                key: 'manualCompleteTimeDisplayText',
                sortKey: 'manualCompleteTime',
                label: this.$tc('administration.controlling.productCompletion.table.completionTime'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 15
            }, {
                key: 'manualCompleteTicket',
                label: this.$tc('administration.controlling.productCompletion.table.ticketNumber'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 15
            }, {
                key: 'productStateForTable',
                label: this.$tc('administration.controlling.productCompletion.table.productState'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                exportable: true,
                width: 20
            }]
        },

        getSelectedProductsActions () {
            const actionsDisabled = this.selectedProductIds.length === 0
            return [{
                label: this.$tc('generals.productStateValues.PRODUCTION_INVALID'),
                reason: 'PRODUCTION_INVALID',
                disabled: actionsDisabled
            }, {
                label: this.$tc('generals.productStateValues.PRODUCTION_CANCELLED'),
                reason: 'PRODUCTION_CANCELLED',
                disabled: actionsDisabled
            }, {
                label: this.$tc('generals.productStateValues.PRODUCTION_FINISHED'),
                reason: 'PRODUCTION_FINISHED',
                disabled: actionsDisabled
            }, {
                label: this.$tc('generals.productStateValues.PRODUCTION_FINISHED_XML_CANCELLED'),
                reason: 'PRODUCTION_FINISHED_XML_CANCELLED',
                disabled: actionsDisabled
            }]
        }
    },
    watch: {
        '$global.localization.locale': {
            handler: function () {
                this.productList.forEach(product => this.setProductInfo(product))
            },
            deep: true
        }
    },
    mounted () {
        this.getProductList()

        // Watch the length of currently visible rows, in order to unselect rows that have been filtered after selection
        this.$watch(() => this.$refs.adminProducts.getTableRows.length, () => {
            const visibleRowsIds = this.$refs.adminProducts.getTableRows.map(row => row.id)
            this.productList.forEach(product => {
                if (this.selectedProductIds.includes(product.id) && !visibleRowsIds.includes(product.id)) {
                    this.toggleSelected(product)
                }
            })
        })
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="admin-products-table-container">
            <div class="admin-products-header">
                <SmartSelect id="admin-products-actions"
                             v-bind:key="`admin-products-actions_${selectedProductIds.length}`"
                             v-bind:options="getSelectedProductsActions"
                             v-bind:option-label-specifiers="['label']"
                             v-bind:placeholder="$tc('administration.controlling.productCompletion.table.selectAction')"
                             v-bind:clear-input-on-submit="true"
                             v-bind:sort-options="false"
                             v-bind:allow-input="false"
                             v-bind:submit-button="false"
                             @select-change="showConfirmationDialogForProductCompletion($event)">
                </SmartSelect>
                <div class="admin-products-header-button-wrapper">
                    <IconButton v-bind:icon-class="areAllSelected() ? 'fas fa-check-square' : 'far fa-square'"
                                v-bind:tooltip="areAllSelected()
                                    ? this.$tc('administration.controlling.productCompletion.table.unselectAllProducts')
                                    : this.$tc('administration.controlling.productCompletion.table.selectAllProducts')"
                                v-if="this.isSelectedColumnVisible"
                                @button-submit="toggleAllSelected()">
                    </IconButton>
                </div>
            </div>
            <Table table-id="adminProducts"
                   class="admin-products-table"
                   v-bind:table-config="getTableConfig"
                   v-bind:table-data="productList"
                   v-bind:filter-placeholder="$tc('administration.controlling.productCompletion.table.filterPlaceholder')"
                   v-bind:table-empty-message="$tc('administration.controlling.productCompletion.table.tableEmpty')"
                   v-bind:filter-no-results-message="$tc('administration.controlling.productCompletion.table.filterNoResults')"
                   default-sorting-key="shortTitle"
                   v-bind:show-spinner="showSpinner"
                   v-bind:custom-user-filter="true"
                   v-bind:read-only="false"
                   @column-visibility-change="handleColumnVisibilityChange"
                   @row-click="toggleSelected($event.row)"
                   ref="adminProducts">
                <template #cell(selected)="data">
                    <IconButton v-if="!isCompleted(data.row)"
                                v-bind:icon-class="isProductSelected(data.row) ? 'fas fa-check-square' : 'far fa-square'"
                                @button-submit="toggleSelected(data.row)">
                    </IconButton>
                    <Icon v-else
                          v-bind:tooltip="$tc('generals.productStateValues.FINISHED_MANUALLY')"
                          icon-class="fas fa-user-edit"
                          icon-type="dark"
                          v-bind:icon-large="true">
                    </Icon>
                </template>
                <template #cell(productNumber)="data">
                    <RouteLink v-bind:displayed-text="data.row.productNumber"
                               v-bind:target="{name: 'Product', params: {productId: data.row.id}}"
                               v-bind:tooltip="$tc('generals.goToProduct')"
                               v-bind:is-highlighted-link=true>
                    </RouteLink>
                </template>
                <!-- shortTitle: default cell content -->
                <!-- releaseDateDisplayText: default cell content -->
                <!-- manualCompleteTimeDisplayText: default cell content -->
                <template #cell(manualCompleteTicket)="data">
                    <RouteLink v-if="data.row.manualCompleteTicket"
                               v-bind:displayed-text="data.row.manualCompleteTicket"
                               v-bind:target="`https://jira.cornelsen.de/browse/${data.row.manualCompleteTicket}`"
                               v-bind:tooltip="$tc('administration.controlling.productCompletion.table.openInJira')"
                               v-bind:is-external-link="true"
                               v-bind:is-highlighted-link=true>
                    </RouteLink>
                </template>
                <!-- productState: default cell content -->
            </Table>
        </div>
    </div>
</template>

<style lang="less">
.admin-products-table-container {
    width: 100%;
    height: 100%;
    padding: 0 var(--container-spacing);

    .admin-products-header {
        position: absolute;
        top: var(--container-spacing);
        left: var(--container-spacing);
        z-index: var(--z-index-header-button);

        ::placeholder {
            color: var(--color-text-mid);
        }

        .admin-products-header-button-wrapper {
            width: 5%;
            position: absolute;
            top: 45px;
            left: 0;
            padding-left: 11px;
        }
    }

    .admin-products-table {
        .c_table-excel-generate-button {
            position: absolute;
            left: calc(var(--input-width) + 8px);
        }
    }
}
</style>
