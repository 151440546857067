<script>
/**
 * IconButton is a button element looking like an icon. Will emit a @button-submit event on click.
 * - tooltip (required): The text displayed as tooltip
 * - iconClass (required): A font awesome icon class. If no class is provided, a question mark icon will be displayed as default.
 * - hoverIconClass (optional): A font awesome icon class. If provided, this icon will show up on hover.
 * - fontSize (default: 17px): Set the size of the icon.
 * - isDisabled (default: false): If true, the button won't be clickable.
 **/
export default {
    name: 'IconButton',
    // @button-submit: Event emitted on click | returns the event
    emits: ['button-submit'],
    props: {
        tooltip: String,
        iconClass: {
            default: 'fas fa-question-circle',
            type: String
        },
        hoverIconClass: String,
        fontSize: {
            default: 17,
            type: Number
        },
        isDisabled: {
            default: false,
            type: Boolean
        },
        text: {
            type: String,
            default: null
        }
    },
    data () {
        return {
            icon: this.iconClass
        }
    },
    methods: {
        switchToDefaultIcon () {
            this.icon = this.iconClass
        },

        switchToHoverIcon () {
            if (!this.isDisabled) {
                this.icon = this.hoverIconClass || this.iconClass
            }
        },

        emitSubmit (event) {
            event.stopPropagation()
            this.$emit('button-submit', event)
        }
    },
    watch: {
        iconClass () {
            this.switchToDefaultIcon()
        }
    }
}
</script>

<template>
    <button class="c_icon-button"
            v-bind:disabled="isDisabled"
            v-bind:class="{'m--disabled': isDisabled}"
            v-bind:style="{'font-size': `${fontSize}px`}"
            v-on:click="emitSubmit($event)"
            v-on:mouseover="switchToHoverIcon()"
            v-on:mouseleave="switchToDefaultIcon()">
        <span class="c_icon-button_text" v-if="text">{{ text }}</span>
        <span class="c_icon-button_icon"
              v-bind:class="icon"
              v-bind:title="tooltip">
        </span>
    </button>
</template>

<style lang="less">
.c_icon-button {
    font-family: "Source Sans Pro", sans-serif;
    background-color: transparent;
    color: var(--color-icon);
    height: var(--button-height);
    border: 0;
    outline: none;

    &:hover {
        cursor: pointer;
        color: var(--color-icon-active);
    }

    &.m--disabled {
        pointer-events: none;
        color: var(--color-icon-inactive);
    }

    .c_icon-button_icon {
        padding: 4px;
    }

    .c_icon-button_text {
        margin-right: 8px;
        margin-left: 8px;
    }
}
</style>
