<script>
/**
 * Controlling overview
 **/
import ContentHeader from '@/components/ContentHeader.vue'
import SubNavigation from '@/components/SubNavigation.vue'

export default {
    name: 'Controlling',
    components: {
        ContentHeader,
        SubNavigation
    },
    data () {
        return {
            subNavigationItems: [{
                id: 'models',
                label: 'administration.controlling.subNavigation.processModels',
                target: { name: 'ProcessModels' }
            }, {
                id: 'migration',
                label: 'administration.controlling.subNavigation.migrationsOverview',
                target: { name: 'MigrationsOverview' }
            }, {
                id: 'product-completion',
                label: 'administration.controlling.subNavigation.productCompletion',
                target: { name: 'ProductCompletion' }
            }, {
                id: 'conflicts',
                label: 'administration.controlling.subNavigation.conflicts',
                target: { name: 'ProductConflicts' }
            }, {
                id: 'operation',
                label: 'administration.controlling.subNavigation.operation',
                target: { name: 'Operation' }
            }, {
                id: 'testUser',
                label: 'administration.controlling.subNavigation.testUser',
                target: { name: 'CreateTestUser' }
            }]
        }
    }
}
</script>

<template>
    <div class="generals-container">
        <ContentHeader v-bind:header-title="$tc('administration.controlling.headline')"
                       v-bind:is-primary-header=true>
        </ContentHeader>
        <div class="administration-content-container">
            <SubNavigation v-bind:navigation-items="subNavigationItems"
                           default-location-id="models">
            </SubNavigation>
            <router-view class="administration-content-view"></router-view>
        </div>
    </div>
</template>

<style scoped lang="less">
.administration-content-container {
    width: 100%;
    height: calc(100% - var(--primary-headline-height));
    padding: var(--container-spacing) 0;

    .administration-content-view {
        position: relative;
        width: 100%;
        height: calc(100% - var(--sub-navigation-height));
        padding-top: var(--container-spacing);
        overflow: hidden;
    }
}
</style>
