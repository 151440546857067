<script>
/**
 * Tasks overview
 **/
import ContentHeader from '@/components/ContentHeader.vue'
import SubNavigation from '@/components/SubNavigation.vue'
import { axiosService } from '@/mixins/axiosService'

export default {
    name: 'TasksListOverview',
    mixins: [
        axiosService
    ],
    components: {
        ContentHeader,
        SubNavigation
    },
    data () {
        return {
            tasksLoaded: false, // wait until we know the amount of tasks
            tasksCount: 0,
            visibleTasksView: {
                id: 'taskListOverview.general',
                label: 'taskList.tasks',
                count: 0,
                target: { name: 'TaskList' }
            },
            invisibleTasksView: {
                id: 'tasksListOverview.hidden',
                label: 'taskList.hiddenTasks',
                count: 0,
                target: { name: 'HiddenTasksList' }
            }
        }
    },
    methods: {
        setTasksCountsBasedOnVisibleTasksCount (event) {
            this.visibleTasksView.count = event.detail
            this.invisibleTasksView.count = this.tasksCount - this.visibleTasksView.count
        },

        setTasksCountsBasedOnInvisibleTasksCount (event) {
            this.invisibleTasksView.count = event.detail
            this.visibleTasksView.count = this.tasksCount - this.invisibleTasksView.count
        }
    },
    computed: {
        getSubNavigationItems () {
            return [this.visibleTasksView, this.invisibleTasksView]
        }
    },
    beforeMount () {
        window.addEventListener('visibleTasksCountChanged', this.setTasksCountsBasedOnVisibleTasksCount)
        window.addEventListener('invisibleTasksCountChanged', this.setTasksCountsBasedOnInvisibleTasksCount)
        this.axiosGet('tasks')
            .then(taskList => {
                this.tasksCount = taskList.length
                this.tasksLoaded = true
            })
            .catch(() => {})
    },
    beforeUnmount () {
        window.removeEventListener('visibleTasksChanged', this.setTasksCountsBasedOnVisibleTasksCount)
        window.removeEventListener('invisibleTasksChanged', this.setTasksCountsBasedOnInvisibleTasksCount)
    }
}
</script>

<template>
    <div class="generals-container">
        <ContentHeader v-bind:header-title="$tc('taskList.headline')"
                       v-bind:is-primary-header=true>
        </ContentHeader>
        <div class="tasks-overview-content-container">
            <SubNavigation v-bind:navigation-items="getSubNavigationItems"
                           default-location-id="tasksListOverview.general">
            </SubNavigation>
            <router-view v-if="tasksLoaded"
                         class="tasks-overview-content-view">
            </router-view>
        </div>
    </div>
</template>

<style scoped lang="less">
.tasks-overview-content-container {
    width: 100%;
    height: calc(100% - var(--primary-headline-height));
    padding: var(--container-spacing) 0;

    .tasks-overview-content-view {
        position: relative;
        width: 100%;
        height: calc(100% - var(--sub-navigation-height));
        padding-top: var(--container-spacing);
        overflow: hidden;
    }
}
</style>
