<script>
/**
 * The User Profile shown in the main navigation of the application.
 * Not meant to be a reusable and configurable component.
 **/
import InteractionLink from '@/components/InteractionLink.vue'
import SmartFormSubheading from '@/components/SmartFormSubheading.vue'
import Checkbox from '@/components/Checkbox.vue'
import EmailInput from '@/components/EmailInput.vue'
import DataRow from '@/components/DataRow.vue'
import HelpText from '@/components/HelpText.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { environmentHandler } from '@/mixins/environmentHandler'

export default {
    name: 'UserProfile',
    mixins: [
        axiosService,
        userHandler,
        environmentHandler
    ],
    components: {
        InteractionLink,
        SmartFormSubheading,
        Checkbox,
        EmailInput,
        DataRow,
        HelpText
    },
    data () {
        return {
            username: this.getUserName(),
            showProfileBadge: true,
            isEditingContactMail: false,
            filterSettings: {
                // enum constant > related "table-id" value
                PRODUCT: 'productList',
                TASK: 'taskList',
                MIGRATION: 'processMigration',
                PRODUCT_COMPLETION: 'adminProducts'
            },
            notificationSettings: {
                REDUCED: 'reducedNotifications',
                PRODUCT: 'notificationNewProduct',
                TASK: 'notificationNewTask',
                TASK_TERMINATION: 'notificationTaskTermination',
                SKIP_PRODUCT_CONTENTS_MERGE_POPUP: 'skipProductContentsMergePopup'
            },
            helpTextWidth: 305
        }
    },
    methods: {

        calculateUserInitials () {
            const usernameParts = this.username.split('.')
            let i = 0
            const len = 2
            let initials = ''

            for (i; i < len; i += 1) {
                if (usernameParts[i]) {
                    initials += usernameParts[i].charAt(0)
                }
            }
            return initials
        },

        setProfileBadgeVisibility (visible) {
            this.showProfileBadge = visible
        },

        openReleaseNotes () {
            window.open(this.getReleaseNotesUrl, '_blank')
        },

        setIsEditingContactMail (editing) {
            this.isEditingContactMail = editing
        },

        resetUserFilter (filterProperty, value) {
            this.updateUserSettingsParameter(
                filterProperty,
                value,
                this.$tc('mainNavigation.saveUserPreferencesError'),
                {
                    standard: this.$tc('mainNavigation.resetUserFilterSuccess.standard'),
                    short: this.$tc('mainNavigation.resetUserFilterSuccess.short')
                })
        },

        getUserPreference (field) {
            const userPreferences = this.getUserSettingsParameter('userPreferences')
            return userPreferences[field]
        },

        setUserPreference (field, value) {
            const userPreferences = this.getUserSettingsParameter('userPreferences')
            userPreferences[field] = value
            this.updateUserSettingsParameter(
                'userPreferences',
                userPreferences,
                this.$tc('mainNavigation.saveUserPreferencesError'),
                {
                    standard: this.$tc('mainNavigation.saveUserPreferencesSuccess.standard'),
                    short: this.$tc('mainNavigation.saveUserPreferencesSuccess.short')
                })
        },

        updateContactMail (inputSubmitEventData) {
            this.updateContactMailInKeycloak(
                inputSubmitEventData.value,
                this.$tc('mainNavigation.saveUserPreferencesError'),
                {
                    standard: this.$tc('mainNavigation.saveUserPreferencesSuccess.standard'),
                    short: this.$tc('mainNavigation.saveUserPreferencesSuccess.short')
                })
            this.setIsEditingContactMail(false)
        }
    },
    computed: {
        isAdminUser () {
            return this.getUserRoles().includes('admin')
        },

        getVersion () {
            return this.$global.currentVersion.toString()
        },

        getReleaseNotesUrl () {
            const language = this.$global.localization.locale
            return this.$global.releaseNotesUrl[language]
        },

        getFrontendBranch () {
            const index = this.$global.currentBranches.frontend.lastIndexOf('/') + 1
            return this.$global.currentBranches.frontend.substring(index)
        },

        getBackendBranch () {
            const index = this.$global.currentBranches.backend.lastIndexOf('/') + 1
            return this.$global.currentBranches.backend.substring(index)
        },

        getEmail () {
            return this.getUserEmail()
        }
    }
}
</script>

<template>
    <div class="user-profile-wrapper">
        <div data-testid="open-user-profile" class="user-profile generals-animate"
             v-bind:class="{'m--visible': showProfileBadge}"
             v-on:click="setProfileBadgeVisibility(false)">
            <span data-testid="user-initials">{{calculateUserInitials()}}</span>
        </div>
        <div class="user-profile-details generals-animate"
             v-bind:class="{'m--visible': !showProfileBadge}"
             v-on:click="setIsEditingContactMail(false)">
            <div class="user-profile-details-heading">
                <span>{{$t('mainNavigation.salutation', [getUserFirstName()])}}</span>
                <span data-testid="close-user-profile" class="user-profile-details-close fas fa-times"
                      v-on:click="setProfileBadgeVisibility(true)">
                </span>
            </div>
            <div class="user-profile-details-body">
                <div class="user-profile-details-container">
                    <InteractionLink data-testid="release-notes"
                                     v-bind:label="`${$tc('mainNavigation.currentReleaseLink')}:`"
                                     v-bind:value="getVersion"
                                     v-bind:is-highlighted="true"
                                     v-bind:is-underlined="true"
                                     v-bind:tooltip="getReleaseNotesUrl"
                                     @input-click="openReleaseNotes()">
                    </InteractionLink>
                </div>
                <div class="user-profile-details-container">
                    <InteractionLink data-testid="username"
                                     v-bind:label="`${$tc('mainNavigation.currentUser')}:`"
                                     v-bind:value="username"
                                     v-bind:is-disabled="true">
                    </InteractionLink>
                </div>
                <div v-if="isAdminUser">
                    <SmartFormSubheading class="user-profile-details-subheading"
                                         v-bind:label="$tc('mainNavigation.currentBranches')">
                    </SmartFormSubheading>
                    <div class="user-profile-details-container">
                        <DataRow data-testid="frontend-branch"
                                 v-bind:label="`${$tc('mainNavigation.currentFrontendBranch')}:`"
                                 v-bind:value="getFrontendBranch"
                                 v-bind:label-width-percentage="52"
                                 v-bind:no-line-break="true">
                        </DataRow>
                    </div>
                    <div class="user-profile-details-container">
                        <DataRow data-testid="backend-branch"
                                 v-bind:label="`${$tc('mainNavigation.currentBackendBranch')}:`"
                                 v-bind:value="getBackendBranch"
                                 v-bind:label-width-percentage="52"
                                 v-bind:no-line-break="true">
                        </DataRow>
                    </div>
                </div>
                <SmartFormSubheading class="user-profile-details-subheading"
                                     v-bind:label="$tc('mainNavigation.filterSettings')">
                </SmartFormSubheading>
                <div class="user-profile-details-container">
                    <InteractionLink v-bind:label="`${$tc('mainNavigation.productListFilter')}:`"
                                     v-bind:value="$tc('mainNavigation.resetFilterToStandard')"
                                     v-bind:is-underlined="true"
                                     @input-click="resetUserFilter(
                                         filterSettings.PRODUCT,
                                         { userIsParticipantFilterText: [$tc('productList.table.productForMe').toLowerCase()]}
                                     )">
                    </InteractionLink>
                </div>
                <div class="user-profile-details-container">
                    <InteractionLink v-bind:label="`${$tc('mainNavigation.taskListFilter')}:`"
                                     v-bind:value="$tc('mainNavigation.resetFilterToStandard')"
                                     v-bind:is-underlined="true"
                                     @input-click="resetUserFilter(
                                       filterSettings.TASK,
                                       { isTaskExecutableFilterText: [$tc('taskList.table.tasksForMe').toLowerCase()]}
                                   )">
                    </InteractionLink>
                </div>
                <div v-if="isAdminUser"
                     class="user-profile-details-container">
                    <InteractionLink v-bind:label="`${$tc('mainNavigation.processMigrationFilter')}:`"
                                     v-bind:value="$tc('mainNavigation.resetFilterToStandard')"
                                     v-bind:is-underlined="true"
                                     @input-click="resetUserFilter(filterSettings.MIGRATION, {})">
                    </InteractionLink>
                </div>
                <div v-if="isAdminUser"
                     class="user-profile-details-container">
                    <InteractionLink v-bind:label="`${$tc('mainNavigation.productCompletionFilter')}:`"
                                     v-bind:value="$tc('mainNavigation.resetFilterToStandard')"
                                     v-bind:is-underlined="true"
                                     @input-click="resetUserFilter(filterSettings.PRODUCT_COMPLETION, {})">
                    </InteractionLink>
                </div>
                <SmartFormSubheading class="user-profile-details-subheading"
                                     v-bind:label="$tc('mainNavigation.systemSettings')">
                </SmartFormSubheading>
                <div class="user-profile-details-container">
                    <Checkbox data-testid="reduced-notifications"
                              v-bind:default-value="getUserPreference(notificationSettings.REDUCED) || false"
                              v-bind:label="`${$tc('mainNavigation.setNotificationType')}:`"
                              @input-change="setUserPreference(notificationSettings.REDUCED, $event?.value)">
                    </Checkbox>
                </div>
                <div class="user-profile-details-container">
                    <InteractionLink v-if="!isEditingContactMail"
                                     data-testid="show-contact-email"
                                     v-bind:label="`${$tc('mainNavigation.setContactMail')}:`"
                                     v-bind:value="getEmail|| username"
                                     v-bind:is-underlined="true"
                                     icon-class="fas fa-edit"
                                     @input-click="setIsEditingContactMail(true)">
                    </InteractionLink>
                    <EmailInput v-else
                                data-testid="edit-contact-email"
                                v-bind:default-value="getEmail"
                                v-bind:label="`${$tc('mainNavigation.setContactMail')}:`"
                                icon-class="fas fa-check"
                                v-bind:show-unsaved-changes="true"
                                v-bind:submit-button="true"
                                @input-blurred="updateContactMail($event)"
                                @input-submit="updateContactMail($event)">
                    </EmailInput>
                    <HelpText class="user-profile-details-help-container m--mail"
                              data-testid="help-contact-email"
                              v-bind:text="$tc('mainNavigation.setContactMailHint')"
                              v-bind:width-px="helpTextWidth"
                              position="left">
                    </HelpText>
                </div>
                <div class="user-profile-details-container">
                    <Checkbox v-bind:default-value="getUserPreference(notificationSettings.TASK_TERMINATION) || false"
                              data-testid="cancel-task-termination"
                              v-bind:label="`${$tc('mainNavigation.setCancelTaskTermination')}:`"
                              @input-change="setUserPreference(notificationSettings.TASK_TERMINATION, $event?.value)">
                    </Checkbox>
                    <HelpText class="user-profile-details-help-container"
                              data-testid="help-cancel-task-termination"
                              v-bind:text="$tc('mainNavigation.setCancelTaskTerminationHint')"
                              v-bind:width-px="helpTextWidth"
                              position="left">
                    </HelpText>
                </div>
                <div class="user-profile-details-container">
                    <Checkbox
                        v-bind:default-value="getUserPreference(notificationSettings.SKIP_PRODUCT_CONTENTS_MERGE_POPUP) || false"
                        v-bind:label="`${$tc('mainNavigation.setSkipProductContentsMergePopup')}:`"
                        @input-change="setUserPreference(notificationSettings.SKIP_PRODUCT_CONTENTS_MERGE_POPUP)">
                    </Checkbox>
                </div>
                <SmartFormSubheading class="user-profile-details-subheading"
                                     v-bind:label="$tc('mainNavigation.notificationSettings')">
                </SmartFormSubheading>
                <div class="user-profile-details-container">
                    <Checkbox v-bind:default-value="getUserPreference(notificationSettings.PRODUCT) || false"
                              data-testid="notification-product-assignment"
                              v-bind:label="`${$tc('mainNavigation.setNotificationProduct')}:`"
                              @input-change="setUserPreference(notificationSettings.PRODUCT, $event?.value)">
                    </Checkbox>
                    <HelpText class="user-profile-details-help-container"
                              data-testid="help-notification-product-assignment"
                              v-bind:text="$tc('mainNavigation.setNotificationProductHint')"
                              v-bind:width-px="helpTextWidth"
                              position="left">
                    </HelpText>
                </div>
                <div class="user-profile-details-container">
                    <Checkbox v-bind:default-value="getUserPreference(notificationSettings.TASK) || false"
                              data-testid="notification-new-task"
                              v-bind:label="`${$tc('mainNavigation.setNotificationTask')}:`"
                              @input-change="setUserPreference(notificationSettings.TASK, $event?.value)">
                    </Checkbox>
                    <HelpText class="user-profile-details-help-container"
                              data-testid="help-notification-new-task"
                              v-bind:text="$tc('mainNavigation.setNotificationTaskHint')"
                              v-bind:width-px="helpTextWidth"
                              position="left">
                    </HelpText>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.user-profile-wrapper {
    .user-profile {
        position: absolute;
        right: -500px;
        height: var(--main-navigation-line-height);
        width: var(--main-navigation-line-height);
        border-radius: 50%;
        border: 2px solid var(--color-border-dark);
        color: var(--color-text-mid);
        font-size: 24px;
        text-transform: uppercase;
        padding-top: 7px;
        text-align: center;
        cursor: pointer;
        margin-top: 2px;

        &:hover {
            color: var(--color-link);
            border-color: var(--color-border-active);
        }

        &.m--visible {
            right: 16px;
        }
    }

    .user-profile-details {
        position: absolute;
        top: var(--container-spacing);
        right: -1000px;
        width: auto;
        height: auto;
        background-color: var(--color-background-default);
        border: 1px solid var(--color-border-light);
        z-index: var(--z-index-main-navigation);
        box-shadow: 0 5px 10px 0 var(--color-border-dark);

        &.m--visible {
            right: var(--container-spacing);
        }

        .user-profile-details-heading {
            position: relative;
            height: var(--main-navigation-line-height);
            padding: 14px 35px 14px 14px;
            font-family: "Source Sans Pro Bold", sans-serif;
            background-color: var(--color-background-light);

            .user-profile-details-close {
                position: absolute;
                cursor: pointer;
                font-size: 14px;
                right: 16px;
                top: 18px;

                &:hover {
                    color: var(--color-icon-active);
                }
            }
        }

        .user-profile-details-body {
            position: relative;
            max-height: 80vh;
            width: 645px;
            cursor: default;
            padding: calc(var(--container-spacing) / 2) 0 var(--container-spacing) 0;
            overflow-x: hidden;
            overflow-y: auto;

            .user-profile-details-subheading {
                margin: 30px 14px 10px 14px;
            }

            .user-profile-details-container {
                position: relative;
                width: 100%;
                height: var(--input-height);
                padding: 0 14px;

                .generals-input-label {
                    width: 300px;
                }

                .c_data-row-wrapper {
                    border: 0;

                    .c_data-row-label,
                    .c_data-row-value {
                        padding: 13px 0;
                    }
                }

                .user-profile-details-help-container {
                    position: absolute;
                    right: 35px;
                    bottom: 28px;

                    &.m--mail {
                        bottom: 26px;
                    }
                }
            }
        }
    }
}
</style>
