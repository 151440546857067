<script>
/**
 * Product information sub view | Sub view of a Product
 *
 **/
import ProductDetails from '@/views/products/product-portfolio/ProductDetails.vue'
import ProductMembers from '@/views/products/product-portfolio/ProductMembers.vue'

export default {
    name: 'ProductInformation',
    components: {
        ProductDetails,
        ProductMembers
    },
    data () {
        return {
            productInfo: this.$attrs.productInfo
        }
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="product-information-container">
            <div class="product-details-wrapper">
                <ProductDetails v-bind:product-id="productInfo.id"
                                v-bind:product-number="productInfo.productNumber">
                </ProductDetails>
            </div>
            <div class="product-members-wrapper">
                <ProductMembers v-bind:product-id="productInfo.id"></ProductMembers>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.product-information-container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .product-details-wrapper {
        position: relative;
        float: left;
        width: var(--product-information-details-width);
        height: 100%;
        padding-right: var(--container-spacing);
        overflow: hidden;
    }

    .product-members-wrapper {
        position: relative;
        float: left;
        width: calc(100% - var(--product-information-details-width));
        height: 100%;
        padding-left: var(--container-spacing);
        overflow: hidden;
        border-left: 1px solid var(--color-border-light);
    }
}
</style>
