import _ from 'lodash'

export const customFormKeyHandler = {
    methods: {
        /**
         * Extracts the component key from the given customFormKey String. It is the first part of the customFormKey
         * returned from the backend.
         *
         * @param {string} customFormKey the identifier of the custom form returned from the backend.
         *                               A string in this format: "componentKey|parameter1|parameter2|..."
         * @returns {string} the component key
         */
        componentKeyBy (customFormKey) {
            if (!customFormKey) {
                return 'Default'
            } else {
                const customFormKeyParts = customFormKey.split('|')
                return customFormKeyParts[0]
            }
        },
        /**
         * Extracts the parameters from the given customFormKey String. They are the part of the customFormKey returned
         * from the backend - everything after the first part.
         *
         * @param {string} customFormKey the identifier of the custom form returned from the backend,
         *                               a string in this format: "componentKey|parameter1|parameter2|..."
         * @returns {string[]} the parameters
         */
        parametersFrom (customFormKey) {
            if (!customFormKey) {
                return []
            } else {
                const customFormKeyParts = customFormKey.split('|')
                return customFormKeyParts.slice(1)
            }
        },
        /**
         * returns a map of the given parameter names and values
         *
         * @param {string[]} parameterNames the names of the parameters
         * @param {string[]} parameterValues the values of the parameters
         *
         * @returns {Object} the parameters as key-value pairs. Values that are undefined are omitted.
         */
        asMap (parameterNames, parameterValues) {
            const mapWithUndefinedValues = _.zipObject(parameterNames, parameterValues)
            return _.pickBy(mapWithUndefinedValues, (value) => value !== undefined)
        }
    }
}
