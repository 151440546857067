<script>
/**
 * Task view
 *
 * @property {String} formJson
 * @property {String} reihenausgabeSchluessel
 **/
import EssentialSmartForm from '@/components/EssentialSmartForm.vue'
import { axiosService } from '@/mixins/axiosService'
import { taskExecutionHandler } from '@/mixins/taskExecutionHandler'
import { smartFormHelper } from '@/mixins/smartFormHelper'

export default {
    name: 'CustomTaskCommissioning',
    mixins: [
        axiosService,
        taskExecutionHandler,
        smartFormHelper
    ],
    components: {
        EssentialSmartForm
    },
    props: {
        taskData: Object
    },
    // @task-completable-changed: Event emitted when the task-completable state changed
    emits: ['task-completable-changed'],
    data () {
        return {
            task: this.taskData,
            customForm: null,
            contractsDashboardUrl: 'https://app.powerbi.com/reportEmbed?reportId=20093559-26e3-4e22-9074-cfc79cf59060&pageName=ReportSectionb15cf1a1bd63311fcb6b&autoAuth=true&ctid=0be0d70f-f404-4497-9fa7-3a7b7c98630d&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLW5vcnRoLWV1cm9wZS1nLXByaW1hcnktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D&filter=Produkt%2FReihenschl_x00FC_ssel%20eq%20',
            editorsSelectField: 'upload_images_to_product_storage',
            editorsSelectOptions: [],
            formType: this.taskData.formKey,
            formIsValid: false,
            formWasSavedSuccessfully: true,
            config: {
                PhotographersForm: {
                    formKey: 'PHOTOGRAPHER',
                    fieldId: 'Photographers_with_series_contract',
                    getErrorMessage: 'task.taskViews.photographers.getPhotographersFormError',
                    updateErrorMessage: 'task.taskViews.photographers.updatePhotographersFormError'

                },
                IllustratorsForm: {
                    formKey: 'ILLUSTRATOR',
                    fieldId: 'illustrators_with_series_contract',
                    getErrorMessage: 'task.taskViews.illustrators.getIllustratorsFormError',
                    updateErrorMessage: 'task.taskViews.illustrators.updateIllustratorsFormError'
                }
            }
        }
    },
    methods: {
        async getCustomForm () {
            async function getProductEditors (appContext) {
                return appContext.axiosGet(`roles/${appContext.task.productId}`)
                    .then(productRoles => {
                        const internalEditors = productRoles.find(role => role.sapId === 'ZP01')
                        const externalEditors = productRoles.find(role => role.sapId === 'ZP03')
                        return internalEditors.participants
                            .concat(externalEditors.participants)
                            .map(editor => `${editor.firstName} ${editor.lastName}`)
                    })
                    .catch(() => {})
            }
            async function getAllEditors (appContext) {
                return appContext.axiosGet(`participants/editorNames/${appContext.task.productId}/sorted`)
                    .then(allEditors => {
                        return allEditors.map(editor => {
                            return { label: editor, value: editor }
                        })
                    })
                    .catch(() => {})
            }
            async function getSeriesEditionKey (appContext) {
                return appContext.axiosPost(
                    'product/lookup',
                    {
                        productNumber: appContext.task.productNumber.replaceAll('-', ''),
                        properties: ['reihenausgabeSchluessel']
                    })
                    .then(productMetadata => Promise.resolve(productMetadata?.metadata?.reihenausgabeSchluessel))
                    .catch(() => Promise.resolve(null))
            }

            const formTypeConfig = this.config[this.formType]
            const productEditorNames = await getProductEditors(this)
            this.editorsSelectOptions = await getAllEditors(this)
            const seriesEditionsKey = await getSeriesEditionKey(this)
            const form = this.loadFormConfiguration(formTypeConfig.formKey)

            this.axiosGet(
                `forms/${this.formType}/${this.task.productNumber}`,
                this.$tc(formTypeConfig.getErrorMessage))
                .then(formData => {
                    function applyEditorOptions (field, appContext) {
                        if (field.id === appContext.editorsSelectField && appContext.editorsSelectOptions.length > 0) {
                            field.options = appContext.editorsSelectOptions
                        }
                    }

                    if (!formData.values.editor) {
                        formData.values.editor = productEditorNames.join(', ')
                    }
                    const sectionId = this.getSectionIndexByFieldId(form, formTypeConfig.fieldId)
                    form.sections[sectionId].defaultValues[formTypeConfig.fieldId] = `${this.contractsDashboardUrl}'${seriesEditionsKey}'`
                    this.applyValuesToFormConfigurationAndReturnChanges(null, formData.values, true, form, applyEditorOptions, this)
                    this.customForm = form
                })
                .catch(() => {})
        },

        setFormValid (isValid) {
            this.formIsValid = isValid
        },

        saveForm (formSubmitEventData) {
            return this.saveSmartForm(
                this.formType,
                this.task.productNumber,
                this.customForm,
                formSubmitEventData.changes,
                this.$tc(this.config[this.formType].updateErrorMessage))
                .then(() => {
                    this.formWasSavedSuccessfully = true
                    return Promise.resolve(true)
                })
                .catch(() => {
                    this.formWasSavedSuccessfully = false
                    return Promise.reject(new Error())
                })
        }
    },
    computed: {
        isTaskCompletable () {
            return this.formIsValid && this.formWasSavedSuccessfully
        }
    },
    watch: {
        isTaskCompletable (isCompletable) {
            this.$emit('task-completable-changed', {
                isCompletable: isCompletable
            })
        }
    },
    mounted () {
        this.getCustomForm()
    }
}
</script>

<template>
    <div class="custom-task-content-container">
        <EssentialSmartForm v-if="customForm"
                            id="customForm"
                            v-bind:hide-submit-button="true"
                            v-bind:form-config="customForm"
                            @form-change="saveForm($event)"
                            @form-submittable-change="setFormValid($event)">
        </EssentialSmartForm>
    </div>
</template>

<style scoped lang="less">
.custom-task-content-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
</style>
