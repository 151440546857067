<script>
/**
 * Checklist is a custom-made list of checkboxes with an optional additional input to enter a different option.
 * It will throw a @checklist-change event when checking or unchecking options or after submitting the additional input.
 * Available properties are:
 * - label (optional): If provided, there will be a label next to the input field.
 * - options (required): The list of options. Options should contain a label and value:
 *      - id: Identifier of the checkbox.
 *      - label: The displayed text next to the checkbox.
 *      - value: The value of the checkbox.
 *      - disabled: If true, the checkbox can't be checked or unchecked.
 *      - checked: True or false
 * - defaultValues: List of 'options.value' plus an optional 'customOption.value'
 * - singleChoice (default: false): If true, only a single checkbox option can be selected.
 * - allowCustomOption (default: false): If true, there will be an additional checkbox that enables a text input, allowing the user to apply a different value.
 * - isDisabled (default: false): If true, the user can't change any values.
 **/
import TextInput from '@/components/TextInput.vue'
import _ from 'lodash'

export default {
    name: 'Checklist',
    components: {
        TextInput
    },
    // @checklist-change: Event emitted on change | returns the event and selected options
    emits: ['checklist-change'],
    props: {
        label: String,
        options: Array,
        defaultValues: {
            default: Array,
            type: [Array, String, Number]
        },
        singleChoice: {
            default: false,
            type: Boolean
        },
        allowCustomOption: {
            default: false,
            type: Boolean
        },
        isDisabled: {
            default: false,
            type: Boolean
        }
    },
    data () {
        return {
            // keep state handling inside this component - don't involve the surrounding component in everything
            // (the surrounding component uses the options prop instead)
            checkListOptions: [],
            checkedDefaults: !this.defaultValues ? [] : (Array.isArray(this.defaultValues) ? this.defaultValues : [this.defaultValues]),
            customOption: {
                checked: false,
                value: ''
            },
            inputValue: ''
        }
    },
    methods: {
        isOptionDisabled (option) {
            if (!this.singleChoice) {
                return this.isDisabled || option.disabled
            }
            const hasSelectedOption = this.isOptionChecked(this.customOption) ||
                this.checkListOptions.some(_option => this.isOptionChecked(_option))

            return this.isDisabled ||
                option.disabled ||
                (hasSelectedOption && !this.isOptionChecked(option))
        },

        isOptionChecked (option) {
            return option.checked === true
        },

        setCustomValue (inputChangeEventData) {
            this.customOption.value = inputChangeEventData.value
            this.emitChecklistChanged()
        },

        emitChecklistChanged () {
            const checkOptions = this.checkListOptions.filter(option => this.isOptionChecked(option))
            const valueList = checkOptions.map(option => option.value)
            if (this.isOptionChecked(this.customOption) && this.customOption.value !== '') {
                valueList.push(this.customOption.value)
            }
            this.$emit('checklist-change', {
                value: valueList
            })
            this.$forceUpdate()
        },

        setupOptions () {
            this.checkListOptions = _.cloneDeep(this.options)
            this.checkListOptions.forEach(option => {
                option.checked = this.checkedDefaults.includes(option.value)
            })
            const customValue = this.checkedDefaults.find(value => {
                return !this.options.some(option => option.value === value)
            })

            this.customOption.checked = !!customValue
            if (this.customOption.value === '' && customValue) {
                this.customOption.value = customValue
            }
        }
    },
    watch: {
        options: {
            handler: function () {
                // detecting a change in the single values of the options array is used as workaround
                // as vueJS does not recognize array changes well and calls the handler function much to often
                const optionValues = this.options.map(option => option.value)
                const checkListOptionValues = this.checkListOptions.map(option => option.value)

                if (optionValues.toString() !== checkListOptionValues.toString()) {
                    console.log('Checklist: options actually changed')
                    this.setupOptions()
                }
            },
            deep: true
        },
        defaultValues: {
            handler: function () {
                // detecting a change in the single values of the default values array is used as workaround
                // as vueJS does not recognize array changes well and calls the handler function much to often
                if (this.defaultValues.toString() !== this.checkedDefaults.toString()) {
                    this.checkedDefaults = this.defaultValues
                    this.setupOptions()
                }
            },
            deep: true
        }
    },
    beforeMount () {
        this.setupOptions()
    }
}
</script>

<template>
    <div class="c_checklist-wrapper generals-input-wrapper">
        <label v-if="label"
               class="c_checklist-label generals-input-label">
            <span>{{label}}</span>
        </label>
        <div class="c_checklist-container generals-input-container">
            <div class="c_checklist-options-container">
                <div v-for="option in checkListOptions"
                     v-bind:key="option.id || option.label"
                     class="c_checklist-option">
                    <div class="c_checklist-option-checkbox generals-checkbox"
                         v-bind:class="{'m--disabled': isOptionDisabled(option)}">
                        <label>
                            <input type="checkbox"
                                   v-bind:disabled="isOptionDisabled(option)"
                                   v-bind:checked="isOptionChecked(option)"
                                   v-on:click.stop
                                   v-on:change="emitChecklistChanged()"
                                   v-model="option.checked" />
                            <span></span>
                        </label>
                    </div>
                    <div class="c_checklist-option-label">{{option.label}}</div>
                </div>
                <div v-if="allowCustomOption"
                     class="c_checklist-option">
                    <div class="c_checklist-option-checkbox generals-checkbox"
                         v-bind:class="{'m--disabled': isOptionDisabled(customOption)}">
                        <label>
                            <input type="checkbox"
                                   v-bind:disabled=isOptionDisabled(customOption)
                                   v-bind:checked=isOptionChecked(customOption)
                                   v-on:click.stop
                                   v-on:change="emitChecklistChanged()"
                                   v-model="customOption.checked" />
                            <span></span>
                        </label>
                    </div>
                    <div class="c_checklist-option-label">{{$tc('checkList.customOptionLabel')}}</div>
                </div>
                <TextInput v-if="allowCustomOption"
                           v-bind:is-disabled="!isOptionChecked(customOption) || isOptionDisabled(customOption)"
                           v-bind:submit-button=false
                           v-bind:show-unsaved-changes=true
                           v-bind:default-value="customOption.value"
                           icon-class="fas fa-pen"
                           class="c_checklist-custom-input"
                           @input-blurred="setCustomValue($event)"
                           @input-submit="setCustomValue($event)">
                </TextInput>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.c_checklist-wrapper {

    .c_checklist-label {
    }

    .generals-input-container.c_checklist-container {
        padding-top: 12px;
        height: auto;
        min-height: var(--input-height);

        .c_checklist-options-container {
            position: relative;
            width: var(--input-width);

            .c_checklist-option {
                width: 100%;
                display: flex;
                min-height: var(--checkbox-height);

                &:not(:first-child) {
                    margin-top: 5px;
                }

                .generals-checkbox.c_checklist-option-checkbox {
                    width: var(--checkbox-width);
                    float: left;
                }

                .c_checklist-option-label {
                    width: calc(100% - var(--checkbox-width));
                    margin-top: -1px;
                    float: left;
                    padding-left: 8px;
                }
            }

            .c_checklist-custom-input {
                margin-top: 5px;
            }
        }
    }
}
</style>
