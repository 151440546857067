<script>
/**
 * Product process history sub view | Sub view of a Product
 *
 * @property {Object} dataObjects
 * @property {String} documentReferenceId
 * @property {Number} correctionRound
 * @property {Number} rejectionRound
 **/
import Table from '@/components/Table.vue'
import Icon from '@/components/Icon.vue'
import IconButton from '@/components/IconButton.vue'
import InteractionLink from '@/components/InteractionLink.vue'
import { axiosService } from '@/mixins/axiosService'
import { userHandler } from '@/mixins/userHandler'
import { downloadHandler } from '@/mixins/downloadHandler'
import { regexHelper } from '@/mixins/regexHelper'
import { copyHandler } from '@/mixins/copyHandler'
import { dateTimeHelper } from '@/mixins/dateTimeHelper'

export default {
    name: 'ProductProcessHistory',
    mixins: [
        axiosService,
        userHandler,
        downloadHandler,
        regexHelper,
        copyHandler,
        dateTimeHelper
    ],
    components: {
        Table,
        Icon,
        IconButton,
        InteractionLink
    },
    props: {
        productId: [String, Number]
    },
    data () {
        return {
            productInfo: this.$attrs.productInfo || { id: this.productId },
            processHistory: [],
            historyEntryDataObjectTypes: {
                DOCUMENT: {
                    key: 'DOCUMENT',
                    label: this.$tc('product.processHistory.historyEntryDataObjectTypes.document'),
                    icon: 'fas fa-file-contract'
                },
                CUSTOM_FORM_DOCUMENT: {
                    key: 'CUSTOM_FORM_DOCUMENT',
                    label: this.$tc('product.processHistory.historyEntryDataObjectTypes.custom_form_document'),
                    icon: 'fas fa-file-contract'
                },
                HYPERLINK: {
                    key: 'HYPERLINK',
                    label: this.$tc('product.processHistory.historyEntryDataObjectTypes.hyperlink'),
                    icon: 'fas fa-external-link-alt'
                },
                TEXT: {
                    key: 'TEXT',
                    label: this.$tc('product.processHistory.historyEntryDataObjectTypes.text'),
                    icon: 'fas fa-pen'
                }
            }
        }
    },
    methods: {
        getHistory () {
            this.axiosGet(
                `processHistory/listByProductId?productId=${this.productInfo.id}`,
                this.$tc('product.processHistory.getProcessHistoryError'))
                .then(processHistory => {
                    processHistory.forEach(entry => {
                        entry.dataObjects.forEach(dataObject => {
                            if (this.dataObjectIsLink(dataObject)) {
                                if (!this.isValidUrl(dataObject.value)) {
                                    dataObject.value = `https://${dataObject.value}`
                                }
                            }
                        })
                        entry.createdAtDisplayText = this.getFormattedLongDate(entry.createdAt, this.locale)
                    })
                    this.processHistory = processHistory
                })
                .catch((error) => {
                    console.error(error)
                })
        },

        isDownloadAllButtonDisabled (historyEntry) {
            return this.getEntryDocuments(historyEntry).length === 0 || historyEntry.isDownloading
        },

        getDownloadAllButtonIcon (historyEntry) {
            return historyEntry.isDownloading
                ? 'fas fa-circle-notch fa-spin'
                : 'fas fa-download'
        },

        downloadFiles (historyEntry) {
            if (!historyEntry.dataObjects || !Array.isArray(historyEntry.dataObjects)) {
                console.error('Download not possible, history entry does not possess data objects')
                return
            }
            if (historyEntry.dataObjects.every(dataObject => this.dataObjectIsDocument(dataObject))) {
                this.downloadDocumentFiles(historyEntry)
            } else if (historyEntry.dataObjects.every(dataObject => this.dataObjectIsCustomFormDocument(dataObject))) {
                this.downloadCustomFormFiles(historyEntry)
            } else {
                console.error('Download not possible, unknown data object type')
            }
        },

        downloadDocumentFiles (historyEntry) {
            if (!historyEntry.isDownloading) {
                historyEntry.isDownloading = true
                this.$forceUpdate()
                const taskName = historyEntry.label.replaceAll(' ', '_')
                const zipFilename = `${taskName}-Material.zip`
                const documentIds = this.getEntryDocuments(historyEntry).map(documentEntry => documentEntry.documentReferenceId)
                this.downloadFileFromUrl(
                    `dataexchange/document/downloadMultipleFiles/${documentIds}?filename=${zipFilename}`,
                    false,
                    zipFilename)
                    .then(() => {
                        historyEntry.isDownloading = false
                        this.$forceUpdate()
                    })
                    .catch(() => {
                        historyEntry.isDownloading = false
                        this.$forceUpdate()
                    })
            }
        },

        downloadCustomFormFiles (historyEntry) {
            if (historyEntry.isDownloading) {
                return
            }
            historyEntry.isDownloading = true
            const taskName = historyEntry.label.replaceAll(' ', '_')
            const zipFilename = `${taskName}-Material.zip`
            const productFileReferenceIds = historyEntry.dataObjects.map(dataObject => {
                const num = dataObject.value.replace('product/file/', '')
                return parseInt(num)
            })
            this.$forceUpdate()
            this.downloadAllFilesFromUrl(
                this.productId, productFileReferenceIds,
                false,
                zipFilename
            ).then(() => {
                historyEntry.isDownloading = false
                this.$forceUpdate()
            })
        },

        getCorrectionRoundTooltip (historyEntry) {
            if (historyEntry.rejectionRound !== null) {
                return historyEntry.rejectionRound !== 0
                    ? this.$t('product.processHistory.correctionRoundWithRejection', [historyEntry.correctionRound, historyEntry.rejectionRound])
                    : this.$t('product.processHistory.correctionRoundZeroRejection', [historyEntry.correctionRound])
            } else {
                return this.$tc('product.processHistory.correctionRoundOrRejectionRound')
            }
        },

        getHistoryEntryDataObjectType (dataObject) {
            return this.historyEntryDataObjectTypes[dataObject.type]
        },

        getDataObjectTypeText (dataObject) {
            return this.getHistoryEntryDataObjectType(dataObject)?.label ||
                   this.$tc('product.processHistory.historyEntryDataObjectTypes.unknown')
        },

        getHistoryEntryIcon (dataObject) {
            return this.getHistoryEntryDataObjectType(dataObject)?.icon ||
                   'fas fa-question-circle'
        },

        getEntryDocuments (historyEntry) {
            return historyEntry.dataObjects.filter(dataObject => this.hasDownloadables(dataObject))
        },

        dataObjectIsDocument (dataObject) {
            return dataObject.type === this.historyEntryDataObjectTypes.DOCUMENT.key
        },

        hasDownloadables (dataObject) {
            return this.dataObjectIsDocument(dataObject) ||
            (this.dataObjectIsCustomFormDocument(dataObject) && (dataObject.value ?? '').startsWith('product/file/'))
        },

        dataObjectIsCustomFormDocument (dataObject) {
            return dataObject.type === this.historyEntryDataObjectTypes.CUSTOM_FORM_DOCUMENT.key
        },

        dataObjectIsLink (dataObject) {
            return dataObject.type === this.historyEntryDataObjectTypes.HYPERLINK.key
        },

        dataObjectIsText (dataObject) {
            return dataObject.type === this.historyEntryDataObjectTypes.TEXT.key
        },

        getAttachmentLabel (dataObject) {
            const key = dataObject?.fieldId || this.getHistoryEntryDataObjectType(dataObject)?.key || 'UNKNOWN'
            return this.$tc(`product.processHistory.expandableContent.${key}`)
        },

        getAttachmentName (dataObject) {
            return dataObject.label || dataObject.value
        },

        copyAttachmentHandler (historyEntry, dataObject) {
            this.dataObjectIsLink(dataObject)
                ? this.copyValueToClipboard(dataObject.value, true)
                : this.attachmentActionHandler(historyEntry, dataObject)
        },

        attachmentActionHandler (historyEntry, dataObject) {
            if (this.dataObjectIsDocument(dataObject)) {
                this.downloadFileFromUrl(
                    dataObject.value,
                    false,
                    this.getAttachmentName(dataObject)
                )
            } else if (this.dataObjectIsCustomFormDocument(dataObject)) {
                this.downloadFileFromUrl(
                    dataObject.value,
                    false,
                    this.getAttachmentName(dataObject)
                )
            } else if (this.dataObjectIsLink(dataObject)) {
                window.open(dataObject.value, '_blank')
            }
        }
    },
    computed: {
        locale () {
            return this.$global.localization.locale
        },

        getTableConfig () {
            return [{
                key: 'label',
                label: this.$tc('product.processHistory.table.taskName'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 20
            }, {
                key: 'productContent',
                label: this.$tc('product.processHistory.table.contentName'),
                filterable: true,
                sortable: true,
                breakAnywhere: true,
                alignment: 'left',
                width: 15
            }, {
                key: 'createdAtDisplayText',
                sortKey: 'createdAt',
                label: this.$tc('product.processHistory.table.createdAt'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 15
            }, {
                key: 'username',
                label: this.$tc('product.processHistory.table.userName'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 20
            }, {
                key: 'annotation',
                label: this.$tc('product.processHistory.table.annotation'),
                filterable: true,
                sortable: true,
                alignment: 'left',
                width: 15
            }, {
                key: 'action',
                label: null,
                filterable: false,
                sortable: false,
                alignment: 'right',
                width: 5
            }]
        }
    },
    mounted () {
        this.getHistory()
    }
}
</script>

<template>
    <div class="generals-container">
        <div class="product-process-history-container">
            <div class="product-process-history-table-container">
                <Table table-id="productHistory"
                       v-bind:table-config="getTableConfig"
                       v-bind:table-data="processHistory"
                       v-bind:read-only=true
                       default-sorting-key="createdAtDisplayText"
                       v-bind:table-empty-message="$tc('product.processHistory.table.tableEmpty')"
                       v-bind:filter-no-results-message="$tc('product.processHistory.table.filterNoResults')"
                       v-bind:filter-placeholder="$tc('product.processHistory.table.filterPlaceholder')"
                       v-bind:allow-fullscreen="true"
                       v-bind:expandable-rows="true">
                    <!-- label: default cell content -->
                    <!-- productContent: default cell content -->
                    <template #cell(correctionRound)="data">
                        <div v-bind:title="getCorrectionRoundTooltip(data.row)">
                            <span>{{data.row.correctionRound}}</span>
                            <span v-if="data.row.rejectionRound !== null "> ({{data.row.rejectionRound}})</span>
                        </div>
                    </template>
                    <!-- createdAtDisplayText: default cell content -->
                    <!-- username: default cell content -->
                    <template #cell(annotation)="data">
                        <div v-dompurify-html="data.row.annotation"></div>
                    </template>
                    <template #cell(action)="data">
                        <IconButton v-bind:key="`key_${data.row.isDownloading}`"
                                    v-bind:is-disabled="isDownloadAllButtonDisabled(data.row)"
                                    v-bind:icon-class="getDownloadAllButtonIcon(data.row)"
                                    v-bind:tooltip="$tc('product.processHistory.table.downloadFiles')"
                                    @button-submit="downloadFiles(data.row)">
                        </IconButton>
                    </template>
                    <template #expandable-content="expandData">
                        <div class="product-process-history-table-expanded-content">
                            <div class="product-process-history-metadata-container">
                                <div v-if="expandData.row.dataObjects.length === 0"
                                     class="product-process-history-metadata-item m--placeholder">
                                    <span>{{$tc('product.processHistory.table.noDataProvided')}}</span>
                                </div>
                                <template v-else v-for="(dataObject, index) in expandData.row.dataObjects" v-bind:key="index">
                                    <div class="product-process-history-metadata-item">
                                        <Icon class="product-process-history-metadata-item-icon"
                                              v-bind:icon-large="true"
                                              v-bind:tooltip="getDataObjectTypeText(dataObject)"
                                              v-bind:icon-class="getHistoryEntryIcon(dataObject)">
                                        </Icon>
                                        <InteractionLink class="product-process-history-metadata-item-input generals-table-input "
                                                         v-bind:is-highlighted="!dataObjectIsText(dataObject)"
                                                         v-bind:is-disabled="dataObjectIsText(dataObject)"
                                                         v-bind:label="getAttachmentLabel(dataObject)"
                                                         v-bind:value="getAttachmentName(dataObject)"
                                                         v-bind:icon-class="dataObjectIsLink(dataObject) ? 'fas fa-external-link-alt' : 'fas fa-download'"
                                                         v-bind:ctrl-icon-class="dataObjectIsLink(dataObject) ? 'fas fa-copy' : null"
                                                         @ctrl-input-click="copyAttachmentHandler(expandData.row, dataObject)"
                                                         @input-click="attachmentActionHandler(expandData.row, dataObject)">
                                        </InteractionLink>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                </Table>
            </div>
        </div>
    </div>
</template>

<style lang="less">
.product-process-history-container {
    position: relative;
    width: 100%;
    height: 100%;

    .product-process-history-table-container {
        position: relative;
        width: 100%;
        height: 100%;

        .product-process-history-table-expanded-content {
            position: relative;
            width: 100%;

            .product-process-history-metadata-container {
                width: 100%;

                .product-process-history-metadata-item {
                    min-height: var(--data-row-height);
                    padding: 6px 12px;

                    &.m--placeholder {
                        padding: 15px 0 0 20px;
                    }

                    .product-process-history-metadata-item-icon {
                        float: left;
                        text-align: left;
                        padding-top: 4px;
                        margin-right: var(--container-spacing);
                    }

                    .product-process-history-metadata-item-input {

                        .generals-input-container {
                            width: auto;
                        }
                    }
                }
            }
        }
    }
}
</style>
