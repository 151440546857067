/**
 * A reusable service for handling basic functions.
 **/

export const basicHelper = {
    methods: {
        // Function that provides expected behaviour of $nextTick()
        vueNextTick (callback) {
            this.$nextTick().then(() => { // Wait for the new content to be rendered
                /* The requestAnimationFrame() method tells the browser that you wish to perform an animation and requests that the browser call a specified function to update an animation before the next repaint.
                 * https://www.w3schools.com/jsref/met_win_requestanimationframe.asp
                 * For some reason you need to double requestAnimationFrame to make sure repaint is finished.
                 * https://github.com/vuejs/vue/issues/9200#issuecomment-468512304
                 */
                window.requestAnimationFrame(() => {
                    window.requestAnimationFrame(() => callback())
                })
            })
        }
    }
}
